import React, { useEffect } from 'react';
import { Text, Group, Rect, Line, Circle } from 'react-konva';
import LinkIndicator from '../LinkIndicator';
import Ring from './Ring';
import _ from 'lodash';

import { startWordSearchEngine, startNumberSearchEngine } from 'api/studio/manuscripts';
import { sliceIntoChunks, extractCellsContent, WORDSEARCH_DESIGN } from 'settings/puzzels';

const DUMMY = {
  grid_str:
    '                                                                                                                            ',
  solution_str:
    '                                                                                                                            ',

  solution_directions: {},
};

const TGridWordSearch = ({
  type,
  shapeProps,
  setTextareaProps,
  isEditMode,
  setIsEditMode,
  setTextareaValue,
  isSelected,
  scale,
  setSelectedShapeId,
  stageWidth,
  stageHeight,
  bleedingTop,
  bleedingLeft,
  onSelect,
  onDragStart,
  onDragMove,
  onDragEnd,
  onChange,
  hasRings = false,
  trForceUpdate,
}) => {
  const shapeRef = React.useRef();
  const [dummy, setDummy] = React.useState(DUMMY); // for word-search
  const [grid, setGrid] = React.useState([]);
  const [initText] = React.useState(shapeProps.text);
  const nRows = React.useCallback(
    () =>
      shapeProps.staticLink.grid ? shapeProps.staticLink.grid.size[0] : shapeProps.staticLink.rows,
    [shapeProps.staticLink.grid, shapeProps.staticLink.rows]
  );

  const nCols = React.useCallback(
    () =>
      shapeProps.staticLink.grid ? shapeProps.staticLink.grid.size[1] : shapeProps.staticLink.cols,
    [shapeProps.staticLink.grid, shapeProps.staticLink.cols]
  );

  const radius = (shapeProps.width + shapeProps.width / nCols()) / 2;
  const puzzleShape = shapeProps.staticLink.puzzleShape?.toLowerCase() ?? null;

  React.useEffect(() => {
    const t = typeof shapeProps.text === 'object' ? shapeProps.text.grid_str : shapeProps.text;
    const cells = extractCellsContent(t || initText);

    const rows = sliceIntoChunks(cells, nCols());
    const grid = rows.map((row, rowIndex) => {
      return row.map((cell, colIndex) => {
        return {
          text: cell,
          x: colIndex * (shapeProps.width / nCols()),
          y: rowIndex * (shapeProps.height / nRows()),
        };
      });
    });

    // for word-search solution with rings
    if (hasRings && typeof shapeProps.text === 'object') {
      setDummy(shapeProps.text); // shapeProps.text => here is an object with solution_str, grid_str, solution_directions
    }

    setGrid(grid);
  }, [
    shapeProps.height,
    shapeProps.width,
    shapeProps.staticLink.rows,
    shapeProps.staticLink.cols,
    shapeProps.fontSize,
    shapeProps.text,
    shapeProps.staticLink.grid,
  ]);

  const loadDummyPuzzle = _.debounce(() => {
    if (!isSelected) return;

    const design = WORDSEARCH_DESIGN[puzzleShape];

    if (!design) return;
    const { min } = design;
    const staticLink = { ...shapeProps.staticLink };

    // set rows and cols to min value if shape changed
    if (shapeProps.staticLink.rows < min) staticLink.rows = min;
    if (shapeProps.staticLink.cols < min) staticLink.cols = min;
    const fontSize = Math.max(
      Math.min(Math.round(shapeProps.height / nRows()) - 2, shapeProps.fontSize),
      6
    );

    const startSearchEngine =
      type === 'word-search' ? startWordSearchEngine : startNumberSearchEngine;
    // generate puzzle from api and set dummy
    if (shapeProps.staticLink.rows && shapeProps.staticLink.cols)
      startSearchEngine({
        nrows: staticLink.rows,
        ncols: staticLink.cols,
        npuzzles: 1,
        nwords: type === 'word-search' ? staticLink.nWords : undefined,
        total_numbers: type === 'number-search' ? staticLink.nWords : undefined,
        puzzle_shape: staticLink.puzzleShape,
        difficulty: staticLink.difficulty,
        text:
          type === 'word-search' && staticLink.commaSeparatedWords
            ? staticLink.commaSeparatedWords
            : undefined,
        wordlists: type === 'word-search' ? [2] : undefined,
        char_lengths: type === 'number-search' ? [5, 6, 7] : undefined,
      }).then(res => {
        if (!res.success && (res.messages || res.errors)) {
          if (res.messages)
            res.messages.forEach(msg => {
              alert(msg);
              // setMsgs([{ type: 'warning', content: msg }]);
            });
          else if (res.errors)
            res.errors.forEach(err => {
              alert(err);
              // setMsgs([{ type: 'error', content: err }]);
            });
        } else if (res.word_search_puzzles && res.word_search_puzzles[0]) {
          setDummy(res.word_search_puzzles[0]);

          onChange({
            ...shapeProps,
            staticLink,
            fontSize,
            text: {
              puzzle_shape: res.puzzle_shape, // to auto select shape in manuscript form
              grid_str: res.word_search_puzzles[0].grid_str,
              solution_str: res.word_search_puzzles[0].solution_str,
              solution_directions: res.word_search_puzzles[0].solution_directions,
            },
          });
        } else if (res.number_search_puzzles && res.number_search_puzzles[0]) {
          setDummy(res.number_search_puzzles[0]);

          onChange({
            ...shapeProps,
            staticLink,
            fontSize,
            text: {
              puzzle_shape: res.puzzle_shape, // to auto select shape in manuscript form
              grid_str: res.number_search_puzzles[0].grid_str,
              solution_str: res.number_search_puzzles[0].solution_str,
              solution_directions: res.number_search_puzzles[0].solution_directions,
            },
          });
        }

        trForceUpdate();
      });
  }, 1000);

  const load = () => {
    loadDummyPuzzle();
    return () => {
      loadDummyPuzzle.cancel();
    };
  };
  useEffect(load, []);

  useEffect(load, [
    shapeProps.staticLink.rows,
    shapeProps.staticLink.cols,
    shapeProps.staticLink.commaSeparatedWords,
    shapeProps.staticLink.puzzleShape,
    shapeProps.staticLink.difficulty,
    shapeProps.staticLink.nWords,
  ]);

  return (
    <>
      {shapeProps.link && isSelected && !isEditMode && (
        <LinkIndicator shapeProps={shapeProps} offset={false} />
      )}
      <Group
        onMouseDown={onSelect}
        onTap={onSelect}
        onDblTap={() => {
          setSelectedShapeId(null); // UI fix - Don't remove it
          setIsEditMode(true);
          setSelectedShapeId(shapeProps.id);
        }}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragStart={onDragStart}
        onDragMove={onDragMove}
        onDragEnd={e => onDragEnd(shapeProps, e)}
        onTransformEnd={e => {
          // transformer is changing scale of the node
          // and NOT its width or height- shapeRef.current.fontSize()/scale/100/2 - shapeRef.current.padding()/scale/100
          // but in the store we have only width and height
          // to match the data better we will re- shapeRef.current.fontSize()/scale/100/2 - shapeRef.current.padding()/scale/100set scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);

          onChange(
            {
              ...shapeProps,
              x: node.x(),
              y: node.y(), // set minimal value
              width: node.width() * scaleX,
              // height: node.width() * scaleX, // Quick fix
              height:
                shapeProps.staticLink &&
                shapeProps.staticLink.puzzleShape &&
                shapeProps.staticLink.puzzleShape === 'normal'
                  ? node.height() * scaleY
                  : node.width() * scaleX,
              scaleX: 1,
              scaleY: 1,
              rotation: node.rotation(),
            },
            true
          );
          trForceUpdate();
        }}
      >
        {shapeProps.staticLink.puzzleShape === 'normal' && (
          <Rect // this to constrain width
            key={'outline-' + shapeProps.staticLink.puzzleShape + shapeProps.id}
            x={0}
            y={0}
            width={shapeProps.width}
            height={shapeProps.height}
            stroke={'black'}
            // stroke={'rgba(0,0,0,0)'}
            fill={'rgba(0,0,0,0)'}
            strokeWidth={
              shapeProps.staticLink.borders.checked1 ? shapeProps.staticLink.borders.width1 : 0
            }
          />
        )}
        {shapeProps.staticLink.puzzleShape === 'circle' && (
          <Circle
            key={'outline-' + shapeProps.staticLink.puzzleShape + shapeProps.id}
            x={shapeProps.width / 2}
            y={shapeProps.height / 2}
            radius={radius}
            stroke={'black'}
            // stroke={'rgba(0,0,0,0)'}
            fill={'rgba(0,0,0,0)'}
            strokeWidth={
              shapeProps.staticLink.borders.checked1 ? shapeProps.staticLink.borders.width1 : 0
            }
          />
        )}

        {['triangle', 'squares', 'bowtie'].includes(puzzleShape) && (
          <Line
            key={'outline-' + shapeProps.staticLink.puzzleShape + shapeProps.id}
            points={WORDSEARCH_DESIGN[puzzleShape].points(shapeProps)}
            stroke={'black'}
            // fill={'rgba(0,0,0,0)'}
            strokeWidth={
              shapeProps.staticLink.borders.checked1 ? shapeProps.staticLink.borders.width1 : 0
            }
          />
        )}

        {hasRings &&
          dummy &&
          dummy.solution_directions &&
          Object.keys(dummy.solution_directions).map((word, i) => {
            const start = dummy.solution_directions[word].start;
            const direction = dummy.solution_directions[word].direction;
            let cellWidth = shapeProps.width / nCols();
            let cellHeight = shapeProps.height / nRows();

            const x1 = start[1] * cellWidth + cellWidth / 2;
            const y1 = start[0] * cellHeight + cellHeight / 2;
            const x2 = x1 + direction[0] * cellWidth * (word.length - 1);
            const y2 = y1 + direction[1] * cellHeight * (word.length - 1);

            return (
              <>
                <Ring
                  index={i}
                  points={[x1, y1, x2, y2]}
                  strokeWidth={shapeProps.fontSize + 4}
                  outlineStroke={shapeProps.staticLink.borders.color2}
                  outlineStrokeWidth={
                    shapeProps.staticLink.borders.checked2
                      ? shapeProps.staticLink.borders.width2
                      : 0
                  }
                  incX={cellWidth / 4}
                  lineCap={'round'}
                  fillColor={shapeProps.staticLink.ringsFillColor}
                />
              </>
            );
          })}
        {grid.map((cellsChunk, i) =>
          cellsChunk.map((word, j) => {
            const text =
              hasRings && !shapeProps.staticLink.borders.checked2
                ? dummy.solution_str[i * nCols() + j]
                : hasRings && shapeProps.staticLink.borders.checked1
                ? dummy.grid_str[i * nCols() + j]
                : word.text;

            return (
              <>
                {i < nRows() &&
                  text !== '-' &&
                  (type !== 'cross-words' ||
                    (type === 'cross-words' && !shapeProps.id.includes('puzzle'))) && (
                    <Text
                      ellipsis
                      wrap={'none'}
                      key={'col' + j + '-' + 'row' + i}
                      {...shapeProps}
                      x={word.x}
                      y={word.y}
                      width={shapeProps.width / nCols()}
                      height={shapeProps.height / nRows()}
                      lineHeight={shapeProps.lineHeight}
                      text={text}
                      rotation={0}
                      verticalAlign={'middle'}
                    />
                  )}
              </>
            );
          })
        )}
      </Group>
    </>
  );
};

export default TGridWordSearch;
