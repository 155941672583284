import React, { useState, useEffect } from 'react';
import FormSlider from 'components/forms/elements/FormSlider';
import SelectWrapper, { loadAsyncOptions } from 'components/forms/elements/SelectWrapper';
import { getCategories, getTags } from 'api/common';
import { getElements } from 'api/elements';
import { useGlobalContext } from 'state/hooks/global';
import Tags from 'components/common/Tags';

const recentlyUsedSlider = {
  title: 'Recently used',
  type: 'misc',
  itemWidth: 80,
  itemHeight: 80,
};

function FormatStudioFormImages({ className, handleOnSliderElClick }) {
  const [elTypes, setElTypes] = useState([]);
  const [selectedElType, setSelectedElType] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [allowedTags, setAllowedTags] = useState([]);

  const [tagsArr, setTagsArr] = useState([]);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    let str = '';
    tagsArr.forEach(tag => {
      const index = allowedTags.findIndex(
        allowedTag => allowedTag.name.toLowerCase() === tag.name.toLowerCase()
      );
      if (index > -1) {
        str += `&tag=${allowedTags[index].id}`;
      }
    });
    setTags(str);
  }, [tagsArr]);

  const [recentlyUsedImages, setRecentlyUsedImages] = useState([
    { type: '' },
    { type: '' },
    { type: '' },
    { type: '' },
  ]);

  useEffect(() => {
    setSelectedCategory(null);
    setCategories([]);
    setTagsArr([]);
    if (selectedElType) {
      getTags({
        kind: 'elements',
        element_type_id: selectedElType,
        sort: 'alpha',
      }).then(res => {
        if (res.success) {
          setAllowedTags(res.tags);
        }
      });
    }
  }, [selectedElType]);

  // Load Initial 15 slider
  useEffect(() => {
    if (!selectedElType) return;
    getCategories({
      search: '',
      offset: 0,
      limit: 15,
      kind: 'elements',
      element_type: selectedElType,
      sort: 'alpha',
    }).then(res => {
      const items = res.categories.map(cat => ({
        label: cat.title,
        value: cat.id,
      }));
      setCategories(items);
    });
  }, [selectedElType]);

  const { elementTypes } = useGlobalContext();
  useEffect(() => {
    if (elementTypes.length) {
      const opts = elementTypes.map(item => ({
        label: item.name,
        value: item.id,
      }));
      setElTypes(opts);
      setSelectedElType(opts[0].value);
    }
  }, [elementTypes]);

  return (
    <div className={`${className} form__studio-images`}>
      <div className="form__studio-heading">GALLERY</div>
      <div className="form-studio-scroll">
        <div className="d-flex flex-column" style={{ minHeight: 'calc(100vh - 12.5rem)' }}>
          <div className={`${selectedCategory ? 'd-none' : ''}`}>
            <div className=" d-flex flex-column mb-3">
              <SelectWrapper
                options={elTypes}
                value={selectedElType}
                setValue={setSelectedElType}
              />
            </div>
            <div className=" d-flex flex-column mb-4">
              <label className="mb-2">Category</label>
              <SelectWrapper
                async
                key={selectedElType}
                options={categories}
                value={selectedCategory}
                setValue={setSelectedCategory}
                loadOptions={(search, loadedOptions = []) => {
                  const offset = loadedOptions.length;
                  return loadAsyncOptions({
                    loadedOptions,
                    getItems: getCategories,
                    params: {
                      search,
                      offset,
                      limit: 15,
                      kind: 'elements',
                      element_type: selectedElType,
                      sort: 'alpha',
                    },
                    itemsKey: 'categories',
                    setItemsExternally: setCategories,
                    // setSelectedItem: setSelectedCategory,
                  });
                }}
              />
            </div>
          </div>

          <Tags
            key={'element-tags' + selectedElType}
            title={'Gallery Tags'}
            allowedTags={allowedTags}
            tagsArr={tagsArr}
            setTagsArr={setTagsArr}
            classNameA={''}
            classNameB={''}
            inputClassName={'form-control light'}
            height={50}
            inputPlaceholder={'Filter by tags...'}
            showHead={false}
            showCount={false}
            scrollDirection={'x'}
            searchable={true}
          />
          <div className={`${selectedCategory ? 'd-none' : ''}`}>
            <FormSlider
              form={'images'}
              title={recentlyUsedSlider.title}
              type={recentlyUsedSlider.type}
              items={recentlyUsedImages}
              itemWidth={recentlyUsedSlider.itemWidth / 1.2}
              itemHeight={recentlyUsedSlider.itemHeight / 1.2}
              handleOnSliderElClick={handleOnSliderElClick}
            />
          </div>
          <div style={{ overflowY: 'auto', height: 'calc(100vh - 45.8333rem)' }}>
            <p></p>

            {categories.map(slider => {
              if (selectedCategory === slider.value || !selectedCategory)
                return (
                  <FormSlider
                    async
                    key={slider.value}
                    id={slider.value}
                    title={slider.label}
                    type={'image'}
                    getItems={getElements}
                    getItemsParams={{
                      limit: 15,
                      kind: 'elements',
                      sort: 'alpha',
                      tags,
                    }}
                    // items={slider.items}
                    itemWidth={90}
                    itemHeight={80}
                    selectedSlider={selectedCategory}
                    setSelectedSlider={setSelectedCategory}
                    recentlyUsedImages={recentlyUsedImages}
                    setRecentlyUsedImages={setRecentlyUsedImages}
                    handleOnSliderElClick={handleOnSliderElClick}
                  />
                );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormatStudioFormImages;
