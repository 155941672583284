import React, { useEffect, useState } from 'react';

export const LetterS = props => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88">
        <path
          d="M17.89 0h88.9c8.85 0 16.1 7.24 16.1 16.1v90.68c0 8.85-7.24 16.1-16.1 16.1H16.1c-8.85 0-16.1-7.24-16.1-16.1v-88.9C0 8.05 8.05 0 17.89 0zM33.9 71.72l17.82-1.12c.38 2.89 1.17 5.09 2.36 6.59 1.94 2.45 4.7 3.68 8.29 3.68 2.67 0 4.74-.62 6.18-1.88 1.46-1.26 2.18-2.72 2.18-4.37 0-1.57-.68-2.98-2.05-4.23-1.37-1.24-4.57-2.4-9.59-3.52-8.23-1.84-14.09-4.3-17.59-7.37-3.54-3.05-5.3-6.96-5.3-11.71 0-3.11.91-6.05 2.72-8.83 1.81-2.79 4.53-4.96 8.16-6.55 3.63-1.58 8.61-2.38 14.94-2.38 7.76 0 13.68 1.44 17.75 4.34 4.07 2.89 6.49 7.48 7.27 13.79l-17.65 1.05c-.47-2.76-1.46-4.77-2.96-6.01-1.51-1.26-3.59-1.88-6.24-1.88-2.18 0-3.83.47-4.94 1.39-1.1.92-1.65 2.05-1.65 3.38 0 .96.45 1.82 1.34 2.6.86.81 2.96 1.54 6.27 2.23 8.2 1.77 14.07 3.56 17.61 5.37 3.55 1.81 6.14 4.04 7.75 6.73 1.61 2.67 2.42 5.67 2.42 9 0 3.89-1.07 7.48-3.22 10.78-2.16 3.28-5.16 5.78-9.04 7.48-3.86 1.7-8.73 2.55-14.61 2.55-10.32 0-17.48-1.99-21.46-5.97-3.99-3.96-6.23-9.01-6.76-15.14z"
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </svg>
    </>
  );
};
