import React from 'react';
import { RegularPolygon, Transformer } from 'react-konva';

const TTriangle = ({
  shapeProps,
  isSelected,
  onSelect,
  onDragStart,
  onDragMove,
  onDragEnd,
  onChange,
}) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();

  // const [offsetX, setOffsetX] = React.useState(null);

  // React.useEffect(() => {
  //   if (isSelected) {
  //     // we need to attach transformer manually
  //     trRef.current.nodes([shapeRef.current]);
  //     trRef.current.getLayer().batchDraw();  
  //   }
  // }, [isSelected]);

  React.useEffect(() => {
    onChange({
      ...shapeProps,
      offsetY: -1 * 0.25 *shapeProps.radius
    })
    // setOffsetY(-1 * 0.25 *shapeProps.radius);
  }, [shapeProps.radius]);

  return (
    <>
      <RegularPolygon
        onMouseDown={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragStart={onDragStart}
        onDragMove={onDragMove}
        onDragEnd={(e) => onDragEnd(shapeProps, e)}
        onTransformEnd={e => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            radius: Math.max(node.radius() * scaleX, 5),
            rotation: node.rotation(),
          }, true);
        }}
      />
    </>
  );
};

export default TTriangle;
