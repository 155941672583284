export const colors = {
  blue: '#02A3FF',
  green: '#87C46A',
  red: '#E85051',
  yellow: '#ECB53E',
  bgBlue: '#02A3FF33',
  bgGreen: '#87C46A33',
  bgRed: '#E8505133',
  bgYellow: '#ECB53E33',
  bgLight: '#f5f6fa',
  bgDark: '#293039',
  darkGrey: '#7C7D7E',
  bgGrey: '#383E46',
  borderGrey: '#707070',
  lightGrey: '#DDDDDD',
  black: '#171b1f',
  white: '#FFFFFF',
};
