import React, { useState } from 'react';
import Main from 'layouts/Main';
import TopControls from 'components/common/TopControls';
import Slider from 'components/common/Slider';
import AccountEditor from './AccountEditor';
import { cloneObj } from 'helpers/objects';
import permissions from 'settings/permissions';

function Accounts() {
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const handleAfterAdd = user => {
    setAccounts(prev => [user, ...prev]);
    setSelectedAccount(user);
  };
  const handleAfterEdit = user => {
    setAccounts(prev =>
      prev.map(acc =>
        acc.id === user.id ? { ...user, name: user.first_name + ' ' + user.last_name } : acc
      )
    );
    setSelectedAccount({
      ...user,
    });
  };

  const handleNewAccount = () => {
    setSelectedAccount({
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      profile_picture: null,
      resources_permissions: {
        ...cloneObj(permissions),
      },
    });
  };

  return (
    <div>
      <Main>
        <TopControls
          btns={[
            {
              text: 'Accounts',
              path: `/settings/accounts`,
              selected: true,
            },
          ]}
        />
        <div className="resources">
          <Slider
            title={'Accounts'}
            type={'account'}
            selectable={true}
            items={accounts}
            setItems={setAccounts}
            selectedItem={selectedAccount}
            setSelectedItem={setSelectedAccount}
            handleNew={handleNewAccount}
            outerSelection={false}
            readOnly={true}
          />
          {selectedAccount && (
            <AccountEditor
              acc={selectedAccount}
              setAcc={setSelectedAccount}
              isNew={!selectedAccount.id}
              handleAfterAdd={handleAfterAdd}
              handleAfterEdit={handleAfterEdit}
            />
          )}
        </div>
      </Main>
    </div>
  );
}

export default Accounts;
