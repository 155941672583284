import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import Spinner from 'components/common/Spinner';
import PermissionsManager from '../../views/settings/accounts/PermissionsManager';
import Button from 'components/common/Button';
import './AccountForm.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDropzone } from 'react-dropzone';
import ProgressBar from 'components/common/ProgressBar';
import IncludeSvg from 'components/common/IncludeSvg';

export default function AccountForm({
  account,
  setAccount,
  isLoading,
  setIsLoading,
  handleSaveAccount,
  handleResetAccount,
  file,
  setFile,
  isNew,
  status,
  setStatus,
  uploadProgress,
}) {
  const [nonFieldError, setNonFieldError] = useState('');
  const [showImage, setShowImage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const uploadRef = useRef();
  const [disabled, setDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm();

  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];
    setFile(file);
  });

  const createPoster = file => {
    if (!file) return false;

    const maxWeight = 25165824;

    let size = file.size;

    if (size > maxWeight) return false;
    return URL.createObjectURL(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
  });

  const handleUpload = e => {
    e.preventDefault();
    uploadRef.current.click();
  };

  const handleDelete = e => {
    e.preventDefault();
    setFile(null);
    setImage(null);
  };

  useEffect(() => {
    if (file) {
      setImage(createPoster(file));
    }
  }, [file]);

  // useEffect(() => {
  //   if (image) {
  //     setDisabled(true);
  //   } else {
  //     setDisabled(false);
  //   }
  // }, [image]);

  useEffect(() => {
    setNonFieldError('');
    clearErrors();

    if (isNew) {
      setFile(null);
      setImage(null);
    } else if (account && account.profile_picture) {
      setFile(null);
      setLoading(true);
      setImage(account.profile_picture);
    } else if (account && account.profile_picture === null && !isNew) {
      setFile(null);
      setImage(null);
    }
  }, [isNew, account]);

  const handleInputChange = e => {
    setAccount({
      ...account,
      [e.target.id]: e.target.id === 'is_active' ? e.target.checked : e.target.value,
    });
    setNonFieldError('');
  };
  return (
    <form className="account-form" onSubmit={handleSubmit(handleSaveAccount)}>
      <div className="account-form__left">
        <div>
          &nbsp;
          {nonFieldError && (
            <div className="error text-center h5" role="alert">
              {nonFieldError}
            </div>
          )}
          <div className="form-group img">
            <div className="drop-area shadow-sm border" {...getRootProps()}>
              <input disabled={disabled} {...getInputProps()} />
              <span ref={uploadRef}></span>

              {!loading && !image && (
                <div className="drag-symbol">
                  <span></span>
                  <span></span>
                  <IncludeSvg name="avatar" />
                </div>
              )}

              <span
                style={{
                  opacity: !showImage ? 0 : loading ? 0.5 : 1,
                  // display: !image ? 'none' : 'flex',
                }}
              >
                <LazyLoadImage
                  key={image}
                  beforeLoad={() => {
                    setShowImage(false);
                    setStatus('preparing preview');
                  }}
                  afterLoad={() => {
                    setLoading(false);
                    setShowImage(true);
                    setStatus('');
                  }}
                  src={image}
                />
              </span>
              {loading && status === 'preparing preview' && (
                <div
                  style={{
                    position: 'absolute',
                    width: 100,
                    height: 100,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              )}

              {file && <ProgressBar percentage={uploadProgress} />}
            </div>
            <button
              className="pp-delete-btn"
              disabled={(!file && !image) || loading}
              onClick={handleDelete}
            >
              <IncludeSvg name="delete" />
            </button>
            <button
              className="pp-add-btn"
              disabled={(file && image) || loading}
              onClick={handleUpload}
            >
              <IncludeSvg name="format-plus" />
            </button>
          </div>
          <div className="form-group">
            <label htmlFor="first_name">First Name</label>
            <input
              className="form-control"
              id="first_name"
              type="text"
              autoComplete="first_name"
              placeholder="First Name"
              value={account.first_name}
              onInput={handleInputChange}
              {...register('first_name', {
                required: 'First Name is required!',
                pattern: {
                  value: /[a-zA-Z]{2,}/,
                  message: 'First Name must be alpha and 2 chars at least!',
                },
              })}
            />
            {errors.first_name && (
              <span className="error" role="alert">
                &nbsp;{errors.first_name.message}
              </span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="last_name">Last Name</label>
            <input
              className="form-control"
              id="last_name"
              type="text"
              autoComplete="last_name"
              placeholder="Last Name"
              value={account.last_name}
              onInput={handleInputChange}
              {...register('last_name', {
                required: 'Last Name is required!',
                pattern: {
                  value: /[a-zA-Z]{2,}/,
                  message: 'Last Name must be alpha and 2 chars at least!',
                },
              })}
            />
            {errors.last_name && (
              <span className="error" role="alert">
                &nbsp;{errors.last_name.message}
              </span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="email">E-mail</label>
            <input
              className="form-control"
              id="email"
              type="text"
              placeholder="Your email address"
              autoComplete="off"
              value={account.email}
              onInput={handleInputChange}
              {...register('email', {
                required: 'Email is required!',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Email is not correct!',
                },
              })}
            />
            {errors.email && (
              <span className="error" role="alert">
                &nbsp;{errors.email.message}
              </span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              className="form-control"
              id="password"
              type="password"
              autoComplete="off"
              placeholder="Your password"
              value={account.password}
              onInput={handleInputChange}
              {...register('password', {
                required: isNew ? 'Password is required!' : false,
                minLength: {
                  value: 8,
                  message: 'Password is not correct',
                },
              })}
            />
            {errors.password && (
              <span className="error" role="alert">
                &nbsp;{errors.password.message}
              </span>
            )}
          </div>
        </div>
        <div className="account-form__is-active form-group">
          <div className="form-check">
            <input
              className="form-check-input "
              id="is_active"
              type="checkbox"
              checked={account.is_active}
              onChange={handleInputChange}
            />
            <label className="form-check-label" htmlFor="is_active">
              Is Active.
            </label>
          </div>
        </div>
        <div className="account-form__btns">
          {/* <button className="btn" onClick={() => setNonFieldError('')}>
          Log in {isLoading && <Spinner />}
        </button> */}
          <Button
            disabled={isLoading}
            label={'Save'}
            className="btn-blue-forms"
            style={{ width: 100, height: 40 }}
          />
          <Button
            disabled={isLoading}
            onClick={handleResetAccount}
            label={'Reset'}
            className="btn-white-forms"
            style={{ width: 100, height: 40 }}
          />
        </div>
      </div>
      <div className="account-form__right">
        <input
          type="hidden"
          name="resources_permissions"
          value={JSON.stringify(account.resources_permissions)}
          {...register('resources_permissions')}
        />
        <PermissionsManager
          perms={account.resources_permissions}
          setPerms={perms => setAccount({ ...account, resources_permissions: perms })}
        />
      </div>
    </form>
  );
}
