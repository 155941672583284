import SelectWrapper from 'components/forms/elements/SelectWrapper';
import Button from 'components/common/Button';
import InputNumber from 'components/forms/elements/InputNumber';
import { capitalizeFirst } from 'helpers/text';
import _ from 'lodash';

function SudokuForm({
  sudokuFormInputs,
  sudokuFormData,
  setSudokuFormData,
  updateManuscriptWithSudoku,
}) {
  return (
    <div className="sudoku-form">
      {Object.keys(sudokuFormInputs.groups).map((groupTitle, index) => (
        <>
          <div className={`form-group`}>
            <label>{capitalizeFirst(groupTitle.replace('_', ' '), true)}</label>

            <div className={`row row-cols-2`}>
              {sudokuFormInputs.groups[groupTitle].map(input =>
                input.type === 'number' ? (
                  <div className="col mb-2">
                    <label className="mb-2">{input.label}</label>
                    <InputNumber
                      value={sudokuFormData[input.dataField]}
                      setValue={value =>
                        setSudokuFormData({
                          ...sudokuFormData,
                          [input.dataField]: value,
                        })
                      }
                      className="form-control"
                      type="number"
                      step={1}
                      min={1}
                    />
                  </div>
                ) : (
                  <div className="col mb-2">
                    <label className="mb-2">{input.label}</label>
                    <SelectWrapper
                      options={input.options}
                      value={sudokuFormData[input.dataField]}
                      setValue={value =>
                        setSudokuFormData({
                          ...sudokuFormData,
                          [input.dataField]: value,
                        })
                      }
                      menuPalcement={['Puzzle Shape', 'Difficulty'].includes(input.label) && 'top'}
                    />
                  </div>
                )
              )}

              <div className="d-flex w-100 justify-content-center mt-4">
                <Button
                  onClick={() => updateManuscriptWithSudoku()}
                  label={'Load Puzzles'}
                  className="btn-blue-forms"
                  style={{ width: 150, height: 40 }}
                />
              </div>
            </div>
          </div>
        </>
      ))}
    </div>
  );
}

export default SudokuForm;
