import { Layer, Line, Rect, Stage, Transformer } from 'react-konva';
import React, { useEffect, useRef, useState } from 'react';
import { collectLinksFromKonvaObj, collectRolesFromKonvaObj, getShapesNodes } from 'helpers/studio';
import { deleteCover, editCover } from 'api/studio/covers';
import { deleteFormat, editFormat } from 'api/studio/formats';
import { useIsShiftDown, useOnClickOnEscape, useOnClickOutside } from 'hooks/util';

import Confirm from 'components/common/Confirm';
import FormNavigator from 'components/studio/FormNavigator';
import FormatStudioFormDB from 'components/forms/FormatStudioFormDB';
import FormatStudioFormImages from 'components/forms/FormatStudioFormImages';
import FormatStudioFormLink from 'components/forms/FormatStudioFormLink';
import FormatStudioFormShapes from 'components/forms/FormatStudioFormShapes';
import FormatStudioFormText from 'components/forms/FormatStudioFormText';
import Hotkeys from 'react-hot-keys';
import PopupMenu from 'components/common/PopupMenu';
import TCircle from 'components/studio/shapes/TCircle';
import TGrid from 'components/studio/shapes/TGrid';
import TImage from 'components/studio/shapes/TImage';
import TLine from 'components/studio/shapes/TLine';
import TRectangle from 'components/studio/shapes/TRectangle';
import TStar from 'components/studio/shapes/TStar';
import TText from 'components/studio/shapes/TText';
import TTriangle from 'components/studio/shapes/TTriangle';
import TList from 'components/studio/shapes/TList';
import TTableOfContents from 'components/studio/shapes/TTableOfContents';
import ZoomableArea from 'components/common/ZoomableArea';
import { colors } from 'settings/colors';
import { floor10 } from 'helpers/math';
import { getBoundries } from 'helpers/shapes';
import { getUniqueId } from 'helpers/generators';
import { jsPDF } from 'jspdf';
import { useGlobalContext } from 'state/hooks/global';
import { useHistory } from 'react-router-dom';
import { useStudioContext } from 'hooks/studio';

import { calculateTextWidth } from 'helpers/studio';
import { useSelector } from 'react-redux';
import useTrigger from 'hooks/useTrigger';
import { sendPdfCreationNotification } from 'api/common';
import { newEl } from 'helpers/shapes';

const ANCHORS = {
  sides: ['middle-left', 'middle-right'],
  corners: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
};

function FormatsStage({ format, show, toRemove, setToRemove, isCoversStage = false }) {
  const { user } = useSelector(state => state.auth);
  const canChangeFormats =
    (!isCoversStage && user?.resources_permissions?.format?.change_format) ||
    (isCoversStage && user?.resources_permissions?.cover?.change_cover);
  const routerHistory = useHistory();
  const spaceRef = useRef(null);
  const stageRef = useRef(null);
  const backgroundRef = useRef(null);
  const stageContRef = useRef(null);
  const marginsRectRef = useRef(null);
  const popupRef = useRef(null);
  const shapesLayerRef = useRef(null);
  const zoomableAreaRef = useRef(null);
  const [textareaProps, setTextareaProps] = useState({});
  const [textareaValue, setTextareaValue] = useState('');
  const [textareaLoaded, setTextareaLoaded] = useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);

  const [shapes, setShapes] = useState([]);
  const [selectedShapeId, setSelectedShapeId] = useState(null);
  const [secondarySelectedIds, setSecondarySelectedIds] = useState([]);

  const [form, setForm] = useState('text');
  const [scale, setScale] = useState(0);
  const [, setSaved] = useState(false);
  const { isShiftDown } = useIsShiftDown();

  const [showXAxis, setShowXAxis] = useState(false);
  const [showYAxis, setShowYAxis] = useState(false);
  const [showTopMargin, setShowTopMargin] = useState(false);
  const [showBottomMargin, setShowBottomMargin] = useState(false);
  const [showLeftMargin, setShowLeftMargin] = useState(false);
  const [showRightMargin, setShowRightMargin] = useState(false);
  const [showTopBleeding, setShowTopBleeding] = useState(false);
  const [showBottomBleeding, setShowBottomBleeding] = useState(false);
  const [showLeftBleeding, setShowLeftBleeding] = useState(false);
  const [showRightBleeding, setShowRightBleeding] = useState(false);

  const [imageDataURL, setImageDataURL] = useState(null);
  const [actionPopup, setActionPopup] = useState(false);
  const [actionPopupPos, setActionPopupPos] = useState({ top: 0, left: 0 });

  const [history, setHistory] = useState([]);
  const [historyStep, setHistoryStep] = useState(0);
  const [noOtherInput, setNoOtherInput] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const { setMsgs } = useGlobalContext();

  const [margins] = useState({
    x1: (-format.width * 96) / 2 + format.margin.left * 96,
    x2: (format.width * 96) / 2 - format.margin.right * 96,
    y1: (-format.height * 96) / 2 + format.margin.top * 96,
    y2: (format.height * 96) / 2 - format.margin.bottom * 96,
  });

  const [bleedings] = useState({
    x1: (-format.width * 96) / 2 + format.bleeding.left * 96,
    x2: (format.width * 96) / 2 - format.bleeding.right * 96,
    y1: (-format.height * 96) / 2 + format.bleeding.top * 96,
    y2: (format.height * 96) / 2 - format.bleeding.bottom * 96,
  });

  const [isSavingPDF, setIsSavingPDF] = useState(false);
  const [progressPDF, setProgressPDF] = useState(0);
  const [status, setStatus] = useState('');

  const [shouldDeselcedEverything, trigDeselectEverything] = useTrigger(false);

  const { formats, setFormats, dragEl, dragImgSrc, dragImgID, dragImgWidth, dragImgHeight } =
    useStudioContext();

  useOnClickOutside(popupRef, () => setActionPopup(false));
  useOnClickOnEscape(popupRef, () => setActionPopup(false));

  useEffect(() => {
    if (progressPDF === 100) {
      setTimeout(() => {
        setIsSavingPDF(false);
        setProgressPDF(0);
      }, 1000);
    }
  }, [progressPDF]);

  const popupOptions = [
    // {
    //   title: 'Copy',
    //   handler: handleCopy,
    // },
    // {
    //   title: 'Paste',
    //   handler: handlePaste,
    // },
    {
      title: 'Delete',
      handler: () => {
        deleteShapes([selectedShapeId, ...secondarySelectedIds]);
      },
    },
    {
      title: 'Duplicate',
      handler: () => {
        handleCreateDuplicate();
      },
    },
    {
      title: 'Move To Top',
      handler: () => {
        handleZIndexChange('top');
      },
    },
    {
      title: 'Move To Top-Most',
      handler: () => {
        handleZIndexChange('veryTop');
      },
    },
    {
      title: 'Move To Bottom',
      handler: () => {
        handleZIndexChange('bottom');
      },
    },
    {
      title: 'Move To Bottom-Most',
      handler: () => {
        handleZIndexChange('veryBottom');
      },
    },
  ];

  const handleClickOutside = () => {
    setIsEditMode(false);
    setSelectedShapeId(null);
  };

  const checkDeselect = e => {
    // console.log(stageRef.current.toJSON())
    // deselect when clicked on empty area
    const clickedOnEmptyArea =
      e.target === stageRef.current ||
      e.target === backgroundRef.current ||
      e.target === marginsRectRef.current;

    if (clickedOnEmptyArea) {
      setIsEditMode(false);
      setSelectedShapeId(null);
    }
  };

  const generatePDF = async dpi => {
    await sendPdfCreationNotification({
      kind: isCoversStage ? 'studio_cover' : 'studio_format',
      id: format.id,
    });
    setScale(100);
    await setIsSavingPDF(true);

    const preview = format.width > format.height ? 'l' : 'p';
    var pdf = new jsPDF(preview, 'in', [format.width, format.height]);
    setProgressPDF(50);

    try {
      if (stageRef.current.toDataURL) {
        const image = await addImageProcess(shapesLayerRef, dpi);
        pdf.addImage(
          image,
          'JPEG',
          0,
          0,
          stageRef.current.width() / 96,
          stageRef.current.height() / 96,
          undefined,
          'FAST' // 'SLOW' will reduce the size but will take longer
        );
      }

      pdf.setProperties({
        title: format.title,
        author: 'Funcrane ©' + new Date().getFullYear(),
      });
      await setProgressPDF(100);
      setTimeout(() => {
        pdf.save(`${format.title} - (${dpi} DPI).pdf`);
      }, 1000);
    } catch (err) {
      console.log(err);
    } finally {
      setIsSavingPDF(false);
    }
  };

  async function addImageProcess(ref, dpi) {
    return new Promise((resolve, reject) => {
      let img = new Image();

      img.src = ref.current.toDataURL({ pixelRatio: dpi / 72 });
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  }

  const handleSave = ({ showMsg, fromClosePopup }) => {
    if (!stageRef.current) return;
    const stage_json = JSON.parse(stageRef.current.toJSON());

    stage_json.children[0].children = shapes.map(shape => {
      let className;

      if (shape.id.includes('text')) className = 'Text';
      else if (shape.id.includes('rect')) className = 'Rect';
      else if (shape.id.includes('circle')) className = 'Ellipse';
      else if (shape.id.includes('start')) className = 'Star';
      else if (shape.id.includes('triangle')) className = 'RegularPolygon';
      else if (shape.id.includes('image')) className = 'Image';
      else if (shape.id.includes('line')) className = 'Line';
      else if (shape.id.includes('grid')) className = 'Grid';

      return {
        attrs: shape,
        className,
      };
    });

    const content_types = collectRolesFromKonvaObj({ shapes });
    const links_arr = collectLinksFromKonvaObj({ shapes });
    if (isCoversStage) {
      editCover({ is_published: true, stage_json, content_types }, format.id).then(res => {
        if (res.success) {
          setSaved(true);
          setStatus('saved');
          handleFormatUpdate(res.format, fromClosePopup);
          if (showMsg) setMsgs([{ type: 'success', content: 'Cover updated successfully!' }]);
        }
      });
    } else {
      editFormat({ is_published: true, stage_json, content_types, links_arr }, format.id).then(
        res => {
          if (res.success) {
            setSaved(true);
            setStatus('saved');
            handleFormatUpdate(res.format, fromClosePopup);
            if (showMsg) setMsgs([{ type: 'success', content: 'Format updated successfully!' }]);
          }
        }
      );
    }
  };

  useEffect(() => {
    let timeoutClearStatus;
    if (timeoutClearStatus) clearTimeout(timeoutClearStatus);

    setStatus('saving...');
    const timeout = setTimeout(() => {
      handleSave({ showMsg: false });
    }, 1000);

    return () => {
      clearTimeout(timeout);
      timeoutClearStatus = setTimeout(() => {
        setStatus('');
      }, 3000);
    };
  }, [shapes]);

  const removeFormatFromStage = () => {
    const list = [...formats];
    const index = list.findIndex(item => item.id === format.id);
    if (index > -1) list.splice(index, 1);
    setFormats(list);
    if (!list.length && !isCoversStage) routerHistory.push('/home/formats');
    if (!list.length && isCoversStage) routerHistory.push('/home/covers');
  };

  const handleFormatUpdate = (newFormat, fromClosePopup) => {
    const list = [...formats];
    const index = list.findIndex(item => item.id === format.id);
    if (index > -1) list[index] = newFormat;
    if (fromClosePopup) list.splice(index, 1);
    setFormats(list);
  };

  const handleCancel = () => {
    if (history.length) setIsOpen(true);
    else removeFormatFromStage();
  };

  const handleCancelAfterConfirm = c => {
    if (c) {
      handleSave({ showMsg: true, fromClosePopup: true });
      setSaved(true);
      removeFormatFromStage(format);
    } else {
      if (!format.is_published) {
        if (isCoversStage) {
          deleteCover(format.id).then(res => {
            if (res.success) {
              removeFormatFromStage(format);
            }
          });
        } else {
          deleteFormat(format.id).then(res => {
            if (res.success) {
              removeFormatFromStage(format);
            }
          });
        }
      } else {
        removeFormatFromStage(format);
      }
    }
  };

  useEffect(() => {
    if (selectedShapeId)
      window.oncontextmenu = e => {
        e.preventDefault();
        setActionPopup(true);
        const pos = stageRef.current.getPointerPosition();
        setActionPopupPos({
          left: pos.x,
          top: pos.y,
        });
      };
    else window.oncontextmenu = null;

    return () => {
      window.oncontextmenu = null;
    };
  }, [scale, selectedShapeId]);

  useEffect(() => {
    if (toRemove === format.id) {
      handleCancel();
      setToRemove(null);
    }
  }, [toRemove]);

  const deleteShapes = ids => {
    setSelectedShapeId(null);
    setSecondarySelectedIds([]);
    const list = [...shapes];
    ids.forEach(id => {
      const index = list.findIndex(item => item.id === id);
      if (index > -1) {
        list.splice(index, 1);
      }
    });
    setShapes(list);
    updateHistory();
  };

  const isIdInSecondarySelectedIds = id => {
    const list = [...secondarySelectedIds];
    const index = list.findIndex(item => item === id);
    return index > -1;
  };

  const handleSelect = (id, isDraggingSelect = false) => {
    setIsEditMode(false);
    let list = [...secondarySelectedIds];
    // Single Select
    if (!isDraggingSelect && !isShiftDown) {
      setSelectedShapeId(id);
      list = [];
      // Multi Select
    } else {
      if (!selectedShapeId) setSelectedShapeId(id);
      else if (selectedShapeId !== id && !list.includes(id)) list.push(id);
    }

    setSecondarySelectedIds(list);
  };

  const handleDragMove = e => {
    const x = Math.round(e.target.x());
    const y = Math.round(e.target.y());

    const index = shapes.findIndex(shape => shape.id === selectedShapeId);
    const node = { ...shapes[index], x, y };

    if (!secondarySelectedIds.length) {
      const boundries = getBoundries(node);
      if (Math.abs(margins.x2 - boundries.x2) < 7) {
        setShowRightMargin(true);
      } else setShowRightMargin(false);

      if (Math.abs(margins.y2 - boundries.y2) < 7) {
        setShowBottomMargin(true);
      } else setShowBottomMargin(false);

      if (Math.abs(margins.x1 - boundries.x1) < 7) {
        setShowLeftMargin(true);
      } else setShowLeftMargin(false);

      if (Math.abs(margins.y1 - boundries.y1) < 7) {
        setShowTopMargin(true);
      } else setShowTopMargin(false);

      if (Math.abs(bleedings.x2 - boundries.x2) < 7) {
        setShowRightBleeding(true);
      } else setShowRightBleeding(false);

      if (Math.abs(bleedings.y2 - boundries.y2) < 7) {
        setShowBottomBleeding(true);
      } else setShowBottomBleeding(false);

      if (Math.abs(bleedings.x1 - boundries.x1) < 7) {
        setShowLeftBleeding(true);
      } else setShowLeftBleeding(false);

      if (Math.abs(bleedings.y1 - boundries.y1) < 7) {
        setShowTopBleeding(true);
      } else setShowTopBleeding(false);

      if (Math.abs(x) < 7) setShowYAxis(true);
      else setShowYAxis(false);

      if (Math.abs(y) < 7) setShowXAxis(true);
      else setShowXAxis(false);
    }
  };

  const handleChange = (newAttrs, updateHistoryRecord = false) => {
    const list = [...shapes];
    const i = list.findIndex(item => item.id === newAttrs.id);
    list[i] = newAttrs;
    setShapes(list);
    if (updateHistoryRecord) updateHistory();
  };

  const handleDragEnd = (shapeProps, e) => {
    const boundries = getBoundries(shapeProps);
    let x = e.target.x();
    let y = e.target.y();

    if (!secondarySelectedIds.length) {
      if (showXAxis) y = 0;
      if (showYAxis) x = 0;
      if (showLeftMargin) x = margins.x1 + (boundries.x - boundries.x1);
      if (showRightMargin) x = margins.x2 - (boundries.x2 - boundries.x);
      if (showTopMargin) y = margins.y1 + boundries.y - boundries.y1;
      if (showBottomMargin) y = margins.y2 - (boundries.y2 - boundries.y);
      if (showLeftBleeding) x = bleedings.x1 + (boundries.x - boundries.x1);
      if (showRightBleeding) x = bleedings.x2 - (boundries.x2 - boundries.x);
      if (showTopBleeding) y = bleedings.y1 + boundries.y - boundries.y1;
      if (showBottomBleeding) y = bleedings.y2 - (boundries.y2 - boundries.y);
    }

    handleChange(
      {
        ...shapeProps,
        x,
        y,
      },
      true
    );

    setShowXAxis(false);
    setShowYAxis(false);
    setShowLeftMargin(false);
    setShowRightMargin(false);
    setShowTopMargin(false);
    setShowBottomMargin(false);
    setShowLeftBleeding(false);
    setShowRightBleeding(false);
    setShowTopBleeding(false);
    setShowBottomBleeding(false);
  };

  const updateHistory = () => {
    let updatedHistory = history.slice(0, historyStep + 1);
    const currentShapes = [...shapes];
    const nextStep = historyStep + 1;
    updatedHistory = [...updatedHistory, currentShapes];
    setHistory(updatedHistory);
    setHistoryStep(nextStep);
  };

  const handleMove = (axe, dir, shift) => {
    if (isEditMode) return false;
    const ids = [selectedShapeId, ...secondarySelectedIds];
    const list = [...shapes];
    ids.forEach(id => {
      const index = list.findIndex(item => item.id === id);
      const item = list[index];
      if (index > -1) {
        const delta = shift ? 5 : 1;
        item[axe] = dir === '+' ? item[axe] + delta : item[axe] - delta;
      }
    });
    setShapes(list);
    updateHistory();
  };

  const handleCopy = e => {
    if (e) e.preventDefault();
    if (!selectedShapeId) return;
    const idsArr = [selectedShapeId, ...secondarySelectedIds];
    const elements = shapes.filter(shape => idsArr.includes(shape.id));
    localStorage.setItem('copied-items', JSON.stringify(elements));
    // navigator.clipboard.writeText(JSON.stringify(elements)); // worked only in chrome
    setMsgs([{ type: 'success', content: 'Element(s) copied successfully!' }]);
  };

  const handlePaste = e => {
    e.preventDefault();
    const text = localStorage.getItem('copied-items');
    // navigator.clipboard.readText().then(text => {
    if (!text) return;
    const pastedShapes = [...JSON.parse(text)].map(
      shape =>
        shape && {
          ...shape,
          id: shape.id + getUniqueId('--copied-from-' + format.id + '-'),
        }
    );
    setShapes(shapes => [...shapes, ...pastedShapes]);
    setMsgs([{ type: 'success', content: 'Element(s) pasted successfully!' }]);
    // });
  };

  const handleUndo = () => {
    setSelectedShapeId(null);
    if (historyStep === 0) {
      return;
    }
    const step = historyStep - 1;
    setHistoryStep(step);
    setShapes([...history[step]]);
  };

  const handleRedo = () => {
    setSelectedShapeId(null);
    if (historyStep === history.length - 1) {
      return;
    }
    const step = historyStep + 1;
    setHistoryStep(step);
    setShapes(history[step]);
  };

  const handleZIndexChange = type => {
    const list = [...shapes];
    const index = list.findIndex(item => item.id === selectedShapeId);
    const item = { ...list[index] };

    switch (type) {
      case 'top':
        if (index < list.length - 1) {
          list.splice(index, 1);
          list.splice(index + 1, 0, item);
        }
        break;
      case 'veryTop':
        if (index < list.length - 1) {
          list.splice(index, 1);
          list.push(item);
        }
        break;
      case 'bottom':
        if (index > 0) {
          list.splice(index, 1);
          list.splice(index - 1, 0, item);
        }
        break;
      case 'veryBottom':
        if (index > 0) {
          list.splice(index, 1);
          list.unshift(item);
        }
        break;
      default:
        console.log(type, 'not supported!');
        break;
    }
    setShapes(list);
    updateHistory();
  };

  const handleOnSliderElClick = type => {
    setSelectedShapeId(null);
    const [shape, id] = newEl(type, 0, 0, {
      dragEl,
      dragImgSrc,
      dragImgID,
      dragImgWidth,
      dragImgHeight,
    });
    if (shape && id) {
      setShapes(shapes.concat([shape]));
      setSelectedShapeId(id);
      updateHistory();
    }
  };

  const handleCreateDuplicate = () => {
    const list = [...shapes];
    const index = list.findIndex(item => item.id === selectedShapeId);
    const item = { ...list[index] };
    const copyId = getUniqueId('copy-of-' + item.id + '--');
    item.id = copyId;
    item.x = item.x + 20;
    item.y = item.y + 20;

    list.splice(index + 1, 0, item);
    setShapes(list);
    setSelectedShapeId(copyId);
    updateHistory();
  };

  const handleOnClickOutside = e => {
    if (e.target === zoomableAreaRef.current) {
      trigDeselectEverything();
    }
  };

  useEffect(() => {
    if (format && format.stage_json) setShapes(getInitialShapes(format));
  }, []);

  useEffect(() => {
    if (show === false) setSelectedShapeId(null);
  }, [show]);

  useEffect(() => {
    if (!isEditMode) setTextareaLoaded(false);
  }, [isEditMode]);

  useEffect(() => {
    if (selectedShapeId && form !== 'link' && form !== 'database') setForm('text');
  }, [selectedShapeId]);

  // whenever selectedShape null, empty the the secondary Ids
  useEffect(() => {
    if (!selectedShapeId) {
      setSecondarySelectedIds([]);
    }
  }, [selectedShapeId]);

  // whenver adding or removing items deselect all
  useEffect(() => {
    setSecondarySelectedIds([]);
  }, [shapes.length]);

  useEffect(() => {
    if (stageRef.current && stageRef.current.attrs.width)
      setImageDataURL(stageRef.current.toDataURL());
  }, [format, scale]);

  const pageSize = (() => {
    const bleeding = format.bleeding;
    const haveBleeding =
      bleeding && bleeding.top + bleeding.right + bleeding.bottom + bleeding.left > 0;

    // return `${(format.width - bleeding.left - bleeding.right).toFixed(2)}"X${(
    //   format.height -
    //   bleeding.top -
    //   bleeding.bottom
    // ).toFixed(2)}"` + haveBleeding
    //   ? ` (${format.width.toFixed(2)}"X${format.height.toFixed(2)}")`
    //   : ' ';

    return format
      ? `${(format.width - bleeding.right - bleeding.left).toFixed(2)}"x${(
          format.height -
          bleeding.top -
          bleeding.bottom
        ).toFixed(2)}"` +
          ' ' +
          (haveBleeding ? `(${format.width.toFixed(2)}"x${format.height.toFixed(2)}")` : '')
      : '';
  })();

  return (
    show && (
      <Hotkeys
        keyName="
        up, down, left, right,
        shift+up, shift+down, shift+left, shift+right,
        ctrl+up, ctrl+down, ctrl+left, ctrl+right, ctrl+enter, ctrl+return, ctrl+z, ctrl+y, ctrl+c, ctrl+v, 
        cmd+up, cmd+down, cmd+left, cmd+right, cmd+enter, cmd+return, cmd+z, cmd+shift+z, cmd+c, cmd+v,
        delete, backspace"
        onKeyDown={(keyName, e, handle) => {
          if (noOtherInput) {
            switch (keyName) {
              // Arrows => move selected shapes by 1px
              case 'up':
                handleMove('y', '-');
                break;
              case 'down':
                handleMove('y', '+');
                break;
              case 'left':
                handleMove('x', '-');
                break;
              case 'right':
                handleMove('x', '+');
                break;

              // Shift + Arrow => move selected shapes by 5px
              case 'shift+up':
                handleMove('y', '-', true);
                break;
              case 'shift+down':
                handleMove('y', '+', true);
                break;
              case 'shift+left':
                handleMove('x', '-', true);
                break;
              case 'shift+right':
                handleMove('x', '+', true);
                break;

              // Ctrl or Cmd + Arrow => change z-index of the shape
              case 'ctrl+up':
              case 'cmd+up':
                handleZIndexChange('veryTop');
                break;
              case 'ctrl+down':
              case 'cmd+down':
                handleZIndexChange('veryBottom');
                break;
              case 'ctrl+left':
              case 'cmd+left':
                handleZIndexChange('top');
                break;
              case 'ctrl+right':
              case 'cmd+right':
                handleZIndexChange('bottom');
                break;

              // others => duplicate, undo, redo, delete
              case 'ctrl+enter':
              case 'cmd+enter':
              case 'ctrl+return':
              case 'cmd+return':
                handleCreateDuplicate();
                break;
              case 'ctrl+c':
              case 'cmd+c':
                handleCopy(e);
                break;
              case 'ctrl+v':
              case 'cmd+v':
                handlePaste(e);
                break;
              case 'ctrl+z':
              case 'cmd+z':
                handleUndo();
                break;
              case 'ctrl+y':
              case 'cmd+shift+z':
                handleRedo();
                break;
              case 'delete':
              case 'backspace':
                deleteShapes([selectedShapeId, ...secondarySelectedIds]);
                break;
            }
          }
        }}
      >
        <div
          ref={spaceRef}
          tabIndex={1}
          className={`${show ? 'd-flex' : 'd-none'} align-items-stretch`}
          style={{ outline: 'none' }}
        >
          <div className="format-stage-area w-100">
            {format && (
              <>
                <FormNavigator
                  form={form}
                  setForm={setForm}
                  pageSize={pageSize}
                  options={
                    canChangeFormats
                      ? [
                          { name: 'text' },
                          { name: 'puzzle' },
                          { name: 'shapes' },
                          { name: 'images' },
                          {
                            name: 'link',
                          },
                          {
                            name: 'database',
                          },
                        ]
                      : []
                  }
                />
                <ZoomableArea
                  ref={zoomableAreaRef}
                  onClick={handleOnClickOutside}
                  key={format.id}
                  isPannable={!selectedShapeId}
                  show={show}
                  initZoom={floor10(
                    Math.min(
                      (window.innerHeight - 300) / (format.height * 96),
                      (window.innerWidth - 700) / (format.width * 96)
                    ) * 100,
                    1
                  )}
                  format={format}
                  setScale={setScale}
                  status={status}
                  stageWidth={
                    Math.floor(
                      // (
                      format.width *
                        // +
                        // Number(format.bleeding.left) +
                        // Number(format.bleeding.right)
                        96 *
                        scale
                    ) / 100
                    // )
                  }
                  stageHeight={
                    Math.floor(
                      // (
                      format.height *
                        // +
                        // Number(format.bleeding.top) +
                        // Number(format.bleeding.bottom)
                        96 *
                        scale
                    ) / 100
                    // )
                  }
                >
                  <div
                  // style={{
                  //   border: `${
                  //     format.bleeding.top > 0 ||
                  //     format.bleeding.right > 0 ||
                  //     format.bleeding.bottom > 0 ||
                  //     format.bleeding.left > 0
                  //       ? '1px solid red'
                  //       : 'none'
                  //   }`,
                  //   backgroundColor: '#f5f6fa',
                  //   paddingTop: (format.bleeding.top * 96 * scale) / 100,
                  //   paddingRight: (format.bleeding.right * 96 * scale) / 100,
                  //   paddingBottom:
                  //     (format.bleeding.bottom * 96 * scale) / 100,
                  //   paddingLeft: (format.bleeding.left * 96 * scale) / 100,
                  // }}
                  >
                    <div
                      ref={stageContRef}
                      style={{
                        position: 'relative',
                        backgroundColor: 'white',
                      }}
                      onDrop={e => {
                        e.preventDefault();
                        setSelectedShapeId(null);
                        // register event position
                        stageRef.current.setPointersPositions(e);
                        // add image

                        const pos = stageRef.current.getPointerPosition();

                        const [shape, id] = newEl(
                          dragEl,
                          (pos.x * 100) / scale + stageRef.current.offsetX(),
                          (pos.y * 100) / scale + stageRef.current.offsetY(),
                          {
                            dragImgSrc,
                            dragImgID,
                            dragImgWidth,
                            dragImgHeight,
                          }
                        );

                        if (shape && id) {
                          setShapes(shapes.concat([shape]));
                          setSelectedShapeId(id);
                          updateHistory();
                        }
                      }}
                      onDragOver={e => e.preventDefault()}
                    >
                      <KonvaStage
                        // editable
                        isCoversStage={isCoversStage}
                        stageRef={stageRef}
                        shapesLayerRef={shapesLayerRef}
                        backgroundRef={backgroundRef}
                        format={format}
                        scale={scale}
                        checkDeselect={checkDeselect}
                        marginsRectRef={marginsRectRef}
                        shapes={shapes}
                        selectedShapeId={selectedShapeId}
                        secondarySelectedIds={secondarySelectedIds}
                        isIdInSecondarySelectedIds={isIdInSecondarySelectedIds}
                        setTextareaProps={setTextareaProps}
                        isEditMode={isEditMode}
                        setIsEditMode={setIsEditMode}
                        setTextareaValue={setTextareaValue}
                        setSelectedShapeId={setSelectedShapeId}
                        handleSelect={handleSelect}
                        handleDragMove={handleDragMove}
                        handleDragEnd={handleDragEnd}
                        handleChange={handleChange}
                        showXAxis={showXAxis}
                        showYAxis={showYAxis}
                        setShowXAxis={setShowXAxis}
                        setShowYAxis={setShowYAxis}
                        showTopMargin={showTopMargin}
                        showBottomMargin={showBottomMargin}
                        showLeftMargin={showLeftMargin}
                        showRightMargin={showRightMargin}
                        showTopBleeding={showTopBleeding}
                        showBottomBleeding={showBottomBleeding}
                        showLeftBleeding={showLeftBleeding}
                        showRightBleeding={showRightBleeding}
                        shouldDeselcedEverything={shouldDeselcedEverything}
                        handleClickOutside={handleClickOutside}
                      />
                    </div>
                    {/* for debugging */}
                    {/* <img src={imageDataURL} /> */}
                    {
                      <textarea
                        className={`knova-textarea ${!isEditMode ? 'd-none' : ''}`}
                        rows={1}
                        onFoucs={e => {
                          isEditMode && e.stopPropagation();
                        }}
                        onMouseEnter={e => {
                          if (!textareaLoaded) {
                            e.target.focus();
                            e.target.setSelectionRange(0, e.target.value.length);
                            setTextareaLoaded(true);
                          }
                        }}
                        onChange={e => {
                          // e.target.style.height = 'inherit';
                          // e.target.style.height = `${e.target.scrollHeight}px`;
                          const list = [...shapes];
                          const index = list.findIndex(shape => shape.id === textareaProps.id);
                          const item = list[index];
                          item.text = e.target.value;
                          const availableWidth =
                            (format.width - format.margin.left - format.margin.right) * 96;

                          const width = calculateTextWidth({
                            text: e.target.value,
                            fontSize: item.fontSize,
                            fontFamily: item.fontFamily,
                            minWidth: item.width,
                            canvasWidth: availableWidth,
                          });
                          item.width = width;

                          setTextareaValue(e.target.value);
                          list[index] = item;
                          setShapes(list);
                        }}
                        style={textareaProps.styles}
                        value={textareaValue}
                      ></textarea>
                    }

                    {actionPopup && (
                      <div
                        style={{
                          position: 'absolute',
                          border: '1px solid black',
                          ...actionPopupPos,
                        }}
                        useOnClickOutside={() => {
                          setActionPopup(false);
                        }}
                      >
                        {actionPopup && (
                          <PopupMenu
                            popupRef={popupRef}
                            options={popupOptions}
                            setIsOpen={setActionPopup}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </ZoomableArea>
              </>
            )}
          </div>
          {canChangeFormats && (
            <div className="form form__studio noselect">
              <FormatStudioFormText
                isPuzzleTab={form === 'puzzle'}
                className={`${form !== 'text' && form !== 'puzzle' ? 'd-none' : ''}`}
                textareaProps={textareaProps}
                selectedShapeId={selectedShapeId}
                secondarySelectedIds={secondarySelectedIds}
                setSelectedShapeId={setSelectedShapeId}
                shapes={shapes}
                setShapes={setShapes}
                updateHistory={updateHistory}
                onChange={handleChange}
                stageWidth={format.width * 96}
                setNoOtherInput={setNoOtherInput}
                format={format}
              />

              <FormatStudioFormShapes
                className={`${form !== 'shapes' ? 'd-none' : ''}`}
                handleOnSliderElClick={handleOnSliderElClick}
              />
              <FormatStudioFormImages
                className={`${form !== 'images' ? 'd-none' : ''}`}
                handleOnSliderElClick={handleOnSliderElClick}
              />
              <FormatStudioFormLink
                className={`${form !== 'link' ? 'd-none' : ''}`}
                setForm={setForm}
                selectedShapeId={selectedShapeId}
                secondarySelectedIds={secondarySelectedIds}
                setSelectedShapeId={setSelectedShapeId}
                shapes={shapes}
                onChange={handleChange}
                setShapes={setShapes}
              />
              <FormatStudioFormDB
                className={`${form !== 'database' ? 'd-none' : ''}`}
                setForm={setForm}
                selectedShapeId={selectedShapeId}
                secondarySelectedIds={secondarySelectedIds}
                setSelectedShapeId={setSelectedShapeId}
                shapes={shapes}
                onChange={handleChange}
                setShapes={setShapes}
              />
              <div className="d-flex justify-content-center lower-buttons">
                <div className="d-flex w-100 px-4">
                  <button
                    className={`btn-white-forms mx-2 justify-content-center align-items-center ${
                      (!isCoversStage &&
                        user.resources_permissions &&
                        user.resources_permissions.format.can_create_pdf) ||
                      (isCoversStage &&
                        user.resources_permissions &&
                        user.resources_permissions.cover.can_create_pdf)
                        ? 'd-flex'
                        : 'd-none'
                    }`}
                    onClick={() => generatePDF(300)}
                  >
                    Create PDF
                  </button>
                  <button
                    className="btn-blue-forms mx-2"
                    onClick={() => handleSave({ showMsg: true })}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
          {progressPDF ? (
            <Confirm
              isOpen={progressPDF}
              setIsOpen={null}
              title={'Saving PDF...'}
              msg={progressPDF + '% - ' + 'Please wait!'}
              cancelText={'Cancel'}
              progress={progressPDF}
              showClose={false}
              showProgress={true}
              showCancel={false}
              showConfirm={false}
            />
          ) : (
            ''
          )}
          <Confirm
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={'Save Changes!'}
            msg={'Do you want to save changes?'}
            confirmText={'Save'}
            cancelText={"Don't Save"}
            handleConfirm={() => handleCancelAfterConfirm(true)}
            handleCancel={() => handleCancelAfterConfirm(false)}
          />
        </div>
      </Hotkeys>
    )
  );
}

export default FormatsStage;

export const getInitialShapes = format => {
  if (!format) return [];
  let children = format.stage_json.children[0].children.filter(
    item =>
      item.className === 'Text' ||
      item.className === 'Rect' ||
      item.className === 'Ellipse' ||
      item.className === 'Star' ||
      item.className === 'Image' ||
      item.className === 'RegularPolygon' || // Trianglef
      item.className === 'Line' ||
      item.className === 'Grid'
  );
  return children.map(child => child.attrs);
};

export const KonvaStage = React.memo(
  ({
    stageRef,
    shapesLayerRef = null,
    isCoversStage = false,
    backgroundRef = null,
    format,
    scale,
    shapes,
    checkDeselect = () => {},
    marginsRectRef = null,
    bleedingRectRef = null,
    selectedShapeId = null,
    secondarySelectedIds = [],
    isIdInSecondarySelectedIds = () => {},
    setTextareaProps = {},
    isEditMode = false,
    setIsEditMode = () => {},
    setTextareaValue = () => {},
    setSelectedShapeId = () => {},
    handleSelect = () => {},
    handleDragMove = () => {},
    handleDragEnd = () => {},
    handleChange = () => {},
    showXAxis = false,
    showYAxis = false,
    setShowXAxis = () => {},
    setShowYAxis = () => {},
    showTopMargin = false,
    showBottomMargin = false,
    showLeftMargin = false,
    showRightMargin = false,
    showTopBleeding = false,
    showBottomBleeding = false,
    showLeftBleeding = false,
    showRightBleeding = false,
    editable = false,
    index = 0,
    shouldDeselcedEverything = false,
    handleClickOutside = () => {},
    handleStoryPagesCreation = null,
    pageNumber = 0,
  }) => {
    const trRef = useRef(null);
    const trRefPrim = useRef(null);
    const { isShiftDown } = useIsShiftDown();

    useEffect(() => {
      if (shouldDeselcedEverything) handleClickOutside();
    }, [shouldDeselcedEverything]);

    useEffect(() => {
      const nodes = getShapesNodes(stageRef, [selectedShapeId, ...secondarySelectedIds]);

      if (trRef.current) {
        trRef.current.nodes(nodes);
        trRef.current.getLayer().batchDraw();
      }

      if (trRefPrim.current) {
        const index = nodes.findIndex(node => node.attrs.id === selectedShapeId);
        const primNodes = nodes.length > 1 ? [nodes[index]] : [];
        trRefPrim.current.nodes(primNodes);
        trRefPrim.current.getLayer().batchDraw();
      }
    }, [selectedShapeId, secondarySelectedIds]);

    const handleTrForceUpdate = () => {
      setTimeout(() => {
        if (trRef && trRef.current) {
          trRef.current.forceUpdate();
        }
      }, 100);
    };

    return (
      <Stage
        key={index}
        ref={stageRef}
        width={Number(Math.floor((format.width * 96 * scale) / 100))}
        height={Number(Math.floor((format.height * 96 * scale) / 100))}
        offsetX={-Number(Math.floor(format.width * 96)) / 2}
        offsetY={-Number(Math.floor(format.height * 96)) / 2}
        className="shadow"
        onMouseDown={checkDeselect}
        onTouchStart={checkDeselect}
        scaleX={scale / 100}
        scaleY={scale / 100}
      >
        <Layer ref={shapesLayerRef}>
          <Rect
            ref={backgroundRef}
            key={'background-element'}
            fill={'white'}
            x={0}
            y={0}
            width={Number(Math.floor(format.width * 96))}
            height={Number(Math.floor(format.height * 96))}
            offsetX={Number(Math.floor(format.width * 96)) / 2}
            offsetY={Number(Math.floor(format.height * 96)) / 2}
          />

          {shapes.map(shape => {
            return (
              <>
                {shape.id.includes('grid') && (
                  <TGrid
                    type={
                      shape.id.includes('sudoku')
                        ? 'sudoku'
                        : shape.id.includes('word-search')
                        ? 'word-search'
                        : shape.id.includes('number-search')
                        ? 'number-search'
                        : 'cross-words'
                    }
                    key={shape.id}
                    shapeProps={shape}
                    isSelected={
                      shape.id === selectedShapeId || isIdInSecondarySelectedIds(shape.id)
                    }
                    setTextareaProps={setTextareaProps}
                    isEditMode={isEditMode}
                    setIsEditMode={setIsEditMode}
                    setTextareaValue={setTextareaValue}
                    scale={scale}
                    setSelectedShapeId={setSelectedShapeId}
                    shapes={shapes}
                    stageWidth={Number(Math.floor((format.width * 96 * scale) / 100))}
                    stageHeight={Number(Math.floor((format.height * 96 * scale) / 100))}
                    bleedingTop={format.bleeding.top * 96}
                    bleedingLeft={format.bleeding.left * 96}
                    onSelect={() => handleSelect(shape.id)}
                    onDragStart={() => handleSelect(shape.id, true)}
                    onDragMove={handleDragMove}
                    onDragEnd={handleDragEnd}
                    onChange={handleChange}
                    showXAxis={showXAxis}
                    showYAxis={showYAxis}
                    setShowXAxis={setShowXAxis}
                    setShowYAxis={setShowYAxis}
                    hasRings={
                      (shape.id.includes('word-search') || shape.id.includes('number-search')) &&
                      shape.id.includes('solution')
                    }
                    trForceUpdate={handleTrForceUpdate}
                  />
                )}
                {(shape.id.includes('word-list') || shape.id.includes('number-list')) && (
                  <TList
                    type={
                      shape.id.includes('article')
                        ? 'article'
                        : shape.id.includes('smart')
                        ? 'smart'
                        : 'classic'
                    }
                    isNumberList={!!shape.id.includes('number-list')}
                    key={shape.id}
                    shapeProps={shape}
                    isSelected={
                      shape.id === selectedShapeId || isIdInSecondarySelectedIds(shape.id)
                    }
                    setTextareaProps={setTextareaProps}
                    isEditMode={isEditMode}
                    setIsEditMode={setIsEditMode}
                    setTextareaValue={setTextareaValue}
                    scale={scale}
                    setSelectedShapeId={setSelectedShapeId}
                    shapes={shapes}
                    stageWidth={Number(Math.floor((format.width * 96 * scale) / 100))}
                    stageHeight={Number(Math.floor((format.height * 96 * scale) / 100))}
                    bleedingTop={format.bleeding.top * 96}
                    bleedingLeft={format.bleeding.left * 96}
                    onSelect={() => handleSelect(shape.id)}
                    onDragStart={() => handleSelect(shape.id, true)}
                    onDragMove={handleDragMove}
                    onDragEnd={handleDragEnd}
                    onChange={handleChange}
                    trForceUpdate={handleTrForceUpdate}
                    showXAxis={showXAxis}
                    showYAxis={showYAxis}
                    setShowXAxis={setShowXAxis}
                    setShowYAxis={setShowYAxis}
                    handleStoryPagesCreation={handleStoryPagesCreation} // for story
                    pageNumber={pageNumber}
                  />
                )}
                {shape.id.includes('text') &&
                  !shape.id.includes('word-list') &&
                  !shape.id.includes('number-list') &&
                  !shape.id.includes('table') && (
                    <TText
                      key={shape.id}
                      shapeProps={shape}
                      isSelected={
                        shape.id === selectedShapeId || isIdInSecondarySelectedIds(shape.id)
                      }
                      setTextareaProps={setTextareaProps}
                      isEditMode={isEditMode}
                      setIsEditMode={setIsEditMode}
                      setTextareaValue={setTextareaValue}
                      scale={scale}
                      setSelectedShapeId={setSelectedShapeId}
                      shapes={shapes}
                      stageWidth={Number(Math.floor((format.width * 96 * scale) / 100))}
                      stageHeight={Number(Math.floor((format.height * 96 * scale) / 100))}
                      bleedingTop={format.bleeding.top * 96}
                      bleedingLeft={format.bleeding.left * 96}
                      marginLeft={format.margin.left * 96}
                      marginRight={format.margin.right * 96}
                      onSelect={() => handleSelect(shape.id)}
                      onDragStart={() => handleSelect(shape.id, true)}
                      onDragMove={handleDragMove}
                      onDragEnd={handleDragEnd}
                      onChange={handleChange}
                      showXAxis={showXAxis}
                      showYAxis={showYAxis}
                      setShowXAxis={setShowXAxis}
                      setShowYAxis={setShowYAxis}
                    />
                  )}

                {shape.id.includes('table') && (
                  <TTableOfContents
                    key={shape.id}
                    type={shape.id.includes('classic') && 'classic'}
                    shapeProps={shape}
                    isSelected={
                      shape.id === selectedShapeId || isIdInSecondarySelectedIds(shape.id)
                    }
                    setTextareaProps={setTextareaProps}
                    isEditMode={isEditMode}
                    setIsEditMode={setIsEditMode}
                    setTextareaValue={setTextareaValue}
                    scale={scale}
                    setSelectedShapeId={setSelectedShapeId}
                    stageWidth={Number(Math.floor((format.width * 96 * scale) / 100))}
                    stageHeight={Number(Math.floor((format.height * 96 * scale) / 100))}
                    bleedingTop={format.bleeding.top * 96}
                    bleedingLeft={format.bleeding.left * 96}
                    onSelect={() => handleSelect(shape.id)}
                    onDragStart={() => handleSelect(shape.id, true)}
                    onDragMove={handleDragMove}
                    onDragEnd={handleDragEnd}
                    onChange={handleChange}
                    trForceUpdate={handleTrForceUpdate}
                    showXAxis={showXAxis}
                    showYAxis={showYAxis}
                    setShowXAxis={setShowXAxis}
                    setShowYAxis={setShowYAxis}
                  />
                )}
                {shape.id.includes('image') && (
                  <TImage
                    key={shape.id}
                    shapeProps={shape}
                    imgID={shape.imgID}
                    src={shape.src}
                    isSelected={
                      shape.id === selectedShapeId || isIdInSecondarySelectedIds(shape.id)
                    }
                    onSelect={() => handleSelect(shape.id)}
                    onDragStart={() => handleSelect(shape.id, true)}
                    onDragMove={handleDragMove}
                    onDragEnd={handleDragEnd}
                    onChange={handleChange}
                    showXAxis={showXAxis}
                    showYAxis={showYAxis}
                    setShowXAxis={setShowXAxis}
                    setShowYAxis={setShowYAxis}
                  />
                )}
                {shape.id.includes('rect') && (
                  <TRectangle
                    key={shape.id}
                    shapeProps={shape}
                    isSelected={
                      shape.id === selectedShapeId || isIdInSecondarySelectedIds(shape.id)
                    }
                    isRounded={shape.id.includes('rounded')}
                    onSelect={() => handleSelect(shape.id)}
                    onDragStart={() => handleSelect(shape.id, true)}
                    onDragMove={handleDragMove}
                    onDragEnd={handleDragEnd}
                    onChange={handleChange}
                    showXAxis={showXAxis}
                    showYAxis={showYAxis}
                    setShowXAxis={setShowXAxis}
                    setShowYAxis={setShowYAxis}
                  />
                )}
                {shape.id.includes('circle') && (
                  <TCircle
                    key={shape.id}
                    shapeProps={shape}
                    isSelected={
                      shape.id === selectedShapeId || isIdInSecondarySelectedIds(shape.id)
                    }
                    onSelect={() => handleSelect(shape.id)}
                    onDragStart={() => handleSelect(shape.id, true)}
                    onDragMove={handleDragMove}
                    onDragEnd={handleDragEnd}
                    onChange={handleChange}
                    showXAxis={showXAxis}
                    showYAxis={showYAxis}
                    setShowXAxis={setShowXAxis}
                    setShowYAxis={setShowYAxis}
                  />
                )}
                {shape.id.includes('triangle') && (
                  <TTriangle
                    key={shape.id}
                    shapeProps={shape}
                    isSelected={
                      shape.id === selectedShapeId || isIdInSecondarySelectedIds(shape.id)
                    }
                    onSelect={() => handleSelect(shape.id)}
                    onDragStart={() => handleSelect(shape.id, true)}
                    onDragMove={handleDragMove}
                    onDragEnd={handleDragEnd}
                    onChange={handleChange}
                    showXAxis={showXAxis}
                    showYAxis={showYAxis}
                    setShowXAxis={setShowXAxis}
                    setShowYAxis={setShowYAxis}
                  />
                )}
                {shape.id.includes('star') && (
                  <TStar
                    key={shape.id}
                    shapeProps={shape}
                    isSelected={
                      shape.id === selectedShapeId || isIdInSecondarySelectedIds(shape.id)
                    }
                    onSelect={() => handleSelect(shape.id)}
                    onDragStart={() => handleSelect(shape.id, true)}
                    onDragMove={handleDragMove}
                    onDragEnd={handleDragEnd}
                    onChange={handleChange}
                    showXAxis={showXAxis}
                    showYAxis={showYAxis}
                    setShowXAxis={setShowXAxis}
                    setShowYAxis={setShowYAxis}
                  />
                )}
                {shape.id.includes('line') && (
                  <TLine
                    key={shape.id}
                    shapeProps={shape}
                    isSelected={
                      shape.id === selectedShapeId || isIdInSecondarySelectedIds(shape.id)
                    }
                    onSelect={() => handleSelect(shape.id)}
                    onDragStart={() => handleSelect(shape.id, true)}
                    onDragMove={handleDragMove}
                    onDragEnd={handleDragEnd}
                    onChange={handleChange}
                    showXAxis={showXAxis}
                    showYAxis={showYAxis}
                    setShowXAxis={setShowXAxis}
                    setShowYAxis={setShowYAxis}
                  />
                )}
              </>
            );
          })}
        </Layer>
        <Layer>
          {
            // editable &&
            format.bleeding &&
              (format.bleeding.top > 0 ||
                format.bleeding.right > 0 ||
                format.bleeding.bottom > 0 ||
                format.bleeding.left > 0) && (
                <Rect
                  key={'paper-boundries-rectangle'}
                  ref={bleedingRectRef}
                  fillEnabled={false}
                  x={format.bleeding.left * 96 - Number(Math.floor(format.width * 96)) / 2}
                  y={format.bleeding.top * 96 - Number(Math.floor(format.height * 96)) / 2}
                  width={(format.width - format.bleeding.left - format.bleeding.right) * 96}
                  height={(format.height - format.bleeding.top - format.bleeding.bottom) * 96}
                  stroke={'#ff0000'}
                  // dash={[10, 10]}
                  strokeWidth={100 / scale}
                />
              )
          }

          {
            // editable &&
            format.margin &&
              (format.margin.top > 0 ||
                format.margin.right > 0 ||
                format.margin.bottom > 0 ||
                format.margin.left > 0) && (
                <Rect
                  key={'margins-rectangle'}
                  ref={marginsRectRef}
                  fillEnabled={false}
                  x={format.margin.left * 96 - Number(Math.floor(format.width * 96)) / 2}
                  y={format.margin.top * 96 - Number(Math.floor(format.height * 96)) / 2}
                  width={(format.width - format.margin.left - format.margin.right) * 96}
                  height={(format.height - format.margin.top - format.margin.bottom) * 96}
                  stroke={'blue'}
                  dash={[10, 10]}
                  strokeWidth={100 / scale}
                />
              )
          }

          {showXAxis && (
            <Line
              points={[-1000000, 0, 1000000, 0]}
              stroke={'gray'}
              strokeWidth={1}
              name={'guid-line-x'}
              dash={[4, 6]}
            />
          )}
          {showYAxis && (
            <Line
              points={[0, -1000000, 0, 1000000]}
              stroke={'gray'}
              strokeWidth={1}
              name={'guid-line-y'}
              dash={[4, 6]}
            />
          )}
          {showTopMargin && (
            <Line
              points={[
                -1000000,
                (-format.height * 96) / 2 + format.margin.top * 96,
                1000000,
                (-format.height * 96) / 2 + format.margin.top * 96,
              ]}
              stroke={'gray'}
              strokeWidth={1}
              name={'guid-line-margin-top'}
              dash={[4, 6]}
            />
          )}
          {showBottomMargin && (
            <Line
              points={[
                -1000000,
                (format.height * 96) / 2 - format.margin.bottom * 96,
                1000000,
                (format.height * 96) / 2 - format.margin.bottom * 96,
              ]}
              stroke={'gray'}
              strokeWidth={1}
              name={'guid-line-margin-bottom'}
              dash={[4, 6]}
            />
          )}
          {showLeftMargin && (
            <Line
              points={[
                (-format.width * 96) / 2 + format.margin.left * 96,
                -1000000,
                (-format.width * 96) / 2 + format.margin.left * 96,
                1000000,
              ]}
              stroke={'gray'}
              strokeWidth={1}
              name={'guid-line-margin-left'}
              dash={[4, 6]}
            />
          )}
          {showRightMargin && (
            <Line
              points={[
                (format.width * 96) / 2 - format.margin.right * 96,
                -1000000,
                (format.width * 96) / 2 - format.margin.bottom * 96,
                1000000,
              ]}
              stroke={'gray'}
              strokeWidth={1}
              name={'guid-line-margin-right'}
              dash={[4, 6]}
            />
          )}

          {isCoversStage && showTopBleeding && (
            <Line
              points={[
                -1000000,
                (-format.height * 96) / 2 + format.bleeding.top * 96,
                1000000,
                (-format.height * 96) / 2 + format.bleeding.top * 96,
              ]}
              stroke={'gray'}
              strokeWidth={1}
              name={'guid-line-margin-top'}
              dash={[4, 6]}
            />
          )}
          {isCoversStage && showBottomBleeding && (
            <Line
              points={[
                -1000000,
                (format.height * 96) / 2 - format.bleeding.bottom * 96,
                1000000,
                (format.height * 96) / 2 - format.bleeding.bottom * 96,
              ]}
              stroke={'gray'}
              strokeWidth={1}
              name={'guid-line-margin-bottom'}
              dash={[4, 6]}
            />
          )}
          {isCoversStage && showLeftBleeding && (
            <Line
              points={[
                (-format.width * 96) / 2 + format.bleeding.left * 96,
                -1000000,
                (-format.width * 96) / 2 + format.bleeding.left * 96,
                1000000,
              ]}
              stroke={'gray'}
              strokeWidth={1}
              name={'guid-line-margin-left'}
              dash={[4, 6]}
            />
          )}
          {isCoversStage && showRightBleeding && (
            <Line
              points={[
                (format.width * 96) / 2 - format.bleeding.right * 96,
                -1000000,
                (format.width * 96) / 2 - format.bleeding.bottom * 96,
                1000000,
              ]}
              stroke={'gray'}
              strokeWidth={1}
              name={'guid-line-margin-right'}
              dash={[4, 6]}
            />
          )}

          {isCoversStage && (
            <Line
              points={[
                (-format.width * 96) / 2 + format.spineBoundries.left * 96,
                -1000000,
                (-format.width * 96) / 2 + format.spineBoundries.left * 96,
                1000000,
              ]}
              stroke={'#ff0000'}
              strokeWidth={1}
              name={'guid-line-spine-left'}
              dash={[4, 6]}
            />
          )}

          {isCoversStage && (
            <Line
              points={[
                (-format.width * 96) / 2 + format.spineBoundries.right * 96,
                -1000000,
                (-format.width * 96) / 2 + format.spineBoundries.right * 96,
                1000000,
              ]}
              stroke={'#ff0000'}
              strokeWidth={1}
              name={'guid-line-spine-right'}
              dash={[4, 6]}
            />
          )}

          {selectedShapeId && (
            <>
              <Transformer
                // ref={trRef.current[getKey]}
                ref={trRef}
                boundBoxFunc={(oldBox, newBox) => {
                  // limit resize
                  if (newBox.width < 5 || newBox.height < 5) {
                    return oldBox;
                  }
                  return newBox;
                }}
                rotationSnaps={[0, 90, 180, 270]}
                rotateAnchorOffset={25}
                centeredScaling={isShiftDown ? true : false}
                shouldOverdrawWholeArea={
                  (selectedShapeId &&
                    ((selectedShapeId.includes('text') && !selectedShapeId.includes('story')) ||
                      selectedShapeId.includes('grid')) &&
                    !secondarySelectedIds.length) ||
                  isShiftDown
                    ? false
                    : true
                }
                enabledAnchors={
                  !secondarySelectedIds.length
                    ? (selectedShapeId.includes('text') &&
                        !selectedShapeId.includes('story-word-list-text')) ||
                      selectedShapeId.includes('line')
                      ? ANCHORS.sides
                      : selectedShapeId.includes('star') ||
                        selectedShapeId.includes('triangle') ||
                        (selectedShapeId.includes('grid') &&
                          !selectedShapeId.includes('word-search'))
                      ? ANCHORS.corners
                      : undefined
                    : undefined
                }
              />
              {/* This Transformer to defien primary shape (selected first)*/}
              <Transformer
                // ref={trRef.current[getKey]}
                ref={trRefPrim}
                borderStroke={colors.yellow}
                // borderDash={[10,3]}
                borderStrokeWidth={2}
                enabledAnchors={false}
                rotateEnabled={false}
              />
            </>
          )}
        </Layer>
      </Stage>
    );
  }
);
