import axios from 'axios';
import urls from 'settings/urls.js';

// Covers
export async function getCovers(category_id, sort, tags, offset) {
  const requestOptions = {
    method: 'GET',
    url: urls.covers(category_id, sort, tags, offset),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function getOneCover(id) {
  const requestOptions = {
    method: 'GET',
    url: urls.oneCover(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function addCover(data) {
  const requestOptions = {
    method: 'POST',
    url: urls.newCover,
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function editCover(data, id) {
  const requestOptions = {
    method: 'PATCH',
    url: urls.editCover(id),
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function deleteCover(id) {
  const requestOptions = {
    method: 'DELETE',
    url: urls.deleteCover(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

// Paper Thickness List
export async function getPaperThicknessList(offset, limit) {
  const requestOptions = {
    method: 'GET',
    url: urls.paperThicknessList(offset, limit),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function addPaperThickness(data) {
  const requestOptions = {
    method: 'POST',
    url: urls.newPaperThickness,
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function editPaperThickness(data, id) {
  const requestOptions = {
    method: 'PATCH',
    url: urls.editPaperThickness(id),
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function deletePaperThickness(id) {
  const requestOptions = {
    method: 'DELETE',
    url: urls.deletePaperThickness(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}
