import React from 'react';
import Main from 'layouts/Main';
import Slider from 'components/common/Slider';
import TopControls from 'components/common/TopControls';
import { useParams } from 'react-router-dom';

function ManageElements() {
  const { elType } = useParams();
  return (
    <div className="manage-formats-page">
      <Main>
        <TopControls
          btns={[
            {
              text: 'Elements',
              path: `/elements/${elType}`,
            },
            {
              text: 'Manage Elements',
              path: `/elements/${elType}/manage`,
              selected: true,
            },
          ]}
        />
        <div className="resources">
          <Slider
            title={'Categories'}
            type={'element-type'}
            newTitle={'New Category'}
            showTitle={'categories'}
          />
        </div>
      </Main>
    </div>
  );
}

export default ManageElements;
