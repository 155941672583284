import React from 'react';
import { capitalizeFirst } from 'helpers/text';
import { useHistory } from 'react-router-dom';

function SideMenuItem({name, route, active}) {
  const history = useHistory();
  return (
    <div className={`noselect side-menu-item ${active ? 'active' : ''}`} onClick={() => {if(!active) history.push(route)}}>
      <div className="title">{capitalizeFirst(name)}</div>
    </div>
  );
}

export default SideMenuItem;