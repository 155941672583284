// Built-in
import React, { useState, useEffect, useCallback } from 'react';

// External
import { useHistory } from 'react-router-dom';
import { useQuery } from 'hooks/router';
import { default as SlideModal } from 'react-slide-out';

// API
import { getTags } from 'api/common';
import { getOneTemplate } from 'api/studio/templates';
import { getPageSizes } from 'api/studio/formats';

// Context
import { useStudioContext } from 'hooks/studio';

// Components
import Main from 'layouts/Main';
import TabsNavigator from 'components/studio/TabsNavigator';
import TemplatesStage from 'components/studio/TemplatesStage';
import TemplateSpecForm from 'components/forms/TemplateSpecForm';

function TemplateWorkspace() {
  // state
  const [showSlideModal, setShowSlideModal] = useState(false);
  const [allowedTags, setAllowedTags] = useState([]);
  const [tagsArr] = useState([]);
  const [, setTags] = useState(''); // templateted String
  const [pageSizes, setPageSizes] = useState([]);
  const [toRemove, setToRemove] = useState(null);

  // hooks
  const history = useHistory();
  const query = useQuery();
  const { templates, setTemplates, selectedTemplate, setSelectedTemplate } = useStudioContext();

  // methods
  const multipleAPICalls = useCallback(async function (list, callFn) {
    const requests = list.map(item => callFn(item));
    return Promise.all(requests);
  }, []);

  const addTemplateToWorkspace = template => {
    const list = [...templates];
    list.push(template);
    setTemplates(list);
  };

  const loadTemplates = useCallback(
    async function (ids) {
      try {
        const list = await multipleAPICalls(ids, getOneTemplate);
        setTemplates(list.map(res => (res.success ? res.template : {})));
      } catch {
        history.replace('/');
      }
    },
    [multipleAPICalls, history, setTemplates]
  );

  const ids = query.get('ids');
  useEffect(() => {
    const idsArr = ids && ids.length ? ids.split(',') : null;
    if (idsArr) loadTemplates(idsArr);
    else history.replace('/');

    return () => {
      setTemplates([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTags({ kind: 'studio_formats' }).then(res => {
      if (res.success) {
        setAllowedTags(res.tags);
      } else {
        console.log(res.errors);
      }
    });
  }, []);

  useEffect(() => {
    getPageSizes(0, 10000).then(res => {
      // page sizes can't be more than 10000
      if (res.success) {
        setPageSizes(res.page_sizes);
      } else {
        console.log(res.errors);
      }
    });
  }, []);

  // watchers
  useEffect(() => {
    if (templates.length) {
      setSelectedTemplate(templates[templates.length - 1]);
      history.replace(`/home/templates/workspace?ids=${templates.map(f => f.id).join(',')}`);
    }
  }, [templates, templates.length, history, setSelectedTemplate]);

  useEffect(() => {
    let str = '';
    tagsArr.forEach(tag => {
      const index = allowedTags.findIndex(
        allowedTag => allowedTag.name.toLowerCase() === tag.name.toLowerCase()
      );
      if (index > -1) {
        str += `&tag=${allowedTags[index].id}`;
      }
    });
    setTags(str);
  }, [tagsArr, allowedTags]);

  // render
  return (
    <Main>
      <TabsNavigator
        type={'template'}
        items={templates}
        selectedItem={selectedTemplate}
        setSelectedItem={setSelectedTemplate}
        setShowSlideModal={setShowSlideModal}
        setToRemove={setToRemove}
      />
      {templates.length > 0 &&
        templates.map(template => {
          return (
            <TemplatesStage
              key={template.id}
              template={template}
              show={template.id === selectedTemplate.id}
              toRemove={toRemove}
              setToRemove={setToRemove}
              allowedTags={allowedTags}
            />
          );
        })}
      <SlideModal
        isOpen={showSlideModal}
        onOutsideClick={() => {
          setShowSlideModal(false);
        }}
      >
        <TemplateSpecForm
          allowedTags={allowedTags}
          pageSizes={pageSizes}
          templateCategoryId={selectedTemplate ? selectedTemplate.category : 1}
          closeSlideModal={() => {
            setShowSlideModal(false);
          }}
          addTemplateToWorkspace={addTemplateToWorkspace}
        />
      </SlideModal>
    </Main>
  );
}

export default TemplateWorkspace;
