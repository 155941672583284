import React from 'react';
import { useHistory } from 'react-router-dom';

function TopControls({ btns }) {
  const history = useHistory();
  return (
    <div className="top-controls shadow-sm ">
      {btns.map(
        btn =>
          !btn.hidden && (
            <button
              key={btn.path}
              className={`${btn.selected && 'active'}`}
              onClick={() => {
                if (btn.action) btn.action();
                history.push(btn.path);
              }}
            >
              {btn.text}
            </button>
          )
      )}
    </div>
  );
}

export default TopControls;
