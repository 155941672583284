import axios from 'axios';
import urls from 'settings/urls.js';

// Formats
export async function getLanguages(params) {
  const requestOptions = {
    method: 'GET',
    url: urls.languages,
    parmas: params || {
      offset: 0,
      limit: 10000,
    },
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function getOneLanguage(id) {
  const requestOptions = {
    method: 'GET',
    url: urls.oneLanguage(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function addLanguage(data) {
  const requestOptions = {
    method: 'POST',
    url: urls.addLanguage,
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function editLanguage(data, id) {
  const requestOptions = {
    method: 'PATCH',
    url: urls.editLanguage(id),
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function deleteLanguage(id) {
  const requestOptions = {
    method: 'DELETE',
    url: urls.deleteLanguage(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}
