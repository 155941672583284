import React, { useState, useEffect } from 'react';
import 'components/common/List.scss';
// import ListControls from 'components/common/ListControls';
import { countWords } from 'helpers/text';

function VersionList({
  listName,
  title,
  paragraphs,
  setParagraphs,
  // selectedParagraphs,
  // setSelectedParagraphs,
  size,
  transfer,
  transferTrig,
  setTransferTrig,
  // formatVersion,
  // updateList,
  // sortList,
  // letterCase,
  // additionalLetterCase=false,
  // setLetterCase,
  isReadOnly,
  selectable = true,
}) {
  const [search, setSearch] = useState('');
  const [focus, setFocus] = useState(0);
  // const [sortDir, setSortDir] = useState('Sort');

  // const handleSelectToggle = (index, deslectAllFirst) => {
  //   if (selectable) {
  //     let list;
  //     if (deslectAllFirst) list = [];
  //     else list = selectedParagraphs.slice();
  //     if (selectedParagraphs.includes(index)) {
  //       const i = list.findIndex(item => item === index);
  //       list.splice(i, 1);
  //     } else {
  //       list.push(index);
  //     }
  //     setSelectedParagraphs(list);
  //   }
  // };

  // const selectAll = () => {
  //   if (selectable) setSelectedParagraphs([...Array(paragraphs.length).keys()]);
  // };

  // const deslectAll = () => {
  //   setSelectedParagraphs([]);
  // };

  // const removeDuplicates = () => {
  //   if (!isReadOnly)
  //     updateList(setParagraphs, list =>
  //       [...new Set(list)]
  //         .filter((v, i, a) => a.findIndex(t => t === v) === i)
  //         .filter(i => i !== '')
  //     );
  // };

  // const removeEmptyCells = () => {
  //   if (!isReadOnly)
  //     updateList(setParagraphs, list => [...list].filter(i => i !== ''));
  // };

  // const transferItems = all => {
  //   const list = paragraphs.slice();
  //   let filteredList;
  //   if (all) filteredList = list;
  //   else filteredList = list.filter((el, i) => selectedParagraphs.includes(i));
  //   transfer(filteredList);
  //   if (all) setParagraphs([]);
  //   // else deleteSelected();
  // };

  // const deleteSelected = () => {
  //   if (!isReadOnly)
  //     updateList(setParagraphs, list =>
  //       list.filter((el, i) => !selectedParagraphs.includes(i))
  //     );
  // };

  // const toggleLetterCase = () => {
  //   const options = ['aa', 'Aa', 'AA'];
  //   if(additionalLetterCase) options.unshift('--');
  //   let i = (options.findIndex(o => o === letterCase) + 1) % 3;
  //   setLetterCase(options[i]);
  // };

  // const toggleSort = () => {
  //   const options = ['Sort', 'Ascending', 'Descending'];
  //   let i = (options.findIndex(o => o === sortDir) + 1) % 3;
  //   if (i === 0) i = 1;

  //   setSortDir(options[i]);
  // };

  const focusPrev = () => {
    if (focus > -1) setFocus(focus - 1);
  };

  const focusNext = () => {
    if (focus < paragraphs.length - 1) setFocus(focus + 1);
  };

  // useEffect(() => {
  //   if (!isReadOnly)
  //     updateList(setParagraphs, list =>
  //       list.map(word => formatVersion(letterCase, word))
  //     );
  // }, [letterCase]);

  // useEffect(() => {
  //   if (!isReadOnly) sortList(sortDir);
  // }, [sortDir]);

  // useEffect(() => {
  //   if (!isReadOnly) {
  //     updateList(setParagraphs, list =>
  //       list.map(word => formatVersion(letterCase, word))
  //     );
  //     setSortDir('Ascending');
  //   }
  // }, [paragraphs.length]);

  // useEffect(() => {
  //   if (!isReadOnly) removeEmptyCells();
  // }, [paragraphs.length]);

  useEffect(() => {
    if (!isReadOnly && transferTrig.val) {
      // transferItems(transferTrig.all);
      setTransferTrig({ val: false, all: false });
    }
  }, [transferTrig && transferTrig.val]);

  // useEffect(() => {
  //   if (!isReadOnly) {
  //     if (focus > -1) handleSelectToggle(focus, true);
  //     else {
  //       deslectAll();
  //       const el = document.getElementById(`${listName}-option-${focus}`);
  //       if (el) el.focus();
  //     }
  //   }
  // }, [focus]);

  // useEffect(() => {
  //   if (!isReadOnly && selectedParagraphs.length) setSelectedParagraphs([]);
  // }, [search]);

  return (
    <div
      tabIndex="0"
      className="list"
      // onKeyUp={e => {
      //   e.preventDefault();
      //   e.stopPropagation();
      //   if (e.ctrlKey && e.key === 'a' || e.metaKey && e.key === 'a') {
      //     selectAll();
      //   }
      //   if (e.key === 'ArrowDown') {
      //     focusNext();
      //   }
      //   if (e.key === 'ArrowUp') {
      //     focusPrev();
      //   }
      // }}
    >
      <h4 className="list__title">{title}</h4>
      {/* {!isReadOnly && (
        <ListControls
          search={search}
          setSearch={setSearch}
          setFocus={setFocus}
          listName={listName}
          toggleSort={toggleSort}
          sortDir={sortDir}
          letterCase={letterCase}
          toggleLetterCase={toggleLetterCase}
          removeDuplicates={removeDuplicates}
          selectedItems={selectedParagraphs}
          deleteSelected={deleteSelected}
        />
      )} */}

      <div style={{ height: size * 22 + 20 }} className="list__list-wrapper shadow-sm border">
        <div
          style={{ height: size * 22 }}
          className="list__list"
          onClick={e => {
            e.target.focus();
            !search && setFocus(paragraphs.length);
          }}
        >
          {paragraphs.map(
            (paragraph, index) =>
              (!search || (search && paragraph.includes(search))) && (
                <div
                  className={`list__option--paragraph`}
                  // className={`list__option--paragraph ${
                  //   selectedParagraphs.includes(index) ? 'selected' : ''
                  // } ${focus === index ? 'focus' : ''}`}
                  key={index}
                  id={`${listName}-option-${index}`}
                  // onClick={e => {
                  //   e.stopPropagation();
                  //   if (e.ctrlKey) handleSelectToggle(index);
                  //   else {
                  //     handleSelectToggle(index, true);
                  //   }
                  // }}
                >
                  <div>
                    <span>
                      <pre>{paragraph}</pre>
                    </span>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
      <div className="list__list--counter">
        <span>
          {/* {selectedParagraphs && selectedParagraphs.length
            ? `${selectedParagraphs.length} selected`
            : ''} */}
        </span>
        <span>
          {countWords(paragraphs[0])} Word{countWords(paragraphs[0]) === 1 ? '' : 's'}
        </span>
      </div>
    </div>
  );
}

export default VersionList;
