import SelectWrapper from 'components/forms/elements/SelectWrapper';
import Button from 'components/common/Button';
import { capitalizeFirst } from 'helpers/text';
import _ from 'lodash';

function DatabaseForm({
  isSwaps,
  setIsSwaps,
  languages,
  language,
  setLanguage,
  versionNames,
  versionName,
  setVersionName,
  isLoading,
  handleUpdateVariablesRequest,
  // runCounters
}) {
  return (
    <div>
      <div className="form-group">
        <div className="list-filters-section d-flex align-items-center gap-1">
          {['variables', 'swaps'].map((opt, index) => (
            <div
              key={opt}
              className={`list-filters difficulty w-50 text-center ${
                (opt === 'swaps' && isSwaps) || (opt === 'variables' && !isSwaps) ? 'selected' : ''
              }`}
              onClick={() => {
                setIsSwaps(opt === 'swaps');
              }}
            >
              {capitalizeFirst(opt)}&nbsp;
            </div>
          ))}
        </div>
      </div>
      <div className=" d-flex flex-column mb-3 form-group">
        <label className="mb-3">Data Language</label>
        <SelectWrapper
          options={languages.map(lang => ({
            label: lang.name,
            value: lang.id,
          }))}
          value={language}
          setValue={setLanguage}
        />
      </div>
      <div className=" d-flex flex-column mb-3 form-group">
        <label className="mb-3">Data Version</label>
        <SelectWrapper
          options={versionNames.map(v => ({
            label: v.title,
            value: v.id,
          }))}
          value={versionName}
          setValue={setVersionName}
        />
      </div>
      <div className="d-flex justify-content-center mt-4">
        <Button
          disabled={isLoading}
          onClick={handleUpdateVariablesRequest}
          label={'Update variables'}
          className="btn-blue-forms"
          style={{ width: 200, height: 40 }}
        />
      </div>
      {/* <div className="d-flex justify-content-center mt-4">
        <Button
          disabled={isLoading}
          onClick={runCounters}
          label={'Run Counters'}
          className="btn-blue-forms"
          style={{ width: 160, height: 40 }}
        />
      </div> */}
      <div className="d-flex justify-content-center mt-4"></div>
    </div>
  );
}

export default DatabaseForm;
