export const dummyText =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.";

export const dummyStory = `
In the quiet town of Eldenwood, nestled between rolling hills and ancient forests, there lived a young girl named Clara. Clara was an inquisitive soul, her days spent exploring the winding paths of the forest and the old, forgotten trails that crisscrossed the countryside. Eldenwood was a place of whispered legends and old magic, where the veil between the ordinary and the extraordinary was thin.

One misty morning, Clara decided to venture deeper into the forest than she ever had before. The air was thick with the scent of pine and earth, and the only sounds were the rustling leaves and the distant call of a bird. As she walked, she came upon a path she had never seen, a narrow, overgrown trail that seemed to beckon her forward. Curiosity getting the better of her, she followed it.

The trail wound deeper and deeper into the heart of the forest, the trees growing taller and more ancient. Clara’s excitement grew with each step, her imagination conjuring up all sorts of wonders that might lie ahead. Finally, the path ended at a small clearing, bathed in a soft, golden light. In the center of the clearing stood an old, gnarled tree, its branches reaching out like welcoming arms. At the base of the tree was a door, small and wooden, with intricate carvings that seemed to shift and change in the light.


Clara approached the door with a mixture of trepidation and excitement. Her fingers traced the carvings, which felt warm and alive under her touch. Taking a deep breath, she pushed the door open and stepped inside. To her astonishment, she found herself in a grand hall, filled with a soft, shimmering light. The walls were lined with shelves, each holding an array of peculiar objects: glowing stones, ancient tomes, and bottles filled with swirling, colorful liquids.

In the center of the hall stood a figure, tall and cloaked in a deep, emerald green robe. The figure turned to face Clara, revealing a kindly face with twinkling eyes. "Welcome, Clara," the figure said in a voice that resonated with warmth and wisdom. "I have been expecting you."

"Who are you?" Clara asked, her voice barely a whisper.

"I am Elowen, the guardian of the forest’s secrets," the figure replied. "This place is a sanctuary for the magic that sustains our world, and you, Clara, have been chosen to be its next guardian."

Clara’s eyes widened in disbelief. "Me? But why me?"

Elowen smiled gently. "You have the heart and spirit of an explorer, and a deep connection to the magic of this land. It is a rare and precious gift."


Over the next days, Clara learned from Elowen the ways of the forest and the ancient magic that flowed through it. She discovered that the objects in the hall were not just curiosities but powerful artifacts that could heal, protect, and transform. Elowen taught her how to harness the energy of the forest, to listen to the whispers of the trees, and to read the stories written in the stars.

As the weeks passed, Clara grew more confident in her new role. She learned to blend the magic with her everyday life, helping the people of Eldenwood in ways they could never imagine. She healed sick animals, protected the town from storms, and ensured the crops thrived. The townsfolk, though unaware of the source of their newfound fortune, were grateful and happier than ever.

One day, Elowen came to Clara with a solemn expression. "My time here is ending, Clara," she said. "You are ready to be the guardian of Eldenwood. Trust in yourself and in the magic that flows through you."

Clara felt a pang of sadness but also a sense of resolve. She hugged Elowen tightly, feeling the warmth and love of her mentor. "Thank you for everything," she whispered.

Elowen smiled. "Remember, Clara, the magic is a part of you now. Use it wisely and with a kind heart."

With that, Elowen vanished, leaving Clara standing alone in the grand hall. But she didn’t feel alone. She felt the presence of the forest, the magic, and the countless generations of guardians who had come before her. Clara stepped outside into the golden light of the clearing, ready to embrace her destiny and protect the enchanted world of Eldenwood.`;

export const dummyStory2 = `
Lily had always loved visiting her grandmother's house in the countryside, nestled at the edge of Whispering Woods, where the old cottage exuded a charm that captivated her imagination. At twelve years old, Lily was known for her insatiable curiosity and adventurous spirit. But it was the woods that held her fascination the most.

One sunny afternoon, while exploring her grandmother's attic—a treasure trove of forgotten memories—Lily stumbled upon an old, weathered map tucked away in a dusty trunk. The map was unlike anything she had seen before, adorned with intricate symbols and faded ink that hinted at something mysterious hidden deep within Whispering Woods.

Heart racing with excitement, Lily spread the map across the attic floor. It depicted winding paths, gnarled trees, and a marked spot with the cryptic words: "X marks the spot!" Could it be a buried treasure, left behind by pirates or forgotten explorers?

Determined to uncover the truth, Lily packed a small backpack with provisions—a flashlight, some snacks, and a trusty compass—and set off into Whispering Woods. The late afternoon sun cast long shadows through the trees, dappling the forest floor with golden light as she ventured deeper into the heart of the woods.

As Lily followed the map's directions, she encountered challenges along the way. A rickety wooden bridge stretched precariously over a babbling brook, its ancient boards creaking beneath her feet. With careful steps and a steady heart, she crossed to the other side, feeling a rush of triumph as she continued her journey.

Further along the path, a mischievous squirrel chattered overhead, dropping acorns that bounced off her backpack. Laughing softly, Lily tossed a few crumbs of her snack to the furry creature, making a new forest friend before pressing onward.

The map led Lily to a dark, foreboding cave nestled in the shadow of a towering oak tree. With a deep breath, she summoned her courage and entered the cool darkness, her flashlight casting eerie shadows on the rough stone walls. The cave echoed with mysterious whispers, as if the very stones held secrets waiting to be revealed.

At last, deep within the cave, Lily discovered the spot marked on the map—a small alcove hidden behind a curtain of shimmering stalactites. There, nestled amidst a bed of moss, lay an ancient chest adorned with intricate carvings and adorned with rusted iron bands.

But as Lily approached the chest, a low growl reverberated through the cave, sending shivers down her spine. Out from the shadows stepped a mythical creature—a guardian of the treasure, its eyes gleaming with curiosity and caution. Lily held her breath, unsure of what to do next.

Remembering her grandmother's tales of kindness and courage, Lily slowly approached the creature, offering a handful of her remaining snacks as a gesture of friendship. The creature sniffed the offering tentatively before accepting it, its demeanor softening as trust bloomed between them.

Together, Lily and the creature opened the chest, revealing not gold or jewels, but a collection of cherished memories—a faded photograph, a well-worn journal, and a heartfelt letter from her grandmother, recounting adventures from her own childhood.

With the treasure uncovered and a new friendship forged, Lily bid farewell to the creature and Whispering Woods. As she returned to her grandmother's cottage, the setting sun painted the sky in hues of orange and pink, casting a warm glow over the familiar path home.

With a heart full of gratitude and wonder, Lily realized that sometimes the greatest treasures are not found in gold or riches, but in the memories we make and the connections we cherish along the way. And so, nestled under the watchful gaze of Whispering Woods, Lily carried the stories of her adventure in her heart—a testament to the magic that awaits those brave enough to seek it.
`;
