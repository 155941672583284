// This will be a konva group that will contain the table of content
// table will look like this

//                   Table of Contents
// 1. Introduction .......................................... 1
// 2. Chapter 1 ............................................. 2
// 3. Chapter 2 ............................................. 3
// 4. Chapter 3 ............................................. 4
// 5. Chapter 4 ............................................. 5
// 6. Chapter 5 ............................................. 6
// 7. Conclusion ............................................ 7
// 8. References ............................................ 8

// Each line will be a konva group that will contain 3 text nodes

import React from 'react';
import { Group, Text, Rect } from 'react-konva';
import { calculateTextWidth } from 'helpers/studio';
import LinkIndicator from '../LinkIndicator';

const TClassicTableOfContents = ({
  type,
  shapeProps,
  setTextareaProps,
  isEditMode,
  setIsEditMode,
  setTextareaValue,
  isSelected,
  scale,
  setSelectedShapeId,
  stageWidth,
  stageHeight,
  bleedingTop,
  bleedingLeft,
  onSelect,
  onDragStart,
  onDragMove,
  onDragEnd,
  onChange,
  trForceUpdate,
  showXAxis,
  showYAxis,
  setShowXAxis,
  setShowYAxis,
}) => {
  const [table, setTable] = React.useState([]);
  const shapeRef = React.useRef();

  const tableOfContents = shapeProps.staticLink.tableOfContents;
  const titleHeight = shapeProps.fontSize * shapeProps.lineHeight * 1.5;
  const tableHeight = titleHeight + table.length * shapeProps.fontSize * shapeProps.lineHeight;

  React.useEffect(() => {
    const t = tableOfContents.map((item, index) => {
      const numberWidth = calculateTextWidth({
        text: `${index + 1}. `,
        fontSize: shapeProps.fontSize,
        fontFamily: shapeProps.fontFamily,
        fontStyle: shapeProps.fontStyle,
        addedSpace: 0,
        minWidth: 0,
        canvasWidth: shapeProps.width,
      });

      const titleWidth =
        calculateTextWidth({
          text: item.title,
          fontSize: shapeProps.fontSize,
          fontFamily: shapeProps.fontFamily,
          fontStyle: shapeProps.fontStyle,
          addedSpace: 0,
          minWidth: 0,
          canvasWidth: shapeProps.width,
        }) +
        item.title.length * shapeProps.letterSpacing;

      const pageNumberWidth = calculateTextWidth({
        text: item.pageNumber,
        fontSize: shapeProps.fontSize,
        fontFamily: shapeProps.fontFamily,
        fontStyle: shapeProps.fontStyle,
        addedSpace: 0,
        minWidth: 0,
        canvasWidth: shapeProps.width,
      });

      const oneDotWidth = calculateTextWidth({
        text: '.',
        fontSize: shapeProps.fontSize,
        fontFamily: shapeProps.fontFamily,
        fontStyle: shapeProps.fontStyle,
        addedSpace: 0,
        minWidth: 0,
        canvasWidth: shapeProps.width,
      });

      const numberOfDots = Math.floor(
        (shapeProps.width - (numberWidth + titleWidth + pageNumberWidth)) / oneDotWidth
      );

      const y = titleHeight + index * shapeProps.fontSize * shapeProps.lineHeight;

      const number = {
        text: `${index + 1}.`,
        x: 0,
        y,
        width: numberWidth,
      };

      const title = {
        text: item.title,
        x: numberWidth,
        y,
        width: titleWidth,
      };

      const dots = {
        text: ' ' + '.'.repeat(numberOfDots - 2) + ' ',
        x: numberWidth + titleWidth,
        y,
        width: oneDotWidth * numberOfDots,
      };

      const pageNumber = {
        text: item.pageNumber,
        x: shapeProps.width - pageNumberWidth,
        y,
        width: pageNumberWidth,
      };

      return {
        number,
        title,
        dots,
        pageNumber,
      };
    });

    setTable(t);
    trForceUpdate();
  }, [
    shapeProps.staticLink.tableOfContents,
    shapeProps.width,
    shapeProps.fontSize,
    shapeProps.fontFamily,
    shapeProps.fontStyle,
    shapeProps.lineHeight,
  ]);

  const sharedProps = {
    fill: shapeProps.fill,
    fontSize: shapeProps.fontSize,
    fontFamily: shapeProps.fontFamily,
    fontStyle: shapeProps.fontStyle,
    letterSpacing: shapeProps.letterSpacing,
    lineHeight: shapeProps.lineHeight,
  };

  return (
    <>
      {shapeProps.link && isSelected && !isEditMode && (
        <LinkIndicator shapeProps={shapeProps} offset={false} />
      )}
      <Group
        onMouseDown={onSelect}
        onTap={onSelect}
        onDblTap={() => {
          setSelectedShapeId(null); // UI fix - Don't remove it
          setIsEditMode(true);
          setSelectedShapeId(shapeProps.id);
        }}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragStart={onDragStart}
        onDragMove={onDragMove}
        onDragEnd={e => onDragEnd(shapeProps, e)}
        onTransformEnd={e => {
          // transformer is changing scale of the node
          // and NOT its width or height- shapeRef.current.fontSize()/scale/100/2 - shapeRef.current.padding()/scale/100
          // but in the store we have only width and height
          // to match the data better we will re- shapeRef.current.fontSize()/scale/100/2 - shapeRef.current.padding()/scale/100set scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          onChange(
            {
              ...shapeProps,
              x: node.x(),
              y: node.y(), // set minimal value
              width: node.width() * scaleX,
              // height: node.width() * scaleX, // Quick fix
              height: node.height() * scaleY,
              scaleX: 1,
              scaleY: 1,
              rotation: node.rotation(),
            },
            true
          );
          trForceUpdate();
        }}
      >
        <Rect // this to constrain width
          key={'outline-' + shapeProps.id}
          x={0}
          y={0}
          width={shapeProps.width}
          height={tableHeight}
          stroke={'rgba(0,0,0,0)'}
          fill={'rgba(0,0,0,0)'}
        />
        <Text
          text={shapeProps.text}
          fill={shapeProps.fill}
          align={'center'}
          x={0}
          y={0}
          width={shapeProps.width}
          fontSize={shapeProps.fontSize * 1.5}
          wrap={'none'}
        />

        {table.map(row => {
          return (
            <Group>
              <Text
                text={row.number.text}
                align={'left'}
                x={row.number.x}
                y={row.number.y}
                width={row.number.width}
                wrap={'none'}
                {...sharedProps}
              />

              <Text
                text={row.title.text}
                align={'left'}
                x={row.title.x}
                y={row.title.y}
                width={row.title.width}
                wrap={'none'}
                {...sharedProps}
              />

              <Text
                text={row.dots.text}
                align={'left'}
                x={row.dots.x}
                y={row.dots.y}
                width={row.dots.width}
                wrap={'none'}
                {...sharedProps}
              />

              <Text
                text={row.pageNumber.text}
                align={'left'}
                x={row.pageNumber.x}
                y={row.pageNumber.y}
                width={row.pageNumber.width}
                wrap={'none'}
                {...sharedProps}
              />
            </Group>
          );
        })}
      </Group>
    </>
  );
};

export default TClassicTableOfContents;
