import React, { useState, useEffect, useRef } from 'react';
import { useOnClickOnEscape, useWindowSize } from 'hooks/util';
import { getCategories } from 'api/common';
import { addFormat, editFormat, getOneFormat } from 'api/studio/formats';
import { Stage, Layer, Rect } from 'react-konva';
import { useHistory } from 'react-router-dom';
import CheckboxLock from 'components/forms/elements/CheckboxLock';
import InputNumber from 'components/forms/elements/InputNumber';
import SelectWrapper from 'components/forms/elements/SelectWrapper';
import { pageHandSides } from 'settings/pageHandSides';
import Tags from 'components/common/Tags';
import { useGlobalContext } from 'state/hooks/global';

function FromatSpecForm({
  closeSlideModal,
  pageSizes,
  allowedTags,
  format = {},
  formatCategoryId,
  addFormatToWorkspace,
  showCategory = false,
  updateFormatFromSlideModal,
}) {
  const previewRef = useRef(null);
  const history = useHistory();
  const [width] = useWindowSize();
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [pageSizeIndex, setPageSizeIndex] = useState(0);
  const [pageHandSide, setPageHandSide] = useState(pageHandSides[0].value);

  const [marginT, setMarginT] = useState(0);
  const [marginR, setMarginR] = useState(0);
  const [marginB, setMarginB] = useState(0);
  const [marginL, setMarginL] = useState(0);
  const [marginsLocked, setMarginsLocked] = useState(true);

  const [bleedingT, setBleedingT] = useState(0);
  const [bleedingR, setBleedingR] = useState(0);
  const [bleedingB, setBleedingB] = useState(0);
  const [bleedingL, setBleedingL] = useState(0);
  const [bleedingsLocked, setBleedingsLocked] = useState(true);

  const [scale, setScale] = useState(1);
  const [tagsArr, setTagsArr] = useState([]);

  // Categories
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const { setMsgs } = useGlobalContext();

  const dims = {
    previewWidth: () => previewRef.current.clientWidth,

    totalHeightInPx: () => (pageSizes[pageSizeIndex].height + bleedingT + bleedingB) * 96,

    totalWidthInPx: () => (pageSizes[pageSizeIndex].width + bleedingR + bleedingL) * 96,

    pageHeight: () => pageSizes[pageSizeIndex].height,

    pageWidth: () => pageSizes[pageSizeIndex].width,

    pageHeightInPx: () => pageSizes[pageSizeIndex].height * 96,

    pageWidthInPx: () => pageSizes[pageSizeIndex].width * 96,

    marginsHeightInPx: () => (pageSizes[pageSizeIndex].height - marginT - marginB) * 96,

    marginsWidthInPx: () => (pageSizes[pageSizeIndex].width - marginR - marginL) * 96,
  };

  const isEditForm = format && format.id > 0;

  useEffect(() => {
    if (!format || !format.id) return;
    getOneFormat(format.id).then(res => {
      if (res.success) {
        const format = res.format;
        setTitle(format.title);
        setPageSizeIndex(
          pageSizes.findIndex(size => size.height === format.height && size.width === format.width)
        );
        setPageHandSide(format.page_hand_side);
        setMarginT(format.margin.top);
        setMarginR(format.margin.right);
        setMarginB(format.margin.bottom);
        setMarginL(format.margin.left);
        setBleedingT(format.bleeding.top);
        setBleedingR(format.bleeding.right);
        setBleedingB(format.bleeding.bottom);
        setBleedingL(format.bleeding.left);
        const tagsArr = allowedTags.filter(tag => format.tags.includes(tag.id));
        setTagsArr(tagsArr);
      }
    });
  }, [format && format.id]);

  useEffect(() => {
    setScale(
      Math.min(
        dims.previewWidth() / 1.5 / dims.pageHeightInPx(),
        dims.previewWidth() / 1.5 / dims.pageWidthInPx()
      )
    );
  }, [
    pageSizeIndex,
    marginT,
    marginR,
    marginB,
    marginL,
    bleedingT,
    bleedingR,
    bleedingB,
    bleedingL,
    width,
  ]);

  useOnClickOnEscape(closeSlideModal);

  const tagsIdsArr = () => tagsArr.map(item => item.id);

  const handleSubmit = () => {
    if (!title.trim()) {
      setTitleError(true);
      return;
    }

    if (!isEditForm) {
      addFormat({
        title: title,
        page_size: pageSizes[pageSizeIndex].id,
        page_hand_side: pageHandSide,
        margin: {
          left: Number(marginL),
          right: Number(marginR),
          top: Number(marginT),
          bottom: Number(marginB),
        },
        bleeding: {
          left: Number(bleedingL),
          right: Number(bleedingR),
          top: Number(bleedingT),
          bottom: Number(bleedingB),
        },
        category: selectedCategory || formatCategoryId,
        tags: tagsIdsArr(),
      }).then(res => {
        if (res.success) {
          addFormatToWorkspace(res.format);
          history.push(`/home/formats/workspace`);
          closeSlideModal();
        } else {
          console.log(res);
        }
      });
    } else {
      // edit format
      editFormat({ title, tags: tagsIdsArr() }, format.id).then(res => {
        if (res.success) {
          updateFormatFromSlideModal(res.format);
          setMsgs([{ type: 'success', content: 'Format updated successfully!' }]);
        } else {
          console.log(res);
        }
      });
    }
  };

  const handleLockedMarginsChange = val => {
    setMarginT(val);
    setMarginR(val);
    setMarginB(val);
    setMarginL(val);
  };

  const handleLockedBleedingsChange = val => {
    if (val <= dims.pageWidth() * 0.2) {
      setBleedingT(val);
      setBleedingR(val);
      setBleedingB(val);
      setBleedingL(val);
    }
  };

  const loadCategories = (search, offset) => {
    const params = {
      kind: 'studio_formats',
      sort: 'alpha',
      search,
      offset,
    };
    return getCategories(params)
      .then(res => {
        if (res.success) {
          const list = [...categories];
          const items = offset === 0 ? res['categories'] : list.concat(res['categories']);

          const options = res.categories.map(category => ({
            label: category.title,
            value: category.id,
          }));

          setCategories(offset > 0 ? [...categories, ...options] : options);
          if (offset === 0 && search === '') setSelectedCategory(items[0].id);

          return {
            options,
            hasMore: offset + 15 < res.count,
            additional: {
              offset: offset + 15,
            },
          };
        }
      })
      .catch(e => console.log);
  };

  return (
    <div className="form__spec">
      <div className="form__spec--preview" ref={previewRef}>
        <div
          className=" d-flex justify-content-center align-items-center"
          // style={{ width: 700, height: 700, position: 'relative' }}
        >
          <div
            style={{
              position: 'absolute',
              border: `${
                bleedingT > 0 || bleedingR > 0 || bleedingB > 0 || bleedingL > 0
                  ? '1px solid red'
                  : 'none'
              }`,
              padding: `${bleedingT * 96 * scale}px ${bleedingR * 96 * scale}px ${
                bleedingB * 96 * scale
              }px ${bleedingL * 96 * scale}px`,
            }}
          >
            <Stage
              className="shadow"
              width={dims.pageWidthInPx() * scale}
              height={dims.pageHeightInPx() * scale}
            >
              <Layer>
                <Rect
                  x={0}
                  y={0}
                  width={dims.pageWidthInPx() * scale}
                  height={dims.pageHeightInPx() * scale}
                  fill="white"
                />
                {(marginT > 0 || marginR > 0 || marginB > 0 || marginL > 0) && (
                  <Rect
                    x={marginL * 96 * scale - 0.5}
                    y={marginT * 96 * scale - 0.5}
                    width={dims.marginsWidthInPx() * scale}
                    height={dims.marginsHeightInPx() * scale}
                    stroke="blue"
                    strokeWidth={1}
                  />
                )}
              </Layer>
            </Stage>
          </div>
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              color: 'gray',
              opacity: 0.6,
            }}
          >
            <p style={{ fontSize: 20, textAlign: 'end', marginBottom: -10, marginRight: -25 }}>
              Inches
            </p>
            <h1 style={{ fontSize: 100, fontStyle: 'bold' }}>
              {pageSizes[pageSizeIndex].width + ' x ' + pageSizes[pageSizeIndex].height}
            </h1>
          </div>
        </div>
      </div>

      <div className="form form__spec--controls">
        <div className="form__spec--controls-heading">Format Specifications</div>
        <div className="scrollable-window " style={{ maxHeight: '77vh' }}>
          {showCategory && (
            <div className="form-group d-flex flex-column">
              <label className="mb-3">Format Category</label>
              <SelectWrapper
                async
                options={categories}
                value={selectedCategory}
                setValue={setSelectedCategory}
                loadOptions={(search, loadedOptions = []) =>
                  loadCategories(search, loadedOptions.length)
                }
              />
            </div>
          )}
          <div className="form-group">
            <label>Title</label>
            <input
              className="form-control light mb-2"
              type="text"
              placeholder="Title goes here..."
              value={title}
              onChange={e => {
                setTitle(e.target.value);
                setTitleError(false);
              }}
            />
            {titleError && <span className="text-error">Title is required!</span>}
          </div>
          <div className="form-section-2">
            <div className={`form-group ${isEditForm ? 'disabled' : ''}`}>
              <label>Page size</label>
              <SelectWrapper
                className={`form-control`}
                options={pageSizes.map((size, index) => ({
                  label: `${size.width} x ${size.height} Inches`,
                  value: index,
                }))}
                isSearchable={true}
                value={pageSizeIndex}
                setValue={setPageSizeIndex}
              />
            </div>
            <div className={`form-group ${isEditForm ? 'disabled' : ''}`}>
              <label>Page hand side</label>
              <SelectWrapper
                className={`form-control`}
                options={pageHandSides}
                value={pageHandSide}
                setValue={setPageHandSide}
              />
            </div>
          </div>
          <div className={`form-group ${isEditForm ? 'disabled' : ''}`}>
            <label>Margin</label>
            <div className="form-section-3">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center">
                  <label className="text-end px-4">Left</label>
                  <InputNumber
                    className={'form-control'}
                    min={0}
                    step={0.01}
                    value={marginL}
                    setValue={val => {
                      if (marginsLocked) handleLockedMarginsChange(val);
                      else setMarginL(val);
                    }}
                  />
                </div>
                <div className="d-flex align-items-center mt-3">
                  <label className="text-end px-4">Right</label>
                  <InputNumber
                    className={'form-control'}
                    min={0}
                    step={0.01}
                    value={marginR}
                    setValue={val => {
                      if (marginsLocked) handleLockedMarginsChange(val);
                      else setMarginR(val);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center middle">
                <CheckboxLock
                  className="form-check-input"
                  checked={marginsLocked}
                  onChange={() => {
                    setMarginsLocked(!marginsLocked);
                  }}
                />
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center">
                  <InputNumber
                    className={'form-control'}
                    min={0}
                    step={0.01}
                    value={marginT}
                    setValue={val => {
                      if (marginsLocked) handleLockedMarginsChange(val);
                      else setMarginT(val);
                    }}
                  />
                  <label className="px-4">Top</label>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <InputNumber
                    className={'form-control'}
                    min={0}
                    step={0.01}
                    value={marginB}
                    setValue={val => {
                      if (marginsLocked) handleLockedMarginsChange(val);
                      else setMarginB(val);
                    }}
                  />
                  <label className="px-4">Bottom</label>
                </div>
              </div>
            </div>
          </div>
          <div className={`form-group ${isEditForm ? 'disabled' : ''}`}>
            <label>Bleeding</label>
            <div className="form-section-3">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center">
                  <label className="text-end px-4">Left</label>
                  <InputNumber
                    className={'form-control'}
                    min={0}
                    max={dims.pageWidth() * 0.2}
                    step={0.01}
                    value={bleedingL}
                    setValue={val => {
                      if (bleedingsLocked) handleLockedBleedingsChange(val);
                      else setBleedingL(val);
                    }}
                  />
                </div>
                <div className="d-flex align-items-center mt-3">
                  <label className="text-end px-4">Right</label>
                  <InputNumber
                    className={'form-control'}
                    min={0}
                    max={dims.pageWidth() * 0.2}
                    step={0.01}
                    value={bleedingR}
                    setValue={val => {
                      if (bleedingsLocked) handleLockedBleedingsChange(val);
                      else setBleedingR(val);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center middle">
                <CheckboxLock
                  className="form-check-input"
                  checked={bleedingsLocked}
                  onChange={() => {
                    setBleedingsLocked(!bleedingsLocked);
                  }}
                />
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center">
                  <InputNumber
                    className={'form-control'}
                    min={0}
                    max={dims.pageHeight() * 0.2}
                    step={0.01}
                    value={bleedingT}
                    setValue={val => {
                      if (bleedingsLocked) handleLockedBleedingsChange(val);
                      else setBleedingT(val);
                    }}
                  />
                  <label className="px-4">Top</label>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <InputNumber
                    className={'form-control'}
                    min={0}
                    max={dims.pageHeight() * 0.2}
                    step={0.01}
                    value={bleedingB}
                    setValue={val => {
                      if (bleedingsLocked) handleLockedBleedingsChange(val);
                      else setBleedingB(val);
                    }}
                  />
                  <label className="px-4">Bottom</label>
                </div>
              </div>
            </div>
          </div>

          <Tags
            title={'Tags'}
            allowedTags={allowedTags}
            tagsArr={tagsArr}
            setTagsArr={setTagsArr}
            classNameA={'form-group'}
            classNameB={''}
            inputClassName={'form-control light'}
            height={50}
            inputPlaceholder={'Teens, Adults, Seasons,... etc'}
            showCount={false}
          />
        </div>
        <div className="d-flex justify-content-center mt-4">
          <div className="d-flex w-75" style={{ position: 'absolute', bottom: 25 }}>
            <button
              className="btn-white-forms mx-2"
              onClick={() => {
                closeSlideModal();
              }}
            >
              Cancel
            </button>
            <button className="btn-blue-forms mx-2" onClick={handleSubmit}>
              {isEditForm ? 'Save' : 'Next'}
            </button>
          </div>
        </div>
      </div>
      <button className="form__spec--close-btn" onClick={closeSlideModal}>
        x
      </button>
    </div>
  );
}

export default FromatSpecForm;
