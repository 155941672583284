import { asyncLogout } from 'api/auth';
import { useDispatch } from 'react-redux';

export default function LogoutButton() {
  const dispatch = useDispatch();
  const logout = () => dispatch(asyncLogout());
  return (
    <button className="btn btn-link logout-btn" onClick={logout}>
      Logout
    </button>
  );
}
