import Button from 'components/common/Button';
import React, { useState, useEffect, useRef } from 'react';
import { getTags, addTags, deleteTags } from 'api/common';
import IncludeSvg from 'components/common/IncludeSvg';
import { capitalizeFirst } from 'helpers/text';

import { useOnClickOutside } from 'hooks/util';
import './TagsEditor.scss';
import PopupMenu from 'components/common/PopupMenu';
import { useGlobalContext } from 'state/hooks/global';

import { useSelector } from 'react-redux';

function TagsEditor({ scope, category }) {
  const { user } = useSelector(state => state.auth);
  const canAdd = user?.resources_permissions?.tag?.add_tag;
  const canChange = user?.resources_permissions?.tag?.change_tag;
  const canDelete = user?.resources_permissions?.tag?.delete_tag;

  const [kind, setKind] = useState('');
  const [typeId, setTypeId] = useState('');
  const [tags, setTags] = useState([]);
  const [params, setParams] = useState({});
  const [newTags, setNewTags] = useState([]);
  const [toBeDeletedTagsIds, setToBeDeletedTagsIds] = useState([]);
  const [sort, setSort] = useState('most_popular');
  const [sortTitle, setSortTitle] = useState('Alphabetically');
  const [sortDir, setSortDir] = useState('asc');
  const [sortDirTitle, setSortDirTitle] = useState('Ascending');
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [isSortDirOpen, setIsSortDirOpen] = useState(false);
  const popupSortRef = useRef();
  const popupSortDirRef = useRef();
  const [search, setSearch] = useState('');
  const searchRef = useRef();

  useOnClickOutside(popupSortRef, () => setIsSortOpen(false));
  useOnClickOutside(popupSortDirRef, () => setIsSortDirOpen(false));

  const { setMsgs } = useGlobalContext();

  const [isLoading, setIsLoading] = useState(false);

  const handleSave = () => {
    // handle add or add and delete
    if (newTags.length) {
      const data = {
        tags: newTags,
      };
      setIsLoading(true);
      addTags(data, params)
        .then(res => {
          // const list = [...res.tags, ...tags];
          // setTags(list);
          setNewTags([]);
          setIsLoading(false);

          if (toBeDeletedTagsIds.length) {
            return deleteTags({
              ids: toBeDeletedTagsIds,
            }).then(res => {
              setToBeDeletedTagsIds([]);
              loadTags(true);
            });
          } else {
            loadTags(true);
          }
        })
        .catch(err => {
          setIsLoading(false);
        });

      // handle delete only
    } else if (toBeDeletedTagsIds.length) {
      deleteTags({
        ids: toBeDeletedTagsIds,
      }).then(res => {
        setToBeDeletedTagsIds([]);
        loadTags(true);
      });
    }
  };

  const handleNewTag = () => {
    setNewTags([search.trim(), ...newTags]);
    setSearch('');
  };

  useEffect(() => {
    if (scope && category) {
      if (scope === 'elements') {
        setKind(scope);
        setTypeId(category.id);
      } else {
        setKind(category.kind);
      }
    }
  }, [scope, category]);

  const loadTags = showUpdatedMsg => {
    if (kind) {
      const p = {
        kind,
      };

      if (kind === 'elements' && typeof typeId === 'number') {
        p.element_type_id = typeId;
      }

      setParams(p);

      p.sort = sort;
      p.sort_dir = sortDir;

      if (kind !== 'elements' || (kind === 'elements' && typeof typeId === 'number')) {
        setIsLoading(true);
        getTags(p)
          .then(res => {
            setTags(res.tags);
            setIsLoading(false);
          })
          .catch(err => {
            setIsLoading(false);
          });
      }
    }

    if (showUpdatedMsg) {
      setMsgs([
        {
          type: 'success',
          content: `Tags updated successfully!`,
        },
      ]);
    }
  };

  useEffect(() => {
    searchRef.current.focus();
    setTags([]);
    loadTags();
  }, [kind, typeId, sort, sortDir]);

  useEffect(() => {
    setNewTags([]);
    setToBeDeletedTagsIds([]);
  }, [kind, typeId]);

  return (
    <div>
      <div className="tags-editor__title">{category && (category.title || category.name)} Tags</div>
      <div className="tags-editor shadow-sm">
        <div className="tags-editor__controls">
          <div className="tags-editor__search-input--wrapper">
            <input
              ref={searchRef}
              className="tags-editor__search-input"
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder="Search tags..."
            />
          </div>
          <div
            className="group noselect sort-by"
            onClick={() => {
              setIsSortOpen(!isSortOpen);
            }}
          >
            <span className="variable">{sortTitle}</span>
            <span className={`${isSortOpen ? 'rotate-180' : ''}`}>
              <IncludeSvg name="arrow-drop-down" />
            </span>

            <PopupMenu
              popupRef={popupSortRef}
              type={'sort'}
              setOption={setSort}
              setOptionTitle={setSortTitle}
              isOpen={isSortOpen}
              setIsOpen={setIsSortOpen}
            />
          </div>
          <div
            className="group noselect sort-by"
            onClick={() => {
              setIsSortDirOpen(!isSortDirOpen);
            }}
          >
            <span className="variable">{sortDirTitle}</span>
            <span className={`${isSortDirOpen ? 'rotate-180' : ''}`}>
              <IncludeSvg name="arrow-drop-down" />
            </span>
            <PopupMenu
              popupRef={popupSortDirRef}
              type={'sortDir'}
              setOption={setSortDir}
              setOptionTitle={setSortDirTitle}
              isOpen={isSortDirOpen}
              setIsOpen={setIsSortDirOpen}
            />
          </div>
        </div>
        <div className="tags-editor__tags--wrapper">
          <div className="tags-editor__tags">
            {isLoading && (
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            )}
            {!isLoading &&
              newTags
                .filter(t => !search.trim() || t.toLocaleLowerCase() === search.toLocaleLowerCase())
                .map((tag, index) => (
                  <span key={index} className="tag new">
                    <span className="name">{capitalizeFirst(tag)}</span>{' '}
                    <span
                      className="btn close-btn"
                      onClick={() => {
                        const list = [...newTags];
                        list.splice(index, 1);
                        setNewTags(list);
                      }}
                    >
                      x
                    </span>
                  </span>
                ))}

            {search.trim() &&
              !tags
                .map(tag => tag.name.toLocaleLowerCase())
                .concat(newTags.map(tag => tag.toLowerCase()))
                .includes(search.trim().toLowerCase()) &&
              canAdd && (
                <div className="tag add-tag noselect" onClick={handleNewTag}>
                  <div className="plus-btn">
                    <IncludeSvg name="tag-plus" />
                  </div>
                  &nbsp;Add &nbsp;
                  <span style={{ fontWeight: 'bold' }}>"{capitalizeFirst(search.trim())}"</span>
                </div>
              )}
            {tags
              .filter(
                t =>
                  !toBeDeletedTagsIds.includes(t.id) &&
                  (!search.trim() ||
                    t.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
              )
              .map((tag, index) => (
                <span key={tag.id} className={`tag ${!canDelete ? 'px-2' : ''}`}>
                  <span className="name">{capitalizeFirst(tag.name)}</span>{' '}
                  {canDelete && (
                    <span
                      className="btn close-btn"
                      onClick={() => {
                        setToBeDeletedTagsIds([...toBeDeletedTagsIds, tag.id]);
                      }}
                    >
                      x
                    </span>
                  )}
                </span>
              ))}
          </div>
        </div>
        {canChange && (
          <div className="tags-editor__save-button">
            <Button
              disabled={isLoading}
              onClick={handleSave}
              label={'Save'}
              className="btn-blue-forms"
              style={{ width: 100, height: 40 }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default TagsEditor;
