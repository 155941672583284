import React from 'react';
import IncludeSvg from 'components/common/IncludeSvg';
import { useHistory } from 'react-router-dom';
import { capitalizeFirst } from 'helpers/text';
function SideBarIcon({ name, route, active, seperator}) {
  const history = useHistory();
  return (
    <>
    {seperator && <hr />}
    <div className={`noselect side-bar-item ${active ? 'active' : ''}` }
    onClick={()=> {if(!active) history.push(route)}}
    >
      <IncludeSvg name={name} />
      <div className="title">{capitalizeFirst(name)}</div>
    </div>
    </>
  );
}

export default SideBarIcon;
