import React, { useState, useEffect, useRef } from 'react';
import { useOnClickOnEscape, useWindowSize } from 'hooks/util';
import { addManuscript } from 'api/studio/manuscripts';
import { useHistory } from 'react-router-dom';
// import InputNumber from 'components/forms/elements/InputNumber';
import SelectWrapper from 'components/forms/elements/SelectWrapper';
import Tags from 'components/common/Tags';
import { getCategories } from 'api/common';
import CheckList from 'components/forms/elements/CheckList';

import { getTags } from 'api/common';
import { getTemplates } from 'api/studio/templates';
import IncludeSvg from 'components/common/IncludeSvg';

import { PUZZLE_CONTENT_TYPES } from 'settings/puzzels';
import moment from 'moment';

function ManuscriptSpecForm({
  closeSlideModal,
  allowedTags,
  pageSizes,
  manuscriptCategoryId,
  addManuscriptToWorkspace,
}) {
  const history = useHistory();
  const [step, setStep] = useState('a');

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [templateError, setTemplateError] = useState(false);
  const [templatesAllowedTags, setTemplatesAllowedTags] = useState([]);
  const [templatesTagsArr, setTemplatesTagsArr] = useState([]);
  const [templatesTags, setTemplatesTags] = useState('');
  const [tagsArr, setTagsArr] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [isTemplatesLoading, setIsTemplatesLoading] = useState(false);
  const [templatesCount, setTemplatesCount] = useState(false);
  const [checkedTemplates, setCheckedTemplates] = useState([]);
  const [pageSize, setPageSize] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);

  useOnClickOnEscape(closeSlideModal);

  useEffect(() => {
    getTags({ kind: 'studio_templates', sort: 'alpha' }).then(res => {
      if (res.success) {
        setTemplatesAllowedTags(res.tags);
      }
    });
  }, []);

  useEffect(() => {
    if (checkedTemplates.length) {
      const size = pageSizes.find(size => checkedTemplates[0].page_size === size.id);
      if (size) setPageSize(`${size.width} x ${size.height} Inchs`);
      else setPageSize(`-`);

      const types = checkedTemplates[0].content_types;

      const contentTypes = types
        .filter(type => PUZZLE_CONTENT_TYPES.includes(type))
        .map(type =>
          type.includes('Coloring Book Image') || type.includes('story-word-list-text')
            ? type
            : type
                .split('- ')
                [type.includes('grid') ? 0 : 1].toLowerCase()
                .replace(' ', '-')
                .replace('-grid-', '')
        );
      setContentTypes([...new Set(contentTypes)]);
    }
  }, [checkedTemplates]);

  useEffect(() => {
    if (checkedTemplates.length) setTemplateError(false);
  }, [checkedTemplates.length]);

  useEffect(() => {
    if (selectedCategory) {
      setCheckedTemplates([]);
      loadTemplates(0);
    }
  }, [selectedCategory, templatesTags]);

  useEffect(() => {
    let str = '&';
    templatesTagsArr.forEach(tag => {
      const index = templatesAllowedTags.findIndex(
        allowedTag => allowedTag.name.toLowerCase() === tag.name.toLowerCase()
      );
      if (index > -1) {
        str += `tag=${templatesAllowedTags[index].id}&`;
      }
    });
    const s = str.slice(0, -1);
    setTemplatesTags(s);
  }, [templatesTagsArr]);

  const loadCategories = (search, offset) => {
    const params = {
      kind: 'studio_templates',
      sort: 'alpha',
      search,
      offset,
    };
    return getCategories(params)
      .then(res => {
        if (res.success) {
          const list = [...categories];
          const items = offset === 0 ? res['categories'] : list.concat(res['categories']);

          const options = res.categories.map(category => ({
            label: category.title,
            value: category.id,
          }));

          setCategories(offset > 0 ? [...categories, ...options] : options);
          if (offset === 0 && search === '') setSelectedCategory(items[0].id);

          return {
            options,
            hasMore: offset + 15 < res.count,
            additional: {
              offset: offset + 15,
            },
          };
        }
      })
      .catch(e => console.log);
  };

  const tagsIdsArr = () => tagsArr.map(item => item.id);

  const loadTemplates = offset => {
    if (isTemplatesLoading) return;
    if (offset === 0) {
      setTemplates([]);
      setTemplatesCount(0);
    }
    if (offset === 0 || offset < templatesCount) {
      setIsTemplatesLoading(true);
      getTemplates(selectedCategory, 'alpha', templatesTags, offset)
        .then(res => {
          if (res.success) {
            const list = [...templates];
            const items = offset === 0 ? res['templates'] : list.concat(res['templates']);
            setTemplatesCount(res.count);
            setTimeout(() => {
              setTemplates(items);
              setIsTemplatesLoading(false);
            }, 500);
          }
        })
        .catch(e => console.log);
    }
  };
  const handleSubmit = () => {
    if (!checkedTemplates.length) {
      setStep('a');
      setTemplateError(true);
      return;
    }

    if (step === 'a') {
      setStep('b');
      return;
    }

    if (step === 'b') {
      if (!title.trim()) {
        setTitleError(true);
        return;
      }

      addManuscript({
        title: title,
        category: manuscriptCategoryId,
        tags: tagsIdsArr(),
        template_id: checkedTemplates[0].id,
      }).then(res => {
        if (res.success) {
          // addManuscriptToWorkspace(res.manuscript);
          history.push(`/home/manuscripts/workspace/?ids=${res.manuscript.id}`);
          closeSlideModal();
        } else {
          console.log(res);
        }
      });
    }
  };

  return (
    <div className="form__spec">
      <div className="form__spec--preview manuscript">
        {checkedTemplates.length ? (
          <div className=" d-flex flex-column w-100 justify-content-start align-items-center">
            <h3 className="title mb-4">Manuscript Sepecifications</h3>
            <h4 className="w-100 text-left">Template details</h4>
            <div
              className="details d-flex w-100 mx-5 p-4 h4"
              style={{
                border: '2px solid var(--sys)',
                borderRadius: 10,
              }}
            >
              {[
                { label: 'Name', valueField: 'title' },
                { label: 'Page Size', valueField: 'page_size' },
                { label: 'Number of Pages', valueField: 'number_of_pages' },
                { label: 'Created', valueField: 'created_at' },
                { label: 'Updated', valueField: 'updated_at' },
                { label: 'Last Used', valueField: 'last_used_at' },
                { label: 'Times Used', valueField: 'times_used' },
              ].map(detail => (
                <div className={detail.label === 'Name' ? 'mb-4' : 'mb-1'}>
                  <span className="label">{detail.label}:</span>
                  <span className="value">
                    {detail.valueField === 'page_size'
                      ? pageSize
                      : detail.valueField === 'created_at' || detail.valueField === 'updated_at'
                      ? moment(checkedTemplates[0]?.[detail.valueField]).format('MMM DD, YYYY')
                      : checkedTemplates[0]?.[detail.valueField] ?? '-'}
                  </span>
                </div>
              ))}
            </div>
            {contentTypes.length ? (
              <>
                <h4 className="w-100 text-left mt-4">Content Types</h4>
                <div
                  className="details d-flex w-100 mx-5 p-3 h4"
                  style={{
                    border: '2px solid var(--sys)',
                    borderRadius: 10,
                  }}
                >
                  <div className="top-controls icon-btns">
                    {contentTypes.map(type => (
                      <button>
                        <IncludeSvg name={type.includes('story') ? 'story-manuscript' : type} />
                      </button>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </div>

      <div className="form form__spec--controls">
        {step === 'a' && (
          <div
            style={{
              height: 'calc(100vh - 150px)',
              paddingBottom: 50,
            }}
          >
            <div>
              <div className="form__spec--controls-heading">Select Template</div>
              <div className="form-group d-flex flex-column">
                <label className="mb-3">Template Category</label>
                <SelectWrapper
                  async
                  options={categories}
                  value={selectedCategory}
                  setValue={setSelectedCategory}
                  loadOptions={(search, loadedOptions = []) =>
                    loadCategories(search, loadedOptions.length)
                  }
                />
              </div>

              <Tags
                title={'Template Tags'}
                allowedTags={templatesAllowedTags}
                tagsArr={templatesTagsArr}
                setTagsArr={setTemplatesTagsArr}
                classNameA={'form-group'}
                classNameB={''}
                inputClassName={'form-control light'}
                height={50}
                inputPlaceholder={'Search...'}
                showHead={false}
                showCount={false}
                scrollDirection={'x'}
                searchable={true}
              />
              <CheckList
                radioSelect
                title={'Template'}
                itemsType={'templates'}
                items={templates}
                loadItems={loadTemplates}
                checkedItems={checkedTemplates}
                setCheckedItems={setCheckedTemplates}
                isLoading={isTemplatesLoading}
              />
              {templateError && <span className="text-error">Template is required!</span>}
            </div>
          </div>
        )}
        {step === 'b' && (
          <div>
            <div className="form__spec--controls-heading">Manuscript Specifications</div>
            <div className="scrollable-window " style={{ maxHeight: '77vh' }}>
              <div className="form-group">
                <label>Title</label>
                <input
                  className="form-control light mb-2"
                  type="text"
                  placeholder="Title goes here..."
                  value={title}
                  onChange={e => {
                    setTitle(e.target.value);
                    setTitleError(false);
                  }}
                />
                {titleError && <span className="text-error">Title is required!</span>}
              </div>

              <Tags
                title={'Tags'}
                allowedTags={allowedTags}
                tagsArr={tagsArr}
                setTagsArr={setTagsArr}
                classNameA={'form-group'}
                classNameB={''}
                inputClassName={'form-control light'}
                height={50}
                inputPlaceholder={'Teens, Adults, Seasons,... etc'}
                showCount={false}
              />
            </div>
          </div>
        )}
        <div className="d-flex justify-content-center mt-4">
          <div className="form-steps-group" style={{ position: 'absolute', bottom: 100 }}>
            <span
              className={`form-step ${step === 'a' ? 'active' : ''}`}
              onClick={() => setStep('a')}
            >
              &nbsp;
            </span>
            <span
              className={`form-step ${step === 'b' ? 'active' : ''}`}
              onClick={() => handleSubmit()}
            >
              &nbsp;
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="d-flex w-75" style={{ position: 'absolute', bottom: 25 }}>
            <button
              className="btn-white-forms mx-2"
              onClick={() => {
                closeSlideModal();
              }}
            >
              Cancel
            </button>
            <button className="btn-blue-forms mx-2" onClick={handleSubmit}>
              Next
            </button>
          </div>
        </div>
      </div>
      <button className="form__spec--close-btn" onClick={closeSlideModal}>
        x
      </button>
    </div>
  );
}

export default ManuscriptSpecForm;
