import './VersionsTable.scss';

import React, { useEffect, useState, version } from 'react';

import Button from 'components/common/Button';
import IncludeSvg from 'components/common/IncludeSvg';
import ScrollableWindow from 'layouts/ScrollableWindow';
import { useGlobalContext } from 'state/hooks/global';
import { useSelector } from 'react-redux';

function VersionsTable({
  isSwap,
  variables,
  selectedVariable,
  setSelectedVariable,
  selectedVersion,
  setSelectedVersion,
  versionNames,
  selectedVersionName,
  setSelectedVersionName,
  left,
  language,
  isCreateMode,
  isManage,
  handleAddVersionName,
  setSelectedVersionStatus,
  handleVersionNameSelect,
}) {
  const { user } = useSelector(state => state.auth);
  const canChangeVariables = user?.resources_permissions?.variable?.change_variable;
  const [currVariables, setCurrVariables] = useState([]);
  const [tableCount, setTableCount] = useState([]);
  const { languages, getLanguageID } = useGlobalContext();

  const handleVersionSelect = (vari, versionName, ver) => {
    setSelectedVariable(vari);
    setSelectedVersionName(versionName);
    setSelectedVersion(ver);
  };

  const updateSelectedVersionStatus = () => {
    if (selectedVariable && selectedVersionName) {
      const index1 = versionNames.findIndex(vN => vN.id === selectedVersionName.id);
      const index2 = currVariables.findIndex(vari => vari.id === selectedVariable.id);
      // will konw if it exists or not from editor itself
      if (tableCount[index1][index2]) setSelectedVersionStatus(false);
      else setSelectedVersionStatus(null);
    }
  };

  useEffect(() => {
    if (languages.length && versionNames.length) {
      // get language id
      const lang_id = getLanguageID(language);

      // filter versinos for selected langaugae
      const currentVariables = variables.map(vari => {
        return {
          ...vari,
          versions: vari.versions
            .filter(ver => ver.language_id === lang_id)
            .reduce(function (result, item) {
              result[item['version_name_id']] = item;
              return result;
            }, {}),
        };
      });

      setCurrVariables(currentVariables);

      if (selectedVariable && selectedVersionName)
        setSelectedVersion(
          currentVariables.find(vari => vari.id === selectedVariable.id)?.versions[
            selectedVersionName.id
          ]
        );

      // create empty table
      const table = [];
      versionNames.forEach(() => {
        const row = [];
        variables.forEach(() => {
          row.push(0);
        });
        table.push(row);
      });

      // fill table with counters
      languages.forEach(language => {
        versionNames.forEach((versionName, index1) => {
          variables.forEach((variable, index2) => {
            if (
              variable.versions &&
              variable.versions.filter(
                ver => ver.language_id === language.id && ver.version_name_id === versionName.id
              ).length
            )
              table[index1][index2] += 1;
          });
        });
      });

      setTableCount(table);
      updateSelectedVersionStatus();
    }
  }, [
    variables,
    versionNames,
    versionNames.length,
    language,
    languages,
    languages.length,
    selectedVariable,
    isManage,
    isSwap,
  ]);

  useEffect(() => {
    if (selectedVariable && !selectedVersionName) {
      handleVersionNameSelect(versionNames[0]);
    }
  }, [selectedVariable, selectedVersionName, versionNames]);

  useEffect(() => {
    if (!selectedVariable) setSelectedVersionName(null);
  }, [selectedVariable]);

  useEffect(() => {
    updateSelectedVersionStatus();
  }, [selectedVersionName, selectedVariable]);

  const handleAddVersionNameClick = () => {
    handleAddVersionName((isSwap ? 'Swap Version ' : 'Version ') + (versionNames.length + 1));
  };

  return (
    !!currVariables.length && (
      <div className={`versions-table ${isManage ? 'd-none' : ''}`}>
        <div className="versions-table__main">
          <div className="versions-table__version-names noselect">
            {versionNames.map((versionName, index) => (
              <div
                key={versionName.id}
                className={`versions-table__version-name-wrapper ${index % 2 ? '' : 'shadow-sm'}`}
                onClick={() => handleVersionNameSelect(versionName)}
              >
                <div
                  className={`versions-table__version-name ${
                    selectedVersionName && selectedVersionName.id === versionName.id
                      ? 'selected'
                      : ''
                  }`}
                >
                  {versionName.title}
                </div>
              </div>
            ))}
          </div>
          <table style={{ left: left + 150 }}>
            <tbody>
              {tableCount.length === versionNames.length &&
                versionNames.map((versionName, index1) => (
                  <tr key={versionName.id} className={index1 % 2 ? '' : 'shadow-sm'}>
                    {/* dummy cell */}
                    {isCreateMode && (
                      <td key={versionName.title + versionName.id + `-1`}>
                        <div className={index1 % 2 ? '' : 'shadow-sm'}>
                          <IncludeSvg name="var-missing" />
                        </div>
                      </td>
                    )}
                    {currVariables.map((vari, index2) => (
                      <td key={versionName.title + versionName.id + `[${index2}]`}>
                        <div
                          className={`${
                            index1 % 2 ||
                            (isCreateMode && !(index2 % 2)) ||
                            (!isCreateMode && index2 % 2)
                              ? ''
                              : 'shadow-sm'
                          } ${
                            selectedVariable &&
                            selectedVersionName &&
                            selectedVariable.id === vari.id &&
                            selectedVersionName.id === versionName.id
                              ? 'selected'
                              : ''
                          } `}
                          onClick={() => {
                            handleVersionSelect(vari, versionName, vari.versions[versionName.id]);
                          }}
                        >
                          {vari.versions && vari.versions[`${versionName.id}`] ? (
                            <IncludeSvg name="var-exists" />
                          ) : tableCount[index1][index2] ? (
                            <IncludeSvg name="var-partial" />
                          ) : (
                            <IncludeSvg name="var-missing" />
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {canChangeVariables && (
          <div className="versions-table__main">
            <div className="versions-table__version-names noselect">
              <div key={'add-button'} className="versions-table__version-name-wrapper add-btn">
                <Button
                  label="Version"
                  svgName="version-plus"
                  iconColor={'var(--white)'}
                  className="btn-blue-forms no-right-radius shadow-sm"
                  onClick={handleAddVersionNameClick}
                  style={{ height: 40 }}
                />
              </div>
            </div>
            <table style={{ left: left + 150 }}>
              <tbody>
                <tr
                  className={`${tableCount.length % 2 ? '' : 'shadow-sm'} tr-fixed-bottom`}
                  key={'add-btn-row'}
                >
                  {/* dummy cell */}
                  {isCreateMode && (
                    <td key={'add-btn-row' + '-1'}>
                      <div className={tableCount.length % 2 ? '' : 'shadow-sm'}></div>
                    </td>
                  )}

                  {currVariables.map((vari, index) => (
                    <td key={'add-btn-row' + vari.id}>
                      <div
                        className={`${
                          tableCount.length % 2 ||
                          (isCreateMode && !(index % 2)) ||
                          (!isCreateMode && index % 2)
                            ? ''
                            : 'shadow-sm'
                        }`}
                      ></div>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    )
  );
}

export default VersionsTable;
