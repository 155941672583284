import React, { useRef, useEffect, useLayoutEffect } from 'react';
import { Group, Line, Rect } from 'react-konva';
import { getUniqueId } from 'helpers/generators';

function calculateRotation(x1, y1, x2, y2) {
  const radians = Math.atan2(y2 - y1, x2 - x1);
  const degrees = radians * (180 / Math.PI);
  return degrees;
}

const Ring = ({
  index,
  points,
  strokeWidth,
  incX = 0,
  outlineStroke = 'black',
  outlineStrokeWidth = 1,
  fillColor = 'transparent',
}) => {
  const lineRef = useRef(null);
  const rectRef = useRef(null);
  let x1 = points[0];
  let y1 = points[1];
  const x2 = points[2];
  const y2 = points[3];
  const length = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2) + incX * 2;
  const rotation = calculateRotation(...points);

  const updateRectangle = () => {
    const line = lineRef.current;
    const rect = rectRef.current;

    if (line && rect) {
      rect.width(length);
      const centerX = (x1 + x2) / 2;
      const centerY = (y1 + y2) / 2;
      rect.position({ x: centerX, y: centerY });
      rect.rotation(rotation);
      rect.draw();
    }
  };

  useLayoutEffect(() => {
    updateRectangle(); // Initial setup
  }, [points]);

  return (
    <Group key={getUniqueId('group')}>
      <Line
        key={getUniqueId('line')}
        ref={lineRef}
        points={points}
        stroke="transparent"
        strokeWidth={strokeWidth}
      />
      <Rect
        key={getUniqueId('ring')}
        ref={rectRef}
        height={strokeWidth} // Adjust height as needed
        strokeWidth={outlineStrokeWidth}
        stroke={outlineStroke}
        offsetX={length / 2}
        offsetY={strokeWidth / 2}
        cornerRadius={strokeWidth / 2}
        fill={fillColor}
      />
    </Group>
  );
};

export default Ring;
