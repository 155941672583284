import { useOnClickOnEscape, useOnClickOutside } from 'hooks/util';
import { useRef, useState } from 'react';

export default function usePopup({popupOptions = [], initialPopupStatus = false}) {
  const [popup, setPopup] = useState(initialPopupStatus);
  const popupRef = useRef(null);

  useOnClickOutside(popupRef, () => setPopup(false));
  useOnClickOnEscape(() => setPopup(false));

  return {
    popupRef,
    popup,
    setPopup,
    popupOptions
  };
}
