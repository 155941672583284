const convertToAlpha = num => {
  let alpha = '';
  const alphabet = 'ABCDEFGHIJKlmnopqrstuvwxyz';
  while (num > 0) {
    const remainder = (num - 1) % 26;
    alpha = alphabet.charAt(remainder) + alpha;
    num = Math.floor((num - remainder) / 26);
  }
  return alpha;
};

const convertToRoman = num => {
  let roman = '';
  const romanNumList = {
    m: 1000,
    cm: 900,
    d: 500,
    cd: 400,
    c: 100,
    xc: 90,
    l: 50,
    xl: 40,
    x: 10,
    ix: 9,
    v: 5,
    iv: 4,
    i: 1,
  };
  const romanNumListKeys = Object.keys(romanNumList);
  for (let i = 0; i < romanNumListKeys.length; i++) {
    const key = romanNumListKeys[i];
    while (num >= romanNumList[key]) {
      roman += key;
      num -= romanNumList[key];
    }
  }
  return roman;
};

export const convertNumber = (num, type = '1') => {
  let formattedNumber = '';
  switch (type) {
    case 'a':
      formattedNumber = convertToAlpha(num);
      break;
    case 'i':
      formattedNumber = convertToRoman(num);
      break;
    default:
      formattedNumber = num;
      break;
  }
  return formattedNumber;
};
