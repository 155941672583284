import React, { useState, useEffect } from 'react';
import linkFromObject from 'settings/format-link-types';
import SelectWrapper from 'components/forms/elements/SelectWrapper';
import CheckList from 'components/forms/elements/CheckList';
import InputNumber from 'components/forms/elements/InputNumber';

function FormatStudioFormLink({
  className,
  setForm,
  selectedShapeId,
  onChange,
  secondarySelectedIds,
  setSelectedShapeId,
  shapes,
  setShapes,
}) {
  const [link, setLink] = useState({
    type: '',
  });

  const [typeOptions, setTypeOptions] = useState([]);

  useEffect(() => {
    if (selectedShapeId) {
      setTypeOptions(
        Object.keys(linkFromObject)
          // .filter(o =>
          //   selectedShapeId.includes('text') ? o !== 'Puzzle' : o === 'Puzzle'
          // )
          .map(key => ({
            label: key,
            value: key,
          }))
      );
    }
  }, [selectedShapeId]);

  useEffect(() => {
    if (
      selectedShapeId &&
      link.type &&
      link.role &&
      !link.format &&
      !link.role.includes('Data field')
    ) {
      const l = {
        type: link.type,
        role: link.role || linkFromObject[link.type].roles[0],
      };

      if (link.type !== 'List') {
        l.format = l.format || linkFromObject[link.type].formats[0];
      } else {
        l.rows = 1;
        l.cols = 1;
      }

      if (linkFromObject['General'].roles.includes(l.role)) {
        l.type = 'General'; // for backward compatibility
      }
      setLink(l);
    }
  }, [link.role]);

  const applyOnSelectedNode = callback => {
    if (selectedShapeId) {
      const list = [...shapes];
      const index = shapes.findIndex(item => item.id === selectedShapeId);
      const node = list[index];
      callback(node);
    }
  };

  useEffect(() => {
    applyOnSelectedNode(node => {
      if (!node) return;
      node.link = link.type === 'General' ? { ...link, type: 'Text' } : link;
      // text and general were same
      // to avoid repeat logic for general, we show general but we use text
      onChange(node, false);
    });
  }, [link]);

  useEffect(() => {
    if (selectedShapeId) {
      applyOnSelectedNode(node => {
        if (node && node.link && node.link.role && node.link.role !== 'Data field') {
          if (linkFromObject['General'].roles.includes(node.link.role))
            setLink({ ...node.link, type: 'General' }); // for backward compatibility
          else setLink(node.link);
        } else setLink({ type: '' });
      });
    }
  }, [selectedShapeId]);

  return (
    <div className={`${className} form__studio-links`}>
      <div className="form__studio-heading">LINKS</div>
      <div className="form__studio-scroll">
        {selectedShapeId &&
        ((selectedShapeId.includes('text') && !selectedShapeId.includes('list')) ||
          selectedShapeId.includes('rect') ||
          selectedShapeId.includes('image')) ? (
          <div>
            <div className=" d-flex flex-column mb-3 form-group">
              <label className="mb-3">Link type</label>
              <SelectWrapper
                options={typeOptions}
                value={link.type}
                setValue={value => setLink({ ...link, type: value })}
              />
            </div>
            {link.type && linkFromObject[link.type] && (
              <div className=" d-flex flex-column mb-3 form-group">
                <label className="mb-3">{link.type} role</label>
                <SelectWrapper
                  options={linkFromObject[link.type].roles.map(type => ({
                    label: type,
                    value: type,
                  }))}
                  value={link.role}
                  setValue={value => setLink({ ...link, role: value })}
                />
              </div>
            )}
            {link.type && linkFromObject[link.type] && linkFromObject[link.type].formats.length ? (
              <CheckList
                radioSelect
                title={link.type + ' format'}
                // itemsType={'formats'}
                items={linkFromObject[link.type].formats.map(link => ({
                  id: link,
                  title: link,
                }))}
                // loadItems={loadFormats}
                checkedItems={[{ id: link.format, title: link }]}
                setCheckedItems={arr => setLink({ ...link, format: arr[0].id })}
                // isLoading={isFormatsLoading}
              />
            ) : (
              ''
            )}
            {link.type === 'List' && (
              <div>
                <div className="form-group">
                  <label className="mb-3">Dimentions</label>

                  <div className="d-flex flex-row ">
                    <div className="d-flex align-items-center mb-2" style={{ marginRight: 30 }}>
                      <label style={{ marginRight: 13 }}>Rows</label>
                      <InputNumber
                        value={link.rows || 1}
                        setValue={value => setLink({ ...link, rows: value })}
                        className="form-control"
                        type="number"
                        step={1}
                        min={1}
                      />
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <label style={{ marginRight: 13 }}>Columns</label>
                      <InputNumber
                        value={link.cols || 1}
                        setValue={value => setLink({ ...link, cols: value })}
                        className="form-control"
                        type="number"
                        step={1}
                        min={1}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <p>Select some element ( Text / Rect / Image).</p>
        )}
      </div>
    </div>
  );
}

export default FormatStudioFormLink;
