import { useContext, useState } from 'react';
import { globalContext } from '../context/global';

export function useGlobalContext() {
  return useContext(globalContext);
}

export function useGlobalContextProvider() {
  const [msgs, setMsgs] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);
  const [languages, setLanguages] = useState([]);
  const [elementTypes, setElementTypes] = useState([]);
  const [pageSizes, setPageSizes] = useState([]);

  const getLanguageID = lang => {
    const language = languages.find(
      l => l.name.replaceAll(' ', '-').toLowerCase() === lang.toLowerCase()
    );
    if (language) return language.id;
    else return 1;
  };

  const getElementTypeID = type => {
    const elementType = elementTypes.find(
      t => t.name.replaceAll(' ', '-').toLowerCase() === type.toLowerCase()
    );
    if (elementType) return elementType.id;
    else return 1;
  };

  return {
    msgs,
    setMsgs,
    showSpinner,
    setShowSpinner,
    languages,
    setLanguages,
    getLanguageID,
    elementTypes,
    setElementTypes,
    getElementTypeID,
    pageSizes,
    setPageSizes,
  };
}
