import React from 'react';
import { Text, Group, Rect, Line, Circle } from 'react-konva';
import LinkIndicator from '../LinkIndicator';
import Ring from './Ring';
import { sliceIntoChunks, extractCellsContent, WORDSEARCH_DESIGN } from 'settings/puzzels';

const TGridCrossWords = ({
  type,
  shapeProps,
  setTextareaProps,
  isEditMode,
  setIsEditMode,
  setTextareaValue,
  isSelected,
  scale,
  setSelectedShapeId,
  stageWidth,
  stageHeight,
  bleedingTop,
  bleedingLeft,
  onSelect,
  onDragStart,
  onDragMove,
  onDragEnd,
  onChange,
  hasRings = false,
  trForceUpdate,
}) => {
  const shapeRef = React.useRef();
  // const [dummy, setDummy] = React.useState(DUMMY); // for word-search
  const [grid, setGrid] = React.useState([]);
  const [initText] = React.useState(shapeProps.text);
  const nRows = React.useCallback(
    () =>
      shapeProps.staticLink.grid ? shapeProps.staticLink.grid.size[0] : shapeProps.staticLink.rows,
    [shapeProps.staticLink.grid, shapeProps.staticLink.rows]
  );

  const nCols = React.useCallback(
    () =>
      shapeProps.staticLink.grid ? shapeProps.staticLink.grid.size[1] : shapeProps.staticLink.cols,
    [shapeProps.staticLink.grid, shapeProps.staticLink.cols]
  );

  React.useEffect(() => {
    const t = typeof shapeProps.text === 'object' ? shapeProps.text.grid_str : shapeProps.text;
    const cells = extractCellsContent(t || initText);

    const rows = sliceIntoChunks(cells, nCols());
    const grid = rows.map((row, rowIndex) => {
      return row.map((cell, colIndex) => {
        return {
          text: cell,
          x: colIndex * (shapeProps.width / nCols()),
          y: rowIndex * (shapeProps.height / nRows()),
        };
      });
    });

    setGrid(grid);
  }, [
    shapeProps.height,
    shapeProps.width,
    shapeProps.staticLink.rows,
    shapeProps.staticLink.cols,
    shapeProps.fontSize,
    shapeProps.text,
    shapeProps.staticLink.grid,
  ]);

  return (
    <>
      {shapeProps.link && isSelected && !isEditMode && (
        <LinkIndicator shapeProps={shapeProps} offset={false} />
      )}
      <Group
        onMouseDown={onSelect}
        onTap={onSelect}
        onDblTap={() => {
          setSelectedShapeId(null); // UI fix - Don't remove it
          setIsEditMode(true);
          setSelectedShapeId(shapeProps.id);
        }}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragStart={onDragStart}
        onDragMove={onDragMove}
        onDragEnd={e => onDragEnd(shapeProps, e)}
        onTransformEnd={e => {
          // transformer is changing scale of the node
          // and NOT its width or height- shapeRef.current.fontSize()/scale/100/2 - shapeRef.current.padding()/scale/100
          // but in the store we have only width and height
          // to match the data better we will re- shapeRef.current.fontSize()/scale/100/2 - shapeRef.current.padding()/scale/100set scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);

          onChange(
            {
              ...shapeProps,
              x: node.x(),
              y: node.y(), // set minimal value
              width: node.width() * scaleX,
              // height: node.width() * scaleX, // Quick fix
              height:
                shapeProps.staticLink &&
                shapeProps.staticLink.puzzleShape &&
                shapeProps.staticLink.puzzleShape === 'normal'
                  ? node.height() * scaleY
                  : node.width() * scaleX,
              scaleX: 1,
              scaleY: 1,
              rotation: node.rotation(),
            },
            true
          );
          trForceUpdate();
        }}
      >
        <Rect // this to constrain width
          key={'outline-' + shapeProps.staticLink.puzzleShape + shapeProps.id}
          x={0}
          y={0}
          width={shapeProps.width}
          height={shapeProps.height}
          stroke={'black'}
          // stroke={'rgba(0,0,0,0)'}
          fill={'rgba(0,0,0,0)'}
          strokeWidth={
            shapeProps.staticLink.borders.checked1 ? shapeProps.staticLink.borders.width1 : 0
          }
        />
        {grid.map((cellsChunk, i) =>
          cellsChunk.map((word, j) => {
            const text = word.text;
            const smallNumbers = shapeProps.staticLink?.smallNumbers ?? '';
            const smallText = smallNumbers.split(',');
            const smallNumber = smallText[i * nCols() + j] || '';

            return (
              <>
                {i < nRows() && text !== '-' && !shapeProps.id.includes('puzzle') && (
                  <Text
                    ellipsis
                    wrap={'none'}
                    key={'col' + j + '-' + 'row' + i}
                    {...shapeProps}
                    x={word.x}
                    y={word.y}
                    width={shapeProps.width / nCols()}
                    height={shapeProps.height / nRows()}
                    lineHeight={shapeProps.lineHeight}
                    text={text}
                    rotation={0}
                    verticalAlign={'middle'}
                  />
                )}
                {i < nRows() && text === '-' && (
                  <Rect
                    key={'col' + j + '-' + 'row' + i}
                    x={word.x}
                    y={word.y}
                    width={shapeProps.width / nCols()}
                    height={shapeProps.height / nRows()}
                    fill={'#000000'}
                  />
                )}
                {i < nRows() && smallNumber && (
                  <Text
                    key={'small-text--col' + j + '-' + 'row' + i}
                    text={smallNumber.trim()}
                    x={
                      word.x +
                      shapeProps.width / nCols() -
                      shapeProps.height / nRows() / (smallNumber.trim().length > 1 ? 3 : 4)
                    }
                    y={word.y + shapeProps.height / nRows() / 8}
                    fill={shapeProps.fill}
                    fontSize={shapeProps.height / nRows() / 4}
                  />
                )}
              </>
            );
          })
        )}

        {[...Array(nRows()).keys()].map(
          (row, i) =>
            i > 0 && (
              <Line
                points={[
                  0,
                  (shapeProps.height / nRows()) * i,
                  shapeProps.width,
                  (shapeProps.height / nRows()) * i,
                ]}
                stroke={
                  i % (shapeProps.staticLink.grid?.blocks[1] ?? 3) === 0
                    ? shapeProps.staticLink.borders.color1
                    : shapeProps.staticLink.borders.color2
                }
                strokeWidth={
                  i % (shapeProps.staticLink.grid?.blocks[1] ?? 3) === 0
                    ? shapeProps.staticLink.borders.checked1
                      ? shapeProps.staticLink.borders.width1
                      : 0
                    : shapeProps.staticLink.borders.checked2
                    ? shapeProps.staticLink.borders.width2
                    : 0
                }
              />
            )
        )}
        {[...Array(nCols()).keys()].map(
          (col, j) =>
            j > 0 && (
              <Line
                points={[
                  (shapeProps.width / nCols()) * j,
                  0,
                  (shapeProps.width / nCols()) * j,
                  shapeProps.height,
                ]}
                stroke={
                  j % (shapeProps.staticLink.grid?.blocks[0] ?? 3) === 0
                    ? shapeProps.staticLink.borders.color1
                    : shapeProps.staticLink.borders.color2
                }
                strokeWidth={
                  j % (shapeProps.staticLink.grid?.blocks[0] ?? 3) === 0
                    ? shapeProps.staticLink.borders.checked1
                      ? shapeProps.staticLink.borders.width1
                      : 0
                    : shapeProps.staticLink.borders.checked2
                    ? shapeProps.staticLink.borders.width2
                    : 0
                }
              />
            )
        )}
      </Group>
    </>
  );
};

export default TGridCrossWords;
