// -----> Before API updated <-----
// import FormatsStage from './FormatsStage';

// const MM_TO_INCH = 0.0393701;

// const dummyCover = {
//   image: null,
//   content_types: [],
//   page_hand_side: '0',
//   stage_json: null,
//   paper_thickness: 0.05 * MM_TO_INCH,
//   manuscript_number_of_pages: 200,
//   height: 10,
//   width: 8,
//   margin: {
//     top: 0.5,
//     left: 0.5,
//     bottom: 0.5,
//     right: 0.5,
//   },
//   bleeding: {
//     top: 0.5,
//     left: 0.5,
//     bottom: 0.5,
//     right: 0.5,
//   },
// };

// function CoverStage({ cover, show, toRemove, setToRemove, allowedTags }) {
//   return (
//     <FormatsStage
//       isCoversStage={true}
//       format={{
//         // AFTER API UPDATED => delete this line and the next one
//         ...dummyCover,

//         ...cover,

//         // AFTER API UPDATED => replace the dummy cover with the real one

//         width:
//           dummyCover.width * 2 +
//           dummyCover.bleeding.left +
//           dummyCover.bleeding.right +
//           dummyCover.paper_thickness * dummyCover.manuscript_number_of_pages,
//         height: dummyCover.height + dummyCover.bleeding.top + dummyCover.bleeding.bottom,
//         margin: {
//           top: dummyCover.margin.top + dummyCover.bleeding.top,
//           left: dummyCover.margin.left + dummyCover.bleeding.left,
//           bottom: dummyCover.margin.bottom + dummyCover.bleeding.bottom,
//           right: dummyCover.margin.right + dummyCover.bleeding.right,
//         },
//         spineBoundries: {
//           left: dummyCover.bleeding.left + dummyCover.width,
//           right:
//             dummyCover.bleeding.left +
//             dummyCover.width +
//             dummyCover.paper_thickness * dummyCover.manuscript_number_of_pages,
//         },
//       }}
//       show={show}
//       toRemove={toRemove}
//       setToRemove={setToRemove}
//       allowedTags={allowedTags}
//     />
//   );
// }
// export default CoverStage;

// ---> After API updated <---

import FormatsStage from './FormatsStage';
import { useEffect, useState } from 'react';
import { useGlobalContext } from 'state/hooks/global';
// import { paperThicknesses } from '../../settings/paperThicknesses';

const MM_TO_INCH = 0.0393701;

function CoverStage({ cover, show, toRemove, setToRemove, allowedTags, paperThicknessList }) {
  const [paperThicknessInInches, setPaperThicknessInInches] = useState(0);
  const [pageWidth, setPageWidth] = useState(0);
  const [pageHeight, setPageHeight] = useState(0);
  const { pageSizes } = useGlobalContext();

  useEffect(() => {
    if (cover && cover.paper_thickness && paperThicknessList.length) {
      const paperThickness = paperThicknessList.find(t => t.id === cover.paper_thickness);
      setPaperThicknessInInches(paperThickness.value * MM_TO_INCH);
    }
  }, [cover, paperThicknessList]);

  useEffect(() => {
    if (cover && cover.page_size && pageSizes.length) {
      const pageSize = pageSizes.find(p => p.id === cover.page_size);
      setPageWidth(pageSize.width);
      setPageHeight(pageSize.height);
    }
  }, [cover, pageSizes]);

  const bleeding = cover.bleeding || {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  };

  const margin = cover.margin || {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  };

  return (
    <FormatsStage
      isCoversStage={true}
      format={{
        ...cover,
        width:
          pageWidth * 2 +
          bleeding.left +
          bleeding.right +
          paperThicknessInInches * (cover.number_of_pages || 0),
        height: pageHeight + bleeding.top + bleeding.bottom,
        margin: {
          top: margin.top + bleeding.top,
          left: margin.left + bleeding.left,
          bottom: margin.bottom + bleeding.bottom,
          right: margin.right + bleeding.right,
        },
        spineBoundries: {
          left: bleeding.left + pageWidth,
          right: bleeding.left + pageWidth + paperThicknessInInches * (cover.number_of_pages || 0),
        },
      }}
      show={show}
      toRemove={toRemove}
      setToRemove={setToRemove}
      allowedTags={allowedTags}
    />
  );
}
export default CoverStage;
