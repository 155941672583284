import React, { useState, useEffect, useRef } from 'react';
import { useOnClickOnEscape, useWindowSize } from 'hooks/util';
import { addTemplate } from 'api/studio/templates';
import { Stage, Layer, Rect } from 'react-konva';
import { useHistory } from 'react-router-dom';
import InputNumber from 'components/forms/elements/InputNumber';
import SelectWrapper from 'components/forms/elements/SelectWrapper';
import Tags from 'components/common/Tags';

function FromatSpecForm({
  closeSlideModal,
  pageSizes,
  allowedTags,
  templateCategoryId,
  addTemplateToWorkspace,
}) {
  const previewRef = useRef(null);
  const history = useHistory();
  const [width] = useWindowSize();
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [pageSizeIndex, setPageSizeIndex] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(200);

  const [scale, setScale] = useState(1);
  const [tagsArr, setTagsArr] = useState([]);

  const dims = {
    previewWidth: () => previewRef.current.clientWidth,
    totalHeightInPx: () => pageSizes[pageSizeIndex].height * 96,
    totalWidthInPx: () => pageSizes[pageSizeIndex].width * 96,
    pageHeight: () => pageSizes[pageSizeIndex].height,
    pageWidth: () => pageSizes[pageSizeIndex].width,
    pageHeightInPx: () => pageSizes[pageSizeIndex].height * 96,
    pageWidthInPx: () => pageSizes[pageSizeIndex].width * 96,
    marginsHeightInPx: () => 0,
    marginsWidthInPx: () => 0,
  };

  useEffect(() => {
    setScale(
      Math.min(
        dims.previewWidth() / 1.5 / dims.pageHeightInPx(),
        dims.previewWidth() / 1.5 / dims.pageWidthInPx()
      )
    );
  }, [pageSizeIndex, width]);

  useOnClickOnEscape(closeSlideModal);

  const tagsIdsArr = () => tagsArr.map(item => item.id);

  const handleSubmit = () => {
    if (!title.trim()) {
      setTitleError(true);
      return;
    }

    addTemplate({
      title: title,
      page_size: pageSizes[pageSizeIndex].id,
      number_of_pages: numberOfPages,
      is_published: true,
      category: templateCategoryId,
      tags: tagsIdsArr(),
    }).then(res => {
      if (res.success) {
        addTemplateToWorkspace(res.template);
        history.push(`/home/templates/workspace`);
        closeSlideModal();
      } else {
        console.log(res);
      }
    });
  };

  return (
    <div className="form__spec">
      <div className="form__spec--preview" ref={previewRef}>
        <div className=" d-flex justify-content-center align-items-center">
          <div
            style={{
              position: 'absolute',
            }}
          >
            <Stage
              className="shadow"
              width={dims.pageWidthInPx() * scale}
              height={dims.pageHeightInPx() * scale}
            >
              <Layer>
                <Rect
                  x={0}
                  y={0}
                  width={dims.pageWidthInPx() * scale}
                  height={dims.pageHeightInPx() * scale}
                  fill="white"
                />
                {/* {(marginT > 0 || marginR > 0 || marginB > 0 || marginL > 0) && (
                  <Rect
                    x={marginL * 96 * scale}
                    y={marginT * 96 * scale}
                    width={dims.marginsWidthInPx() * scale}
                    height={dims.marginsHeightInPx() * scale}
                    stroke="blue"
                    strokeWidth={1}
                  />
                )} */}
              </Layer>
            </Stage>
          </div>
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              color: 'gray',
              opacity: 0.6,
            }}
          >
            <p style={{ fontSize: 20, textAlign: 'end', marginBottom: -10, marginRight: -25 }}>
              Inches
            </p>
            <h1 style={{ fontSize: 100, fontStyle: 'bold' }}>
              {pageSizes[pageSizeIndex].width + ' x ' + pageSizes[pageSizeIndex].height}
            </h1>
          </div>
        </div>
      </div>

      <div className="form form__spec--controls">
        <div className="form__spec--controls-heading">Template Specifications</div>
        <div className="scrollable-window " style={{ maxHeight: '77vh' }}>
          <div className="form-group">
            <label>Title</label>
            <input
              className="form-control light mb-2"
              type="text"
              placeholder="Title goes here..."
              value={title}
              onChange={e => {
                setTitle(e.target.value);
                setTitleError(false);
              }}
            />
            {titleError && <span className="text-error">Title is required!</span>}
          </div>
          <div className="form-section-2">
            <div className="form-group">
              <label>Page size</label>
              <SelectWrapper
                className={`form-control`}
                options={pageSizes.map((size, index) => ({
                  label: `${size.width} x ${size.height} Inches`,
                  value: index,
                }))}
                isSearchable={true}
                value={pageSizeIndex}
                setValue={setPageSizeIndex}
              />
            </div>
            <div className="form-group">
              <label>Number of pages</label>
              <InputNumber
                className={'form-control'}
                min={0}
                step={1}
                value={numberOfPages}
                setValue={setNumberOfPages}
              />
            </div>
          </div>
          <Tags
            title={'Tags'}
            allowedTags={allowedTags}
            tagsArr={tagsArr}
            setTagsArr={setTagsArr}
            classNameA={'form-group'}
            classNameB={''}
            inputClassName={'form-control light'}
            height={50}
            inputPlaceholder={'Teens, Adults, Seasons,... etc'}
            showCount={false}
          />
        </div>
        <div className="d-flex justify-content-center mt-4">
          <div className="d-flex w-75" style={{ position: 'absolute', bottom: 25 }}>
            <button
              className="btn-white-forms mx-2"
              onClick={() => {
                closeSlideModal();
              }}
            >
              Cancel
            </button>
            <button className="btn-blue-forms mx-2" onClick={handleSubmit}>
              Next
            </button>
          </div>
        </div>
      </div>
      <button className="form__spec--close-btn" onClick={closeSlideModal}>
        x
      </button>
    </div>
  );
}

export default FromatSpecForm;
