import TemplatePage from './TemplatePage';
import React, { useEffect, useRef, useState } from 'react';

const Pages = React.memo(
  ({
    loading,
    setLoading = () => {},
    percentage,
    setPercentage = () => {},
    pages,
    pageSizes,
    template = {},
    handlePageClick = () => {},
    selectedPages = [],
    show,
    onClick = () => {},
    setRefs,
    lastSelected,
    handleStoryPagesCreation,
  }) => {
    const pagesRefs = useRef([]);

    useEffect(() => {
      if (setRefs) setRefs(pagesRefs.current.sort((a, b) => a.index > b.index));
    }, [pages.length, pagesRefs.current]);

    useEffect(() => {
      if (lastSelected) {
        const el = document.querySelector(`#t-${template.id}-pn-${lastSelected}`);
        if (el)
          el.scrollIntoView({
            behavior: 'smooth',
          });
      }
    }, [lastSelected]);

    return (
      <div className="pages-wrapper">
        <div className="pages" onClick={onClick}>
          {pages.map((page, index) => (
            <TemplatePage
              key={JSON.stringify(page) + index}
              isSelected={selectedPages.includes(index + 1)}
              pageSizes={pageSizes}
              // template={template.page_size ? template : {...template, page_size:23}}
              template={template}
              handlePageClick={handlePageClick}
              index={index}
              page={page}
              show={show}
              passRef={(stageRef, shapesLayerRef) => {
                const obj = { index, ref: stageRef, shapesLayerRef };
                if (pagesRefs.current[index]) pagesRefs.current[index] = obj;
                else pagesRefs.current.push(obj);
                const p = Math.round((100 / pages.length) * pagesRefs.current.length);
                setPercentage(p > 100 ? 100 : p);
                if (pagesRefs.current.length >= pages.length || pages.length === 0) {
                  setLoading(false);
                } else {
                  setLoading(true);
                }
              }}
              handleStoryPagesCreation={handleStoryPagesCreation}
              pageNumber={index + 1}
            />
          ))}
        </div>

        {loading && (
          <div
            className="pages d-flex justify-content-center align-items-center w-100 h-100"
            style={{
              position: 'absolute',
              top: 0,
              backgroundColor: 'var(--background)',
            }}
          >
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
            <div>
              <span className="px-4">{percentage ? percentage + '%' : 'loading...'}</span>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default Pages;
