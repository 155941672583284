import Header from 'components/layout/Header';
import SideBar from 'components/layout/SideBar';
import SideMenu from 'components/layout/SideMenu';
import { React, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { colors } from 'settings/colors';
import { getPageSizes } from 'api/studio/formats';
import { useGlobalContext } from 'state/hooks/global';

function Main({ children }) {
  const { setPageSizes } = useGlobalContext();
  const location = useLocation();
  const pathname = location.pathname.split('/');
  const activeSpace = pathname[1];
  const activeMenu = pathname[2];
  const activeTab = pathname[3];

  const setSysColor = (color, bgColor) => {
    document.documentElement.style.setProperty('--sys', color);
    document.documentElement.style.setProperty('--sys-bg', bgColor);
  };

  useEffect(() => {
    getPageSizes(0, 10000)
      .then(res => {
        setPageSizes(res.page_sizes);
      })
      .catch(console.log);
  }, []);

  useEffect(() => {
    if (location) {
      let sysColor = colors.blue;
      let sysBgColor = colors.bgBlue;

      if (location.pathname.includes('templates')) {
        sysColor = colors.yellow;
        sysBgColor = colors.bgYellow;
      } else if (location.pathname.includes('manuscripts')) {
        sysColor = colors.green;
        sysBgColor = colors.bgGreen;
      } else if (location.pathname.includes('covers')) {
        sysColor = colors.red;
        sysBgColor = colors.bgRed;
      }

      setSysColor(sysColor, sysBgColor);

      return () => {
        setSysColor(colors.blue, colors.bgBlue);
      };
    }
  }, [location]);

  return (
    <div calss="main-layout">
      <div className="header">
        <Header />
      </div>
      <div className="body-section">
        <SideBar activeSpace={activeSpace} />
        <SideMenu activeSpace={activeSpace} activeMenu={activeMenu} activeTab={activeTab} />
        <div className="main-section">{children}</div>
      </div>
    </div>
  );
}
export default Main;
