import axios from 'axios';
import urls from 'settings/urls.js';

// WordLists
export async function getElements({ category_id, sort, tags = '', offset, limit }) {
  const requestOptions = {
    method: 'GET',
    url: urls.elements(category_id, sort, tags, offset, limit),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function getOneElement(id, params) {
  const requestOptions = {
    method: 'GET',
    url: urls.oneElement(id),
    params,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function addElement(data, setStatus, setUploadPorgress) {
  const requestOptions = {
    method: 'POST',
    url: urls.newElement,
    data,
    onUploadProgress: progressEvent => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      setStatus('');
      setUploadPorgress(percent);
    },
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function editElement(data, id, setStatus, setUploadPorgress) {
  const requestOptions = {
    method: 'PATCH',
    url: urls.editElement(id),
    data,
    onUploadProgress: progressEvent => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      setStatus('');
      setUploadPorgress(percent);
    },
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function deleteElement(id) {
  const requestOptions = {
    method: 'DELETE',
    url: urls.deleteElement(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

// Element types
export async function getElementTypes(params) {
  const requestOptions = {
    method: 'GET',
    url: urls.elementTypes,
    params,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function addElementType(data) {
  const requestOptions = {
    method: 'POST',
    url: urls.newElementType,
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function editElementType(data, id) {
  const requestOptions = {
    method: 'PATCH',
    url: urls.editElementType(id),
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function deleteElementType(id) {
  const requestOptions = {
    method: 'DELETE',
    url: urls.deleteElementType(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}
