const LIMIT = 50;
const domain = `${process.env.REACT_APP_API}/api/v1`;

const urls = {
  domain: domain,
  authToken: `${domain}/users/token/`,
  refreshToken: `${domain}/users/token/refresh/`,
  users: id => `${domain}/users/${id}/`,
  languages: `${domain}/languages/?bypass_authorization=true`,
  oneLanguage: id => `${domain}/languages/${id}/`,
  addLanguage: `${domain}/languages/new/`,
  editLanguage: id => `${domain}/languages/update/${id}/`,
  deleteLanguage: id => `${domain}/languages/delete/${id}/`,
  categories: `${domain}/categories/?bypass_authorization=true`,
  newCategory: `${domain}/categories/new/`,
  editCategory: id => `${domain}/categories/update/${id}/`,
  deleteCategory: id => `${domain}/categories/delete/${id}/`,
  tags: `${domain}/tags/?bypass_authorization=true`,
  addTags: `${domain}/tags/new/`,
  deleteTags: `${domain}/tags/delete/`,
  accounts: `${domain}/users/`,
  oneAccount: id => `${domain}/users/${id}/`,
  newAccount: `${domain}/users/new/`,
  editAccount: id => `${domain}/users/update/${id}/`,
  deleteAccount: id => `${domain}/users/delete/${id}/`,
  formats: (category_id, sort, tags, offset, listFilter) =>
    `${domain}/formats/?bypass_authorization=true&category=${category_id}&sort=${sort}${
      tags && `${tags}`
    }${listFilter && `${listFilter}`}&offset=${offset}&limit=${LIMIT}`,
  oneFormat: id => `${domain}/formats/${id}/`,
  newFormat: `${domain}/formats/new/`,
  editFormat: id => `${domain}/formats/update/${id}/`,
  deleteFormat: id => `${domain}/formats/delete/${id}/`,
  duplicateFormat: id => `${domain}/formats/duplicate/${id}/`,
  templates: (category_id, sort, tags, offset) =>
    `${domain}/templates/?bypass_authorization=true&category=${category_id}&sort=${sort}${
      tags && `${tags}`
    }&offset=${offset}&limit=${LIMIT}`,
  oneTemplate: id => `${domain}/templates/${id}/`,
  newTemplate: `${domain}/templates/new/`,
  editTemplate: id => `${domain}/templates/update/${id}/`,
  deleteTemplate: id => `${domain}/templates/delete/${id}/`,
  manuscripts: (category_id, sort, tags, offset) =>
    `${domain}/manuscripts/?bypass_authorization=true&category=${category_id}&sort=${sort}${
      tags && `${tags}`
    }&offset=${offset}&limit=${LIMIT}`,
  oneManuscript: id => `${domain}/manuscripts/${id}/`,
  newManuscript: `${domain}/manuscripts/new/`,
  editManuscript: id => `${domain}/manuscripts/update/${id}/`,
  deleteManuscript: id => `${domain}/manuscripts/delete/${id}/`,
  updateManuscriptVariables: id => `${domain}/manuscripts/${id}/update-variables/`,
  covers: (category_id, sort, tags, offset) =>
    `${domain}/covers/?bypass_authorization=true&category=${category_id}&sort=${sort}${
      tags && `${tags}`
    }&offset=${offset}&limit=${LIMIT}`,
  oneCover: id => `${domain}/covers/${id}/`,
  newCover: `${domain}/covers/new/`,
  editCover: id => `${domain}/covers/update/${id}/`,
  deleteCover: id => `${domain}/covers/delete/${id}/`,
  pageSizes: (offset, limit) =>
    `${domain}/page_sizes/?bypass_authorization=true&offset=${offset}&limit=${limit || LIMIT}`,
  newPageSize: `${domain}/page_sizes/new/`,
  editPageSize: id => `${domain}/page_sizes/update/${id}/`,
  deletePageSize: id => `${domain}/page_sizes/delete/${id}/`,
  paperThicknessList: (offset, limit) =>
    `${domain}/paper_thickness/?bypass_authorization=true&offset=${offset}&limit=${limit || LIMIT}`,
  newPaperThickness: `${domain}/paper_thickness/new/`,
  editPaperThickness: id => `${domain}/paper_thickness/update/${id}/`,
  deletePaperThickness: id => `${domain}/paper_thickness/delete/${id}/`,
  WordLists: (category_id, sort, tags, offset, limit) =>
    `${domain}/word_lists/?bypass_authorization=true&category=${category_id}&sort=${sort}${
      tags && `${tags}`
    }&offset=${offset}&limit=${limit || LIMIT}`,
  oneWordList: id => `${domain}/word_lists/${id}/`,
  newWordList: `${domain}/word_lists/new/`,
  editWordList: id => `${domain}/word_lists/update/${id}/`,
  deleteWordList: id => `${domain}/word_lists/delete/${id}/`,
  TriviaLists: (category_id, sort, tags, offset, limit) =>
    `${domain}/trivia_lists/?bypass_authorization=true&category=${category_id}&sort=${sort}${
      tags && `&${tags}`
    }&offset=${offset}&limit=${limit || LIMIT}`,
  oneTriviaList: id => `${domain}/trivia_lists/${id}/`,
  newTriviaList: `${domain}/trivia_lists/new/`,
  editTriviaList: id => `${domain}/trivia_lists/update/${id}/`,
  deleteTriviaList: id => `${domain}/trivia_lists/delete/${id}/`,
  ArticleLists: (category_id, sort, tags, offset, limit) =>
    `${domain}/article_lists/?bypass_authorization=true&category=${category_id}&sort=${sort}${
      tags && `&${tags}`
    }&offset=${offset}&limit=${limit || LIMIT}`,
  oneArticleList: id => `${domain}/article_lists/${id}/`,
  newArticleList: `${domain}/article_lists/new/`,
  editArticleList: id => `${domain}/article_lists/update/${id}/`,
  deleteArticleList: id => `${domain}/article_lists/delete/${id}/`,
  stories: (category_id, sort, tags, offset, limit) =>
    `${domain}/stories/?bypass_authorization=true&category=${category_id}&sort=${sort}${
      tags && `&${tags}`
    }&offset=${offset}&limit=${limit || LIMIT}`,
  oneStory: id => `${domain}/stories/${id}/`,
  newStory: `${domain}/stories/new/`,
  editStory: id => `${domain}/stories/update/${id}/`,
  deleteStory: id => `${domain}/stories/delete/${id}/`,
  elements: (category_id, sort, tags, offset, limit) =>
    `${domain}/elements/?bypass_authorization=true&category=${category_id}&sort=${sort}${
      tags && `${tags}`
    }&offset=${offset}&limit=${limit || LIMIT}`,
  oneElement: id => `${domain}/elements/${id}/`,
  newElement: `${domain}/elements/new/`,
  editElement: id => `${domain}/elements/update/${id}/`,
  deleteElement: id => `${domain}/elements/delete/${id}/`,
  elementTypes: `${domain}/element_types/?bypass_authorization=true`,
  newElementType: `${domain}/element_types/new/`,
  editElementType: id => `${domain}/element_types/update/${id}/`,
  deleteElementType: id => `${domain}/element_types/delete/${id}/`,
  variables: `${domain}/variables/?bypass_authorization=true`,
  newVariable: `${domain}/variables/new/`,
  editVariable: id => `${domain}/variables/update/${id}/`,
  deleteVariable: id => `${domain}/variables/delete/${id}/`,
  versionNames: `${domain}/variables/version_names/?bypass_authorization=true`,
  newVersionName: `${domain}/variables/version_names/new/`,
  newVersion: `${domain}/variables/versions/new/`,
  oneVersion: id => `${domain}/variables/versions/${id}/`,
  editVersion: id => `${domain}/variables/versions/update/${id}/`,
  deleteVersion: id => `${domain}/variables/versions/delete/${id}/`,
  triviaListEngine: `${domain}/trivia_list_engine/`,
  wordSearchEngine: `${domain}/word_search_engine/generate/`,
  articleWordSearchEngine: `${domain}/article_word_search_engine/generate/`,
  numberSearchEngine: `${domain}/number_search_engine/generate/`,
  sudokuEngine: `${domain}/sudoku_engine/generate/`,
  storyEngine: `${domain}/story_engine/`,
  pdfNotificaction: `${domain}/notifications/create_pdf/`,
};

export default urls;
