// To get this list
// fetch(`https://www.googleapis.com/webfonts/v1/webfonts?sort=POPULARITY&key=AIzaSyCF9eyxbqGKe-kT7uu8WTTsfSQI0svnZ0U`)
// .then(res => res.json())
// .then(data =>  data.items.map(item => item.family))

// When ever you change this file you must update index.html as well with the same change

const fontFamilies = [
  'Arial',
  'Mono Space',
  'Helvetica',
  'Roboto',
  'Open Sans',
  'Noto Sans JP',
  'Lato',
  'Montserrat',
  'Roboto Condensed',
  'Source Sans Pro',
  'Poppins',
  'Oswald',
  'Roboto Mono',
  'Noto Sans',
  'Raleway',
  'Ubuntu',
  'Nunito',
  'Roboto Slab',
  'Merriweather',
  'PT Sans',
  'Playfair Display',
  'Rubik',
  'Open Sans Condensed',
  'Lora',
  'Noto Sans KR',
  'Inter',
  'Nunito Sans',
  'Work Sans',
  'Fira Sans',
  'Nanum Gothic',
  'Quicksand',
  'Mukta',
  'PT Serif',
  'Noto Sans TC',
  'Hind Siliguri',
  'Titillium Web',
  'Noto Serif',
  'Inconsolata',
  'Karla',
  'Barlow',
  'Heebo',
  'Oxygen',
  'Bebas Neue',
  'Libre Franklin',
  'PT Sans Narrow',
  'Libre Baskerville',
  'Arimo',
  'Source Code Pro',
  'Dosis',
  'Josefin Sans',
  'IBM Plex Sans',
  'Mulish',
  'Bitter',
  'Cabin',
  'Anton',
  'Dancing Script',
  'Yanone Kaffeesatz',
  'Lobster',
  'Crimson Text',
  'Varela Round',
  'Cairo',
  'EB Garamond',
  'DM Sans',
  'Source Serif Pro',
  'Hind',
  'Abel',
  'Fjalla One',
  'Prompt',
  'Comfortaa',
  'Arvo',
  'Kanit',
  'Exo 2',
  'Architects Daughter',
  'Noto Sans SC',
  'Barlow Condensed',
  'Pacifico',
  'Slabo 27px',
  'Teko',
  'Saira Condensed',
  'Zilla Slab',
  'Indie Flower',
  'Balsamiq Sans',
  'Maven Pro',
  'Merriweather Sans',
  'Shadows Into Light',
  'Asap',
  'Signika Negative',
  'Abril Fatface',
  'IBM Plex Serif',
  'Cormorant Garamond',
  'Overpass',
  'Padauk',
  'Italianno',
  'Questrial',
  'Patrick Hand',
  'Assistant',
  'Noto Serif JP',
  'Fira Sans Condensed',
  'Permanent Marker',
  'Manrope',
  'Hind Madurai',
  'Catamaran',
  'Exo',
  'Caveat',
  'Tajawal',
  'Rajdhani',
  'Satisfy',
  'Acme',
  'ABeeZee',
  'Archivo Narrow',
  'Nanum Myeongjo',
  'Play',
  'Alfa Slab One',
  'Domine',
  'Bree Serif',
  'Signika',
  'Amatic SC',
  'Cookie',
  'Public Sans',
  'Secular One',
  'Martel',
  'Righteous',
  'Vollkorn',
  'Noto Sans HK',
  'Alegreya Sans',
  'Barlow Semi Condensed',
  'M PLUS Rounded 1c',
  'Space Mono',
  'Patua One',
  'Fredoka One',
  'Jost',
  'Antic Slab',
  'Crete Round',
  'Spartan',
  'Cinzel',
  'Space Grotesk',
  'Sarabun',
  'Courgette',
  'Amiri',
  'Ubuntu Condensed',
  'Alegreya',
  'Archivo',
  'M PLUS 1p',
  'Almarai',
  'IBM Plex Mono',
  'Archivo Black',
  'Lobster Two',
  'Encode Sans',
  'Frank Ruhl Libre',
  'PT Sans Caption',
  'Tinos',
  'Great Vibes',
  'Didact Gothic',
  'Francois One',
  'Chakra Petch',
  'Red Hat Display',
  'Spectral',
  'Kaushan Script',
  'Cardo',
  'Gothic A1',
  'Noticia Text',
  'Crimson Pro',
  'Prata',
  'Parisienne',
  'Yantramanav',
  'DM Serif Display',
  'Kalam',
  'Russo One',
  'Gloria Hallelujah',
  'Baloo 2',
  'Quattrocento Sans',
  'Noto Kufi Arabic',
  'Luckiest Guy',
  'Orbitron',
  'Cuprum',
  'Recursive',
  'Sacramento',
  'Old Standard TT',
  'Rokkitt',
  'Cantarell',
  'Asap Condensed',
  'Concert One',
  'Saira',
  'Pathway Gothic One',
  'Passion One',
  'Paytone One',
  'Changa',
  'Chivo',
  'Josefin Slab',
  'Karma',
  'Volkhov',
  'Yellowtail',
  'Eczar',
  'Quattrocento',
  'Noto Serif TC',
  'Special Elite',
  'News Cycle',
  'Ropa Sans',
  'Unna',
  'Sawarabi Mincho',
  'Faustina',
  'PT Mono',
  'Playfair Display SC',
  'Khand',
  'Merienda',
  'Poiret One',
  'Cormorant',
  'Press Start 2P',
  'Vidaloka',
  'Monda',
  'Montserrat Alternates',
  'Neuton',
  'Playball',
  'Sanchez',
  'Advent Pro',
  'Handlee',
  'Sigmar One',
  'Mate SC',
  'Philosopher',
  'Ultra',
  'Economica',
  'Staatliches',
  'Actor',
  'Alata',
  'Hammersmith One',
  'Fira Sans Extra Condensed',
  'El Messiri',
  'Taviraj',
  'Electrolize',
  'Tangerine',
  'Istok Web',
  'Itim',
  'Adamina',
  'Titan One',
  'DM Serif Text',
  'Alice',
  'Marcellus',
  'Bangers',
  'Saira Semi Condensed',
  'Ubuntu Mono',
  'Arapey',
  'BenchNine',
  'Gudea',
  'Carter One',
  'Sawarabi Gothic',
  'Arima Madurai',
  'Amaranth',
  'Cantata One',
  'Yeseva One',
  'Mali',
  'Neucha',
  'Ruda',
  'Rock Salt',
  'Pragati Narrow',
  'Jura',
  'Homemade Apple',
  'Hind Vadodara',
  'Gelasio',
  'Abhaya Libre',
  'Cabin Condensed',
  'Allura',
  'Creepster',
  'Noto Serif SC',
  'Nanum Gothic Coding',
  'Aleo',
  'Gentium Basic',
  'Viga',
  'Noto Serif KR',
  'Mitr',
  'Lusitana',
  'Marck Script',
  'Palanquin',
  'Unica One',
  'Share Tech Mono',
  'Lexend Deca',
  'Sarala',
  'Gentium Book Basic',
  'Bad Script',
  'Allerta',
  'Julius Sans One',
  'Alef',
  'Khula',
  'Armata',
  'Monoton',
  'Rufina',
  'Sora',
  'Pontano Sans',
  'Courier Prime',
  'Fugaz One',
  'Bai Jamjuree',
  'Bowlby One SC',
  'Alex Brush',
  'Aclonica',
  'Nanum Pen Script',
  'Sen',
  'Gochi Hand',
  'Tenor Sans',
  'Mukta Malar',
  'Cousine',
  'Jaldi',
  'Nothing You Could Do',
  'Audiowide',
  'Kosugi Maru',
  'Rubik Mono One',
  'Bungee',
  'Suez One',
  'Shadows Into Light Two',
  'Ramabhadra',
  'Squada One',
  'Sorts Mill Goudy',
  'Red Hat Text',
  'Syncopate',
  'Sintony',
  'Basic',
  'Gruppo',
  'Martel Sans',
  'Varela',
  'Black Ops One',
  'Changa One',
  'Quantico',
  'Lilita One',
  'Lalezar',
  'Overlock',
  'Arsenal',
  'Coda',
  'Pangolin',
  'Pinyon Script',
  'Damion',
  'Bubblegum Sans',
  'Six Caps',
  'Mate',
  'Voltaire',
  'Mr Dafoe',
  'Oleo Script',
  'Annie Use Your Telescope',
  'Fira Mono',
  'Boogaloo',
  'Alegreya Sans SC',
  'Corben',
  'Rancho',
  'Stint Ultra Condensed',
  'Krub',
  'Encode Sans Condensed',
  'Days One',
  'Yrsa',
  'Aldrich',
  'IM Fell English SC',
  'Mada',
  'Candal',
  'Scheherazade',
  'Sriracha',
  'Kreon',
  'Chewy',
  'Literata',
  'Average',
  'Caveat Brush',
  'Covered By Your Grace',
  'Reenie Beanie',
  'Noto Naskh Arabic',
  'Enriqueta',
  'Pridi',
  'Palanquin Dark',
  'Shrikhand',
  'Laila',
  'Spinnaker',
  'Mandali',
  'Judson',
  'Kameron',
  'Markazi Text',
  'Bevan',
  'Anonymous Pro',
  'Forum',
  'Commissioner',
  'Hind Guntur',
  'PT Serif Caption',
  'Rozha One',
  'Podkova',
  'Mukta Vaani',
  'Miriam Libre',
  'Michroma',
  'Fredericka the Great',
  'Lateef',
  'Berkshire Swash',
  'Niramit',
  'Gilda Display',
  'Big Shoulders Display',
  'Racing Sans One',
  'Glegoo',
  'Norican',
  'Noto Sans Devanagari',
  'Black Han Sans',
  'Sansita',
  'Cabin Sketch',
  'Reem Kufi',
  'Ovo',
  'Lemonada',
  'Antic',
  'Londrina Solid',
  'Coming Soon',
  'Duru Sans',
  'Charm',
  'Arizonia',
  'Belgrano',
  'Niconne',
  'Allerta Stencil',
  'IBM Plex Sans Condensed',
  'Holtwood One SC',
  'Arbutus Slab',
  'Telex',
  'Cinzel Decorative',
  'Capriola',
  'Molengo',
  'Comic Neue',
  'Just Another Hand',
  'Leckerli One',
  'Rambla',
  'Nobile',
  'Biryani',
  'Caudex',
  'Darker Grotesque',
  'Overpass Mono',
  'Amita',
  'Trirong',
  'Allan',
  'Mrs Saint Delafield',
  'Herr Von Muellerhoff',
  'VT323',
  'Festive',
  'Graduate',
  'Saira Extra Condensed',
  'Jua',
  'Lexend',
  'Alegreya SC',
  'Scada',
  'Rye',
  'Blinker',
  'Share',
  'Be Vietnam',
  'Kristi',
  'Knewave',
  'Belleza',
  'Contrail One',
  'Libre Caslon Text',
  'Delius',
  'Castoro',
  'Nanum Brush Script',
  'Yesteryear',
  'Amiko',
  'Epilogue',
  'Do Hyeon',
  'Lustria',
  'Cambay',
  'Bowlby One',
  'Marcellus SC',
  'Fauna One',
  'Carrois Gothic',
  'Average Sans',
  'GFS Didot',
  'Skranji',
  'Athiti',
  'David Libre',
  'Aladin',
  'Krona One',
  'Oranienbaum',
  'Nixie One',
  'Carme',
  'Merienda One',
  'Alike',
  'Magra',
  'Trocchi',
  'Seaweed Script',
  'Cedarville Cursive',
  'K2D',
  'Baskervville',
  'Amethysta',
  'Rochester',
  'Suranna',
  'Alatsi',
  'Fira Code',
  'Pattaya',
  'Copse',
  'Averia Serif Libre',
  'Urbanist',
  'Kumbh Sans',
  'Calligraffitti',
  'Bungee Inline',
  'Mr De Haviland',
  'Rouge Script',
  'Marmelad',
  'Bodoni Moda',
  'JetBrains Mono',
  'La Belle Aurore',
  'Stardos Stencil',
  'NTR',
  'Rosario',
  'Slabo 13px',
  'Schoolbell',
  'Halant',
  'Jockey One',
  'Coustard',
  'Sunflower',
  'Sniglet',
  'Cutive Mono',
  'Cormorant Infant',
  'Wallpoet',
  'Quando',
  'Petit Formal Script',
  'Hanuman',
  'Kosugi',
  'Kelly Slab',
  'Rammetto One',
  'Manjari',
  'Esteban',
  'IM Fell DW Pica',
  'Mallanna',
  'Metrophobic',
  'Alike Angular',
  'Bellefair',
  'Oxygen Mono',
  'Grand Hotel',
  'Chonburi',
  'Encode Sans Semi Condensed',
  'Libre Barcode 39',
  'Qwigley',
  'Marvel',
  'Grandstander',
  'Georama',
  'Averia Libre',
  'Maitree',
  'Thasadith',
  'Convergence',
  'Baloo Tamma 2',
  'Tillana',
  'Harmattan',
  'Happy Monkey',
  'Gabriela',
  'Spectral SC',
  'Sofia',
  'Goudy Bookletter 1911',
  'Gugi',
  'Poller One',
  'Noto Sans Tamil',
  'Monsieur La Doulaise',
  'IM Fell English',
  'Proza Libre',
  'Lekton',
  'Poly',
  'Chelsea Market',
  'Love Ya Like A Sister',
  'Goldman',
  'Bentham',
  'Pompiere',
  'Mirza',
  'Brawler',
  'Hepta Slab',
  'Lemon',
  'Radley',
  'Montez',
  'Cutive',
  'Dawning of a New Day',
  'Raleway Dots',
  'Andika',
  'Limelight',
  'Buenard',
  'Rasa',
  'Oregano',
  'Coda Caption',
  'Balthazar',
  'Noto Sans Mandaic',
  'UnifrakturMaguntia',
  'Short Stack',
  'Shippori Mincho',
  'Cormorant SC',
  'BioRhyme',
  'Sue Ellen Francisco',
  'Oxanium',
  'Waiting for the Sunrise',
  'Gravitas One',
  'Emilys Candy',
  'Battambang',
  'Zeyada',
  'ZCOOL XiaoWei',
  'Calistoga',
  'Baloo Da 2',
  'Megrim',
  'Baloo Thambi 2',
  'Henny Penny',
  'McLaren',
  'Antonio',
  'Kadwa',
  'IM Fell Double Pica',
  'Livvic',
  'Kurale',
  'Grenze Gotisch',
  'Finger Paint',
  'Give You Glory',
  'Fanwood Text',
  'Noto Sans Thai',
  'Oleo Script Swash Caps',
  'Freckle Face',
  'Hi Melody',
  'Anaheim',
  'Meddon',
  'Frijole',
  'Ceviche One',
  'Turret Road',
  'Walter Turncoat',
  'Expletus Sans',
  'Patrick Hand SC',
  'Noto Sans Malayalam',
  'B612 Mono',
  'Original Surfer',
  'Atma',
  'Homenaje',
  'Nova Round',
  'Sarpanch',
  'Doppio One',
  'Nova Mono',
  'Antic Didone',
  'Mansalva',
  'Vast Shadow',
  'Share Tech',
  'Averia Sans Libre',
  'ZCOOL QingKe HuangYou',
  'Bungee Shade',
  'Unkempt',
  'Major Mono Display',
  'Big Shoulders Text',
  'Ranchers',
  'Inder',
  'Baumans',
  'Mouse Memoirs',
  'Galada',
  'Katibeh',
  'Clicker Script',
  'Dokdo',
  'Cambo',
  'Ma Shan Zheng',
  'Tienne',
  'Baloo Tammudu 2',
  'Bellota Text',
  'Over the Rainbow',
  'Geo',
  'Strait',
  'Caladea',
  'Noto Sans Hebrew',
  'Inknut Antiqua',
  'Akaya Telivigala',
  'Fondamento',
  'Sail',
  'Della Respira',
  'Timmana',
  'STIX Two Text',
  'Mountains of Christmas',
  'Wendy One',
  'Syne',
  'Vollkorn SC',
  'Chau Philomene One',
  'Euphoria Script',
  'Faster One',
  'Baloo Chettan 2',
  'Metamorphous',
  'Aguafina Script',
  'Gurajada',
  'Orienta',
  'Tenali Ramakrishna',
  'Cormorant Upright',
  'Federo',
  'Ledger',
  'Yatra One',
  'Vesper Libre',
  'Italiana',
  'Shojumaru',
  'Sedgwick Ave',
  'Numans',
  'Noto Sans Kannada',
  'Crafty Girls',
  'Just Me Again Down Here',
  'Aref Ruqaa',
  'Notable',
  'Farro',
  'Delius Swash Caps',
  'Mukta Mahee',
  'Codystar',
  'Carrois Gothic SC',
  'B612',
  'Rakkas',
  'Loved by the King',
  'Noto Sans Telugu',
  'Montaga',
  'The Girl Next Door',
  'Goblin One',
  'Lexend Zetta',
  'Arya',
  'Bubbler One',
  'Fraunces',
  'Meera Inimai',
  'Cherry Cream Soda',
  'Salsa',
  'Amarante',
  'Noto Sans Oriya',
  'Prosto One',
  'Flamenco',
  'Odibee Sans',
  'Ranga',
  'Atkinson Hyperlegible',
  'Montserrat Subrayada',
  'Coiny',
  'Almendra',
  'Encode Sans Expanded',
  'Eater',
  'Ibarra Real Nova',
  'Port Lligat Sans',
  'Asul',
  'Mako',
  'Mina',
  'Noto Sans Gujarati',
  'Noto Sans Gurmukhi',
  'Cormorant Unicase',
  'Fresca',
  'Dynalight',
  'DM Mono',
  'Englebert',
  'Headland One',
  'Imprima',
  'Artifika',
  'Peralta',
  'Gafata',
  'Dela Gothic One',
  'Ruslan Display',
  'Kiwi Maru',
  'Trade Winds',
  'Bilbo Swash Caps',
  'Fontdiner Swanky',
  'RocknRoll One',
  'Pavanam',
  'Nova Square',
  'Noto Sans Bengali',
  'Trykker',
  'Life Savers',
  'Saira Stencil One',
  'Wire One',
  'Scope One',
  'Voces',
  'Noto Sans Sinhala',
  'Libre Barcode 39 Text',
  'Khmer',
  'Cantora One',
  'Andada Pro',
  'Autour One',
  'Delius Unicase',
  'Scheherazade New',
  'Slackey',
  'Stalemate',
  'Milonga',
  'Medula One',
  'Fjord One',
  'Elsie',
  'Baloo Paaji 2',
  'Fahkwang',
  'Puritan',
  'Gaegu',
  'Denk One',
  'Qahiri',
  'Encode Sans Semi Expanded',
  'Port Lligat Slab',
  'Cherry Swash',
  'Noto Sans Display',
  'League Script',
  'UnifrakturCook',
  'Kite One',
  'Nokora',
  'Spicy Rice',
  'Kodchasan',
  'Lakki Reddy',
  'Iceland',
  'KoHo',
  'Lily Script One',
  'Bilbo',
  'Reggae One',
  'Vibur',
  'Tauri',
  'Germania One',
  'Jomhuria',
  'Noto Sans Mono',
  'Paprika',
  'Yusei Magic',
  'Engagement',
  'MedievalSharp',
  'Sarina',
  'Kranky',
  'Habibi',
  'Ribeye',
  'Stoke',
  'Shanti',
  'Song Myung',
  'Chicle',
  'Charmonman',
  'New Rocker',
  'MuseoModerno',
  'Julee',
  'Gamja Flower',
  'Modak',
  'Lovers Quarrel',
  'Libre Barcode 39 Extended Text',
  'Style Script',
  'Mogra',
  'Mystery Quest',
  'ZCOOL KuaiLe',
  'Baloo Bhaina 2',
  'Prociono',
  'Angkor',
  'Shippori Mincho B1',
  'Chango',
  'Text Me One',
  'Manuale',
  'Uncial Antiqua',
  'Newsreader',
  'Rosarivo',
  'Cagliostro',
  'Metal Mania',
  'Simonetta',
  'Lexend Exa',
  'Sumana',
  'Dekko',
  'Varta',
  'Macondo Swash Caps',
  'Baloo Bhai 2',
  'Zilla Slab Highlight',
  'Nosifer',
  'Besley',
  'Pirata One',
  'Solway',
  'Ramaraja',
  'Farsan',
  'Moul',
  'IM Fell DW Pica SC',
  'Tomorrow',
  'Barrio',
  'Kotta One',
  'Akronim',
  'Overlock SC',
  'IM Fell French Canon',
  'Piazzolla',
  'Ruluko',
  'Libre Caslon Display',
  'Sancreek',
  'IM Fell French Canon SC',
  'Poor Story',
  'Sulphur Point',
  'Crushed',
  'Stint Ultra Expanded',
  'Libre Barcode 128',
  'Chilanka',
  'Griffy',
  'Averia Gruesa Libre',
  'Bigelow Rules',
  'Bellota',
  'Stylish',
  'Ephesis',
  'Rationale',
  'Asar',
  'Londrina Outline',
  'Sura',
  'Ravi Prakash',
  'Kulim Park',
  'Fenix',
  'Condiment',
  'Kumar One',
  'Yeon Sung',
  'Koulen',
  'Margarine',
  'Buda',
  'Eagle Lake',
  'Donegal One',
  'Kavivanar',
  'Vampiro One',
  'Cute Font',
  'Offside',
  'Piedra',
  'Quintessential',
  'Swanky and Moo Moo',
  'Kavoon',
  'Allison',
  'Noto Nastaliq Urdu',
  'Rowdies',
  'Jolly Lodger',
  'IM Fell Great Primer',
  'Rum Raisin',
  'Croissant One',
  'East Sea Dokdo',
  'Inika',
  'Kantumruy',
  'Sonsie One',
  'Big Shoulders Stencil Display',
  'Srisakdi',
  'DotGothic16',
  'Chathura',
  'Iceberg',
  'Petrona',
  'Galdeano',
  'Zen Dots',
  'Content',
  'Redressed',
  'Noto Serif Yezidi',
  'Nova Flat',
  'Ribeye Marrow',
  'Sree Krushnadevaraya',
  'Hachi Maru Pop',
  'Rampart One',
  'Marko One',
  'Felipa',
  'Dorsa',
  'Modern Antiqua',
  'Gotu',
  'Miniver',
  'Akaya Kanadaka',
  'Inria Serif',
  'Meie Script',
  'Sansita Swashed',
  'Noto Sans Georgian',
  'Bayon',
  'Maiden Orange',
  'Tulpen One',
  'Jomolhari',
  'Spirax',
  'Ruthie',
  'Wellfleet',
  'Elsie Swash Caps',
  'Linden Hill',
  'Sahitya',
  'Potta One',
  'Girassol',
  'Barriecito',
  'Junge',
  'Gorditas',
  'Bigshot One',
  'Monofett',
  'Sirin Stencil',
  'Kufam',
  'Joti One',
  'Tourney',
  'Molle',
  'Londrina Shadow',
  'Almendra SC',
  'Gupter',
  'Caesar Dressing',
  'Gayathri',
  'Mrs Sheppards',
  'Single Day',
  'Beth Ellen',
  'Andika New Basic',
  'Kdam Thmor',
  'Arbutus',
  'Lancelot',
  'Libre Barcode 128 Text',
  'Revalia',
  'Devonshire',
  'Glass Antiqua',
  'Odor Mean Chey',
  'Romanesco',
  'Smythe',
  'Rhodium Libre',
  'Flavors',
  'Noto Serif Display',
  'Macondo',
  'Risque',
  'Diplomata',
  'Diplomata SC',
  'Stick',
  'Glory',
  'Kaisei Tokumin',
  'Jim Nightshade',
  'Lexend Mega',
  'Fascinate Inline',
  'Irish Grover',
  'Hina Mincho',
  'Atomic Age',
  'Asset',
  'Bahiana',
  'Ewert',
  'Chenla',
  'Princess Sofia',
  'Fascinate',
  'Chela One',
  'Grenze',
  'Inria Sans',
  'Siemreap',
  'IM Fell Great Primer SC',
  'Galindo',
  'Peddana',
  'Jacques Francois Shadow',
  'Almendra Display',
  'Londrina Sketch',
  'Lexend Giga',
  'Snippet',
  'Azeret Mono',
  'Dangrek',
  'Kirang Haerang',
  'Kumar One Outline',
  // "GFS Neohellenic",
  // "Miss Fajardose",
  // "Oldenburg",
  // "Train One",
  // "Plaster",
  // "Libre Barcode 39 Extended",
  // "Dr Sugiyama",
  // "Underdog",
  // "Keania One",
  // "Emblema One",
  // "Red Rose",
  // "Jacques Francois",
  // "Noto Sans Myanmar",
  // "Bona Nova",
  // "Yaldevi",
  // "Unlock",
  // "Zhi Mang Xing",
  // "Nova Slim",
  // "Sedgwick Ave Display",
  // "Brygada 1918",
  // "Bokor",
  // "Butterfly Kids",
  // "Noto Sans Gothic",
  // "IBM Plex Sans Arabic",
  // "Smokum",
  // "Viaoda Libre",
  // "Freehand",
  // "Metal",
  // "Butcherman",
  // "Combo",
  // "Stalinist One",
  // "Hanalei Fill",
  // "IM Fell Double Pica SC",
  // "Mr Bedfort",
  // "Passero One",
  // "Gemunu Libre",
  // "Bungee Outline",
  // "Ballet",
  // "Gluten",
  // "Black And White Picture",
  // "Otomanopee One",
  // "Erica One",
  // "Texturina",
  // "Snowburst One",
  // "Suwannaphum",
  // "Miltonian Tattoo",
  // "Vibes",
  // "Purple Purse",
  // "WindSong",
  // "Astloch",
  // "Aubrey",
  // "Be Vietnam Pro",
  // "Liu Jian Mao Cao",
  // "Sunshiney",
  // "Bungee Hairline",
  // "Bonbon",
  // "New Tegomin",
  // "Preahvihear",
  // "Bahianita",
  // "Ruge Boogie",
  // "Benne",
  // "Alumni Sans",
  // "Trochut",
  // "Noto Sans Armenian",
  // "Hanalei",
  // "Syne Mono",
  // "Gidugu",
  // "Seymour One",
  // "Geostar Fill",
  // "IBM Plex Sans Thai",
  // "Federant",
  // "Klee One",
  // "Nerko One",
  // "Lexend Tera",
  // "Miltonian",
  // "Big Shoulders Stencil Text",
  // "Gowun Batang",
  // "Kaisei HarunoUmi",
  // "Supermercado One",
  // "Lacquer",
  // "Kaisei Decol",
  // "Orelega One",
  // "Kaisei Opti",
  // "Moulpali",
  // "Yomogi",
  // "Fruktur",
  // "Grechen Fuemen",
  // "Bonheur Royale",
  // "Nova Oval",
  // "Sofadi One",
  // "Nova Cut",
  // "Noto Sans Arabic",
  // "Taprom",
  // "Hahmlet",
  // "Truculenta",
  // "Noto Sans Symbols",
  // "Birthstone Bounce",
  // "Nova Script",
  // "Long Cang",
  // "Sevillana",
  // "Stick No Bills",
  // "Birthstone",
  // "Fleur De Leah",
  // "Carattere",
  // "MonteCarlo",
  // "Dhurjati",
  // "Suravaram",
  // "Rubik Beastly",
  // "Zen Loop",
  // "Grey Qo",
  // "Karantina",
  // "IBM Plex Sans KR",
  // "Encode Sans SC",
  // "Are You Serious",
  // "Kenia",
  // "BioRhyme Expanded",
  // "Fasthand",
  // "Imbue",
  // "Xanh Mono",
  // "Lexend Peta",
  // "Langar",
  // "Big Shoulders Inline Text",
  // "Geostar",
  // "Gowun Dodum",
  // "Cherish",
  // "Gideon Roman",
  // "Explora",
  // "Fuggles",
  // "Warnes",
  // "Trispace",
  // "Noto Serif Thai",
  // "Caramel",
  // "Big Shoulders Inline Display",
  // "Zen Tokyo Zoo",
  // "Noto Serif Malayalam",
  // "Noto Sans Coptic",
  // "IBM Plex Sans Hebrew",
  // "Koh Santepheap",
  // "IBM Plex Sans Thai Looped",
  // "Palette Mosaic",
  // "Oi",
  // "Syne Tactile",
  // "Noto Sans Javanese",
  // "Noto Sans Lao",
  // "Libre Barcode EAN13 Text",
  // "Noto Sans Tai Viet",
  // "Noto Serif Georgian",
  // "IBM Plex Sans Devanagari",
  // "Noto Sans Deseret",
  // "Noto Serif Armenian",
  // "Noto Serif Bengali",
  // "Noto Sans Khmer",
  // "Uchen",
  // "Noto Sans Anatolian Hieroglyphs",
  // "Noto Serif Khmer",
  // "Noto Sans Brahmi",
  // "Noto Serif Ethiopic",
  // "Noto Serif Tamil",
  // "Noto Sans Thai Looped",
  // "Noto Sans Ol Chiki",
  // "Noto Serif Devanagari",
  // "Noto Sans Thaana",
  // "Noto Sans Imperial Aramaic",
  // "Noto Serif Lao",
  // "Noto Sans Tagalog",
  // "Noto Sans Cham",
  // "Noto Sans Cherokee",
  // "Noto Serif Hebrew",
  // "Noto Serif Gujarati",
  // "Noto Rashi Hebrew",
  // "Noto Sans Old Turkic",
  // "Noto Sans Avestan",
  // "Noto Sans Canadian Aboriginal",
  // "Noto Sans Tifinagh",
  // "Noto Serif Sinhala",
  // "Noto Sans Balinese",
  // "Noto Serif Kannada",
  // "Noto Serif Myanmar",
  // "Noto Serif Telugu",
  // "Noto Sans Sundanese",
  // "Noto Serif Tibetan",
  // "Noto Sans Old Italic",
  // "Noto Serif Gurmukhi",
  // "Noto Sans Sora Sompeng",
  // "Noto Sans Mongolian",
  // "Noto Serif Nyiakeng Puachue Hmong",
  // "Noto Sans Symbols 2",
  // "Noto Sans Adlam",
  // "Noto Sans Adlam Unjoined",
  // "Noto Sans Carian",
  // "Noto Sans Bamum",
  // "Noto Sans Cypriot",
  // "Noto Sans Hanifi Rohingya",
  // "Noto Sans Tai Tham",
  // "Noto Sans Medefaidrin",
  // "Noto Sans Kayah Li",
  // "Noto Sans Limbu",
  // "Noto Sans Lisu",
  // "Noto Sans Math",
  // "Noto Sans Linear B",
  // "Noto Sans Egyptian Hieroglyphs",
  // "Noto Sans Syriac",
  // "Noto Sans Kaithi",
  // "Noto Sans Samaritan",
  // "Noto Sans Hanunoo",
  // "Noto Serif Balinese",
  // "Noto Sans Inscriptional Parthian",
  // "Noto Sans Inscriptional Pahlavi",
  // "Noto Sans Cuneiform",
  // "Noto Sans Old Hungarian",
  // "Noto Sans Kharoshthi",
  // "Noto Sans Yi",
  // "Noto Serif Grantha",
  // "Noto Sans Runic",
  // "Noto Serif Dogra",
  // "Noto Sans Saurashtra",
  // "Noto Sans Old South Arabian",
  // "Noto Sans Rejang",
  // "Noto Serif Ahom",
  // "Noto Sans Batak",
  // "Noto Serif Tangut",
  // "Noto Sans Tirhuta",
  // "Noto Sans Caucasian Albanian",
  // "Noto Sans Elbasan",
  // "Noto Sans Mahajani",
  // "Noto Sans Old North Arabian",
  // "Noto Sans Khudawadi",
  // "Noto Sans Tamil Supplement",
  // "Noto Sans Old Persian",
  // "Noto Sans Glagolitic",
  // "Noto Sans New Tai Lue",
  // "Noto Sans Khojki",
  // "Noto Sans Syloti Nagri",
  // "Noto Sans Meroitic",
  // "Noto Sans Vai",
  // "Noto Sans Nabataean",
  // "Noto Sans Linear A",
  // "Noto Music",
  // "Noto Sans Tagbanwa",
  // "Noto Sans Old Permic",
  // "Noto Sans Lepcha",
  // "Noto Sans Tai Le",
  // "Noto Sans Soyombo",
  // "Noto Sans Modi",
  // "Noto Sans Pahawh Hmong",
  // "Noto Sans Takri",
  // "Noto Sans Palmyrene",
  // "Noto Sans Duployan",
  // "Noto Sans Phags Pa",
  // "Noto Sans Indic Siyaq Numbers",
  // "Noto Sans Zanabazar Square",
  // "Noto Sans Hatran",
  // "Noto Sans Lycian",
  // "Noto Sans Marchen",
  // "Noto Sans Elymaic",
  // "Noto Sans Wancho",
  // "Noto Sans Gunjala Gondi",
  // "Noto Sans Nushu",
  // "Noto Sans Masaram Gondi",
  // "Noto Traditional Nushu",
  // "Noto Sans Sogdian",
  // "Noto Sans Pau Cin Hau",
  // "Noto Sans Buginese",
  // "Noto Sans Miao",
  // "Noto Sans Bassa Vah",
  // "Noto Sans Buhid",
  // "Noto Sans Sharada",
  // "Noto Sans Osage",
  // "Noto Sans Phoenician",
  // "Noto Sans Newa",
  // "Noto Sans Grantha",
  // "Noto Sans Psalter Pahlavi",
  // "Noto Sans Chakma",
  // "Noto Sans Shavian",
  // "Noto Sans Osmanya",
  // "Noto Sans Warang Citi",
  // "Noto Sans Old Sogdian",
  // "Noto Sans Multani",
  // "Noto Sans Bhaiksuki",
  // "Noto Sans Manichaean",
  // "Noto Sans Ogham",
  // "Noto Sans Mro",
  // "Noto Sans Siddham",
  // "Noto Sans Lydian",
  // "Noto Sans Ugaritic",
  // "Noto Sans Mayan Numerals",
  // "Noto Sans N Ko",
  // "Zen Maru Gothic",
  // "Zen Kurenaido",
  // "Zen Old Mincho",
  // "Zen Kaku Gothic New",
  // "Zen Antique Soft",
  // "Zen Kaku Gothic Antique",
  // "Zen Antique"

  // requested fonts
  'Silkscreen',
  'Rubik Moonrocks',
  'Shantell Sans',
  'Dongle',
  'Tilt Prism',
  'Bungee Outline',
  'Moo Lah Lah',
  'Kalnia',
];

// to get a full text to include in '_fonts.scss'
// let text = '';

// fontFamilies.forEach(f => {
//   text += `@import url("https://fonts.googleapis.com/css2?family=${f.replaceAll(
//     ' ',
//     '+'
//   )}&display=swap");\n`;
// });

// console.log(text);

export default fontFamilies;
