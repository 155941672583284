import React from 'react';
import { Text } from 'react-konva';
import LinkIndicator from './LinkIndicator';
import moment from 'moment';

const TText = ({
  shapeProps,
  setTextareaProps,
  isEditMode,
  setIsEditMode,
  setTextareaValue,
  isSelected,
  scale,
  setSelectedShapeId,
  stageWidth,
  stageHeight,
  bleedingTop,
  bleedingLeft,
  marginLeft,
  marginRight,
  onSelect,
  onDragStart,
  onDragMove,
  onDragEnd,
  onChange,
}) => {
  const shapeRef = React.useRef();
  // const trRef = React.useRef();

  const [offsetX, setOffsetX] = React.useState();
  const [offsetY, setOffsetY] = React.useState();

  const getAngleInRadians = degrees => {
    const pi = Math.PI;
    return degrees * (pi / 180);
  };

  const topLeftPoint = (cx, cy, w, h, rotation, scale) => {
    const x =
      ((cx + bleedingLeft - (w / 2) * Math.cos(rotation) + (h / 2) * Math.sin(rotation)) * scale) /
      100;
    const y =
      ((cy + bleedingTop - (w / 2) * Math.sin(rotation) - (h / 2) * Math.cos(rotation)) * scale) /
      100;
    return { x, y };
  };

  // React.useEffect(() => {
  //   if (isSelected && trRef.current) {
  //     // we need to attach transformer manually
  //     trRef.current.nodes([shapeRef.current]);
  //     trRef.current.getLayer().batchDraw();
  //   }
  // }, [isSelected, trRef.current]);

  React.useEffect(() => {
    if (isSelected && isEditMode) {
      shapeRef.current.hide();
    } else if (!isSelected || !isEditMode) {
      shapeRef.current.show();
    }
  }, [isSelected, isEditMode]);

  // Next 3 useEffects is All about syncing text area with text - be careful!
  React.useEffect(() => {
    if (shapeRef.current) {
      if (isSelected) {
        const node = shapeRef.current;
        const { x, y } = topLeftPoint(
          node.x(),
          node.y(),
          node.width(),
          node.height(),
          getAngleInRadians(node.rotation()),
          scale
        );
        setTextareaProps({
          styles: {
            position: 'absolute',
            left: stageWidth / 2 + x,
            top: stageHeight / 2 + y,
            width: node.width(),
            height: node.height(),
            fontSize: node.fontSize(),
            textAlign: node.align(),
            border: 'none',
            padding: node.padding(),
            overflow: 'hidden',
            background: 'none',
            outline: 'none',
            resize: 'none',
            lineHeight: node.lineHeight(),
            letterSpacing: node.letterSpacing(),
            fontFamily: node.fontFamily(),
            fontWeight: node.fontStyle().includes('bold') ? 'bold' : false,
            fontStyle: node.fontStyle().includes('italic') ? 'italic' : 'normal',
            transformOrigin: 'top left',
            textAlign: node.align(),
            color: node.fill(),
            transform: `translateY(-2px) scale(${scale / 100}) rotateZ(${node.rotation()}deg)`,
          },
          id: shapeProps.id,
        });

        setTextareaValue(node.text());
      }
    }
  }, [
    isSelected,
    isEditMode,
    shapeRef.current,
    scale,
    shapeProps.id,
    shapeProps.fontSize,
    shapeProps.fontStyle,
    shapeProps.fontFamily,
    shapeProps.letterSpacing,
    shapeProps.lineHeight,
    shapeProps.align,
    shapeProps.width,
    shapeProps.height,
    shapeProps.text,
    shapeProps.fill,
    shapeProps.x,
    shapeProps.y,
    shapeProps.rotation,
    stageHeight,
    stageWidth,
  ]);

  React.useEffect(() => {
    // UI fix - Don't remove it
    delete shapeRef.current.height;
  }, [shapeProps]);

  // Track the changes in height and width because of text props
  React.useEffect(() => {
    if (shapeRef.current) {
      setOffsetX(shapeProps.width / 2);
      setOffsetY(shapeRef.current.height() / 2);
      onChange({ ...shapeProps, offsetX, offsetY });
    }
  }, [
    shapeProps.width,
    shapeProps.text,
    shapeProps.fontSize,
    shapeProps.lineHeight,
    shapeProps.letterSpacing,
    shapeProps.fontStyle,
    offsetX,
    offsetY,
  ]);

  const [date, setDate] = React.useState(null);

  React.useEffect(() => {
    // If date is provided
    if (
      shapeProps.link &&
      shapeProps.link.type.includes('Dynamic Date') &&
      moment(shapeProps.text).isValid()
    ) {
      setDate(moment(shapeProps.text).format(shapeProps.link.format));
    } else if (shapeProps.link && shapeProps.link.type.includes('Date')) {
      setDate(moment().format(shapeProps.link.format));
    }
  }, [shapeProps.link]);

  return (
    <>
      {shapeProps.link && isSelected && !isEditMode && (
        <LinkIndicator shapeProps={shapeProps} offset={true} isText={true} />
      )}
      <Text
        wrap={'word'}
        onMouseDown={onSelect}
        onDblClick={() => {
          setSelectedShapeId(null); // UI fix - Don't remove it
          setTimeout(() => {
            setIsEditMode(true);
            setSelectedShapeId(shapeProps.id);
          }, 100);
        }}
        onTap={onSelect}
        onDblTap={() => {
          setSelectedShapeId(null); // UI fix - Don't remove it
          setIsEditMode(true);
          setSelectedShapeId(shapeProps.id);
        }}
        ref={shapeRef}
        {...shapeProps}
        text={
          shapeProps.link &&
          shapeProps.link.role &&
          shapeProps.link.role.includes('Date') &&
          shapeProps.link.type === 'Date'
            ? date
            : shapeProps.text
        }
        offsetX={offsetX}
        offsetY={offsetY}
        draggable
        onDragStart={onDragStart}
        onDragMove={onDragMove}
        onDragEnd={e => onDragEnd(shapeProps, e)}
        onTransformEnd={e => {
          // transformer is changing scale of the node
          // and NOT its width or height- shapeRef.current.fontSize()/scale/100/2 - shapeRef.current.padding()/scale/100
          // but in the store we have only width and height
          // to match the data better we will re- shapeRef.current.fontSize()/scale/100/2 - shapeRef.current.padding()/scale/100set scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();

          // we will reset it back
          node.scaleX(1);

          onChange(
            {
              ...shapeProps,
              x: node.x(),
              y: node.y(), // set minimal value
              width: node.width() * scaleX,
              scaleX: 1,
              scaleY: 1,
              rotation: node.rotation(),
            },
            true
          );
        }}
      />
    </>
  );
};

export default TText;
