import axios from 'axios';
import urls from 'settings/urls.js';

export async function getAccounts(params) {
  const requestOptions = {
    method: 'GET',
    url: urls.accounts,
    params,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function getOneAccount(id) {
  const requestOptions = {
    method: 'GET',
    url: urls.oneAccount(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : null;
}

export async function addAccount(data, setStatus = () => {}, setUploadProgress = () => {}) {
  const requestOptions = {
    method: 'POST',
    url: urls.newAccount,
    data,
    onUploadProgress: progressEvent => {
      const { loaded, total } = progressEvent;
      const percent = Math.floor((loaded * 100) / total);
      setStatus(`uploading: ${percent}%`);
      setUploadProgress(percent);
    },
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function editAccount(data, id, setStatus = () => {}, setUploadProgress = () => {}) {
  const requestOptions = {
    method: 'PATCH',
    url: urls.editAccount(id),
    data,
    onUploadProgress: progressEvent => {
      const { loaded, total } = progressEvent;
      const percent = Math.floor((loaded * 100) / total);
      setStatus(`uploading: ${percent}%`);
      setUploadProgress(percent);
    },
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function deleteAccount(id) {
  const requestOptions = {
    method: 'DELETE',
    url: urls.deleteAccount(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}
