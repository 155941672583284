import axios from 'axios';
import urls from 'settings/urls.js';

// WordLists
export async function getWordLists(category_id, sort, tags, offset, limit) {
  const requestOptions = {
    method: 'GET',
    url: urls.WordLists(category_id, sort, tags, offset, limit),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function getOneWordList(id, params) {
  const { listOffset: offset, listLimit: limit } = params;
  const requestOptions = {
    method: 'GET',
    url: urls.oneWordList(id),
    params: { offset, limit },
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function addWordList(data) {
  const requestOptions = {
    method: 'POST',
    url: urls.newWordList,
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function editWordList(data, id) {
  const requestOptions = {
    method: 'PATCH',
    url: urls.editWordList(id),
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function deleteWordList(id) {
  const requestOptions = {
    method: 'DELETE',
    url: urls.deleteWordList(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

// Trivia Lists
export async function getTriviaLists(category_id, sort, tags, offset, limit) {
  const requestOptions = {
    method: 'GET',
    url: urls.TriviaLists(category_id, sort, tags, offset, limit),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function getOneTriviaList(id) {
  const requestOptions = {
    method: 'GET',
    url: urls.oneTriviaList(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function addTriviaList(data) {
  const requestOptions = {
    method: 'POST',
    url: urls.newTriviaList,
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function editTriviaList(data, id) {
  const requestOptions = {
    method: 'PATCH',
    url: urls.editTriviaList(id),
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function deleteTriviaList(id) {
  const requestOptions = {
    method: 'DELETE',
    url: urls.deleteTriviaList(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

// Article Lists
export async function getArticleLists({ category_id, sort, tags, offset, limit }) {
  const requestOptions = {
    method: 'GET',
    url: urls.ArticleLists(category_id, sort, tags, offset, limit),
  };
  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function getOneArticleList(id) {
  const requestOptions = {
    method: 'GET',
    url: urls.oneArticleList(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function addArticleList(data) {
  const requestOptions = {
    method: 'POST',
    url: urls.newArticleList,
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function editArticleList(data, id) {
  const requestOptions = {
    method: 'PATCH',
    url: urls.editArticleList(id),
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function deleteArticleList(id) {
  const requestOptions = {
    method: 'DELETE',
    url: urls.deleteArticleList(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

// Story Lists
export async function getStories({ category_id, sort, tags, offset, limit }) {
  const requestOptions = {
    method: 'GET',
    url: urls.stories(category_id, sort, tags, offset, limit),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function getOneStory(id) {
  const requestOptions = {
    method: 'GET',
    url: urls.oneStory(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function addStory(data) {
  const requestOptions = {
    method: 'POST',
    url: urls.newStory,
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function editStory(data, id) {
  const requestOptions = {
    method: 'PATCH',
    url: urls.editStory(id),
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function deleteStory(id) {
  const requestOptions = {
    method: 'DELETE',
    url: urls.deleteStory(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}
