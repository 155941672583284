import SelectWrapper, { loadAsyncOptions } from 'components/forms/elements/SelectWrapper';
import Button from 'components/common/Button';
import CheckList from 'components/forms/elements/CheckList';
import InputNumber from 'components/forms/elements/InputNumber';
import { capitalizeFirst } from 'helpers/text';
import { getCategories } from 'api/common';
import _ from 'lodash';

function processStoryItems(str) {
  // Define the regex to match the individual objects
  const objectRegex = /{([^}]*)}/g;

  // Define the regex to match the title, content, and difficulty within each object
  const propertyRegex =
    /'title':\s*'([^']*)',\s*'content':\s*'([^']*)',\s*'difficulty':\s*'([^']*)'/;

  // Array to hold the parsed objects
  const resultArray = [];

  // Find each object in the string
  let match;
  while ((match = objectRegex.exec(str)) !== null) {
    const objectString = match[1];

    // Extract properties from the object string
    const propertyMatch = propertyRegex.exec(objectString);
    if (propertyMatch) {
      const title = propertyMatch[1];
      const content = propertyMatch[2];
      //.replace(/\\n/g, '\n'); // Convert escaped newlines to actual newlines
      const difficulty = propertyMatch[3];

      // Create an object and add it to the result array
      resultArray.push({ title, content, difficulty });
    }
  }

  return resultArray;
}

function StoriesForm({
  storiesFormInputs,
  storiesFormData,
  setStoriesFormData,
  categories,
  setCategories,
  selectedCategory,
  setSelectedCategory,
  stories,
  loadStories,
  selectedStories,
  setSelectedStories,
  isStoriesLoading,
  isLoading,
  updateManuscriptWithStories,
  requiredStoriesNumber,
}) {
  const selectedStoriesCount = _.sumBy(selectedStories, 'items.length') || 0;
  return (
    <div>
      {Object.keys(storiesFormInputs.groups).map((groupTitle, index) => (
        <>
          <div className={`form-group`}>
            <label>{capitalizeFirst(groupTitle.replace('_', ' '), true)}</label>
            <div className="row row-cols-2 ">
              {storiesFormInputs.groups[groupTitle].map(input =>
                input.type === 'number' ? (
                  <div className="col">
                    <label>{input.label}</label>
                    <InputNumber
                      value={storiesFormData[input.dataField]}
                      setValue={value =>
                        setStoriesFormData({
                          ...storiesFormData,
                          [input.dataField]: value,
                        })
                      }
                      className="form-control"
                      type="number"
                      step={1}
                      min={1}
                    />
                  </div>
                ) : (
                  <SelectWrapper
                    options={input.options}
                    value={storiesFormData[input.dataField]}
                    setValue={value =>
                      setStoriesFormData({
                        ...storiesFormData,
                        [input.dataField]: value,
                      })
                    }
                  />
                )
              )}
            </div>
          </div>
        </>
      ))}

      <div className=" d-flex flex-column  form-group">
        <label className="mb-2">Category</label>
        <SelectWrapper
          async
          key={storiesFormData.language_id}
          options={categories}
          value={selectedCategory}
          setValue={value => {
            setSelectedCategory(value);
            setStoriesFormData({
              ...storiesFormData,
              category: value ? value : null,
            });
          }}
          loadOptions={(search, loadedOptions = []) => {
            const offset = loadedOptions.length;
            return loadAsyncOptions({
              loadedOptions,
              getItems: getCategories,
              params: {
                search,
                offset,
                limit: 15,
                language: storiesFormData.language_id,
                kind: 'library_story',
                sort: 'alpha',
              },
              itemsKey: 'categories',
              setItemsExternally: setCategories,
              // setSelectedItem: setSelectedCategory,
            });
          }}
        />
      </div>

      <div>
        <CheckList
          title={'Stories Lists'}
          itemsType={'stories'}
          items={stories.map(list => {
            const processedStoryItems = processStoryItems(list.items);
            return {
              id: list.id,
              title: list.title,
              items: processedStoryItems,
              items_count: processedStoryItems.length,
            };
          })}
          loadItems={loadStories}
          checkedItems={selectedStories}
          setCheckedItems={arr => setSelectedStories(arr)}
          isLoading={isStoriesLoading}
        />
        <div className={`form-group d-flex justify-content-center mt-4 h1`}>
          <label
            style={{
              fontSize: '1.5rem',
              color: selectedStoriesCount >= requiredStoriesNumber ? '' : 'red',
            }}
          >
            Selected: {`${selectedStoriesCount}/${requiredStoriesNumber}`}
          </label>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <Button
            disabled={isLoading || selectedStoriesCount < requiredStoriesNumber}
            onClick={() => updateManuscriptWithStories(false)}
            label={'Refresh'}
            className="btn-blue-forms"
            style={{ width: 100, height: 40 }}
          />
        </div>

        <div className="d-flex justify-content-center mt-4">
          <Button
            disabled={isLoading || selectedStoriesCount < requiredStoriesNumber}
            onClick={() => updateManuscriptWithStories(true)}
            label={'Randomize & Refresh'}
            className="btn-blue-forms"
            style={{ width: 250, height: 40 }}
          />
        </div>
      </div>
    </div>
  );
}

export default StoriesForm;
