export default [
  // home
  { pathPattern: '/home/formats', scope: 'format', perm: 'view_format' },
  { pathPattern: '/home/formats/manage', scope: 'pagesize', perm: 'view_pagesize' },
  { pathPattern: '/home/templates', scope: 'template', perm: 'view_template' },
  { pathPattern: '/home/manuscripts', scope: 'manuscript', perm: 'view_manuscript' },
  { pathPattern: '/home/covers', scope: 'cover', perm: 'view_cover' },
  { pathPattern: '/home/covers/manage', scope: 'paperthickness', perm: 'view_paperthickness' },
  // library
  { pathPattern: '/library/:language/word-lists', scope: 'wordlist', perm: 'view_wordlist' },
  {
    pathPattern: '/library/:language/trivia-lists/:id',
    scope: 'trivia_list',
    perm: 'view_trivialist',
  },
  {
    pathPattern: '/library/:language/article-lists/:id',
    scope: 'article_list',
    perm: 'view_articlelist',
  },
  {
    pathPattern: '/library/:language/story-lists/:id',
    scope: 'story_list',
    perm: 'view_story',
  },
  // elements
  { pathPattern: '/elements/:elType', scope: 'element', perm: 'view_element' },
  { pathPattern: '/elements/:elType/manage', scope: 'element', perm: 'view_elementtype' },
  // variables
  { pathPattern: '/variables/:language', scope: 'variable', perm: 'view_variable' },
  { pathPattern: '/variables/:language/manage', scope: 'variable', perm: 'change_variable' },
  { pathPattern: '/variables/:language/swaps', scope: 'variable', perm: 'view_variable' },
  // settings
  { pathPattern: '/settings/tags/:kind', scope: 'tag', perm: 'view_tag' },
  { pathPattern: '/settings/languages', scope: 'language', perm: 'view_language' },
];
