// IMPORTANT
// Be carefule while changing this values
// Corresponding values are used in the ManuscirptStage
// Hope you know what you are doing!

export default {
  Count: {
    roles: ['Page Number', 'Problem Number', 'Solution Number', 'Chapter Number', 'Custom Number'],
    formats: ['1, 2, 3, ...', 'a, b, c, ...', 'i, ii, iii, ...'],
  },
  Date: {
    roles: ['Dynamic Date', 'Date 1', 'Date 2', 'Date 3'],
    formats: [
      'MMM DD, YYYY',
      'MM-DD-YYYY',
      'MM/DD/YYYY',
      'DD MMM YYYY',
      'DD-MM-YYYY',
      'DD/MM/YYYY',
      'YYYY',
    ],
  },
  General: {
    roles: ['Heading', 'Subheading', 'Author(s)', 'ISBN'],
    formats: ['Rich text', 'Numbers', 'Alphabets'],
  },
  Text: {
    roles: ['Text 1', 'Text 2', 'Text 3', 'Text 4', 'Text 5', 'Text 6'],
    formats: ['Rich text', 'Numbers', 'Alphabets'],
  },
  Theme: {
    roles: ['Word List Theme'],
    formats: [],
  },
  Image: {
    roles: ['Coloring Book Image'],
    formats: [],
  },
};
