import React from 'react';

function InputDate({ className, style, disabled, value, setValue }) {
  return (
    <input
      className={className}
      style={style}
      type="date"
      value={value}
      onChange={e => {
        setValue(e.target.value);
      }}
      onClick={e => {
        e.target.select();
      }}
      disabled={disabled ? true : false}
    />
  );
}

export default InputDate;
