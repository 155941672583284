import SelectWrapper, { loadAsyncOptions } from 'components/forms/elements/SelectWrapper';
import Button from 'components/common/Button';
import CheckList from 'components/forms/elements/CheckList';
import InputNumber from 'components/forms/elements/InputNumber';
import { capitalizeFirst } from 'helpers/text';
import { getCategories } from 'api/common';
import _ from 'lodash';

function TriviaForm({
  difficulty,
  setDifficulty,
  triviaFormInputs,
  triviaFormData,
  categories,
  selectedCategory,
  setSelectedCategory,
  setCategories,
  showAdvancedFilters,
  setTriviaFormData,
  setShowAdvancedFilters,
  trivias,
  loadTriviaLists,
  selectedTrivias,
  setSelectedTrivias,
  isTriviaListLoading,
  isLoading,
  updateManuscriptWithTrivias,
  requiredTriviasNumber,
}) {
  const selectedTiviasCount = _.sumBy(selectedTrivias, 'items.length');
  return (
    <div>
      <div className="form-group">
        <div className="list-filters-section d-flex align-items-center">
          <span>Difficulty: </span>
          {['easy', 'medium', 'hard'].map((opt, index) => (
            <div
              key={opt}
              className={`list-filters difficulty ${difficulty.includes(opt) ? 'selected' : ''}`}
              onClick={() => {
                const list = [...difficulty];
                if (list[index] === null) list[index] = opt;
                else list[index] = null;
                setDifficulty(list);
              }}
            >
              {capitalizeFirst(opt)}&nbsp;
            </div>
          ))}
        </div>
      </div>

      {Object.keys(triviaFormInputs.groups).map((groupTitle, index) => (
        <>
          {index === 1 && (
            <div className=" d-flex flex-column  form-group">
              <label className="mb-2">Category</label>
              <SelectWrapper
                async
                key={triviaFormData.language_id}
                options={categories}
                value={selectedCategory}
                setValue={setSelectedCategory}
                loadOptions={(search, loadedOptions = []) => {
                  const offset = loadedOptions.length;
                  return loadAsyncOptions({
                    loadedOptions,
                    getItems: getCategories,
                    params: {
                      search,
                      offset,
                      limit: 15,
                      language: triviaFormData.language_id,
                      kind: 'library_trivia',
                      sort: 'alpha',
                    },
                    itemsKey: 'categories',
                    setItemsExternally: setCategories,
                    // setSelectedItem: setSelectedCategory,
                  });
                }}
              />
            </div>
          )}
          <div className={`form-group ${index > 0 && !showAdvancedFilters ? 'd-none' : ''}`}>
            <label>{capitalizeFirst(groupTitle.replace('_', ' '), true)}</label>
            <div className="row row-cols-2 ">
              {triviaFormInputs.groups[groupTitle].map(input =>
                input.type === 'number' ? (
                  <div className="col">
                    <label>{input.label}</label>
                    <InputNumber
                      value={triviaFormData[input.dataField]}
                      setValue={value =>
                        setTriviaFormData({
                          ...triviaFormData,
                          [input.dataField]: value,
                        })
                      }
                      className="form-control"
                      type="number"
                      step={1}
                      min={1}
                    />
                  </div>
                ) : (
                  <SelectWrapper
                    options={input.options}
                    value={triviaFormData[input.dataField]}
                    setValue={value =>
                      setTriviaFormData({
                        ...triviaFormData,
                        [input.dataField]: value,
                      })
                    }
                  />
                )
              )}
            </div>
          </div>
          {index === 2 && (
            <div
              className="color-sys mb-3"
              onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
            >
              {!showAdvancedFilters ? 'Show' : 'Hide'} Advanced Filters
            </div>
          )}
        </>
      ))}

      <div>
        <CheckList
          title={'Trivia Lists'}
          itemsType={'trivias'}
          items={trivias.map(list => ({
            id: list.id,
            title: `${list.title} (${list.items.length})`,
            items: list.items,
          }))}
          loadItems={loadTriviaLists}
          checkedItems={selectedTrivias}
          setCheckedItems={arr => setSelectedTrivias(arr)}
          isLoading={isTriviaListLoading}
        />
        <div className={`form-group d-flex justify-content-center mt-4 h1`}>
          <label
            style={{
              fontSize: '1.5rem',
              color: selectedTiviasCount >= requiredTriviasNumber ? '' : 'red',
            }}
          >
            Selected: {`${selectedTiviasCount}/${requiredTriviasNumber}`}
          </label>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <Button
            disabled={isLoading || selectedTiviasCount < requiredTriviasNumber}
            onClick={() => updateManuscriptWithTrivias(false)}
            label={'Refresh'}
            className="btn-blue-forms"
            style={{ width: 100, height: 40 }}
          />
        </div>

        <div className="d-flex justify-content-center mt-4">
          <Button
            disabled={isLoading || selectedTiviasCount < requiredTriviasNumber}
            onClick={() => updateManuscriptWithTrivias(true)}
            label={'Randomize & Refresh'}
            className="btn-blue-forms"
            style={{ width: 250, height: 40 }}
          />
        </div>
      </div>
    </div>
  );
}

export default TriviaForm;
