import React, { useState, useEffect, useRef, useCallback } from 'react';
import Main from 'layouts/Main';
import './ElementEditor.scss';
import TopControls from 'components/common/TopControls';

import IncludeSvg from 'components/common/IncludeSvg';
import { useParams, useHistory } from 'react-router-dom';
import Tags from 'components/common/Tags';
import { getTags } from 'api/common';
import { addElement, getOneElement, editElement } from 'api/elements';
import { useGlobalContext } from 'state/hooks/global';
import PopupMenu from 'components/common/PopupMenu';
import { useOnClickOutside } from 'hooks/util';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDropzone } from 'react-dropzone';
import ProgressBar from 'components/common/ProgressBar';
import { useSelector } from 'react-redux';
import { max } from 'lodash';

function ElementEditor() {
  const { user } = useSelector(state => state.auth);
  const canChangeElements = user?.resources_permissions?.element?.change_element;
  const { elType } = useParams();
  const [name, setName] = useState('');
  const [nameErr, setNameErr] = useState(false);
  const [file, setFile] = useState(null);
  const [fileErr, setFileErr] = useState(false);
  const [image, setImage] = useState(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [allowedTags, setAllowedTags] = useState([]);
  const [tagsArr, setTagsArr] = useState([]);
  const [rotation, setRotation] = useState(0);
  const { id, catId } = useParams();
  const uploadRef = useRef();
  const { setMsgs } = useGlobalContext();
  const history = useHistory();
  const [sort, setSort] = useState('most_popular');
  const [sortTitle, setSortTitle] = useState('Alphabetically');
  const [isOpen, setIsOpen] = useState(false);
  const [prevElId, setPrevElId] = useState();
  const [nextElId, setNextElId] = useState();
  const [loading, setLoading] = useState(id ? true : false);
  const { getElementTypeID } = useGlobalContext();
  const popupRef = useRef();
  const [disabled, setDisabled] = useState(false);
  const [showImage, setShowImage] = useState(true);
  const [status, setStatus] = useState('preparing preview');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [categoryId, setCategoryId] = useState(null);

  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [loadingIndexes, setLoadingIndexes] = useState([]);
  const [uploadedIndexes, setUploadedIndexes] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length === 0) return;
    if (acceptedFiles.length === 1) {
      const file = acceptedFiles[0];
      setName(file.name.split('.')[0]);
      setFile(file);
      setRotation(0);
    } else {
      setFiles(acceptedFiles);
    }
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: true,
  });

  useOnClickOutside(popupRef, () => setIsOpen(false), true);

  useEffect(() => {
    getTags({
      kind: 'elements',
      element_type_id: getElementTypeID(elType),
      sort: 'alpha',
    }).then(res => {
      if (res.success) {
        setAllowedTags(res.tags);
      } else {
        console.log(res.errors);
      }
    });
  }, [getElementTypeID(elType)]);

  useEffect(() => {
    if (image) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [image]);

  useEffect(() => {
    setLoading(true);
    if (id) {
      setTagsArr([]);
      getOneElement(id, { sort })
        .then(res => {
          if (res.success) {
            const el = res.element;
            setName(el.name);
            setImage(el.image);
            setCategoryId(el.category);
            setRotation(el.rotation ? parseInt(el.rotation) : 0);
            if (allowedTags.length) {
              setTagsArr(
                el.tags
                  .map(id => allowedTags.find(tag => tag.id === id))
                  .filter(tag => tag !== undefined) // in case category deleted
              );
            }
            setNextElId(res.next_element_id);
            setPrevElId(res.previous_element_id);
          }
        })
        .catch(err => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [id, allowedTags, sort]);

  useEffect(() => {
    if (name.trim().length) setNameErr(false);
  }, [name]);

  useEffect(() => {
    if (file) {
      setFileErr(false);
      setImage(createPoster(file));
    }
  }, [file]);

  useEffect(() => {
    if (image)
      getHeightAndWidthFromDataUrl(image).then(res => {
        setWidth(res.width);
        setHeight(res.height);
      });
  }, [image]);

  // for multiple files
  useEffect(async () => {
    if (files.length) {
      setFileErr(false);
      setImages(
        files.map(file => {
          const poster = createPoster(file);
          const name = file.name.split('.')[0];
          return { poster, name };
        })
      );
    }
  }, [files]);

  const getHeightAndWidthFromDataUrl = dataURL =>
    new Promise(resolve => {
      const img = new Image();
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.src = dataURL;
    });

  const createPoster = file => {
    if (!file) return false;

    const maxWeight = 25165824;

    let size = file.size;

    if (size > maxWeight) return false;
    return URL.createObjectURL(file);
  };

  const handleUpload = () => {
    uploadRef.current.click();
  };

  const handleRotate = dir => {
    switch (dir) {
      case 'clockwise':
        setRotation((rotation + 90) % 360);
        break;
      case 'anticlockwise':
        setRotation((rotation - 90) % 360);
        break;
    }
  };

  const handleDelete = () => {
    setFile(null);
    setName('');
    setImage(null);
  };

  const handleSave = () => {
    setLoading(true);
    if (!name.trim().length) {
      setNameErr(true);
    }
    if (!image) {
      setFileErr(true);
    }
    if (!name.trim().length || !image) {
      setLoading(false);
      return;
    }
    let formData = new FormData();
    formData.append('name', name.trim());
    formData.append('height', height);
    formData.append('width', width);
    formData.append('rotation', rotation);
    if (file) formData.append('file', file);

    tagsArr
      .map(tag => tag.id)
      .forEach(id => {
        formData.append('tags', id);
      });

    if (catId) {
      formData.append('is_published', true); // Always
      formData.append('category', catId);

      addElement(formData, setStatus, setUploadProgress).then(res => {
        history.push(`/elements/${elType}/${res.element.id}`);
        setMsgs([{ type: 'success', content: 'Element created successfully!' }]);
        setUploadProgress(0);
        setTimeout(() => {
          setLoading(false);
          setFile(null);
          setName('');
        }, 1000);
      });
    } else if (id) {
      editElement(formData, id, setStatus, setUploadProgress).then(res => {
        setMsgs([{ type: 'success', content: 'Element updated successfully!' }]);
        setUploadProgress(0);
        setTimeout(() => {
          setLoading(false);
          setFile(null);
          setName('');
        }, 1000);
      });
    } else {
      setLoading(false);
    }
  };

  const handleNav = where => {
    let id;
    switch (where) {
      case 'prev': {
        if (!prevElId) return;
        else {
          id = prevElId;
        }
        break;
      }
      case 'next': {
        if (!nextElId) return;
        else {
          id = nextElId;
        }
        break;
      }
      default: {
        alert('not supported navigation');
        return;
      }
    }

    history.push(`/elements/${elType}/${id}`);
  };

  const handleCreateNew = () => {
    setName('');
    setFiles([]);
    setFile(null);
    setImage(null);
    setTagsArr([]);
    setRotation(0);
    history.push(`/elements/${elType}/${catId}/new`);
    setTimeout(() => {
      setLoading(false);
      setStatus('');
    }, 500);
  };

  const handleUploadAll = () => {
    if (!catId) return;

    for (const [index, file] of Array.from(files).entries()) {
      setLoadingIndexes(indexes => [...indexes, index]);
      let formData = new FormData();
      formData.append('name', file.name.split('.')[0]);
      formData.append('rotation', rotation);
      formData.append('file', file);

      tagsArr
        .map(tag => tag.id)
        .forEach(id => {
          formData.append('tags', id);
        });

      formData.append('is_published', true); // Always
      formData.append('category', catId);

      getHeightAndWidthFromDataUrl(createPoster(file)).then(res => {
        formData.append('height', res.height);
        formData.append('width', res.width);

        return addElement(formData, setStatus, setUploadProgress).then(res => {
          if (res.success) {
            setUploadedIndexes(indexes => [...indexes, index]);
            setMsgs([
              {
                type: 'success',
                content: `uploaded file successfully!`,
              },
            ]);
          }
        });
      });
    }
  };

  console.log(uploadedIndexes, loadingIndexes);

  return (
    <div className="manage-formats-page">
      <Main>
        <TopControls
          btns={[
            {
              text: 'Elements',
              path: `/elements/${elType}`,
              selected: true,
            },
            {
              text: 'Manage Elements',
              path: `/elements/${elType}/manage`,
            },
          ]}
        />
        <div className="element-editor shadow-sm border">
          {files.length < 2 && (
            <div>
              <div className="name border shadow-sm">
                <span>Name</span>
                <input
                  disabled={loading}
                  type="text"
                  placeholder="Type here..."
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
                <div
                  className="group noselect sort-by"
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  <span className="variable">{sortTitle}</span>
                  <span className={`${isOpen ? 'rotate-180' : ''}`}>
                    <IncludeSvg name="arrow-drop-down" />
                  </span>

                  <PopupMenu
                    popupRef={popupRef}
                    type={'sort'}
                    setOption={setSort}
                    setOptionTitle={setSortTitle}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                  />
                </div>

                <div className={`right d-flex align-items-center justify-content-end`}>
                  <button
                    disabled={loading}
                    className={`arrow ${prevElId && !loading ? 'active' : ''}`}
                    onClick={() => handleNav('prev')}
                  >
                    <IncludeSvg name={'arrow-left'} />
                  </button>
                  <button
                    disabled={loading}
                    className={`arrow  ${nextElId && !loading ? 'active' : ''}`}
                    onClick={() => handleNav('next')}
                  >
                    <IncludeSvg name={'arrow-right'} />
                  </button>
                </div>
              </div>
              {nameErr && (
                <div className="text-danger text-small" style={{ marginTop: 5 }}>
                  Name field is required!
                </div>
              )}
              <div className="drop-area shadow-sm border" {...getRootProps()}>
                <input disabled={disabled} {...getInputProps()} />
                <span ref={uploadRef}></span>

                {!loading && !image && (
                  <div className="drag-symbol">
                    <span></span>
                    <span></span>
                    <IncludeSvg name="drag-n-drop" />
                  </div>
                )}

                <span
                  style={{
                    transform: `rotate(${rotation}deg)`,
                    opacity: !showImage ? 0 : loading ? 0.5 : 1,
                  }}
                >
                  <LazyLoadImage
                    key={image}
                    beforeLoad={() => {
                      setLoading(true);
                      setShowImage(false);
                      setStatus('preparing preview');
                    }}
                    afterLoad={() => {
                      setLoading(false);
                      setShowImage(true);
                      setStatus('');
                    }}
                    src={image}
                  />
                </span>
                {loading && status === 'preparing preview' && (
                  <div
                    style={{
                      position: 'absolute',
                      width: 200,
                      height: 200,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <div className="spinner-border" role="status">
                      <span className="sr-only"></span>
                    </div>
                    <span className="sr-status" style={{ marginTop: 10 }}>
                      {status}
                    </span>
                  </div>
                )}

                {file && <ProgressBar percentage={uploadProgress} />}
              </div>

              {fileErr && <span className="text-danger text-small">Image field is required!</span>}
              <div className="d-flex mt-5">
                <div className="controls w-100">
                  <div>
                    <button
                      disabled={(!file && !image) || loading}
                      onClick={() => handleRotate('clockwise')}
                    >
                      <IncludeSvg name="rotate-cw" />
                    </button>
                    <button
                      disabled={(!file && !image) || loading}
                      onClick={() => handleRotate('anticlockwise')}
                    >
                      <IncludeSvg name="rotate-acw" />
                    </button>
                    <button
                      onClick={handleUpload}
                      disabled={loading}
                      onMouseEnter={() => setDisabled(false)}
                      onMouseLeave={() => setDisabled(true)}
                    >
                      <IncludeSvg name="upload" />
                    </button>
                    <button disabled={(!file && !image) || loading} onClick={handleDelete}>
                      <IncludeSvg name="delete" />
                    </button>
                  </div>
                  <div>
                    {canChangeElements && (
                      <div className="d-flex mt-4 pt-4">
                        <button
                          disabled={!!catId}
                          onClick={handleCreateNew}
                          style={{ width: 100, marginBottom: 0 }}
                        >
                          Create New
                        </button>
                        <button disabled={loading} onClick={handleSave} className="save">
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-50">
                  <Tags
                    title={'Element Tags'}
                    allowedTags={allowedTags}
                    tagsArr={tagsArr}
                    setTagsArr={setTagsArr}
                    classNameB={'border shadow-sm px-3 py-2'}
                    inputClassName={'border shadow-sm w-100'}
                    height={50}
                    inputPlaceholder={'Teens, Adults, Seasons,... etc'}
                    readOnly={loading}
                  />
                </div>
              </div>
            </div>
          )}
          {files.length > 1 && (
            <div>
              <div className="d-flex flex-wrap border rounded multiple-files-preview">
                {images.map((img, index) => (
                  <div className="w-25 p-3 ">
                    <div className="d-flex flex-column shadow-sm border rounded">
                      <div key={index} className="d-flex h-100" style={{ position: 'relative' }}>
                        <LazyLoadImage
                          key={index}
                          src={img.poster}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            aspectRatio: '1/1',
                            opacity: uploadedIndexes.includes(index)
                              ? 1
                              : loadingIndexes.includes(index)
                              ? 0.7
                              : 0.5,
                          }}
                        />
                        {loadingIndexes.includes(index) && !uploadedIndexes.includes(index) && (
                          <div
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                            }}
                          >
                            <div className="spinner-border" role="status">
                              <span className="sr-only"></span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="d-flex m-2">
                        <span>{img.name}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex mt-5">
                <div className="controls w-100">
                  <div>
                    {canChangeElements && (
                      <div className="d-flex mt-4 pt-4">
                        <button
                          disabled={uploadedIndexes.length !== files.length}
                          onClick={handleCreateNew}
                          style={{ width: 100, marginBottom: 0 }}
                        >
                          Create New
                        </button>
                        <button disabled={loading} onClick={handleUploadAll} className="save">
                          Upload All
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-50">
                  <Tags
                    title={'Element Tags'}
                    allowedTags={allowedTags}
                    tagsArr={tagsArr}
                    setTagsArr={setTagsArr}
                    classNameB={'border shadow-sm px-3 py-2'}
                    inputClassName={'border shadow-sm w-100'}
                    height={50}
                    inputPlaceholder={'Teens, Adults, Seasons,... etc'}
                    readOnly={loading}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Main>
    </div>
  );
}

export default ElementEditor;
