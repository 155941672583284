import React, { useState, useRef, useEffect, useMemo } from 'react';
import IncludeSvg from 'components/common/IncludeSvg';
import { useStudioContext } from 'hooks/studio';
import './FormSlider.scss';

function FormSlider({
  form,
  id,
  title,
  type,
  items = [],
  async,
  getItems,
  getItemsParams,
  itemHeight,
  itemWidth,
  selectedSlider,
  setSelectedSlider,
  recentlyUsedShapes,
  recentlyUsedImages,
  setRecentlyUsedShapes,
  setRecentlyUsedImages,
  handleOnSliderElClick,
}) {
  const sliderTrackRef = useRef();
  const {
    setDragEl,
    // setDragImgSrc,
    setDragImgID,
    setDragImgWidth,
    setDragImgHeight,
  } = useStudioContext();

  const [left, setLeft] = useState(0);
  const moveLeft = () => {
    setLeft(left + itemWidth);
  };

  const [sliderItems, setSliderItems] = useState(items);

  const [count, setCount] = useState(0);

  const moveRight = () => {
    setLeft(left - itemWidth);
  };

  const removeDuplicates = (originalArray, prop) => {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.unshift(lookupObject[i]);
    }
    return newArray;
  };

  const addToRecentlyUsed = el => {
    const item = { ...el };
    const elType = item.image ? 'image' : 'icon';
    if (elType === 'image') {
      let list = [...recentlyUsedImages];
      if (!list[0].name) list = [];
      list.unshift(item);
      setRecentlyUsedImages(removeDuplicates(list, 'id'));
    }

    if (elType === 'icon') {
      let list = [...recentlyUsedShapes];
      if (!list[0].title) list = [];
      list.unshift(item);
      setRecentlyUsedShapes(removeDuplicates(list, 'image'));
    }
  };

  const maxHeight = () => {
    let total = 0;
    if (type === 'image') {
      sliderItems.forEach((item, index) => {
        if (index < sliderItems.length / 2) total += (item.height * 150) / item.width + 10;
      });
    }

    return total;
  };

  useEffect(() => {
    if (selectedSlider === id) {
      setLeft(0);
    }
  }, [selectedSlider]);

  const params = useMemo(() => {
    return { ...getItemsParams };
  }, [getItemsParams?.tags]);

  useEffect(() => {
    if (async) {
      setLeft(0);
      getItems({ ...getItemsParams, offset: 0, category_id: id }).then(res => {
        setSliderItems(res.elements);
        setCount(res.count);
      });
    }
  }, [params]);

  useEffect(() => {
    if (!async) {
      setSliderItems(items);
    }
  }, [items]);

  const loadMoreItems = () =>
    getItems({
      ...getItemsParams,
      offset: sliderItems.length,
      category_id: id,
    }).then(res => {
      setSliderItems([...sliderItems, ...res.elements]);
      setCount(res.count);
    });

  // load more
  useEffect(() => {
    if (
      sliderTrackRef.current &&
      sliderItems.length &&
      -left > itemWidth * (sliderItems.length - 4) &&
      count > sliderItems.length
    ) {
      loadMoreItems();
    }
  }, [left]);

  const handleScroll = e => {
    const bottom = e.target.scrollHeight - e.target.scrollTop >= e.target.clientHeight;
    if (bottom) {
      loadMoreItems();
    }
  };

  return (
    <div className={`form-slider ${type === 'text' ? 'mt-2' : ''}`}>
      {title && (
        <div className="d-flex justify-content-between">
          <div className="form-slider__title">{title}</div>
          {type !== 'misc' && (
            <div
              className="ml-auto toggle-show"
              onClick={() => {
                if (selectedSlider) setSelectedSlider(null);
                else setSelectedSlider(id);
              }}
            >
              {selectedSlider ? 'See less' : `See all ${title}`}
            </div>
          )}
        </div>
      )}

      <div
        className={`form-slider__track`}
        style={{
          height: selectedSlider && type !== 'text' ? 'auto' : itemHeight,
        }}
        onScroll={handleScroll}
        ref={sliderTrackRef}
      >
        <div
          className={`form-slider__items ${
            selectedSlider && type !== 'text' ? 'uncollapsed' : ''
          } ${type}`}
          style={{
            left: left,
            display: 'flex',
            flexDirection: selectedSlider && type === 'image' ? 'column' : 'row',
            flexWrap: selectedSlider ? 'wrap' : 'nowrap',
            maxHeight:
              selectedSlider && type === 'image'
                ? maxHeight()
                : !selectedSlider
                ? itemHeight
                : null,
          }}
        >
          {sliderItems.map((item, index) => (
            <div
              key={index}
              className={`form-slider__item-wrapper noselect ${type}  ${
                selectedSlider && type !== 'text' ? 'uncollapsed' : ''
              }`}
              style={{
                height: selectedSlider && type !== 'text' ? 'auto' : itemHeight,
                minWidth: itemWidth,
                maxWidth: 125,
                backgroundColor: type === 'text' && selectedSlider === index + 1 && '#171B1F',
              }}
              onMouseDown={() => {
                if ((type = 'image')) {
                  // setDragImgSrc(item.image);
                  console.log(item.id);
                  setDragImgID(item.id);
                  setDragImgWidth(item.width);
                  setDragImgHeight(item.height);
                }
              }}
              onMouseUp={() => {
                const elType = item.image ? 'image' : item.type;
                if (setRecentlyUsedShapes || setRecentlyUsedImages) addToRecentlyUsed(item);
                if (handleOnSliderElClick) handleOnSliderElClick(elType);
              }}
            >
              {(type === 'icon' || (type === 'misc' && !item.image)) && (
                <span
                  title={item.title}
                  draggable
                  onDragStart={() => {
                    setDragEl(item.type);
                  }}
                  onDragEnd={() => {
                    if (setRecentlyUsedShapes || setRecentlyUsedImages) addToRecentlyUsed(item);
                  }}
                >
                  <IncludeSvg name={item.type} />
                </span>
              )}
              {type === 'text' && <span onClick={() => setSelectedSlider(index + 1)}>{item}</span>}

              {(type === 'image' || (type === 'misc' && item.image)) && (
                <span
                  title={item.name}
                  style={{
                    display: selectedSlider ? 'inline-block' : 'block',
                    width:
                      selectedSlider && type === 'image'
                        ? title === 'Badges'
                          ? 60
                          : 105
                        : (type === 'misc' && item.image) || title === 'Badges'
                        ? 60
                        : 75,
                    height:
                      selectedSlider && type === 'image'
                        ? title === 'Badges'
                          ? 60
                          : (item.height * 105) / item.width
                        : (type === 'misc' && item.image) || title === 'Badges'
                        ? 50
                        : 65,
                    borderRadius: selectedSlider ? 0 : 10,
                    backgroundImage: `url(${item.thumbnail})`,
                    backgroundColor: title === 'Badges' ? 'transparent' : 'white',
                    backgroundSize:
                      selectedSlider && type === 'image'
                        ? 'contain'
                        : title === 'Badges' || form
                        ? 'contain'
                        : 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                  }}
                  draggable
                  onDragStart={() => {
                    const elType = item.image ? 'image' : 'icon';
                    setDragEl(elType);
                    // setDragImgSrc(item.image);
                    setDragImgID(item.id);
                    setDragImgWidth(item.width);
                    setDragImgHeight(item.height);
                  }}
                  onDragEnd={() => {
                    if (setRecentlyUsedShapes || setRecentlyUsedImages) addToRecentlyUsed(item);
                  }}
                ></span>
              )}

              {/* {type === 'image' && <IncludeSvg name={'rect-rounded-ph'} />} */}
              {type === 'misc' && <span></span>}
            </div>
          ))}
        </div>
        <div
          className={`form-slider__track--arrow-wrapper left ${left >= 0 ? 'hide' : ''} ${
            selectedSlider && type !== 'text' && 'uncollapsed'
          }`}
          onClick={moveLeft}
        >
          <span>
            <IncludeSvg name="arrow-left" />
          </span>
        </div>
        <div
          className={`form-slider__track--arrow-wrapper right ${
            sliderTrackRef.current &&
            -left > itemWidth * (sliderItems.length - 4) &&
            count <= sliderItems.length
              ? 'hide'
              : ''
          } ${selectedSlider && type !== 'text' && 'uncollapsed'}`}
          onClick={moveRight}
        >
          <span>
            <IncludeSvg name="arrow-right" />
          </span>
        </div>
      </div>
    </div>
  );
}

export default FormSlider;
