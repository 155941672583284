export function capitalizeFirst(string, all = false) {
  if (all)
    return string
      .split(' ')
      .map(word => capitalizeFirst(word))
      .join(' ');
  else return string.charAt(0).toUpperCase() + string.slice(1);
}

export function countWords(s) {
  if (!s) return 0;
  return s.trim().split(/\s+/).length;
}

export const camelToTitle = camelCase =>
  camelCase
    .replace(/([A-Z])/g, match => ` ${match}`)
    .replace(/^./, match => match.toUpperCase())
    .trim();

export const processString = s => {
  s = JSON.stringify(s);
  let processed = s.replace(/^"|"$/g, '');
  processed = processed.replace(/\\"/g, '"').replace(/\\\\/g, '\\');
  return processed;
};
