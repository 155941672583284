import React, { useState, useEffect } from 'react';
import AccountForm from 'components/forms/AccountForm';
import { useGlobalContext } from 'state/hooks/global';
import { addAccount, editAccount, getOneAccount } from 'api/settings/accounts';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from 'store/auth';

function AccountEditor({ acc, setAcc, isNew, handleAfterAdd, handleAfterEdit }) {
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState({ ...acc });
  const [file, setFile] = useState(null);

  const [status, setStatus] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);

  const { user } = useSelector(state => state.auth);
  const { setMsgs } = useGlobalContext();

  const dispatch = useDispatch();

  useEffect(() => {
    setAccount({ ...acc });
  }, [acc]);

  const updatePermissionsForCurrentUser = updatedUser => {
    if (user.id === updatedUser.id) {
      dispatch(updateUser({ user: updatedUser }));
    }
  };

  const handleSaveAccount = () => {
    const data = { ...account };
    const formData = new FormData();
    if (file) formData.append('profile_picture', file);
    formData.append('first_name', data.first_name);
    formData.append('last_name', data.last_name);
    formData.append('email', data.email);
    if (data.password) formData.append('password', data.password);
    formData.append('is_active', data.is_active);
    formData.append('resources_permissions', JSON.stringify(data.resources_permissions));

    if (isNew) {
      addAccount(formData, setStatus, setUploadProgress).then(res => {
        handleAfterAdd(res.user);
        setMsgs([{ type: 'success', content: 'Element created successfully!' }]);
        setUploadProgress(0);
        setTimeout(() => {
          setLoading(false);
          setFile(null);
        }, 1000);
      });
    } else {
      editAccount(formData, acc.id, setStatus, setUploadProgress).then(res => {
        updatePermissionsForCurrentUser(res.user);
        handleAfterEdit(res.user);
        setMsgs([{ type: 'success', content: 'Account updated successfully!' }]);
        setUploadProgress(0);
        setTimeout(() => {
          setLoading(false);
          setFile(null);
        }, 1000);
      });
    }
  };

  const handleResetAccount = e => {
    e.preventDefault();
    getOneAccount(acc.id).then(res => {
      setAccount({ ...res });
    });
  };

  return (
    <div className="account-editor">
      <AccountForm
        account={account}
        setAccount={setAccount}
        isLoading={loading}
        setIsLoading={setLoading}
        handleSaveAccount={handleSaveAccount}
        handleResetAccount={handleResetAccount}
        file={file}
        setFile={setFile}
        isNew={isNew}
        status={status}
        setStatus={setStatus}
        uploadProgress={uploadProgress}
      />
    </div>
  );
}

export default AccountEditor;
