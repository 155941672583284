import Main from 'layouts/Main';
import React from 'react';
import Slider from 'components/common/Slider';
import TopControls from 'components/common/TopControls';
import { hasAccess } from 'helpers/permissions';
import { useSelector } from 'react-redux';

function ManageFormats() {
  const { user } = useSelector(state => state.auth);

  return (
    <div className="manage-formats-page">
      <Main>
        <TopControls
          btns={[
            {
              text: 'Formats',
              path: '/home/formats',
              hidden: !hasAccess('/home/formats', user),
            },
            {
              text: 'Manage Formats',
              path: '/home/formats/manage',
              selected: true,
              hidden: !hasAccess('/home/formats/manage', user),
            },
          ]}
        />
        <div className="resources">
          <Slider title={'Page sizes'} type={'size'} />
        </div>
      </Main>
    </div>
  );
}

export default ManageFormats;
