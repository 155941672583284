import IncludeSvg from 'components/common/IncludeSvg';
import React from 'react';

function FormNavigator({ form, setForm, pageSize, options }) {
  return (
    <div
      className={`top-controls icon-btns shadow-sm ${
        pageSize ? 'justify-content-between' : 'justify-content-end'
      }`}
    >
      {pageSize && (
        <div className="h4">
          Size: <span style={{ fontWeight: 'bold' }}>{pageSize.split(' ')[0]}</span>
          <span>{' ' + pageSize.split(' ')[1]}</span>
        </div>
      )}
      <div className=" d-flex">
        {options.map(option => (
          <button
            key={option.name}
            className={`${form === option.name ? 'active' : ''}`}
            onClick={() => setForm(option.name)}
            disabled={option.disabled}
          >
            <IncludeSvg name={option.name} />
          </button>
        ))}
      </div>
    </div>
  );
}

export default FormNavigator;
