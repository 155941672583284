import Main from 'layouts/Main';

import React from 'react';
import { useLocation } from "react-router-dom"


function Placeholder(path) {
  const location = useLocation();

  return (
    <Main>
      <div className="d-flex py-5 px-5">
      <h1 className='color-sys'>Path: {location.pathname}</h1>
      </div>
    </Main>
  )
}

export default Placeholder;
