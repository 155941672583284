import React, { useRef } from 'react';
import IncludeSvg from 'components/common/IncludeSvg';

function InputNumber({
  className,
  style,
  disabled,
  min,
  max,
  step,
  value,
  setValue,
  hideArrows,
  onFocus,
  onBlur,
}) {
  const inputRef = useRef();
  return (
    <div style={{ ...style, position: 'relative', opacity: disabled ? 0.6 : 1 }}>
      <input
        ref={inputRef}
        className={className}
        style={style}
        min={min}
        max={max}
        type="number"
        step={step}
        value={value}
        onChange={e => {
          setValue(Number(e.target.value));
        }}
        onFocus={e => {
          e.target.select();
          if (onFocus) onFocus();
        }}
        onBlur={onBlur}
        disabled={disabled ? true : false}
      />
      <div
        style={{
          display: hideArrows ? 'none' : 'flex',
          flexDirection: 'column',
          justifyContnet: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-45%)',
          right: 1,
          zIndex: 500,
        }}
      >
        <span
          style={{
            display: 'flex',
            justifyContnet: 'center',
            alignItems: 'center',
            height: 15,
            cursor: 'pointer',
          }}
          onClick={() => {
            if (!disabled) {
              inputRef.current.stepUp();
              setValue(Number(inputRef.current.value));
            }
          }}
        >
          <IncludeSvg name="arrow-up" />
        </span>
        <span
          style={{
            display: 'flex',
            justifyContnet: 'center',
            alignItems: 'center',
            height: 15,
            cursor: 'pointer',
          }}
          onClick={() => {
            if (!disabled) {
              inputRef.current.stepDown();
              setValue(Number(inputRef.current.value));
            }
          }}
        >
          <IncludeSvg name="arrow-down" />
        </span>
      </div>
    </div>
  );
}

export default InputNumber;
