import { getUniqueId } from 'helpers/generators';
import { dummyText } from 'helpers/dummy';

const getAngleInRadians = degrees => {
  const pi = Math.PI;
  return degrees * (pi / 180);
};

export const getCorners = node => {
  const rotation = node.rotation || 0;
  const rad = getAngleInRadians(rotation);
  const rcos = Math.cos(rad);
  const rsin = Math.sin(rad);
  let cx = node.x;
  let cy = node.y;
  let ox, oy;

  if (node.id.includes('text')) {
    ox = node.offsetX;
    oy = node.offsetY;
  } else if (node.id.includes('triangle')) {
    ox = (node.radius * 3) / Math.sqrt(3) / 2;
    oy = (node.radius * 1.5) / 2;
  } else if (node.id.includes('star')) {
    ox = node.outerRadius;
    oy = node.outerRadius;
  } else if (node.id.includes('circle')) {
    ox = node.radiusX;
    oy = node.radiusY;
  } else {
    ox = node.width / 2;
    oy = node.height / 2;
  }

  return {
    // top right
    tr: {
      x: cx + ox * rcos - oy * rsin,
      y: cy + ox * rsin - oy * rcos,
    },
    // top left
    tl: {
      x: cx - ox * rcos - oy * rsin,
      y: cy - ox * rsin + oy * rcos,
    },
    // bottom left
    bl: {
      x: cx - ox * rcos + oy * rsin,
      y: cy - ox * rsin - oy * rcos,
    },
    // bottom right
    br: {
      x: cx + ox * rcos + oy * rsin,
      y: cy + ox * rsin + oy * rcos,
    },
  };
};

export const getBoundries = shape => {
  const node = { ...shape };
  const corners = getCorners(node);
  return {
    id: node.id,
    x: node.x,
    x1: Math.min(corners['tr'].x, corners['tl'].x, corners['bl'].x, corners['br'].x),
    x2: Math.max(corners['tr'].x, corners['tl'].x, corners['bl'].x, corners['br'].x),
    y: node.y,
    y1: Math.min(corners['tr'].y, corners['tl'].y, corners['bl'].y, corners['br'].y),
    y2: Math.max(corners['tr'].y, corners['tl'].y, corners['bl'].y, corners['br'].y),
  };
};

export const getMirrorY = node => {
  const boundries = getBoundries(node);
  const dy = boundries.y2 - boundries.y;
  return 2 * dy;
};

export const getMirrorX = node => {
  const boundries = getBoundries(node);
  const dx = boundries.x2 - boundries.x;
  return 2 * dx;
};

export const newEl = (type, x = 0, y = 0, dragged) => {
  const id = getUniqueId(type);
  let shape;

  switch (type) {
    case 'rect':
      shape = {
        id,
        x,
        y,
        width: 80,
        height: 80,
        fill: '#000000',
      };
      break;
    case 'rect-frame':
      shape = {
        id,
        x,
        y,
        width: 80,
        height: 80,
        stroke: '#000000',
        fill: 'rgba(0,0,0,0)',
        strokeWidth: 1,
      };
      break;
    case 'rect-rounded':
      shape = {
        id,
        x,
        y,
        width: 80,
        height: 80,
        fill: '#000000',
        cornerRadius: [10, 10, 10, 10],
      };
      break;
    case 'rect-rounded-frame':
      shape = {
        id,
        x,
        y,
        width: 80,
        height: 80,
        stroke: '#000000',
        fill: 'rgba(0,0,0,0)',
        strokeWidth: 1,
        cornerRadius: [10, 10, 10, 10],
      };
      break;
    case 'circle':
      shape = {
        id,
        x,
        y,
        radiusX: 40,
        radiusY: 40,
        fill: '#000000',
      };
      break;
    case 'circle-frame':
      shape = {
        id,
        x,
        y,
        radiusX: 40,
        radiusY: 40,
        stroke: '#000000',
        fill: 'rgba(0,0,0,0)',
        strokeWidth: 1,
      };
      break;
    case 'triangle':
      shape = {
        id,
        x,
        y,
        sides: 3,
        radius: 50,
        fill: '#000000',
      };
      break;
    case 'triangle-frame':
      shape = {
        id,
        x,
        y,
        sides: 3,
        radius: 50,
        stroke: '#000000',
        fill: 'rgba(0,0,0,0)',
        strokeWidth: 1,
      };
      break;
    case 'star':
      shape = {
        id,
        x,
        y,
        numPoints: 6,
        innerRadius: 0.3 * 40,
        outerRadius: 40,
        fill: '#000000',
      };
      break;
    case 'star-frame':
      shape = {
        id,
        x,
        y,
        numPoints: 6,
        innerRadius: 0.3 * 40,
        outerRadius: 40,
        stroke: '#000000',
        fill: 'rgba(0,0,0,0)',
        strokeWidth: 1,
      };
      break;
    case 'solid-line':
      shape = {
        id,
        x,
        y,
        width: 150,
        height: 10, // this is for allignment
        stroke: '#000000',
        strokeWidth: 2,
        rotation: 0,
      };
      break;
    case 'dashed-line':
      shape = {
        id,
        x,
        y,
        width: 150,
        height: 10, // this is for allignment
        stroke: '#000000',
        strokeWidth: 2,
        rotation: 0,
        dash: [20, 5],
      };
      break;
    case 'dotted-line':
      shape = {
        id,
        x,
        y,
        width: 150,
        height: 10, // this is for allignment
        stroke: '#000000',
        strokeWidth: 2,
        rotation: 0,
        dash: [5, 5],
      };
      break;
    case 'centre-line':
      shape = {
        id,
        x,
        y,
        width: 150,
        height: 10, // this is for allignment
        stroke: '#000000',
        strokeWidth: 2,
        rotation: 0,
        dash: [40, 5, 5, 5],
      };
      break;
    case 'cut-line':
      shape = {
        id,
        x,
        y,
        width: 150,
        height: 10, // this is for allignment
        stroke: '#000000',
        strokeWidth: 2,
        rotation: 0,
        dash: [20, 5, 20, 5, 5, 5],
      };
      break;
    case 'image':
      shape = {
        id,
        x,
        y,
        imgID: dragged && dragged.dragImgID,
        width: 200,
        height:
          dragged && dragged.dragImgHeight && dragged.dragImgWidth
            ? dragged.dragImgHeight * (200 / dragged.dragImgWidth)
            : 200,
      };
      break;
    case 'heading-text':
      shape = {
        id,
        x,
        y,
        width: 129,
        padding: 0,
        text: 'Heading',
        fill: '#000000',
        fontSize: 34,
        fontFamily: 'Arial',
        fontStyle: 'Normal',
        letterSpacing: 0,
        lineHeight: 1,
        align: 'left',
      };
      break;
    case 'subheading-text':
      shape = {
        id,
        x,
        y,
        width: 109,
        padding: 0,
        text: 'Subheading',
        fill: '#000000',
        fontSize: 20,
        fontFamily: 'Arial',
        fontStyle: 'Normal',
        letterSpacing: 0,
        lineHeight: 1,
        align: 'left',
      };
      break;
    case 'text':
      shape = {
        id,
        x,
        y,
        width: 24,
        padding: 0,
        text: 'Text',
        fill: '#000000',
        fontSize: 12,
        fontFamily: 'Arial',
        fontStyle: 'Normal',
        letterSpacing: 0,
        lineHeight: 1,
        align: 'left',
      };
      break;
    case 'heading-quick-link-text':
    case 'subheading-quick-link-text':
    case 'isbn-quick-link-text':
    case 'authors-quick-link-text':
    case 'page-number-quick-link-text':
    case 'problem-number-quick-link-text':
    case 'solution-number-quick-link-text':
      const linkRoles = {
        'heading-quick-link-text': 'Heading',
        'subheading-quick-link-text': 'Subheading',
        'isbn-quick-link-text': 'ISBN',
        'authors-quick-link-text': 'Authors',
        'page-number-quick-link-text': 'Page Number',
        'problem-number-quick-link-text': 'Problem Number',
        'solution-number-quick-link-text': 'Solution Number',
      };
      shape = {
        id,
        x,
        y,
        width: 200,
        padding: 0,
        text: type.includes('number') ? '000' : type.split('-')[0].toUpperCase(),
        fill: '#000000',
        fontSize: 34,
        fontFamily: 'Arial',
        fontStyle: 'Normal',
        letterSpacing: 0,
        lineHeight: 1,
        align: 'left',
        link: {
          type: type.includes('number') ? 'Count' : 'General',
          role: linkRoles[type],
          format: type.includes('number') ? '1, 2, 3, ...' : '',
        },
      };
      break;
    case 'classic-word-list-text':
    case 'classic-number-list-text':
      shape = {
        id,
        x: -200,
        y: -12,
        width: 400,
        padding: 0,
        text: type.includes('number')
          ? '123456789, 123456789, 123456789, 123456789, 123456789, 123456789'
          : 'wordgoeshere, wordgoeshere, wordgoeshere, wordgoeshere, wordgoeshere, wordgoeshere',
        fill: '#000000',
        fontSize: 12,
        fontFamily: 'Arial',
        fontStyle: 'Normal',
        letterSpacing: 0,
        lineHeight: 1,
        align: 'center',
        staticLink: {
          type: 'Text',
          role: type,
          cols: 3,
          rows: 2,
        },
      };
      break;
    case 'classic-word-list-block-text':
      shape = {
        id,
        x: x - 200,
        y: y - 12,
        width: 400,
        padding: 0,
        text: 'Across, wordgoeshere, wordgoeshere, Down, wordgoeshere, wordgoeshere',
        fill: '#000000',
        fontSize: 12,
        fontFamily: 'Arial',
        fontStyle: 'Normal',
        letterSpacing: 0,
        lineHeight: 6,
        align: 'left',
        staticLink: {
          isAcrossDown: true,
          markedWords: ['Across', 'Down'],
          markedWordsStyles: { textDecoration: 'underline', fontStyle: 'bold', fill: '#ff0000' },
          type: 'Text',
          role: type,
          cols: 3,
          rows: 2,
        },
      };
      break;
    case 'smart-word-list-text':
    case 'smart-number-list-text':
      shape = {
        id,
        x: x - 200,
        y: y - 12,
        width: 500,
        padding: 0,
        text: type.includes('number')
          ? '123456789, 123456789, 123456789, 123456789, 123456789, 123456789'
          : 'wordgoeshere, wordgoeshere, wordgoeshere, wordgoeshere, wordgoeshere, wordgoeshere',
        fill: '#000000',
        fontSize: 12,
        fontFamily: 'Arial',
        fontStyle: 'Normal',
        letterSpacing: 0,
        lineHeight: 1,
        align: 'center',
        staticLink: {
          type: 'Text',
          role: type,
          cols: 3,
        },
      };
      break;
    case 'article-title-text':
      shape = {
        id,
        x,
        y,
        width: 129,
        padding: 0,
        text: 'Article title',
        fill: '#000000',
        fontSize: 34,
        fontFamily: 'Arial',
        fontStyle: 'Normal',
        letterSpacing: 0,
        lineHeight: 1,
        align: 'left',
        staticLink: {
          type: 'Text',
          role: type,
        },
      };
      break;
    case 'article-word-list-text':
      shape = {
        id,
        x: x - 200,
        y: y - 12,
        width: 500,
        padding: 0,
        text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
        fill: '#000000',
        fontSize: 20,
        fontFamily: 'Arial',
        fontStyle: 'Normal',
        letterSpacing: 1,
        lineHeight: 1,
        align: 'left',
        staticLink: {
          type: 'Text',
          role: type,
          markedWords: [
            'established',
            'distracted',
            'looking',
            'layout',
            'Lorem',
            'normal',
            'content',
            'readable',
          ],
          markedWordsStyles: { textDecoration: 'underline', fontStyle: 'bold', fill: '#ff0000' },
        },
      };
      break;
    case 'story-title-text':
      shape = {
        id,
        x,
        y,
        width: 129,
        padding: 0,
        text: 'Story title',
        fill: '#000000',
        fontSize: 34,
        fontFamily: 'Arial',
        fontStyle: 'Normal',
        letterSpacing: 0,
        lineHeight: 1,
        align: 'left',
        staticLink: {
          type: 'Text',
          role: type,
        },
      };
      break;
    case 'story-word-list-text':
      shape = {
        id,
        x: x - 300,
        y: y - 150,
        width: 600,
        height: 300,
        padding: 0,
        text: dummyText,
        fill: '#000000',
        fontSize: 20,
        fontFamily: 'Arial',
        fontStyle: 'Normal',
        letterSpacing: 1,
        lineHeight: 1,
        align: 'left',
        staticLink: {
          type: 'Text',
          role: type,
        },
      };
      break;
    case 'sudoku-grid - puzzle':
    case 'sudoku-grid - solution':
    case 'word-search-grid - puzzle':
    case 'word-search-grid - solution':
    case 'cross-words-grid - puzzle':
    case 'cross-words-grid - solution':
    case 'number-search-grid - puzzle':
    case 'number-search-grid - solution':
      shape = {
        id,
        x: x - 225,
        y: y - 225,
        width: 450,
        height: 450,
        padding: 0,
        text:
          type.includes('sudoku') || type.includes('number-search')
            ? '123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789123465789'
            : type.includes('word-search')
            ? 'ABCDEFGHIJKABCDEFGHIJKABCDEFGHIJKABCDEFGHIJKABCDEFGHIJKABCDEFGHIJKABCDEFGHIJKABCDEFGHIJKABCDEFGHIJKABCDEFGHIJKABCDEFGHIJK'
            : 'ABCD---HIJKABCDE---JKABCDEFG--ABCDEFGH---DEFGHIJK-BCDEF---JKABCD---HIJKABCDEFG---ABCDEFGH0-KABCDEFG-IJKABCDEFGHIJK',
        fill: '#000000',
        fontSize: 16,
        fontFamily: 'Arial',
        fontStyle: 'Normal',
        letterSpacing: 0,
        lineHeight: 1,
        align: 'center',
        staticLink: {
          type: 'Text',
          role: type,
          rows: type.includes('sudoku') ? 9 : 11,
          cols: type.includes('sudoku') ? 9 : 11,
          nWords: 9,
          commaSeparatedWords:
            type.includes('sudoku') || type.includes('number-search')
              ? 'COOL, PUZZLES, WORDSEARCH, KIDS, ADULTS'
              : null,
          smallNumbers: type.includes('cross-words')
            ? '1, , , , ,2, , , , 3, , , ,4, , , ,5, , 6, , , ,7, , , ,8, , , ,9, , , ,10, , , ,11, , , ,12, , , ,13, , , ,14, , , ,15, , , ,16, , , ,17, , , ,18, , , ,19, , , ,20, , , ,21, , , ,22, , , ,23, , , ,24, , , ,25, , , ,26, , , ,27, , , ,28, , , ,29, , , ,30'
            : null,
          difficulty: 'easy',
          puzzleShape: 'normal',
          borders: {
            checked1: true,
            color1: '#000000',
            width1: type.includes('sudoku') ? 2.5 : 0.5,
            checked2: true,
            color2: '#000000',
            width2: 0.5,
          },
        },
      };
      break;
    case 'trivia-question-text':
      shape = {
        id,
        x: 0,
        y: 0,
        width: 150,
        padding: 0,
        text: 'Trivia question goes here?',
        fill: '#000000',
        fontSize: 12,
        fontFamily: 'Arial',
        fontStyle: 'Normal',
        letterSpacing: 0,
        lineHeight: 1,
        align: 'left',
        link: {
          // TODO: should be statcLink & manuscritp view and api support it
          type: 'Puzzle',
          role: 'Problem',
          format: 'Trivia',
        },
      };
      break;
    case 'trivia-answer-text':
      shape = {
        id,
        x: 0,
        y: 0,
        width: 150,
        padding: 0,
        text: 'Trivia answer goes here!',
        fill: '#000000',
        fontSize: 12,
        fontFamily: 'Arial',
        fontStyle: 'Normal',
        letterSpacing: 0,
        lineHeight: 1,
        align: 'left',
        link: {
          // TODO: should be statcLink ...
          type: 'Puzzle',
          role: 'Solution',
          format: 'Trivia',
        },
      };
      break;

    case 'classic-table-of-contents-text':
      shape = {
        id,
        x: x - 250,
        y: y - 75,
        width: 500,
        height: 150,
        text: 'Table of contents',
        fill: '#000000',
        fontSize: 22,
        fontFamily: 'Arial',
        fontStyle: 'Normal',
        letterSpacing: 0,
        lineHeight: 1.2,
        align: 'center',
        staticLink: {
          type: 'Text',
          role: type,
          tableOfContents: [
            { number: '1', title: 'Intro', dots: '...', pageNumber: '1' },
            { number: '2', title: 'Chapter 1', dots: '...', pageNumber: '50' },
            { number: '3', title: 'Chapter 2', dots: '...', pageNumber: '100' },
            { number: '4', title: 'Chapter 3', dots: '...', pageNumber: '150' },
            { number: '5', title: 'Chapter 4', dots: '...', pageNumber: '200' },
            { number: '6', title: 'Conclusion', dots: '...', pageNumber: '250' },
          ],
        },
      };
      break;

    case 'coloring-book-image':
      shape = {
        id,
        x,
        y,
        link: {
          type: 'Image',
          role: 'Coloring Book Image',
        },
        imgID: 'coloring-book-image',
        width: 500,
        height: 500,
        scaleX: 1,
        scaleY: 1,
        stroke: '#000000',
        opacity: 1,
        rotation: 0,
        strokeWidth: 0,
        cornerRadius: [0, 0, 0, 0],
      };
      break;

    default: {
      alert(type + ' unsupported type');
    }
  }
  if (shape) return [shape, shape.id];
  else return [null, null];
};
