// https://openbase.com/js/react-range
import React from 'react';
import { Range, getTrackBackground } from 'react-range';

const STEP = 1;
const MIN = 0;
const MAX = 100;

const InputRange = ({ value, setValue }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center',
        marginLeft: 10,
      }}
    >
      <Range
        values={[value]}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={values => setValue(values[0])}
        renderTrack={({ props, children }) => (
          <>
            <span style={{ minWidth: 40, fontSize: 15 }}>{value}%</span>
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '3rem',
                display: 'flex',
                width: '100%',
                marginLeft: '0.8333rem',
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '0.4167rem',
                  width: '13.3333rem',
                  borderRadius: '0.3333rem',
                  background: getTrackBackground({
                    values: [value],
                    colors: ['#02a3ff', '#ccc'],
                    min: MIN,
                    max: MAX,
                  }),
                  alignSelf: 'center',
                }}
              >
                {children}
              </div>
            </div>
          </>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '1.3333rem',
              width: '1.3333rem',
              borderRadius: '0.6667rem',
              backgroundColor: '#02a3ff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // boxShadow: "0rem 0.1667rem 0.5rem #AAA",
              outline: 'none',
            }}
          >
            <div
              style={{
                height: '0.4167rem',
                width: '1.6667rem',
                backgroundColor: isDragged ? '#02a3ff' : '02a3ff',
              }}
            />
          </div>
        )}
      />
    </div>
  );
};

export default InputRange;
