// Built-in
import React, { useState, useEffect } from 'react';

// External
import { useHistory } from 'react-router-dom';
import { useQuery } from 'hooks/router';
import { default as SlideModal } from 'react-slide-out';

// API
import { getTags } from 'api/common';
import { getOneManuscript } from 'api/studio/manuscripts';
import { getPageSizes } from 'api/studio/formats';

// Context
import { useStudioContext } from 'hooks/studio';

// Components
import Main from 'layouts/Main';
import TabsNavigator from 'components/studio/TabsNavigator';
import ManuscriptsStage from 'components/studio/ManuscriptsStage';
import ManuscriptSpecForm from 'components/forms/ManuscriptSpecForm';

function ManuscriptWorkspace() {
  // state
  const [showSlideModal, setShowSlideModal] = useState(false);
  const [allowedTags, setAllowedTags] = useState([]);
  const [tagsArr, setTagsArr] = useState([]);
  const [tags, setTags] = useState(''); // formatted String
  const [pageSizes, setPageSizes] = useState([]);
  const [toRemove, setToRemove] = useState(null);

  // hooks
  const history = useHistory();
  const query = useQuery();
  const ids = query.get('ids');
  const { manuscripts, setManuscripts, selectedManuscript, setSelectedManuscript } =
    useStudioContext();

  // methods
  async function multipleAPICalls(list, callFn) {
    const requests = list.map(item => callFn(item));
    return Promise.all(requests);
  }

  const addManuscriptToWorkspace = manuscript => {
    const list = [...manuscripts];
    list.push(manuscript);
    setManuscripts(list);
  };

  async function loadManuscripts(ids) {
    try {
      const list = await multipleAPICalls(ids, getOneManuscript);
      setManuscripts(
        list.map(res => {
          if (res.success) return res.manuscript;
        })
      );
    } catch {
      history.replace('/');
    }
  }

  // created
  useEffect(() => {
    const idsArr = ids && ids.length ? ids.split(',') : null;

    if (idsArr) loadManuscripts(idsArr);
    else history.replace('/');

    return () => {
      setManuscripts([]);
    };
  }, [ids]);

  useEffect(() => {
    getTags({ kind: 'studio_manuscripts' }).then(res => {
      if (res.success) {
        setAllowedTags(res.tags);
      } else {
        console.log(res.errors);
      }
    });
  }, []);

  useEffect(() => {
    getPageSizes(0, 10000).then(res => {
      // page sizes can't be more than 10000
      if (res.success) {
        setPageSizes(res.page_sizes);
      } else {
        console.log(res.errors);
      }
    });
  }, []);

  // watchers
  useEffect(() => {
    if (manuscripts.length) {
      setSelectedManuscript(manuscripts[manuscripts.length - 1]);
      history.replace(`/home/manuscripts/workspace?ids=${manuscripts.map(f => f.id).join(',')}`);
    }
  }, [manuscripts.length]);

  useEffect(() => {
    let str = '';
    tagsArr.forEach(tag => {
      const index = allowedTags.findIndex(
        allowedTag => allowedTag.name.toLowerCase() === tag.name.toLowerCase()
      );
      if (index > -1) {
        str += `&tag=${allowedTags[index].id}`;
      }
    });
    setTags(str);
  }, [tagsArr]);

  // render

  return (
    <Main>
      <TabsNavigator
        type={'manuscript'}
        items={manuscripts}
        selectedItem={selectedManuscript}
        setSelectedItem={setSelectedManuscript}
        setShowSlideModal={setShowSlideModal}
        setToRemove={setToRemove}
      />
      {manuscripts.length > 0 &&
        manuscripts.map(manuscript => {
          return (
            <ManuscriptsStage
              key={manuscript.id}
              manuscript={manuscript}
              show={manuscript.id === selectedManuscript.id}
              toRemove={toRemove}
              setToRemove={setToRemove}
              allowedTags={allowedTags}
            />
          );
        })}
      <SlideModal
        isOpen={showSlideModal}
        onOutsideClick={() => {
          setShowSlideModal(false);
        }}
      >
        <ManuscriptSpecForm
          allowedTags={allowedTags}
          pageSizes={pageSizes}
          manuscriptCategoryId={selectedManuscript ? selectedManuscript.category : 1}
          closeSlideModal={() => {
            setShowSlideModal(false);
          }}
          addManuscriptToWorkspace={addManuscriptToWorkspace}
        />
      </SlideModal>
    </Main>
  );
}

export default ManuscriptWorkspace;
