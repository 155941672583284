import { Group, Rect, Text } from 'react-konva';

import LinkIndicator from '../LinkIndicator';
import React, { useEffect } from 'react';
import { calculateTextWidth } from 'helpers/studio';
import { max } from 'lodash';

const findMaxLenNumber = numberList =>
  numberList.reduce(
    (maxLenNumber, number) => (number.length > maxLenNumber.length ? number : maxLenNumber),
    ''
  );

const sumToIndex = (arr, index) => {
  let sum = 0;
  for (let i = 0; i < index; i++) {
    sum += arr[i];
  }
  return sum;
};

const reCalculateIncs = incs => {
  const newIncs = [...incs];
  const totalPositive = incs.reduce((acc, curr) => (curr > 0 ? acc + curr : acc), 0);
  const negativeCount = incs.reduce((acc, curr) => (curr < 0 ? acc + 1 : acc), 0);
  const reds = totalPositive / negativeCount;
  return newIncs.map(inc => {
    if (inc > 0) return inc;
    return -1 * reds;
  });
};

const extractNumbers = (text, sepArr = ['\n', ',', '-', '|', '/']) => {
  let output = text;
  // replace multiple white spaces with one
  output = output.replaceAll(/\n+/g, '\n');
  output = output.replaceAll(/  +/g, ' ');

  // replace seperators with commas
  sepArr.forEach(sep => {
    output = output.replaceAll(sep, ',');
  });
  output = output.replaceAll(/,+/g, ',');
  return output.trim().split(',');
};

const alignGrid = (grid, align, maxGridWidth) => {
  // 1. get the max width of each row
  const rows = grid.reduce((acc, curr) => {
    if (!acc[curr.row]) {
      acc[curr.row] = [];
    }
    acc[curr.row].push(curr);
    return acc;
  }, []);

  const maxRowWidths = rows.map(row => row.reduce((acc, curr) => acc + curr.width, 0));
  const numbersInEachRow = rows.map(row => row.length);

  // 2. calculate the new x for each number in the grid
  const alignedGrid = grid.map(number => {
    const rowWidth = maxRowWidths[number.row];
    let x = 0;
    if (align === 'center') {
      x = (maxGridWidth - rowWidth) / 2;
    } else if (align === 'right') {
      x = maxGridWidth - rowWidth;
    } else if (align === 'justify') {
      x = ((maxGridWidth - rowWidth) / numbersInEachRow[number.row]) * number.rowIndex;
    }
    return { ...number, x: number.x + x };
  });

  return alignedGrid;
};

const TNumberList = React.memo(
  ({
    type,
    isNumberList,
    shapeProps,
    setTextareaProps,
    isEditMode,
    setIsEditMode,
    setTextareaValue,
    isSelected,
    scale,
    setSelectedShapeId,
    stageWidth,
    stageHeight,
    bleedingTop,
    bleedingLeft,
    onSelect,
    onDragStart,
    onDragMove,
    onDragEnd,
    onChange,
    trForceUpdate,
  }) => {
    const shapeRef = React.useRef();
    const [classicGrid, setClassicGrid] = React.useState([]);
    const [smartGrid, setSmartGrid] = React.useState([]);
    const [articleGrid, setArticleGrid] = React.useState([]);

    const [forcedMinWidth, setForcedMinWidth] = React.useState(0);
    const classNumberlistWidth = shapeProps.id.includes('classic-number-list')
      ? forcedMinWidth > shapeProps.width
        ? forcedMinWidth
        : shapeProps.width
      : null;

    // cols increments for each col in the numberlist
    const [incs, setIncs] = React.useState([0, 0, 0]);

    const getAngleInRadians = degrees => {
      const pi = Math.PI;
      return degrees * (pi / 180);
    };

    const topLeftPoint = (cx, cy, w, h, rotation, scale) => {
      const x =
        ((cx + bleedingLeft - (w / 2) * Math.cos(rotation) + (h / 2) * Math.sin(rotation)) *
          scale) /
        100;
      const y =
        ((cy + bleedingTop - (w / 2) * Math.sin(rotation) - (h / 2) * Math.cos(rotation)) * scale) /
        100;
      return { x, y };
    };

    React.useEffect(() => {
      if (isSelected && isEditMode) {
        shapeRef.current.hide();
      } else if (!isSelected || !isEditMode) {
        shapeRef.current.show();
      }
    }, [isSelected, isEditMode]);

    React.useEffect(() => {
      const sepArr = shapeProps.id.includes('article') ? [' '] : undefined;
      const numbers = extractNumbers(shapeProps.text, sepArr);
      const grid = [];
      if (shapeProps.id.includes('classic-number-list')) {
        const rowsLen = Math.ceil(numbers.length / shapeProps.staticLink.cols);
        const colsLen = Math.ceil(numbers.length / rowsLen);

        const chunkSize = shapeProps.staticLink.isAcrossDown ? rowsLen : colsLen;
        for (let i = 0; i < numbers.length; i += chunkSize) {
          const chunk = numbers.slice(i, i + chunkSize);
          grid.push(chunk);
        }
        setClassicGrid(grid);
      } else if (
        shapeProps.id.includes('smart-number-list') ||
        shapeProps.id.includes('article-number-list')
      ) {
        const rowWidth = shapeProps.width;
        let currRow = 0;
        let currNumber = 0;
        let x = 0;
        numbers.forEach((number, i) => {
          const fontStyle =
            shapeProps.staticLink.markedNumbers &&
            shapeProps.staticLink.markedNumbers.includes(number)
              ? shapeProps.staticLink.markedNumbersStyles.fontStyle
              : shapeProps.fontStyle;
          const width = calculateTextWidth({
            text: number + ' ',
            fontSize: shapeProps.fontSize,
            fontFamily: shapeProps.fontFamily,
            fontStyle: fontStyle,
            addedSpace: 0,
            minWidth: 0,
            canvasWidth: shapeProps.width,
          });

          const numberWidth =
            width +
            shapeProps.letterSpacing * number.length +
            (shapeProps.id.includes('smart') ? 10 : 0); // 10 padding

          // check for returns
          if (x + numberWidth + shapeProps.fontSize > rowWidth) {
            x = numberWidth;
            currRow++;
            currNumber = 0;
          } else {
            x += numberWidth;
          }
          currNumber++;

          const gapX = shapeProps.id.includes('smart') ? 15 : 0;
          const gapY = shapeProps.id.includes('smart') ? 15 : 0;
          const w = {
            text: number,
            x: x - numberWidth + gapX * currNumber,
            y:
              currRow * shapeProps.fontSize +
              shapeProps.fontSize * 0.5 * currRow +
              (shapeProps.lineHeight + gapY) * currRow,
            width: numberWidth,
            row: currRow,
            rowIndex: currNumber - 1,
          };

          if (
            shapeProps.staticLink.markedNumbers &&
            shapeProps.staticLink.markedNumbers.includes(number)
          ) {
            w.textDecoration = shapeProps.staticLink.markedNumbersStyles.textDecoration;
            w.fontStyle = shapeProps.staticLink.markedNumbersStyles.fontStyle;
            w.fill = shapeProps.staticLink.markedNumbersStyles.fill;
          }
          grid.push(w);
        });

        // setSmartListHeight(currRow * shapeProps)
        if (shapeProps.id.includes('smart')) setSmartGrid(grid);
        else if (shapeProps.id.includes('article')) {
          const alignedText = alignGrid(grid, shapeProps.align, shapeProps.width);
          setArticleGrid(alignedText);
        }
      }
    }, [
      shapeProps.width,
      shapeProps.text,
      shapeProps.align,
      shapeProps.staticLink.cols,
      shapeProps.staticLink.rows,
      shapeProps.lineHeight,
      shapeProps.letterSpacing,
      shapeProps.fontSize,
      shapeProps.staticLink.markedNumbersStyles && shapeProps.staticLink.markedNumbersStyles.fill,
      shapeProps.staticLink.markedNumbersStyles &&
        shapeProps.staticLink.markedNumbersStyles.textDecoration,
      shapeProps.staticLink.markedNumbersStyles &&
        shapeProps.staticLink.markedNumbersStyles.fontStyle,
    ]);

    // // for alignment
    // useEffect(() => {
    //   if (!shapeProps.id.includes('article-number-list')) return;
    //   if (articleGrid.length === 0) return;
    //   const alignedText = alignGrid(articleGrid, shapeProps.align, shapeProps.width);
    //   setArticleGrid(alignedText);
    // }, [shapeProps.align, shapeProps.width]);

    // Next 3 useEffects is All about syncing text area with text - be careful!
    React.useEffect(() => {
      if (shapeRef.current) {
        if (isSelected) {
          const node = shapeRef.current;
          // const childNode = childRef.current;

          const { x, y } = topLeftPoint(
            node.x(),
            node.y(),
            node.width(),
            node.height(),
            getAngleInRadians(node.rotation()),
            scale
          );

          setTextareaProps({
            styles: {
              position: 'absolute',
              left: stageWidth / 2 + x + shapeProps.width / 2,
              top: stageHeight / 2 + y,
              width: shapeProps.width,
              height: Math.max(
                200,
                Math.floor((shapeProps.text.length * shapeProps.fontSize) / shapeProps.width) *
                  shapeProps.fontSize *
                  0.6
              ),
              fontSize: shapeProps.fontSize,
              textAlign: shapeProps.align,
              border: 'none',
              padding: shapeProps.padding,
              overflow: 'hidden',
              background: '#FAFAFA',
              outline: 'none',
              resize: 'none',
              lineHeight: shapeProps.lineHeight,
              letterSpacing: shapeProps.letterSpacing,
              fontFamily: shapeProps.fontFamily,
              fontStyle: shapeProps.fontStyle.includes('bold') ? 'bold' : false,
              fontStyle: shapeProps.fontStyle.includes('italic') ? 'italic' : 'normal',
              transformOrigin: 'top left',
              textAlign: 'left',
              color: shapeProps.fill,
              transform: `translateY(-2px) scale(${scale / 100}) rotateZ(${node.rotation()}deg)`,
            },
            id: shapeProps.id,
          });

          setTextareaValue(shapeProps.text);
        }
      }
    }, [
      isSelected,
      isEditMode,
      shapeRef.current,
      scale,
      shapeProps.id,
      shapeProps.fontSize,
      shapeProps.fontStyle,
      shapeProps.fontFamily,
      shapeProps.letterSpacing,
      shapeProps.lineHeight,
      shapeProps.align,
      shapeProps.width,
      shapeProps.height,
      shapeProps.text,
      shapeProps.fill,
      shapeProps.x,
      shapeProps.y,
      shapeProps.rotation,
      stageHeight,
      stageWidth,
    ]);

    // React.useEffect(() => {
    //   if (isSelected && shapeProps.id.includes('classic-number-list')) {
    //     const height = shapeProps.staticLink.rows * (shapeProps.fontSize + shapeProps.lineHeight) * .6; // .25 is UI fix - Don't remove it
    //     onChange({ ...shapeProps, height });
    //   } else {
    //     // UI fix - Don't remove it
    //     delete shapeRef.current.height;
    //   }
    // }, [
    //   isSelected,
    //   shapeProps.staticLink.rows,
    //   shapeProps.fontSize,
    //   shapeProps.padding,
    //   shapeProps.lineHeight,
    // ]);

    React.useEffect(() => {
      if (isSelected && shapeProps.id.includes('classic-number-list')) {
        trForceUpdate();
      }
    }, [
      isSelected,
      shapeProps.staticLink.rows,
      shapeProps.staticLink.cols,
      shapeProps.fontSize,
      shapeProps.padding,
      shapeProps.lineHeight,
    ]);

    React.useEffect(() => {
      // UI fix - Don't remove it
      delete shapeRef.current.height;
    }, [shapeProps]);

    React.useEffect(() => {
      setIncs(new Array(shapeProps.staticLink.cols).fill(0));
      // force showing all numbers in case of trunctation or force min width
      if (shapeProps.id.includes('classic-number-list')) {
        const incs = [];
        let totalInc = 0;
        const colWidth = shapeProps.width / shapeProps.staticLink.cols;
        let maxNumberWidth = 0;
        classicGrid.forEach(col => {
          const maxNumber = findMaxLenNumber(col);
          maxNumberWidth = calculateTextWidth({
            text: maxNumber,
            fontSize: shapeProps.fontSize,
            fontFamily: shapeProps.fontFamily,
            canvasWidth: shapeProps.width,
            minWidth: 0,
          });

          if (maxNumberWidth === colWidth) {
            incs.push(0);
          } else {
            incs.push(maxNumberWidth - colWidth);
            totalInc += maxNumberWidth - colWidth;
          }
        });
        if (totalInc > 0) {
          setForcedMinWidth(shapeProps.width + totalInc);
        } else {
          setIncs(reCalculateIncs(incs));
        }
      }
    }, [
      classicGrid,
      shapeProps.staticLink.cols,
      shapeProps.text,
      shapeProps.fontSize,
      shapeProps.fontFamily,
      shapeProps.width,
    ]);

    return (
      <>
        {shapeProps.link && isSelected && !isEditMode && (
          <LinkIndicator shapeProps={shapeProps} offset={false} />
        )}
        <Group
          onMouseDown={onSelect}
          onDblClick={() => {
            if (shapeProps.id.includes('smart-number-list')) {
              setSelectedShapeId(null); // UI fix - Don't remove it
              setTimeout(() => {
                setIsEditMode(true);
                setSelectedShapeId(shapeProps.id);
              }, 500);
            }
          }}
          onTap={onSelect}
          onDblTap={() => {
            if (shapeProps.id.includes('smart-number-list')) {
              setSelectedShapeId(null); // UI fix - Don't remove it
              setIsEditMode(true);
              setSelectedShapeId(shapeProps.id);
            }
          }}
          ref={shapeRef}
          {...shapeProps}
          draggable
          onDragStart={onDragStart}
          onDragMove={onDragMove}
          onDragEnd={e => onDragEnd(shapeProps, e)}
          onTransformEnd={e => {
            // transformer is changing scale of the node
            // and NOT its width or height- shapeRef.current.fontSize()/scale/100/2 - shapeRef.current.padding()/scale/100
            // but in the store we have only width and height
            // to match the data better we will re- shapeRef.current.fontSize()/scale/100/2 - shapeRef.current.padding()/scale/100set scale on transform end
            const node = shapeRef.current;
            const scaleX = node.scaleX();

            // we will reset it back
            node.scaleX(1);
            onChange(
              {
                ...shapeProps,
                x: node.x(),
                y: node.y(), // set minimal value
                width: node.width() * scaleX,
                scaleX: 1,
                scaleY: 1,
                rotation: node.rotation(),
              },
              true
            );
          }}
        >
          <Rect // this to constrain width
            x={0}
            y={0}
            width={classNumberlistWidth || shapeProps.width}
            height={shapeProps.height || 10}
            stroke={'rgba(0,0,0,0)'}
            fill={'rgba(0,0,0,0)'}
          />
          {articleGrid.map(number => (
            <>
              <Text
                wrap={'none'}
                key={'x' + number.x + ' y' + number.y}
                {...shapeProps}
                x={number.x}
                y={number.y}
                lineHeight={0}
                width={number.width}
                offsetY={-shapeProps.fontSize * 0.5}
                text={number.text.trim()}
                rotation={0}
                verticalAlign={'top'}
                fill={number.fill || shapeProps.fill}
                fontStyle={number.fontStyle}
                textDecoration={number.textDecoration}
              />
              <Rect
                x={number.x}
                y={number.y}
                cornerRadius={shapeProps.fontSize}
                offsetY={shapeProps.fontSize / 2 - 6}
                width={number.width}
                height={shapeProps.fontSize + 10}
                stroke={'transparent'}
                fill={'rgba(0,0,0,0)'}
                strokeWidth={1}
              />
            </>
          ))}
          {smartGrid.map(number => (
            <>
              <Text
                wrap={'none'}
                key={'x' + number.x + ' y' + number.y}
                {...shapeProps}
                x={number.x}
                y={number.y}
                lineHeight={0}
                width={number.width}
                offsetY={-shapeProps.fontSize * 0.5}
                text={number.text.trim()}
                rotation={0}
                verticalAlign={'top'}
                padding={5}
              />
              <Rect
                x={number.x}
                y={number.y}
                cornerRadius={shapeProps.fontSize}
                offsetY={shapeProps.fontSize / 2 - 6}
                width={number.width}
                height={shapeProps.fontSize + 10}
                stroke={'#000000'}
                fill={'rgba(0,0,0,0)'}
                strokeWidth={1}
              />
            </>
          ))}
          {classicGrid.map((numbersChunk, i) =>
            numbersChunk.map((number, j) => {
              let x = 0;
              let y = 0;

              if (!shapeProps.staticLink.isAcrossDown) {
                // consider filling the grid from left to right
                x =
                  j * (classNumberlistWidth / shapeProps.staticLink.cols) +
                  (j === 0 ? 0 : sumToIndex(incs, j));
                y = i * shapeProps.fontSize + i * shapeProps.lineHeight;
              } else {
                // consider filling the grid from top to bottom
                x =
                  i * (classNumberlistWidth / shapeProps.staticLink.cols) +
                  (i === 0 ? 0 : sumToIndex(incs, i));
                y = j * shapeProps.fontSize + j * shapeProps.lineHeight;
              }

              let markedNumberProps = {};
              if (
                shapeProps.staticLink.markedNumbers &&
                shapeProps.staticLink.markedNumbers.includes(number.trim())
              ) {
                markedNumberProps.textDecoration =
                  shapeProps.staticLink.markedNumbersStyles.textDecoration;
                markedNumberProps.fontStyle = shapeProps.staticLink.markedNumbersStyles.fontStyle;
                markedNumberProps.fill = shapeProps.staticLink.markedNumbersStyles.fill;
              }

              return (
                <Text
                  ellipsis
                  wrap={'none'}
                  key={'col' + j + '-' + 'row' + i}
                  {...shapeProps}
                  {...markedNumberProps}
                  x={x}
                  y={y}
                  width={classNumberlistWidth / shapeProps.staticLink.cols + incs[i]}
                  text={number.trim()}
                  lineHeight={1}
                  rotation={0}
                />
              );
            })
          )}
        </Group>
      </>
    );
  }
);

export default TNumberList;
