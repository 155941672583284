import axios from 'axios';
import urls from 'settings/urls.js';
import { login, logout } from 'store/auth';

// Authorization
export const getAuthToken =
  ({ email, password, keep_login }) =>
  async dispatch => {
    try {
      const requestOptions = {
        method: 'POST',
        url: urls.authToken,
        data: {
          email: email,
          password: password,
        },
      };

      const res = await axios(requestOptions);
      const data = res && res.data ? res.data : {};

      axios.interceptors.request.use(config => {
        config.headers.Authorization = 'Bearer ' + data.access;
        return config;
      });

      dispatch(
        login({
          user: data.user,
          access_token: data.access,
          refresh_token: data.refresh,
          expires_at: data.expires_at,
          keep_login: keep_login,
        })
      );
    } catch (error) {
      dispatch(
        login({
          error: 'Email or password is incorrect!',
        })
      );
    }
  };

export const refreshAuthToken = refresh_token => async dispatch => {
  try {
    const requestOptions = {
      method: 'POST',
      url: urls.refreshToken,
      data: {
        refresh: refresh_token,
      },
    };

    const res = await axios(requestOptions);
    const data = res && res.data ? res.data : {};

    // TODO: TEMP FIX
    localStorage.setItem('access_token', data.access);
    localStorage.setItem('expires_at', data.expires_at);
    window.location.reload();

    // dispatch(
    //   login({
    //     access_token: data.access,
    //     expires_at: data.expires_at,
    //   })
    // );
  } catch (error) {
    dispatch(logout());
  }
};

export const asyncLogout = () => async dispatch => {
  // Note: you can call logout API here if needed
  // Temp fix
  localStorage.clear();
  window.location.reload();
  // return dispatch(logout());
};
