import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from 'state/hooks/global';
import { useStudioContext } from 'hooks/studio';
import { useIsShiftDown, useIsAltDown } from 'hooks/util';
import { editManuscript, deleteManuscript } from 'api/studio/manuscripts';
import ManuscriptForm from 'components/forms/ManuscriptForm';
import Pages from './Pages';
import Confirm from 'components/common/Confirm';
import StageTopControls from './StageTopControls';
import './ManuscriptsStage.scss';
import Spinner from 'components/common/Spinner';

import { jsPDF } from 'jspdf';
import { PUZZLE_CONTENT_TYPES } from 'settings/puzzels';
import { useSelector } from 'react-redux';
import { sendPdfCreationNotification } from 'api/common';
import { updateShapeByAttr } from 'helpers/manuscripts';
import _, { set } from 'lodash';

import { convertNumber } from 'helpers/numbers';
import { createTableOfContentsFromSegments } from 'helpers/manuscripts';
import { findFirstPageIndex } from 'helpers/studio';

const isEven = n => {
  return !(n % 2) && n !== 0;
};

const DUMMY_TRIVI_LISTS_FOR_TESTING = [
  {
    id: 1,
    title: 'Trivia List 001',
    items: Array(10)
      .fill()
      .map((_, i) => ({
        q: `This is trivia #${i + 1} - question form Trivia List 001`,
        a: `This is trivia #${i + 1} - answer form Trivia List 001`,
      })),
  },
  {
    id: 2,
    title: 'Trivia List 002',
    items: Array(155)
      .fill()
      .map((_, i) => ({
        q: `This is trivia #${i + 1} - question form Trivia List 002`,
        a: `This is trivia #${i + 1} - answer form Trivia List 002`,
      })),
  },
  {
    id: 3,
    title: 'Trivia List 003',
    items: Array(120)
      .fill()
      .map((_, i) => ({
        q: `This is trivia #${i + 1} - question form Trivia List 003`,
        a: `This is trivia #${i + 1} - answer form Trivia List 003`,
      })),
  },
  {
    id: 4,
    title: 'Trivia List 004',
    items: Array(7)
      .fill()
      .map((_, i) => ({
        q: `This is trivia #${i + 1} - question form Trivia List 004`,
        a: `This is trivia #${i + 1} - answer form Trivia List 004`,
      })),
  },
];

function ManuscriptsStage({ manuscript, show, toRemove, setToRemove, allowedTags }) {
  const { user } = useSelector(state => state.auth);
  const canChangeManuscripts = user?.resources_permissions?.manuscript?.change_manuscript;
  const routerHistory = useHistory();
  const { isShiftDown } = useIsShiftDown();
  const { isAltDown } = useIsAltDown();
  const { setMsgs, pageSizes } = useGlobalContext();
  const { manuscripts, setManuscripts } = useStudioContext();
  const [isOpen, setIsOpen] = useState(false);
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [percentage, setPercentage] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingPDF, setIsSavingPDF] = useState(false);
  const [selectedPages, setSelectedPages] = useState([]);
  const [form, setForm] = useState('database');
  const [gFormData, setGFormData] = useState({
    heading: '',
    subheading: '',
    isbn: '',
    authors: '',
    text_1: '',
    text_2: '',
    text_3: '',
    text_4: '',
    text_5: '',
    text_6: '',
  });

  const [refs, setRefs] = useState();
  const [progressPDF, setProgressPDF] = useState(0);

  // pages selector
  const [pageSelectorMode, setPageSelectorMode] = useState('single');
  const [pageSelectorHandSide, setPageSelectorHandSide] = useState(0);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(1);
  const [from1, setFrom1] = useState(1);
  const [to1, setTo1] = useState(1);
  const [lastSelected, setLastSelected] = useState(1);
  const [segments, setSegments] = useState([]);

  const [trivias, setTrivias] = useState([]);

  const [selectedTrivias, setSelectedTrivias] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);

  const [isAllowedToRenderManuscript, setIsAllowedToRenderManuscript] = useState(false);

  const [storiesToBeInjectedNumber, setStoriesToBeInjectedNumber] = useState(0);
  const [storiesSplitedIntoPages, setStoriesSplitedIntoPages] = useState([]);

  useEffect(() => {
    const storiesToBeInjected = manuscript.links_map.reduce((acc, group) => {
      if (group.links_arr.some(l => l && l.role && l.role.includes('story'))) {
        const indecies = group.indecies;
        const linksArr = group.links_arr;
        const storiesToBeInjectedFromThisGroup =
          linksArr.filter(l => l.role.includes('story-word-list-text')).length * indecies.length;
        acc += storiesToBeInjectedFromThisGroup;
      }
      return acc;
    }, 0);

    setStoriesToBeInjectedNumber(storiesToBeInjected);
  }, [manuscript]);

  const createStoryPages = ({ template, pagesGrids }) => {
    // create array of pages with the same template
    const pages = [];
    for (let i = 0; i < pagesGrids.length; i++) {
      const pageGrid = pagesGrids[i];

      // deep copy template
      const newPage = _.cloneDeep(template);
      if (newPage) {
        newPage.stage_json.children[0].children = newPage.stage_json.children[0].children.map(
          shape => {
            let newShape = { ...shape };
            if (shape.attrs.staticLink && shape.attrs.staticLink.role === 'story-word-list-text') {
              newShape = updateShapeByAttr({
                shape,
                newValue: pageGrid, // Use the block-scoped variable
                attr: 'text',
              });
            }
            return newShape;
          }
        );

        if (i !== 0) {
          newPage.wasNotInTemplate = true;
        }
      }
      pages.push(newPage);
    }

    return pages;
  };

  useEffect(() => {
    if (
      storiesToBeInjectedNumber > 0 &&
      storiesSplitedIntoPages.length === storiesToBeInjectedNumber
    ) {
      const pgs = [...pages];

      storiesSplitedIntoPages.forEach((story, _) => {
        const { index, pagesGrids } = story;
        const template = pages[index];
        const newPgs = createStoryPages({ template, pagesGrids });
        pgs.splice(index, 1, [...newPgs]);
      });
      const pgsFlat = pgs.reduce((acc, val) => acc.concat(val), []);
      setPages(pgsFlat);
      setStoriesSplitedIntoPages([]);
    }
  }, [storiesSplitedIntoPages, storiesToBeInjectedNumber]);

  useEffect(() => {
    runCounters();
  }, [pages.length]);

  useEffect(() => {
    if (manuscript && manuscript.segments) {
      setSegments(manuscript.segments);
    }
  }, [manuscript && manuscript.segments]);

  useEffect(() => {
    setPages(manuscript.data);
    setGFormData({
      heading: manuscript.heading || '',
      subheading: manuscript.subheading || '',
      isbn: manuscript.isbn || '',
      authors: manuscript.authors || '',
      text_1: manuscript.text_1 || '',
      text_2: manuscript.text_2 || '',
      text_3: manuscript.text_3 || '',
      text_4: manuscript.text_4 || '',
      text_5: manuscript.text_5 || '',
      text_6: manuscript.text_6 || '',
    });
    console.log('manuscript.content_types', manuscript.content_types);
    const contentTypes = manuscript.content_types
      .filter(type => PUZZLE_CONTENT_TYPES.includes(type))
      .map(type =>
        type.includes('Coloring Book Image') || type.includes('story-word-list-text')
          ? type
          : type.split('- ')[type.includes('grid') ? 0 : 1].toLowerCase().replace(' ', '-')
      );

    setContentTypes([...new Set(contentTypes)]);
  }, [manuscript]);

  useEffect(() => {
    setSelectedTrivias(trivias.filter(t => manuscript.selected_trivia_list_ids.includes(t.id)));
  }, [manuscript, trivias]);

  const handleSave = () => {
    setIsSaving(true);
    editManuscript(
      {
        is_published: true,
        data: pages,
        ...gFormData,
        selected_trivia_list_ids: selectedTrivias.map(t => t.id),
      },
      manuscript.id
    ).then(res => {
      if (res.success) {
        setIsSaving(false);
        // const list = [...manuscripts];
        // const index = list.findIndex(el => el.id === res.manuscript.id);
        // list[index] = res.manuscript;
        // setManuscripts(list);

        setMsgs([{ type: 'success', content: 'Manuscript updated successfully!' }]);
      }
    });
  };

  const handleStageControls = form => setForm(form);

  const removeManuscriptFromStage = () => {
    const list = [...manuscripts];
    const index = list.findIndex(item => item.id === manuscript.id);
    if (index > -1) list.splice(index, 1);
    setManuscripts(list);
    if (!list.length) routerHistory.push('/home/manuscripts');
  };

  const handleCancel = () => {
    setIsOpen(true);
  };

  const handleCancelAfterConfirm = c => {
    if (c) {
      handleSave();
      removeManuscriptFromStage(manuscript);
    } else {
      if (!manuscript.is_published) {
        deleteManuscript(manuscript.id).then(res => {
          if (res.success) {
            removeManuscriptFromStage(manuscript);
          }
        });
      } else {
        removeManuscriptFromStage(manuscript);
      }
    }
  };

  const handlePageClick = pageNumber => {
    if (
      (isEven(pageNumber) && pageSelectorHandSide === 2) ||
      (!isEven(pageNumber) && pageSelectorHandSide === 1)
    ) {
      return;
    }

    if (isAltDown && selectedPages.length > 0) {
      setPageSelectorMode('2-ranges');
      if (from1 === to && pageNumber >= selectedPages[selectedPages.length - 1]) {
        setFrom1(pageNumber);
      } else if (from1 > to && pageNumber >= selectedPages[selectedPages.length - 1]) {
        setTo1(pageNumber);
      }
    } else if (isShiftDown && selectedPages.length > 0) {
      setPageSelectorMode('range');
      if (pageNumber < selectedPages[0]) {
        setTo(selectedPages[0]);
        setFrom(pageNumber);
      } else {
        setFrom(selectedPages[0]);
        setTo(pageNumber);
      }
    } else {
      if (pageSelectorMode !== 'single') setPageSelectorMode('single');
      setFrom(pageNumber);
      setTo(pageNumber);
      setFrom1(pageNumber);
      setTo1(pageNumber);
    }
  };

  useEffect(() => {
    if (from > to && pageSelectorMode === 'range') return;
    setLastSelected(from);
  }, [from]);

  useEffect(() => {
    if (from > to && pageSelectorMode === 'range') return;
    setLastSelected(to);
  }, [to]);

  useEffect(() => {
    if (from > to && pageSelectorMode === 'range') return;

    if (
      // from > to ||
      pageSelectorMode === 'single'
    ) {
      setTo(from);
    }

    if (from1 < to && !pageSelectorMode.includes('(2-ranges)')) setFrom1(to);
    if (to1 < from1 && !pageSelectorMode.includes('(2-ranges)')) setTo1(from1);

    let start = from < 0 ? 1 : from;
    let end = to > pages.length ? pages.length : to;

    let start1 = from1 < end ? +end : +from1;
    let end1 = to1 > pages.length ? +pages.length : +to1;

    // // Left
    // if (pageSelectorHandSide === 1 && !isEven(start)) {
    //   pageSelectorHandSide === 2 && isEven(start);
    //   if (start + 1 < pages.length) setFrom(start + 1);
    //   else setFrom(start - 1);
    // }
    // // Right
    // else if (pageSelectorHandSide === 2 && isEven(start)) {
    //   if (start - 1 > 0) setFrom(start - 1);
    //   else setFrom(start + 1);
    // }

    const length = end - start + 1;
    const length1 = end1 - start1 + 1;

    const filterFun = page => {
      if (pageSelectorHandSide === 1) return !(page % 2);
      else if (pageSelectorHandSide === 2) return !!(page % 2);
      else return true;
    };

    if (pageSelectorMode.includes('2-ranges')) {
      setSelectedPages([
        ...Array.from({ length }, (v, k) => k + start).filter(filterFun),
        ...Array.from({ length: length1 }, (v, k) => k + start1).filter(filterFun),
      ]);
    } else if (pageSelectorMode === 'range') {
      setSelectedPages(Array.from({ length }, (v, k) => k + start).filter(filterFun));
    } else if (pageSelectorMode === 'single') setSelectedPages([start]);
  }, [pages.length, pageSelectorMode, pageSelectorHandSide, from, to, from1, to1]);

  async function addImageProcess(ref, dpi) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.src = ref.current.toDataURL({ mimeType: 'image/jpeg', pixelRatio: dpi / 72 });
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  }

  const generatePDF = async dpi => {
    const startTime = new Date().getTime();
    await sendPdfCreationNotification({ kind: 'studio_manuscript', id: manuscript.id });
    await setIsSavingPDF(true);
    const preview = manuscript.width > manuscript.height ? 'l' : 'p';
    const bleeding = (manuscript &&
      manuscript.data &&
      manuscript.data[0] &&
      manuscript.data[0].bleeding) || {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    };

    var pdf = new jsPDF(
      preview,
      'in',
      [
        manuscript.width + bleeding.left / 96 + bleeding.right / 96,
        manuscript.height + bleeding.top / 96 + bleeding.bottom / 96,
      ],
      false,
      true
    );
    try {
      for (const { index, ref, shapesLayerRef } of refs) {
        // if (index > 1) break; // for testing to avoid time consumption

        setProgressPDF(parseInt(((index + 1) / refs.length) * 100));

        if (ref.current && ref.current.toDataURL) {
          const image = await addImageProcess(shapesLayerRef, dpi);
          pdf.addImage(
            image,
            'JPEG',
            0,
            0,
            ref.current.width() / 96,
            ref.current.height() / 96,
            undefined,
            'FAST' // 'SLOW' will reduce the size but will take longer
          );
        }
        if (index < refs.length - 1) pdf.addPage(0, 0, manuscript.width, manuscript.height);
      }
      pdf.setProperties({
        title: manuscript.title,
        author: 'Funcrane ©' + new Date().getFullYear(),
      });
      pdf.save(`${manuscript.title} - (${dpi} DPI).pdf`);
    } catch (err) {
      console.log(err);
    } finally {
      setIsSavingPDF(false);
      setProgressPDF(0);
    }

    const endTime = new Date().getTime();
    const timeDiff = endTime - startTime;
    console.log('timeDiff:', timeDiff, 'ms');
  };

  useEffect(() => {
    if (toRemove === manuscript.id) {
      handleCancel();
      setToRemove(null);
    }
  }, [toRemove]);

  const pageSize = (() => {
    const p = pageSizes.find(size => size.id === manuscript.page_size);
    const bleeding =
      (manuscript.data &&
        manuscript.data.length &&
        manuscript.data[0] &&
        manuscript.data[0].bleeding) ||
      0;
    const haveBleeding =
      bleeding && bleeding.top + bleeding.right + bleeding.bottom + bleeding.left;
    return p
      ? `${p.width.toFixed(2)}"x${p.height.toFixed(2)}"` +
          ' ' +
          (haveBleeding
            ? `(${(p.width + bleeding.right + bleeding.left).toFixed(2)}"x${(
                p.height +
                bleeding.top +
                bleeding.bottom
              ).toFixed(2)}")`
            : '')
      : '';
  })();

  const handleStoryPagesCreation = ({ grid, totalHeight, textHeight, pageNumber }) => {
    console.log({ grid, totalHeight, textHeight, pageNumber });
    const chunkedGrid = [];
    const chunkSize = textHeight;
    const totalChunks = Math.ceil(totalHeight / chunkSize);
    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = start + chunkSize;
      const chunk = grid.filter(word => word.y >= start && word.y < end);
      const deltaY = chunk[0].y || 0;
      const repositionedChunk = chunk.map(word => ({ ...word, y: word.y - deltaY }));
      chunkedGrid.push(repositionedChunk);
    }

    setStoriesSplitedIntoPages(prev => [
      ...prev,
      {
        index: pageNumber - 1,
        pagesGrids: chunkedGrid,
      },
    ]);
  };

  const runCounters = () => {
    const counters = {
      'Page Number': 0,
      'Problem Number': 0,
      'Solution Number': 0,
      'Chapter Number': 0,
      'Custom Number': 0,
    };

    const newPages = pages.map(page => {
      if (!page) return null;
      const newPage = { ...page };
      if (page.stage_json) {
        const shapesArr = [...page.stage_json.children[0].children];
        const newShapes = [...shapesArr].map(shape => {
          let newShape = { ...shape };
          if (shape.attrs.link && shape.attrs.link.type === 'Count') {
            counters[shape.attrs.link.role] = counters[shape.attrs.link.role] + 1;
            const count = counters[shape.attrs.link.role];
            newShape = updateShapeByAttr({
              shape,
              newValue: convertNumber(count, shape.attrs.link.format[0]),
              attr: 'text',
            });
          }

          // Update Table of Contents
          if (shape.attrs.staticLink && shape.attrs.staticLink.tableOfContents) {
            const firstPageIndex = findFirstPageIndex(manuscript.links_map);
            newShape = updateShapeByAttr({
              shape,
              newValue: {
                type: 'Text',
                role: shape.attrs.staticLink.type,
                tableOfContents: createTableOfContentsFromSegments(segments, firstPageIndex),
              },
              attr: 'staticLink',
            });
          }
          return newShape;
        });
        newPage.stage_json.children[0].children = newShapes;
      }
      return newPage;
    });

    // if (!manuscript.links_map.length)
    //   setWordSearchFormData({ ...wordSearchFormData, npuzzles: counters['Problem Number'] });

    // if (counters['Problem Number'] !== counters['Puzzle Number']) {
    //   setMsgs([
    //     {
    //       type: 'warning',
    //       content:
    //         "Number of problems and solutions don't match!" +
    //         counters['Problem Number'] +
    //         ',' +
    //         counters['Solution Number'],
    //     },
    //   ]);
    // }
    setPages(newPages);
    setIsAllowedToRenderManuscript(true);
  };

  return (
    <div className={`${show ? 'd-flex' : 'd-none'} align-items-stretch`}>
      <div className="format-stage-area w-100">
        {/* <p className="m-5" style={{maxHeight: 800, overflow: 'scroll'}}>
          {JSON.stringify(manuscript, null, 4)}
        </p> */}
        <StageTopControls
          cb={handleStageControls}
          pageSize={pageSize}
          actions={
            canChangeManuscripts
              ? [
                  {
                    name: 'database',
                  },
                  {
                    name: 'general',
                  },
                  {
                    name: 'trivia',
                    hidden: !contentTypes.includes('trivia'),
                  },
                  {
                    name: 'word-search',
                    hidden: !contentTypes.includes('word-search-grid-'), // leave the dash at the end for now - to refactor later
                  },
                  {
                    name: 'number-search',
                    hidden: !contentTypes.includes('number-search-grid-'),
                  },
                  {
                    name: 'sudoku',
                    hidden: !contentTypes.includes('sudoku-grid-'),
                  },
                  // {
                  //   name: 'cross-words',
                  //   hidden: !contentTypes.includes('cross-words'),
                  // },
                  {
                    name: 'coloring-books',
                    hidden: !contentTypes.includes('Coloring Book Image'), // content is static
                  },
                  {
                    name: 'story',
                    hidden: !contentTypes.includes('story-word-list-text'),
                    icon: 'story-manuscirpt',
                  },
                ]
              : []
          }
          activeAction={form}
        />
        {isAllowedToRenderManuscript && (
          <Pages
            loading={loading}
            setLoading={setLoading}
            percentage={percentage}
            setPercentage={setPercentage}
            pages={pages}
            pageSizes={pageSizes}
            template={manuscript}
            handlePageClick={handlePageClick}
            selectedPages={selectedPages}
            show={show}
            onClick={() => setSelectedPages([])}
            refs={refs}
            setRefs={setRefs}
            lastSelected={lastSelected}
            handleStoryPagesCreation={handleStoryPagesCreation}
          />
        )}
      </div>
      {canChangeManuscripts && (
        <div className="form form__studio noselect">
          <ManuscriptForm
            pageSelectorMode={pageSelectorMode}
            setPageSelectorMode={setPageSelectorMode}
            pageSelectorHandSide={pageSelectorHandSide}
            setPageSelectorHandSide={setPageSelectorHandSide}
            from={from}
            setFrom={setFrom}
            to={to}
            setTo={setTo}
            from1={from1}
            setFrom1={setFrom1}
            to1={to1}
            setTo1={setTo1}
            segments={segments}
            setSegments={setSegments}
            manuscript={manuscript}
            form={form}
            setForm={setForm}
            pages={pages}
            setPages={setPages}
            selectedPages={selectedPages}
            setSelectedPages={setSelectedPages}
            isLoading={loading || isSaving || isSavingPDF}
            setIsLoading={setLoading}
            gFormData={gFormData}
            setGFormData={setGFormData}
            trivias={trivias}
            setTrivias={setTrivias}
            selectedTrivias={selectedTrivias}
            setSelectedTrivias={setSelectedTrivias}
            isAllowedToRenderManuscript={isAllowedToRenderManuscript}
            setIsAllowedToRenderManuscript={setIsAllowedToRenderManuscript}
            runCounters={runCounters}
            requiredStoriesNumber={storiesToBeInjectedNumber}
          />
          {/* <div className="px-4">
          {progressPDF ? `Progress: ${parseInt(progressPDF)}%` : ''}
        </div> */}
          <div className="d-flex justify-content-center lower-buttons">
            <div className="d-flex w-100 px-4">
              <button
                className={`btn-white-forms mx-2 justify-content-center align-items-center ${
                  user.resources_permissions && user.resources_permissions.manuscript.can_create_pdf
                    ? 'd-flex'
                    : 'd-none'
                }`}
                onClick={() => generatePDF(300)}
                disabled={loading || isSaving || isSavingPDF}
              >
                Create PDF
              </button>

              <button
                className="btn-blue-forms mx-2"
                onClick={handleSave}
                disabled={loading || isSaving || isSavingPDF}
              >
                Save &nbsp;{isSaving && <Spinner type={'local'} />}
              </button>
            </div>
          </div>
        </div>
      )}
      <Confirm
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={'Save Changes!'}
        msg={'Do you want to save changes?'}
        confirmText={'Save'}
        cancelText={"Don't Save"}
        handleConfirm={() => handleCancelAfterConfirm(true)}
        handleCancel={() => handleCancelAfterConfirm(false)}
      />

      {progressPDF ? (
        <Confirm
          isOpen={progressPDF}
          setIsOpen={setIsOpen}
          title={'Saving PDF...'}
          msg={progressPDF + '% - ' + 'Please wait!'}
          cancelText={'Cancel'}
          progress={progressPDF}
          showClose={false}
          showProgress={true}
          showCancel={false}
          showConfirm={false}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default ManuscriptsStage;
