import React, { useState, useRef, useEffect } from 'react';
import './Tags.scss';
import { useOnClickOutside } from 'hooks/util';
import IncludeSvg from 'components/common/IncludeSvg';

export const isSelected = ({tag, tagsArr}) => {
  const index = tagsArr.findIndex(t => t.id === tag.id);
  return index > -1 ? true : false;
};

export const toggleSelect = ({tag, tagsArr, setTagsArr}) => {
  const selected = isSelected({tag, tagsArr});

  if (!selected) setTagsArr([...tagsArr, tag]);
  else {
    const i = tagsArr.findIndex(t => t.id === tag.id);
    const list = [...tagsArr];
    list.splice(i, 1);
    setTagsArr(list);
  }
};

export const removeDuplicates = (items, attr) => {
  return items.filter(
    (item, index, self) =>
      index === self.findIndex(i => i[attr] === item[attr])
  );
};

function Tags({
  title,
  allowedTags,
  tagsArr,
  setTagsArr,
  classNameA,
  classNameB,
  inputClassName,
  height,
  inputPlaceholder,
  showHead = true,
  showCount = true,
  readOnly = false,
  scrollDirection = 'y',
  searchable = false,
  showLabelOnly = false,
}) {
  const [tagInput, setTagInput] = useState('');
  const [showList, setShowList] = useState(false);
  const [showSearchList, setShowSearchList] = useState(false);
  const [leftChangesCount, setLeftChangesCount] = useState(0);
  const [showLeftBtn, setShowLeftBtn] = useState(false);
  const [showRightBtn, setShowRightBtn] = useState(false);

  const listRef = useRef(null);
  const listSearchRef = useRef(null);

  const selectedTagsList = useRef(null);
  const searchTags = () =>
    allowedTags.filter(
      tag =>
        tag.name.toLowerCase().includes(tagInput.toLowerCase().trim()) &&
        !tagsArr.includes(tag)
    );

  useOnClickOutside(listRef, () => {
    setShowList(false);
  });
  useOnClickOutside(listSearchRef, () => {
    setShowSearchList(false);
  });

  const toggleShowList = () => {
    setShowList(!showList);
  };

  const scrollLeft = () => {
    selectedTagsList.current.scrollLeft -= 120;
    setLeftChangesCount(leftChangesCount + 1);
  };

  const scrollRight = () => {
    selectedTagsList.current.scrollLeft += 120;
    setLeftChangesCount(leftChangesCount + 1);
  };

  useEffect(() => {
    const el = selectedTagsList.current;
    // hide both buttons and show if it has function
    setShowLeftBtn(false);
    setShowRightBtn(false);

    // 10px to avoid double click when scroll left less than 10 px
    if (el.scrollLeft > 10) setShowLeftBtn(true);
    if (el.scrollWidth - el.clientWidth - el.scrollLeft > 10)
      setShowRightBtn(true);
  }, [leftChangesCount, tagsArr.length]);

  return (
    <div className={`tags ${classNameA}`}>
      <div
        className="d-flex justify-content-between"
        style={{ position: 'absolute' }}
      ></div>
      {showLabelOnly && <label className="mb-3">{title}</label>}
      {showHead && !showLabelOnly && (
        <div className={'tags-head'}>
          <label>{title}</label>
          <div className="allowed-tags">
            <div
              className={`allowed-tags-list top scrollable-y shadow-sm ${
                showList ? '' : 'd-none'
              }`}
              ref={listRef}
            >
              {!allowedTags.length && (
                <span className="empty-state">Tags list is empty!</span>
              )}
              {allowedTags.map((tag, index) => (
                <span
                  key={index}
                  onClick={() => toggleSelect({tag, tagsArr, setTagsArr})}
                  className={`tag no-x ${isSelected({tag, tagsArr}) ? 'selected' : ''}`}
                >
                  {tag.name}
                </span>
              ))}
            </div>
            {/* <span
              calssName="see-all"
              style={{
                cursor: readOnly ? 'default' : 'pointer',
                opacity: readOnly ? 0.5 : 1,
              }}
              onClick={!readOnly ? toggleShowList : () => {}}
            >
              See all tags
            </span> */}
          </div>
        </div>
      )}
      <div className={`tags-body ${classNameB}`}>
        <input
          disabled={readOnly}
          placeholder={inputPlaceholder}
          className={`tag-input ${inputClassName}`}
          type="text"
          value={tagInput}
          onChange={e => {
            setTagInput(e.target.value);
          }}
          onKeyUp={e => {
            if (searchable) setShowSearchList(true);
            // else if (searchable) setShowSearchList(false);

            if (e.key === 'Enter' && tagInput.trim()) {
              const index = allowedTags.findIndex(
                allowedTag =>
                  allowedTag.name.toLowerCase() ===
                  tagInput.trim().toLowerCase()
              );
              if (index > -1) {
                setTagsArr(
                  removeDuplicates([...tagsArr, allowedTags[index]], 'id')
                );
                if (searchable) setShowSearchList(false);
                setTagInput('');
              }
            }
          }}
          onFocus={() => setShowSearchList(true)}
          onInput={() => setShowSearchList(true)}

          // onBlur={() => setShowSearchList(false)}
        />
        <div className="allowed-tags">
          <div
            className={`allowed-tags-list top scrollable-y shadow-sm ${
              showSearchList ? '' : 'd-none'
            }`}
            ref={listSearchRef}
          >
            {!searchTags().length && (
              <span className="empty-state">No tags.</span>
            )}
            {searchTags().map((tag, index) => (
              <span
                key={index}
                onClick={() => {
                  toggleSelect({tag, tagsArr, setTagsArr});
                  setTagInput('');
                  setShowSearchList(false);
                }}
                className={`tag no-x ${isSelected({tag, tagsArr}) ? 'selected' : ''}`}
              >
                {tag.name}
              </span>
            ))}
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <div
            className={`tags-container scrollable-${scrollDirection}`}
            style={{
              marginTop: 10,
              height,
              overflowY: 'scroll',
              scrollBehavior: 'smooth',
            }}
            ref={selectedTagsList}
          >
            {[...tagsArr].map((tag, index) => {
              return (
                <span key={index} className="tag">
                  <span className="name">{tag.name}</span>{' '}
                  <span
                    style={{
                      cursor: readOnly ? 'default' : 'pointer',
                      opacity: readOnly ? 0.5 : 1,
                    }}
                    className="btn close-btn"
                    onClick={() => {
                      if (!readOnly) {
                        const newTags = [...tagsArr];
                        newTags.splice(index, 1);
                        setTagsArr(newTags);
                      }
                    }}
                  >
                    x
                  </span>
                </span>
              );
            })}
          </div>

          <span
            onClick={scrollLeft}
            className={`${showLeftBtn ? 'arrow-wrapper left' : 'd-none'}`}
          >
            <IncludeSvg name="arrow-left" />
          </span>

          <span
            onClick={scrollRight}
            className={`${showRightBtn ? 'arrow-wrapper right' : 'd-none'}`}
          >
            <IncludeSvg name="arrow-right" />
          </span>
        </div>
      </div>
      {(showCount === undefined || showCount) && (
        <div className="tags-counter">
          {tagsArr.length} tag{tagsArr.length === 1 ? '' : 's'}
        </div>
      )}
    </div>
  );
}

export default Tags;
