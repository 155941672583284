import axios from 'axios';
import urls from 'settings/urls.js';

// Templates
export async function getTemplates(category_id, sort, tags, offset) {
  const requestOptions = {
    method: 'GET',
    url: urls.templates(category_id, sort, tags, offset),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function getOneTemplate(id) {
  const requestOptions = {
    method: 'GET',
    url: urls.oneTemplate(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function addTemplate(data) {
  const requestOptions = {
    method: 'POST',
    url: urls.newTemplate,
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function editTemplate(data, id) {
  const requestOptions = {
    method: 'PATCH',
    url: urls.editTemplate(id),
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function deleteTemplate(id) {
  const requestOptions = {
    method: 'DELETE',
    url: urls.deleteTemplate(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}
