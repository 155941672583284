import 'react-slide-out/lib/index.css';

import React, { useEffect, useState } from 'react';

import CoverSpecForm from 'components/forms/CoverSpecForm';
import Main from 'layouts/Main';
import ScrollableWindow from 'layouts/ScrollableWindow';
import SearchControls from 'components/common/SearchControls';
import { default as SlideModal } from 'react-slide-out';
import Slider from 'components/common/Slider';
import TopControls from 'components/common/TopControls';
import { getPageSizes } from 'api/studio/formats';
import { getPaperThicknessList } from 'api/studio/covers';
import { getTags } from 'api/common';
import { useStudioContext } from 'hooks/studio';
import useTrigger from 'hooks/useTrigger';

export default function Covers() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showSlideModal, setShowSlideModal] = useState(false);
  const [categoriesSortBy, setCategoriesSrotBy] = useState('most_popular');
  const [coversSortBy, setCoversSortBy] = useState('most_popular');
  const [allowedTags, setAllowedTags] = useState([]);
  const [tagsArr, setTagsArr] = useState([]);
  const [tags, setTags] = useState(''); // formatted String
  const [pageSizes, setPageSizes] = useState([]);
  const [paperThicknessList, setPaperThicknessList] = useState([]);

  const [categoryId, setCategoryId] = useState(null);
  const [newItemFromSlideModal, setNewItemFromSlideModal] = useState(null);
  const { covers, setCovers } = useStudioContext();

  const addCoverToWorkspace = cover => {
    const list = [...covers];
    list.push(cover);
    setCovers(list);
  };

  const [shouldLoadMore, triggerLoadMore] = useTrigger();

  useEffect(() => {
    getTags({ kind: 'studio_covers', sort: 'alpha' }).then(res => {
      if (res.success) {
        setAllowedTags(res.tags);
      } else {
        console.log(res.errors);
      }
    });
  }, []);

  useEffect(() => {
    let str = '';
    tagsArr.forEach(tag => {
      const index = allowedTags.findIndex(
        allowedTag => allowedTag.name.toLowerCase() === tag.name.toLowerCase()
      );
      if (index > -1) {
        str += `&tag=${allowedTags[index].id}`;
      }
    });
    setTags(str);
  }, [tagsArr]);

  useEffect(() => {
    getPageSizes(0, 10000).then(res => {
      // page sizes can't be more than 10000!
      if (res.success) {
        setPageSizes(res.page_sizes);
      } else {
        console.log(res.errors);
      }
    });
  }, []);

  useEffect(() => {
    getPaperThicknessList(0, 10000).then(res => {
      // page sizes can't be more than 10000!
      if (res.success) {
        setPaperThicknessList(res.paper_thickness_list);
      } else {
        console.log(res.errors);
      }
    });
  }, []);

  return (
    <div className="studio-page">
      <Main>
        <TopControls
          btns={[
            { text: 'Covers', path: '/home/covers', selected: true },
            { text: 'Manage Covers', path: '/home/covers/manage', selected: false },
          ]}
        />

        <div className="resources">
          <Slider
            title={'Categories'}
            type={'category'}
            kind={'studio_covers'}
            selectable={true}
            items={categories}
            selectedItem={selectedCategory}
            setSelectedItem={setSelectedCategory}
            setItems={setCategories}
            setShowSlideModal={setShowSlideModal}
            sortBy={categoriesSortBy}
            setCategoriesSortBy={setCategoriesSrotBy}
            shouldLoadMore={shouldLoadMore}
          />

          <SearchControls
            type={`cover`}
            allowedTags={allowedTags}
            tagsArr={tagsArr}
            setTagsArr={setTagsArr}
            sortBy={coversSortBy}
            setSortBy={setCoversSortBy}
            showing={(selectedCategory && selectedCategory.title) || 'All'}
          />

          <ScrollableWindow onScrollEnd={triggerLoadMore}>
            {!selectedCategory &&
              categories.map(category => (
                <Slider
                  key={category.id}
                  id={category.id}
                  title={category.title}
                  type={'cover'}
                  category={category}
                  tags={tags}
                  sortBy={coversSortBy}
                  setSelectedItem={setSelectedCategory}
                  handleNew={() => setShowSlideModal(true)}
                  setCategoryId={setCategoryId}
                  newItemFromSlideModal={newItemFromSlideModal}
                />
              ))}
          </ScrollableWindow>

          {selectedCategory && (
            <Slider
              uncollapsed
              key={selectedCategory.id}
              id={selectedCategory.id}
              title={selectedCategory.title}
              type={'cover'}
              category={selectedCategory}
              tags={tags}
              sortBy={coversSortBy}
              handleNew={() => setShowSlideModal(true)}
              setSelectedItem={setSelectedCategory}
              setCategoryId={setCategoryId}
              newItemFromSlideModal={newItemFromSlideModal}
            />
          )}
          <SlideModal
            isOpen={showSlideModal}
            onOutsideClick={() => {
              setShowSlideModal(false);
            }}
          >
            <CoverSpecForm
              allowedTags={allowedTags}
              pageSizes={pageSizes}
              paperThicknessList={paperThicknessList}
              coverCategoryId={categoryId}
              closeSlideModal={() => {
                setShowSlideModal(false);
              }}
              addCoverToWorkspace={addCoverToWorkspace}
            />
          </SlideModal>
        </div>
      </Main>
    </div>
  );
}
