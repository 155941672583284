import { useEffect, useRef, useState } from 'react';

import { useWindowSize } from 'hooks/util';

export function useSliderNavigator({
  left,
  setLeft,
  sliderItems,
  selectedItem,
  syncParentLeft,
  removeAddedNewItem,
  outerSelection,
}) {
  const [sliderTrackWidth, setSliderTrackWidth] = useState(null);
  const [, setSliderItemsWidth] = useState(null);
  const [itemWrapperWidth, setItemWrapperWidth] = useState(null);

  const sliderTrackRef = useRef(null);
  const sliderItemsRef = useRef(null);
  const itemWrapperRef = useRef(null);

  const [width] = useWindowSize();

  useEffect(() => {
    sliderTrackRef.current && setSliderTrackWidth(sliderTrackRef.current.clientWidth);
    sliderItemsRef.current && setSliderItemsWidth(sliderItemsRef.current.clientWidth);
    itemWrapperRef.current && setItemWrapperWidth(itemWrapperRef.current.clientWidth);
    setSliderTrackWidth(sliderTrackRef.current.clientWidth);
  }, [sliderItems, sliderItems && sliderItems.length, width]);

  useEffect(() => {
    if (syncParentLeft) syncParentLeft(left);
  }, [left]);

  useEffect(() => {
    if (selectedItem === null) setLeft(0);
  }, [selectedItem]);

  // when diffrent item selected
  useEffect(() => {
    if (selectedItem) {
      const index = sliderItems.findIndex(item => item.id === selectedItem.id);
      if (outerSelection) setLeft(-index * itemWrapperWidth);
      removeAddedNewItem(); // if there is any
    }
  }, [selectedItem, sliderTrackWidth, sliderItems]);

  const moveRight = () => {
    if (left - itemWrapperWidth >= -itemWrapperWidth * hiddenItems() - 10)
      setLeft(left - itemWrapperWidth);
  };

  const moveLeft = () => {
    if (left + itemWrapperWidth <= 0) setLeft(left + itemWrapperWidth);
  };

  const showingNumber = () => {
    if (!sliderItems) return 0;
    const num = Math.round(sliderTrackWidth / itemWrapperWidth);
    if (num > sliderItems.length) return sliderItems.length;
    else return num;
  };

  const hiddenItems = () => {
    if (!sliderItems) return 0;
    return Math.round(
      (itemWrapperWidth * sliderItems.length - sliderTrackWidth) / itemWrapperWidth
    );
  };

  return {
    sliderTrackRef,
    sliderItemsRef,
    itemWrapperRef,
    moveRight,
    moveLeft,
    showingNumber,
    hiddenItems,
    itemWrapperWidth,
    sliderTrackWidth,
    setSliderTrackWidth,
  };
}
