// Built-in
import React, { useState, useEffect } from 'react';

// External
import { useHistory } from 'react-router-dom';
import { useQuery } from 'hooks/router';
import { default as SlideModal } from 'react-slide-out';

// API
import { getTags } from 'api/common';
import { getOneCover } from 'api/studio/covers';
import { getPageSizes } from 'api/studio/formats';
import { getPaperThicknessList } from 'api/studio/covers';

// Context
import { useStudioContext } from 'hooks/studio';

// Components
import Main from 'layouts/Main';
import TabsNavigator from 'components/studio/TabsNavigator';
import CoversStage from 'components/studio/CoversStage';
import CoverSpecForm from 'components/forms/CoverSpecForm';

function CoverWorkspace() {
  // state
  const [showSlideModal, setShowSlideModal] = useState(false);
  const [allowedTags, setAllowedTags] = useState([]);
  const [tagsArr, setTagsArr] = useState([]);
  const [tags, setTags] = useState(''); // formatted String
  const [pageSizes, setPageSizes] = useState([]);
  const [toRemove, setToRemove] = useState(null);

  // hooks
  const history = useHistory();
  const query = useQuery();
  const { covers, setCovers, selectedCover, setSelectedCover } = useStudioContext();

  // methods
  async function multipleAPICalls(list, callFn) {
    const requests = list.map(item => callFn(item));
    return Promise.all(requests);
  }

  const addCoverToWorkspace = cover => {
    const list = [...covers];
    list.push(cover);
    setCovers(list);
  };

  async function loadCovers(ids) {
    try {
      const list = await multipleAPICalls(ids, getOneCover);
      setCovers(
        list.map(res => {
          if (res.success) return res.cover;
        })
      );
    } catch {
      history.replace('/');
    }
  }

  // created
  useEffect(() => {
    const ids = query.get('ids');
    const idsArr = ids && ids.length ? ids.split(',') : null;
    if (idsArr) loadCovers(idsArr);
    else history.replace('/');

    return () => {
      setCovers([]);
    };
  }, []);

  useEffect(() => {
    getTags({ kind: 'studio_covers' }).then(res => {
      if (res.success) {
        setAllowedTags(res.tags);
      } else {
        console.log(res.errors);
      }
    });
  }, []);

  useEffect(() => {
    getPageSizes(0, 10000).then(res => {
      // page sizes can't be more than 10000
      if (res.success) {
        setPageSizes(res.page_sizes);
      } else {
        console.log(res.errors);
      }
    });
  }, []);

  // watchers
  useEffect(() => {
    if (covers.length) {
      setSelectedCover(covers[covers.length - 1]);
      history.replace(`/home/covers/workspace?ids=${covers.map(f => f.id).join(',')}`);
    }
  }, [covers.length]);

  useEffect(() => {
    let str = '';
    tagsArr.forEach(tag => {
      const index = allowedTags.findIndex(
        allowedTag => allowedTag.name.toLowerCase() === tag.name.toLowerCase()
      );
      if (index > -1) {
        str += `&tag=${allowedTags[index].id}`;
      }
    });
    setTags(str);
  }, [tagsArr]);

  const [paperThicknessList, setPaperThicknessList] = useState([]);
  useEffect(() => {
    getPaperThicknessList(0, 10000).then(res => {
      // page sizes can't be more than 10000!
      if (res.success) {
        setPaperThicknessList(res.paper_thickness_list);
      } else {
        console.log(res.errors);
      }
    });
  }, []);

  // render

  return (
    <Main>
      <TabsNavigator
        type={'cover'}
        items={covers}
        selectedItem={selectedCover}
        setSelectedItem={setSelectedCover}
        setShowSlideModal={setShowSlideModal}
        setToRemove={setToRemove}
      />
      {covers.length > 0 &&
        covers.map(cover => {
          return (
            <CoversStage
              key={cover.id}
              cover={cover}
              show={cover.id === selectedCover.id}
              toRemove={toRemove}
              setToRemove={setToRemove}
              allowedTags={allowedTags}
              paperThicknessList={paperThicknessList}
            />
          );
        })}
      <SlideModal
        isOpen={showSlideModal}
        onOutsideClick={() => {
          setShowSlideModal(false);
        }}
      >
        <CoverSpecForm
          allowedTags={allowedTags}
          pageSizes={pageSizes}
          coverCategoryId={selectedCover ? selectedCover.category : 1}
          closeSlideModal={() => {
            setShowSlideModal(false);
          }}
          addCoverToWorkspace={addCoverToWorkspace}
          paperThicknessList={paperThicknessList}
        />
      </SlideModal>
    </Main>
  );
}

export default CoverWorkspace;
