import React from 'react';

function InputText({ className, style, disabled, value, setValue, placeholder }) {
  return (
    <input
      className={className}
      style={style}
      type="text"
      value={value}
      placeholder={placeholder}
      onChange={e => {
        setValue(e.target.value);
      }}
      disabled={disabled ? true : false}
    />
  );
}

export default InputText;
