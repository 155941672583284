import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

function useAutoLogin() {
  const { is_authenticated } = useSelector(state => state.auth);
  const history = useHistory();
  const { state } = useLocation();

  const from = state && state.from && state.redirect_after_login ? state.from : '/';
  useEffect(() => {
    if (is_authenticated && from) {
      history.replace(from);
    }
  }, [is_authenticated, history, from]);
}

export default useAutoLogin;
