import React from 'react';
import { Group, Image, Rect } from 'react-konva';
import { getOneElement } from 'api/elements';
import LinkIndicator from './LinkIndicator';

const imagePromises = {}; // This object will hold ongoing promises

const TImage = ({
  shapeProps,
  isSelected,
  onSelect,
  onDragStart,
  onChange,
  imgID,
  onDragMove,
  onDragEnd,
}) => {
  const shapeRef = React.useRef();
  const imageRef = React.useRef();

  const [image, setImage] = React.useState(null);
  const [offsetX, setOffsetX] = React.useState(null);
  const [offsetY, setOffsetY] = React.useState(null);

  const handleLoad = () => {
    setImage(imageRef.current);
  };

  const loadImage = () => {
    const img = new window.Image();
    const cache = JSON.parse(sessionStorage.getItem('cached-images') || '{}');

    const setImageSrcAndLoad = src => {
      img.src = src;
      img.crossOrigin = 'Anonymous';
      imageRef.current = img;
      imageRef.current.addEventListener('load', handleLoad);
    };

    if (typeof imgID === 'string' && imgID.includes('coloring-book-image')) {
      setImageSrcAndLoad(window.location.origin + '/coloring-book-image.png');
    } else if (cache[imgID]) {
      setImageSrcAndLoad(cache[imgID]);
    } else {
      if (!imagePromises[imgID]) {
        imagePromises[imgID] = getOneElement(imgID).then(res => {
          if (!res.element) return null;
          const imageUrl = res.element.image;
          cache[imgID] = imageUrl;
          sessionStorage.setItem('cached-images', JSON.stringify(cache));
          return imageUrl;
        });
      }

      imagePromises[imgID]
        .then(imageUrl => {
          if (imageUrl) {
            setImageSrcAndLoad(imageUrl);
          }
        })
        .catch(error => {
          console.error('Error loading image:', error);
        })
        .finally(() => {
          // Clean up the promise cache
          delete imagePromises[imgID];
        });
    }
  };

  React.useEffect(() => {
    loadImage();
    return () => {
      if (imageRef.current) {
        imageRef.current.removeEventListener('load', handleLoad);
      }
    };
  }, [imgID]);

  React.useEffect(() => {
    setOffsetX(shapeProps.width / 2);
    setOffsetY(shapeProps.height / 2);
  }, [shapeProps.width, shapeProps.height]);

  return (
    <>
      {shapeProps.link && isSelected && <LinkIndicator shapeProps={shapeProps} />}
      <Group
        ref={shapeRef}
        draggable
        {...shapeProps}
        offsetX={offsetX}
        offsetY={offsetY}
        onDragStart={onDragStart}
        onDragMove={onDragMove}
        onDragEnd={e => onDragEnd(shapeProps, e)}
        onTransformEnd={e => {
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          node.scaleX(1);
          node.scaleY(1);
          onChange(
            {
              ...shapeProps,
              x: node.x(),
              y: node.y(),
              width: Math.max(5, node.width() * scaleX),
              height: Math.max(node.height() * scaleY),
              rotation: node.rotation(),
            },
            true
          );
        }}
        clipFunc={ctx => {
          ctx.beginPath();
          if (!shapeProps.cornerRadius) shapeProps.cornerRadius = [0, 0, 0, 0];
          let topLeft = shapeProps.cornerRadius[0];
          let topRight = shapeProps.cornerRadius[1];
          let bottomLeft = shapeProps.cornerRadius[2];
          let bottomRight = shapeProps.cornerRadius[3];
          if (shapeProps && typeof shapeProps.cornerRadius === 'number') {
            topLeft =
              topRight =
              bottomLeft =
              bottomRight =
                Math.min(shapeProps.cornerRadius, shapeProps.width / 2, shapeProps.height / 2);
          } else if (shapeProps.cornerRadius) {
            topLeft = Math.min(
              shapeProps.cornerRadius[0] || 0,
              shapeProps.width / 2,
              shapeProps.height / 2
            );
            topRight = Math.min(
              shapeProps.cornerRadius[1] || 0,
              shapeProps.width / 2,
              shapeProps.height / 2
            );
            bottomRight = Math.min(
              shapeProps.cornerRadius[2] || 0,
              shapeProps.width / 2,
              shapeProps.height / 2
            );
            bottomLeft = Math.min(
              shapeProps.cornerRadius[3] || 0,
              shapeProps.width / 2,
              shapeProps.height / 2
            );
          }
          ctx.moveTo(topLeft, 0);
          ctx.lineTo(shapeProps.width - topRight, 0);
          ctx.arc(shapeProps.width - topRight, topRight, topRight, (Math.PI * 3) / 2, 0, false);
          ctx.lineTo(shapeProps.width, shapeProps.height - bottomRight);
          ctx.arc(
            shapeProps.width - bottomRight,
            shapeProps.height - bottomRight,
            bottomRight,
            0,
            Math.PI / 2,
            false
          );
          ctx.lineTo(bottomLeft, shapeProps.height);
          ctx.arc(
            bottomLeft,
            shapeProps.height - bottomLeft,
            bottomLeft,
            Math.PI / 2,
            Math.PI,
            false
          );
          ctx.lineTo(0, topLeft);
          ctx.arc(topLeft, topLeft, topLeft, Math.PI, (Math.PI * 3) / 2, false);
          ctx.closePath();
        }}
      >
        <Image image={image} width={shapeProps.width} height={shapeProps.height} />
        <Rect
          onClick={onSelect}
          onTap={onSelect}
          x={shapeProps.strokeWidth}
          y={shapeProps.strokeWidth}
          width={shapeProps.width - shapeProps.strokeWidth}
          height={shapeProps.height - shapeProps.strokeWidth}
          stroke={shapeProps.stroke}
          strokeWidth={shapeProps.strokeWidth}
          offsetX={shapeProps.strokeWidth / 2}
          offsetY={shapeProps.strokeWidth / 2}
        />
      </Group>
    </>
  );
};

export default TImage;
