import {
  startTriviaListEngine,
  startWordSearchEngine,
  startArticleWordSearchEngine,
  startNumberSearchEngine,
  startSudokuEngine,
  updateManuscriptVariables,
  startStoriesEngine,
} from 'api/studio/manuscripts';

import { useEffect, useState } from 'react';
import { collectRolesFromKonvaObj } from 'helpers/studio';
import { getVersionNames } from 'api/variables';
import { getWordLists } from 'api/library';
import { useGlobalContext } from 'state/hooks/global';
import _, { has } from 'lodash';
import PageSelector from './PageSelector';
import WordSearchForm from 'components/forms/manuscript-forms/WordSearchForm';
import NumberSearchForm from 'components/forms/manuscript-forms/NumberSearchForm';
import SudokuForm from 'components/forms/manuscript-forms/SudokuForm';
import GeneralForm from 'components/forms/manuscript-forms/GeneralForm';
import DatabaseForm from 'components/forms/manuscript-forms/DatabaseForm';
import TriviaForm from 'components/forms/manuscript-forms/TriviaForm';
import ElementsForm from 'components/forms/manuscript-forms/ElementsForm';
import StoriesForm from 'components/forms/manuscript-forms/StoriesForm';
import useManuscriptFormInputs from 'hooks/manuscript/useManuscriptFormInputs';
import { updateShapeByAttr, shuffle } from 'helpers/manuscripts';
import { processString } from 'helpers/text';
import {
  autoFillWordSearchForm,
  autoFillNumberSearchForm,
  autoFillSudokuForm,
  autoFillTriviaForm,
  autoFillStoriesForm,
} from 'helpers/manuscirpt-forms-auto-fillers';
import { dummyStory, dummyStory2 } from 'helpers/dummy';

function ManuscriptForm({
  pageSelectorMode,
  setPageSelectorMode,
  pageSelectorHandSide,
  setPageSelectorHandSide,
  from: fromParent,
  setFrom: setFromParent,
  to: toParent,
  setTo: setToParent,
  from1: from1Parent,
  setFrom1: setFrom1Parent,
  to1: to1Parent,
  setTo1: setTo1Parent,
  segments,
  setSegments,
  manuscript,
  form,
  setForm,
  pages,
  setPages,
  selectedPages,
  isLoading,
  setIsLoading,
  gFormData,
  setGFormData,
  trivias,
  setTrivias,
  selectedTrivias,
  setSelectedTrivias,
  isAllowedToRenderManuscript, // UI render Fix
  setIsAllowedToRenderManuscript,
  runCounters,
  requiredStoriesNumber,
}) {
  const selectedSegment = pageSelectorMode;
  const { setMsgs, languages, elementTypes, setElementTypes } = useGlobalContext();
  const [language, setLanguage] = useState(0);
  const [versionNames, setVersionNames] = useState([]);
  const [versionName, setVersionName] = useState(0);
  const [generalFormInputFilterArr, setGeneralFormInputFilterArr] = useState([]);

  // Trivia
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const [triviaFormData, setTriviaFormData] = useState({
    offset: 0,
    category_id: undefined,
    language_id: undefined,
    limit: 30, // this should be the number of generated trivias
    q_max_letters: 1000000, // numbers of characters including spaces
    q_min_letters: 1,
    q_max_words: 1000000,
    q_min_words: 1,
    a_max_letters: 1000000,
    a_min_letters: 1,
    a_max_words: 1000000,
    a_min_words: 1,
  });

  const [requiredTriviasNumber, setRequiredTriviasNumber] = useState(0);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [triviaListsCount, setTriviaListsCount] = useState(0);
  const [isTriviaListLoading, setIsTriviaListLoading] = useState(false);
  const [difficulty, setDifficulty] = useState(['easy', 'medium', 'hard']);
  const [isSwaps, setIsSwaps] = useState(false);

  // Word Search
  const [wordSearchFormData, setWordSearchFormData] = useState({
    offset: 0,
    category_id: undefined,
    language_id: undefined,
    limit: 30, // this should be the number of generated trivias
    nrows: 12,
    ncols: 12,
    npuzzles: 200,
    nwords: 10,
    puzzle_shape: 'circle',
    difficulty: 'easy',
    min_chars: 4,
    max_chars: 11,
    filter_words: true,
    starting_date: null,
  });

  const [wordSearchCategories, setWordSearchCategories] = useState([]);
  const [selectedWordSearchCategory, setSelectedWordSearchCategory] = useState(null);
  const [isWordListsLoading, setIsWordListsLoading] = useState(false);
  const [wordLists, setWordLists] = useState([]);
  const [wordListsCount, setWordListsCount] = useState(0);
  const [selectedWordLists, setSelectedWordLists] = useState([]);
  const [wordSearchPuzzles, setWordSearchPuzzles] = useState([]);

  // Article word search
  const [mode, setMode] = useState('word-lists'); // word-lists, article-lists
  const [activeModes, setActiveModes] = useState([]);
  const [articleCategories, setArticleCategories] = useState([]);
  const [selectedArticleCategory, setSelectedArticleCategory] = useState(null);
  const [articleLists, setArticleLists] = useState([]);
  const [selectedArticleLists, setSelectedArticleLists] = useState([]);

  const [from, setFrom] = useState(fromParent);
  const [to, setTo] = useState(toParent);
  const [from1, setFrom1] = useState(from1Parent);
  const [to1, setTo1] = useState(to1Parent);

  // number search
  // Word Search
  const [numberSearchFormData, setNumberSearchFormData] = useState({
    nrows: 11,
    ncols: 11,
    npuzzles: 200,
    nwords: 10,
    puzzle_shape: 'normal',
    difficulty: 'easy',
    min_chars: 4,
    max_chars: 11,
  });

  const [sudokuFormData, setSudokuFormData] = useState({
    size: 9,
    npuzzles: 200,
    difficulty: 'Easy',
  });

  const [selectedElementType, setSelectedElementType] = useState(null);

  const [elementCategories, setElementCategories] = useState([]);
  const [selectedElementsCategory, setSelectedElementsCategory] = useState(null);

  const [elements, setElements] = useState([]);
  const [selectedElements, setSelectedElements] = useState([]);
  const selectedElementsIds = selectedElements.map(el => el.value);

  // Stories
  const [storiesFormData, setStoriesFormData] = useState({
    // offset: 0,
    category: undefined,
    language_id: undefined,
    // limit: 30,
    // sort: 'alpha',
  });

  const [stories, setStories] = useState([]);
  const [selectedStories, setSelectedStories] = useState([]);

  const [storiesCount, setStoriesCount] = useState(0);
  const [isStoriesLoading, setIsStoriesLoading] = useState(false);

  useEffect(() => {
    if (elementTypes.length && !selectedElementType) {
      setSelectedElementType(elementTypes[0].id);
    }
  }, [elementTypes, selectedElementType]);

  useEffect(() => {
    if (elementCategories.length && !selectedElementsCategory) {
      setSelectedElementsCategory(elementCategories[0].id);
    }
  }, [elementCategories, selectedElementsCategory]);

  useEffect(() => {
    const updateParentRange = _.debounce(() => {
      setFromParent(from);
      setToParent(to);
      setFrom1Parent(from1);
      setTo1Parent(to1);
    }, 500);
    updateParentRange();

    return () => updateParentRange.cancel();
  }, [from, to, from1, to1]);

  useEffect(() => {
    const updateChildRange = () => {
      if (fromParent !== from) setFrom(fromParent);
      if (toParent !== to) setTo(toParent);
      if (from1Parent !== from1) setFrom1(from1Parent);
      if (to1Parent !== to1) setTo1(to1Parent);
    };

    updateChildRange();
  }, [fromParent, toParent, from1Parent, to1Parent]);

  const loadTriviaLists = offset => {
    if (isTriviaListLoading || !triviaFormData.language_id) return;
    if (offset === 0) {
      setTrivias([]);
      setTriviaListsCount(0);
    }

    if (offset === 0 || offset < triviaListsCount) {
      setIsTriviaListLoading(true);
      startTriviaListEngine({ ...triviaFormData, offset, category: selectedCategory }, difficulty)
        .then(res => {
          if (res.success) {
            const list = [...trivias];
            const items = offset === 0 ? res['trivia_lists'] : list.concat(res['trivia_lists']);
            setTriviaListsCount(res.count);
            setTimeout(() => {
              setTrivias(items);
              setIsTriviaListLoading(false);
            }, 500);
          }
        })
        .catch(e => console.log);
    }
  };

  const loadStories = offset => {
    if (isStoriesLoading || !storiesFormData.language_id) return;
    if (offset === 0) {
      setStories([]);
      setStoriesCount(0);
    }

    if (offset === 0 || offset < storiesCount) {
      setIsStoriesLoading(true);
      startStoriesEngine({ ...storiesFormData })
        .then(res => {
          if (res.success) {
            const list = [...stories];
            const items = offset === 0 ? res['stories'] : list.concat(res['stories']);
            setStoriesCount(res.count);
            setTimeout(() => {
              setStories(items);
              setIsStoriesLoading(false);
            }, 500);
          }
        })
        .catch(e => console.log);
    }
  };

  const loadWordLists = offset => {
    if (isWordListsLoading || !wordSearchFormData.language_id) return;
    if (offset === 0) {
      setWordLists([]);
      setWordListsCount(0);
    }

    if (offset === 0 || offset < triviaListsCount) {
      setIsWordListsLoading(true);
      getWordLists(selectedWordSearchCategory, 'alpha', [], offset)
        .then(res => {
          if (res.success) {
            const list = [...wordLists];
            const items = offset === 0 ? res['word_lists'] : list.concat(res['word_lists']);
            setWordListsCount(res.count);
            setTimeout(() => {
              setWordLists(items);
              setIsWordListsLoading(false);
            }, 100);
          }
        })
        .catch(console.log);
    }
  };

  const generateWordSearchPuzzles = cb => {
    setIsLoading(true);
    const wordlistsIds = selectedWordLists.map(wl => wl.id);

    startWordSearchEngine({
      ncols: wordSearchFormData.ncols,
      nrows: wordSearchFormData.nrows,
      npuzzles: wordSearchFormData.npuzzles,
      nwords: wordSearchFormData.nwords,
      puzzle_shape: wordSearchFormData.puzzle_shape,
      difficulty: wordSearchFormData.difficulty,
      min_chars: wordSearchFormData.min_chars,
      max_chars: wordSearchFormData.max_chars,
      filter_words: wordSearchFormData.filter_words,
      wordlists: wordlistsIds,
      starting_date: wordSearchFormData.starting_date,
    })
      .then(res => {
        if (!res.success && res.messages) {
          res.messages.forEach(msg => {
            setMsgs([{ type: 'warning', content: msg }]);
          });
          setIsLoading(false);
        } else {
          cb(res);
          setIsLoading(false);
        }
      })
      .catch(e => {
        setMsgs([{ type: 'warning', content: e.message }]);
      });
  };

  const generateArticleWordSearchPuzzles = cb => {
    setIsLoading(true);
    const articleListsIds = selectedArticleLists.map(a => a.value);

    startArticleWordSearchEngine({
      ncols: wordSearchFormData.ncols,
      nrows: wordSearchFormData.nrows,
      npuzzles: wordSearchFormData.npuzzles,
      nwords: wordSearchFormData.nwords,
      puzzle_shape: wordSearchFormData.puzzle_shape,
      difficulty: wordSearchFormData.difficulty,
      min_chars: wordSearchFormData.min_chars,
      max_chars: wordSearchFormData.max_chars,
      filter_words: wordSearchFormData.filter_words,
      article_lists: articleListsIds,
      starting_date: wordSearchFormData.starting_date,
    })
      .then(res => {
        if (!res.success && res.messages) {
          res.messages.forEach(msg => {
            setMsgs([{ type: 'warning', content: msg }]);
          });
          setIsLoading(false);
        } else {
          cb(res, true);
          setIsLoading(false);
        }
      })
      .catch(e => {
        setMsgs([{ type: 'warning', content: e.message }]);
      });
  };

  const createArray = (start, end) => {
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const generateNumberSearchPuzzles = cb => {
    setIsLoading(true);
    startNumberSearchEngine({
      ncols: numberSearchFormData.ncols,
      nrows: numberSearchFormData.nrows,
      npuzzles: numberSearchFormData.npuzzles,
      total_numbers: numberSearchFormData.nwords,
      puzzle_shape: numberSearchFormData.puzzle_shape,
      difficulty: numberSearchFormData.difficulty,
      // min_chars: wordSearchFormData.min_chars,
      // max_chars: wordSearchFormData.max_chars,
      char_lengths: createArray(numberSearchFormData.min_chars, numberSearchFormData.max_chars),
    })
      .then(res => {
        if (!res.success && res.messages) {
          res.messages.forEach(msg => {
            setMsgs([{ type: 'warning', content: msg }]);
          });
          setIsLoading(false);
        } else {
          cb(res);
          setIsLoading(false);
        }
      })
      .catch(e => {
        setMsgs([{ type: 'warning', content: e.message }]);
      });
  };

  const generateSudokuPuzzles = cb => {
    setIsLoading(true);
    startSudokuEngine({
      ncols_x_nrows: sudokuFormData.size,
      npuzzles: sudokuFormData.npuzzles,
      level: sudokuFormData.difficulty,
    })
      .then(res => {
        if (!res.success && res.messages) {
          res.messages.forEach(msg => {
            setMsgs([{ type: 'warning', content: msg }]);
          });
          setIsLoading(false);
        } else {
          cb(res);
          setIsLoading(false);
        }
      })
      .catch(e => {
        setMsgs([{ type: 'warning', content: e.message }]);
      });
  };

  const injectWordSearchPuzzles = (res, hasAritcleLists) => {
    console.log('res', res);
    setIsLoading(true);
    const types = {
      'word-search-grid - puzzle': 'problem',
      'word-search-grid - solution': 'solution',
      'classic-word-list-text': 'wordlist',
      'smart-word-list-text': 'wordlist',
      'Word List Theme': 'theme',
      'Dynamic Date': 'date',
    };

    if (hasAritcleLists) {
      types['article-word-list-text'] = 'article';
      types['article-title-text'] = 'title';
    }

    const counters = {
      problem: -1,
      solution: -1,
      wordlist: -1,
      theme: -1,
      date: -1,
      errors: 0,
    };

    if (hasAritcleLists) {
      counters.article = -1;
      counters.title = -1;
    }

    const puzzles = res.word_search_puzzles.map(p => {
      const obj = {
        problem: p.grid_str,
        solution: p, // was passing p.solution_str but we need the whole object for rings now
        wordlist: Object.keys(p.solution_directions).join(', '),
        theme: res.word_theme,
        date: p.dynamic_date,
        // 'solution_directions': p.solution_directions,
        title: p.article_title || '',
        article: p.article_content || '',
      };

      return obj;
    });
    const problems = puzzles.map(p => p.problem);
    const solutions = puzzles.map(p => p.solution);
    const wordlists = [
      ...puzzles.map(p => p.wordlist),
      ...puzzles.map(p => p.wordlist),
      // Comment this line or remove it when we support solution_directions
    ];
    const themes = [...puzzles.map(p => p.theme), ...puzzles.map(p => p.theme)];
    const dates = [...puzzles.map(p => p.date), ...puzzles.map(p => p.date)];
    const articles = hasAritcleLists
      ? puzzles.map(p => ({ text: p.article, markedWords: p.wordlist.split(', ') }))
      : [];
    const titles = hasAritcleLists ? puzzles.map(p => p.title) : [];
    const newPages = pages.map((page, index) => {
      if (!page) return null;
      const pageNumber = index + 1;
      const newPage = { ...page };
      if ((!selectedPages.length || selectedPages.includes(pageNumber)) && page.stage_json) {
        const shapesArr = [...page.stage_json.children[0].children];
        const newShapes = [...shapesArr].map(shape => {
          let newShape = { ...shape };
          if (
            (shape.attrs.staticLink &&
              (types[shape.attrs.staticLink.role] === 'problem' ||
                types[shape.attrs.staticLink.role] === 'solution' ||
                types[shape.attrs.staticLink.role] === 'wordlist' ||
                types[shape.attrs.staticLink.role] === 'title' ||
                types[shape.attrs.staticLink.role] === 'article')) ||
            (shape.attrs.link && types[shape.attrs.link.role] === 'theme') ||
            (shape.attrs.link && types[shape.attrs.link.role] === 'date')
          ) {
            const role = shape.attrs.staticLink
              ? shape.attrs.staticLink.role
              : shape.attrs.link.role;
            const type = types[role];
            const index = ++counters[type];
            const items =
              type === 'wordlist'
                ? wordlists
                : type === 'problem'
                ? problems
                : type === 'theme'
                ? themes
                : type === 'date'
                ? dates
                : type === 'article'
                ? articles
                : type === 'title'
                ? titles
                : solutions;
            if (!items[index] && type === 'problem') counters.errors++;
            if (items[index] && type !== 'article') {
              newShape = updateShapeByAttr({
                shape,
                newValue: items[index],
                attr: 'text',
              });
            } else if (items[index] && type === 'title') {
              newShape = updateShapeByAttr({
                shape,
                newValue: items[index],
                attr: 'text',
              });
            } else if (items[index] && type === 'article') {
              newShape = updateShapeByAttr({
                shape,
                newValue: items[index].text,
                attr: 'text',
                markedWords: items[index].markedWords,
              });
            }
          }

          return newShape;
        });
        newPage.stage_json.children[0].children = newShapes;
      }
      setIsLoading(false);
      return newPage;
    });

    if (counters.errors)
      setMsgs([
        {
          type: 'warning',
          content: `Warning: ${counters.errors} puzzles are missing!`,
        },
      ]);

    setPages(newPages);
  };

  const injectSudokuPuzzles = res => {
    setIsLoading(true);
    const types = {
      'sudoku-grid - puzzle': 'problem',
      'sudoku-grid - solution': 'solution',
    };

    const counters = {
      problem: -1,
      solution: -1,
      errors: 0,
    };

    const puzzles = res.sudoku_puzzles.map(p => ({
      problem: p.puzzle_str,
      solution: p.solution_str,
    }));

    const problems = puzzles.map(p => p.problem);
    const solutions = puzzles.map(p => p.solution);

    const newPages = pages.map((page, index) => {
      if (!page) return null;
      const pageNumber = index + 1;
      const newPage = { ...page };
      if ((!selectedPages.length || selectedPages.includes(pageNumber)) && page.stage_json) {
        const shapesArr = [...page.stage_json.children[0].children];
        const newShapes = [...shapesArr].map(shape => {
          let newShape = { ...shape };
          if (
            shape.attrs.staticLink &&
            (types[shape.attrs.staticLink.role] === 'problem' ||
              types[shape.attrs.staticLink.role] === 'solution')
          ) {
            const role = shape.attrs.staticLink
              ? shape.attrs.staticLink.role
              : shape.attrs.link.role;
            const type = types[role];
            const index = ++counters[type];
            const items = type === 'problem' ? problems : solutions;
            if (!items[index] && type === 'problem') counters.errors++;
            if (items[index]) {
              newShape = updateShapeByAttr({
                shape,
                newValue: items[index],
                attr: 'text',
              });
            }
          }

          return newShape;
        });
        newPage.stage_json.children[0].children = newShapes;
      }
      setIsLoading(false);
      return newPage;
    });

    if (counters.errors)
      setMsgs([
        {
          type: 'warning',
          content: `Warning: ${counters.errors} puzzles are missing!`,
        },
      ]);

    setPages(newPages);
  };

  const updateManuscriptWithWordSearch = mode => {
    if (mode === 'word-lists') generateWordSearchPuzzles(injectWordSearchPuzzles);
    if (mode === 'article-lists') generateArticleWordSearchPuzzles(injectWordSearchPuzzles);
  };

  const injectNumberSearchPuzzlesAndWordLists = res => {
    setIsLoading(true);
    const types = {
      'number-search-grid - puzzle': 'problem',
      'number-search-grid - solution': 'solution',
      'classic-number-list-text': 'numberlist',
      'smart-number-list-text': 'numberlist',
      // 'Word List Theme': 'theme',
    };

    const counters = {
      problem: -1,
      solution: -1,
      numberlist: -1,
      // theme: -1,
      errors: 0,
    };

    const puzzles = res.number_search_puzzles.map(p => ({
      problem: p.grid_str,
      solution: p, // was passing p.solution_str but we need the whole object for rings now
      numberlist: Object.keys(p.solution_directions).join(', '),
      // theme: res.word_theme,
      // 'solution_directions': p.solution_directions
    }));

    const problems = puzzles.map(p => p.problem);
    const solutions = puzzles.map(p => p.solution);
    const numberlists = [
      ...puzzles.map(p => p.numberlist),
      ...puzzles.map(p => p.numberlist),
      // Comment this line or remove it when we support solution_directions
    ];
    // const themes = [...puzzles.map(p => p.theme), ...puzzles.map(p => p.theme)];

    const newPages = pages.map((page, index) => {
      if (!page) return null;
      const pageNumber = index + 1;
      const newPage = { ...page };
      if ((!selectedPages.length || selectedPages.includes(pageNumber)) && page.stage_json) {
        const shapesArr = [...page.stage_json.children[0].children];
        const newShapes = [...shapesArr].map(shape => {
          let newShape = { ...shape };
          if (
            shape.attrs.staticLink &&
            (types[shape.attrs.staticLink.role] === 'problem' ||
              types[shape.attrs.staticLink.role] === 'solution' ||
              types[shape.attrs.staticLink.role] === 'numberlist')
            // ||(shape.attrs.link && types[shape.attrs.link.role] === 'theme')
          ) {
            const role = shape.attrs.staticLink
              ? shape.attrs.staticLink.role
              : shape.attrs.link.role;
            const type = types[role];
            const index = ++counters[type];
            const items =
              type === 'numberlist'
                ? numberlists
                : type === 'problem'
                ? problems
                : // : type === 'theme'
                  // ? themes
                  solutions;
            if (!items[index] && type === 'problem') counters.errors++;
            if (items[index]) {
              newShape = updateShapeByAttr({
                shape,
                newValue: items[index],
                attr: 'text',
              });
            }
          }

          return newShape;
        });
        newPage.stage_json.children[0].children = newShapes;
      }
      setIsLoading(false);
      return newPage;
    });

    if (counters.errors)
      setMsgs([
        {
          type: 'warning',
          content: `Warning: ${counters.errors} puzzles are missing!`,
        },
      ]);

    setPages(newPages);
  };

  const updateManuscriptWithNumberSearch = () => {
    generateNumberSearchPuzzles(injectNumberSearchPuzzlesAndWordLists);
  };

  const updateManuscriptWithSudoku = () => {
    generateSudokuPuzzles(injectSudokuPuzzles);
  };

  const injectColoringBookImagesIds = shouldShuffle => {
    const counters = {
      images: 0,
      errors: 0,
    };

    let items;

    if (!shouldShuffle) items = selectedElementsIds;
    else items = shuffle(selectedElementsIds);

    const newPages = pages.map((page, index) => {
      const pageNumber = index + 1;
      if (!page) return null;
      const newPage = { ...page };
      if ((!selectedPages.length || selectedPages.includes(pageNumber)) && page.stage_json) {
        const shapesArr = [...page.stage_json.children[0].children];
        const newShapes = [...shapesArr].map(shape => {
          let newShape = { ...shape };
          if (shape.attrs.link && shape.attrs.link.role === 'Coloring Book Image') {
            counters.images = counters.images + 1;
            const index = counters.images - 1;

            if (!items[index]) counters.errors++;

            newShape = updateShapeByAttr({
              shape,
              newValue: items[index],
              attr: 'imgID',
            });
          }
          if (counters.errors)
            setMsgs([
              {
                type: 'warning',
                content: `Need to select more images, ${counters.errors} images are missing!`,
              },
            ]);
          return newShape;
        });
        newPage.stage_json.children[0].children = newShapes;
      }
      return newPage;
    });

    setPages(newPages);
  };

  const updateManuscriptWithElements = shouldShuffle => {
    injectColoringBookImagesIds(shouldShuffle);
  };

  const injectStories = shouldShuffle => {
    const counters = {
      titles: 0,
      stories: 0,
      errors: 0,
    };

    let items;
    const itemsOfSelectedStories = selectedStories.reduce((acc, curr) => {
      acc.push(...curr.items);
      return acc;
    }, []);
    if (!shouldShuffle) items = itemsOfSelectedStories;
    else items = shuffle(itemsOfSelectedStories);
    const filteredPages = pages.filter(p => !p || !p.wasNotInTemplate);
    const newPages = filteredPages.map((page, index) => {
      const pageNumber = index + 1;
      if (!page) return null;
      const newPage = { ...page };
      if (
        // (!selectedPages.length || selectedPages.includes(pageNumber)) &&
        page.stage_json
      ) {
        const shapesArr = [...page.stage_json.children[0].children];
        const newShapes = [...shapesArr].map(shape => {
          let newShape = { ...shape };
          if (shape.attrs.staticLink && shape.attrs.staticLink.role === 'story-title-text') {
            counters.titles = counters.titles + 1;
            const index = counters.titles - 1;
            newShape = updateShapeByAttr({
              shape,
              newValue: items[index].title,
              attr: 'text',
            });
          } else if (
            shape.attrs.staticLink &&
            shape.attrs.staticLink.role === 'story-word-list-text'
          ) {
            counters.stories = counters.stories + 1;
            const index = counters.stories - 1;

            if (!items[index]) counters.errors++;

            newShape = updateShapeByAttr({
              shape,
              newValue: items[index].content,
              attr: 'text',
            });
          }
          if (counters.errors)
            setMsgs([
              {
                type: 'warning',
                content: `Need to select more images, ${counters.errors} images are missing!`,
              },
            ]);
          return newShape;
        });
        newPage.stage_json.children[0].children = newShapes;
      }
      return newPage;
    });

    setPages(newPages);
  };

  const updateManuscriptWithStories = shouldShuffle => {
    injectStories(shouldShuffle);
  };
  useEffect(() => {
    loadWordLists(0);
  }, [selectedWordSearchCategory]);

  useEffect(() => {
    setSelectedCategory(null);
  }, [triviaFormData.language_id]);

  useEffect(() => {
    if (languages.length) {
      // Set default language English
      const lang = languages.find(lang => lang.name.toLowerCase() === 'english');
      if (lang) {
        setLanguage(lang.id);
        setTriviaFormData({
          ...triviaFormData,
          language_id: lang.id,
        });

        setWordSearchFormData({
          ...wordSearchFormData,
          language_id: lang.id,
        });
      } else {
        setLanguage(languages[0].id);
        setTriviaFormData({
          ...triviaFormData,
          language_id: languages[0].id,
        });

        setWordSearchFormData({
          ...wordSearchFormData,
          language_id: languages[0].id,
        });
      }
    }
  }, [languages]);

  useEffect(() => {
    setSelectedTrivias([]);
    loadTriviaLists(0);
  }, [triviaFormData, difficulty, selectedCategory]);

  useEffect(() => {
    setSelectedStories([]);
    loadStories(0);
  }, [selectedCategory]);

  useEffect(() => {
    if (versionNames.length) {
      setVersionName(versionNames[0].id);
    }
  }, [versionNames]);

  useEffect(() => {
    getVersionNames({ isSwap: false }).then(res => {
      if (res.success) setVersionNames(res.version_names);
    });
  }, []);

  useEffect(() => {
    if (selectedPages && !selectedPages.length) {
      setGeneralFormInputFilterArr([]);
    } else {
      setGeneralFormInputFilterArr(collectRolesFromKonvaObj({ pages, selectedPages }));
    }
  }, [selectedPages]);

  // Auto fill word search form data from segments
  useEffect(() => {
    if (!segments.length || !pageSelectorMode.includes('(2-ranges)')) return;
    let selectedSegment = segments.find(seg => seg.name + ' (2-ranges)' === pageSelectorMode);
    const potentialFormName = selectedSegment.name
      .toLowerCase()
      .replace(' (2-ranges)', '')
      .replace(' ', '-');
    ['trivia', 'word-search', 'number-search', 'sudoku'].includes(potentialFormName) &&
      setForm(potentialFormName);
    const data = {};

    if (selectedSegment.rows) data.nrows = selectedSegment.rows;
    if (selectedSegment.cols) data.ncols = selectedSegment.cols;
    if (selectedSegment.puzzle_shape) data.puzzle_shape = selectedSegment.puzzleShape;
    if (selectedSegment.npuzzles) data.npuzzles = selectedSegment.npuzzles;
    if (selectedSegment.nWords) data.nwords = selectedSegment.nWords;
    setWordSearchFormData(formData => ({ ...formData, ...data }));
  }, [segments, pageSelectorMode]);

  const updatePages = (pages, shapeMapper, sot) => {
    const newPages = pages.map((page, index) => {
      const pageNumber = index + 1;
      if (
        page &&
        page.stage_json &&
        (!selectedPages.length || selectedPages.includes(pageNumber))
      ) {
        const newPage = { ...page };
        const shapesArr = [...page.stage_json.children[0].children];
        const newShapes = [...shapesArr].map(shape => {
          return shapeMapper(shape, sot);
        });
        newPage.stage_json.children[0].children = newShapes;
        return newPage;
      } else {
        return page;
      }
    });
    setPages(newPages);
  };

  const handleUpdateVariablesRequest = () => {
    setIsLoading(true);
    updateManuscriptVariables(
      {
        version_name_id: versionName,
        language_id: language,
        page_number: selectedPages.length && !isSwaps ? selectedPages[0] : undefined,
        is_swap: isSwaps ? true : undefined,
      },
      manuscript.id
    )
      .then(res => {
        if (res.success) {
          setPages(res.manuscript.data);
          setIsLoading(false);
        }
      })
      .catch(e => {
        setIsLoading(false);
      });
  };

  const {
    generalFormInputs,
    triviaFormInputs,
    wordSearchFormInputs,
    numberSearchFormInputs,
    sudokuFormInputs,
    storiesFormInputs,
  } = useManuscriptFormInputs({ generalFormInputFilterArr, languages });

  const handleGFormChanges = formData => {
    function shapeMapper(shape, formData) {
      let newShape = { ...shape };
      if (shape && shape.attrs && shape.attrs.link && shape.attrs.link.role) {
        const validType = Object.keys(formData)
          .map(el => el.replaceAll('_', ' '))
          .includes(
            shape.attrs.link.role
              .toLowerCase()
              .replaceAll('_', ' ')
              .replaceAll('(', '')
              .replaceAll(')', '')
          );

        let gAttr = shape.attrs.link.role
          .toLowerCase()
          .replaceAll(' ', '_')
          .replaceAll('(', '')
          .replaceAll(')', '');

        if (validType) {
          newShape = updateShapeByAttr({
            shape,
            newValue: formData[gAttr],
            attr: 'text',
          });
        }
      }
      return newShape;
    }
    updatePages(pages, shapeMapper, formData);
  };

  // const updateManuscriptFromGForm = gForm => {};

  const fetchWordSearchRowsAndCols = () => {
    // role: either 'word-search-grid' or 'classic-word-list'
    ['word-search-grid', 'classic-word-list', 'classic-number-list'].forEach(role => {
      pages.every(page => {
        if (page && page.stage_json) {
          const shapesArr = [...page.stage_json.children[0].children];
          return [...shapesArr].every(shape => {
            if (
              shape.attrs.staticLink &&
              shape.attrs.staticLink.role &&
              shape.attrs.staticLink.role.includes(role) &&
              shape.attrs.staticLink.cols &&
              shape.attrs.staticLink.rows
            ) {
              if (role === 'word-search-grid') {
                setWordSearchFormData(wordSearchFormData => ({
                  ...wordSearchFormData,
                  nrows: shape.attrs.staticLink.rows,
                  ncols: shape.attrs.staticLink.cols,
                  puzzle_shape: shape.attrs.staticLink.puzzleShape || 'normal',
                }));
              } else if (role === 'classic-word-list' || role === 'classic-number-list')
                setWordSearchFormData(wordSearchFormData => ({
                  ...wordSearchFormData,
                  nwords: shape.attrs.staticLink.rows * shape.attrs.staticLink.cols,
                }));
              return false;
            }
            return true;
          });
        }
        return true;
      });
    });
  };

  useEffect(() => {
    if (pages.length) {
      runCounters();
    }
  }, [pages.length]);

  useEffect(() => {
    // for backward compatibility
    // if ((form === 'word-search' || form === 'number-search') && !manuscript.links_map.length) {
    //   fetchWordSearchRowsAndCols();
    // }

    if (form === 'word-search')
      autoFillWordSearchForm(
        manuscript,
        setWordSearchFormData,
        activeModes,
        setActiveModes,
        setMode,
        selectedPages
      );
    else if (form == 'number-search')
      autoFillNumberSearchForm(manuscript, setNumberSearchFormData, selectedPages);
    else if (form === 'sudoku') autoFillSudokuForm(manuscript, setSudokuFormData, selectedPages);
    else if (form === 'trivia')
      autoFillTriviaForm(manuscript, setRequiredTriviasNumber, selectedPages);
  }, [form, selectedPages]);

  const updateManuscriptWithTrivias = shuffleTrivias => {
    const counters = {
      Problem: 0,
      Solution: 0,
      errors: 0,
    };

    let items;

    if (!shuffleTrivias) items = selectedTrivias.map(list => list.items).flat();
    else items = shuffle(selectedTrivias.map(list => list.items).flat());

    const newPages = pages.map((page, index) => {
      const pageNumber = index + 1;
      if (!page) return null;
      const newPage = { ...page };
      if ((!selectedPages.length || selectedPages.includes(pageNumber)) && page.stage_json) {
        const shapesArr = [...page.stage_json.children[0].children];
        const newShapes = [...shapesArr].map(shape => {
          let newShape = { ...shape };
          if (shape.attrs.link && shape.attrs.link.type === 'Puzzle') {
            counters[shape.attrs.link.role] = counters[shape.attrs.link.role] + 1;
            const index = counters[shape.attrs.link.role] - 1;
            const type = shape.attrs.link.role === 'Problem' ? 'q' : 'a';

            if (!items[index]) counters.errors++;

            newShape = updateShapeByAttr({
              shape,
              newValue: items[index]
                ? items[index][type]
                : 'Error: Out of range, please select more trivia lists!',
              attr: 'text',
            });
          }
          if (counters.errors)
            setMsgs([
              {
                type: 'warning',
                content: `Need to select more lists, ${counters.errors / 2} trivias are missing!`,
              },
            ]);
          return newShape;
        });
        newPage.stage_json.children[0].children = newShapes;
      }
      return newPage;
    });

    setPages(newPages);
  };

  return (
    <div className={`form__studio-text`}>
      <div className="form__studio-heading">{form.replace('-', ' ').toUpperCase()}</div>

      <div
        style={{
          overflowY: 'auto',
          height: 'calc(100vh - 16.6667rem)',
          paddingBottom: 50,
        }}
      >
        <PageSelector
          pageSelectorMode={pageSelectorMode}
          setPageSelectorMode={setPageSelectorMode}
          pageSelectorHandSide={pageSelectorHandSide}
          setPageSelectorHandSide={setPageSelectorHandSide}
          pages={pages}
          from={from}
          setFrom={setFrom}
          to={to}
          setTo={setTo}
          from1={from1}
          setFrom1={setFrom1}
          to1={to1}
          setTo1={setTo1}
          segments={segments}
          setSegments={setSegments}
        />

        {form === 'database' &&
          DatabaseForm({
            isSwaps,
            setIsSwaps,
            languages,
            language,
            setLanguage,
            versionNames,
            versionName,
            setVersionName,
            isLoading,
            handleUpdateVariablesRequest,
            // runCounters
          })}

        {form === 'general' && (
          <GeneralForm
            generalFormInputs={generalFormInputs}
            gFormData={gFormData}
            setGFormData={setGFormData}
            isLoading={isLoading}
            handleGFormChanges={handleGFormChanges}
          />
        )}

        {form === 'trivia' &&
          TriviaForm({
            difficulty,
            setDifficulty,
            triviaFormInputs,
            triviaFormData,
            categories,
            selectedCategory,
            setSelectedCategory,
            setCategories,
            showAdvancedFilters,
            setTriviaFormData,
            setShowAdvancedFilters,
            trivias,
            loadTriviaLists,
            selectedTrivias,
            setSelectedTrivias,
            isTriviaListLoading,
            isLoading,
            updateManuscriptWithTrivias,
            requiredTriviasNumber,
          })}

        {form === 'word-search' &&
          WordSearchForm({
            mode,
            setMode,
            wordSearchFormInputs,
            wordSearchFormData,
            wordSearchCategories,
            selectedWordSearchCategory,
            setSelectedWordSearchCategory,
            setWordSearchCategories,
            wordLists,
            loadWordLists,
            selectedWordLists,
            setSelectedWordLists,
            isWordListsLoading,
            setWordSearchFormData,
            articleCategories,
            setArticleCategories,
            selectedArticleCategory,
            setSelectedArticleCategory,
            articleLists,
            setArticleLists,
            selectedArticleLists,
            setSelectedArticleLists,
            updateManuscriptWithWordSearch,
            activeModes,
          })}

        {form === 'number-search' &&
          NumberSearchForm({
            numberSearchFormInputs,
            numberSearchFormData,
            setNumberSearchFormData,
            updateManuscriptWithNumberSearch,
          })}

        {form === 'sudoku' &&
          SudokuForm({
            sudokuFormInputs,
            sudokuFormData,
            setSudokuFormData,
            updateManuscriptWithSudoku,
          })}
        {form === 'coloring-books' &&
          ElementsForm({
            elementTypes,
            setElementTypes,
            selectedElementType,
            setSelectedElementType,
            elementCategories,
            setElementCategories,
            selectedElementsCategory,
            setSelectedElementsCategory,
            elements,
            setElements,
            selectedElements,
            selectedElementsIds,
            setSelectedElements,
            updateManuscriptWithElements,
            isLoading,
          })}

        {form === 'story' &&
          StoriesForm({
            storiesFormInputs,
            storiesFormData,
            setStoriesFormData,
            categories,
            setCategories,
            selectedCategory,
            setSelectedCategory,
            stories,
            loadStories,
            selectedStories,
            setSelectedStories,
            isStoriesLoading,
            isLoading,
            updateManuscriptWithStories,
            requiredStoriesNumber,
          })}
      </div>
    </div>
  );
}

export default ManuscriptForm;
