import React from 'react';
import { Rect, Transformer } from 'react-konva';
import LinkIndicator from './LinkIndicator';

const TRectangle = ({
  shapeProps,
  isSelected,
  onSelect,
  onDragStart,
  onDragMove,
  onChange,
  onDragEnd,
}) => {
  const shapeRef = React.useRef();
  // const trRef = React.useRef();

  const [offsetX, setOffsetX] = React.useState(null);
  const [offsetY, setOffsetY] = React.useState(null);

  // React.useEffect(() => {
  //   if (isSelected && trRef.current) {
  //     // we need to attach transformer manually
  //     trRef.current.nodes([shapeRef.current]);
  //     trRef.current.getLayer().batchDraw();
  //   }

  // }, [isSelected]);

  React.useEffect(() => {
    setOffsetX(shapeProps.width / 2);
    setOffsetY(shapeProps.height / 2);
  }, [shapeProps.width, shapeProps.height]);

  return (
    <>
      {shapeProps.link && isSelected && <LinkIndicator shapeProps={shapeProps}/>}
      <Rect
        onMouseDown={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        offsetX={offsetX || 40}
        offsetY={offsetY || 40}
        draggable
        onDragStart={onDragStart}
        onDragMove={onDragMove}
        onDragEnd={(e) => onDragEnd(shapeProps, e)}
        onTransformEnd={e => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
            rotation: node.rotation(),
          }, true);
        }}
      />
    </>
  );
};

export default TRectangle;
