import React from 'react';
import Main from 'layouts/Main';
import { useSelector } from 'react-redux';

function Welcome() {
  const { user } = useSelector(state => state.auth);
  return (
    <div className="studio-welcome">
      <Main>
        <h1 style={{ fontSize: '4rem' }} className="lead p-4">
          Hi {user.first_name}, Welcome back!
        </h1>
      </Main>
    </div>
  );
}

export default Welcome;
