import React, { useState } from 'react';
import FormSlider from 'components/forms/elements/FormSlider';
const categoriesSlider = {
  title: '',
  type: 'text',
  itemHeight: 40,
  itemWidth: 90,
  items: ['Shapes', 'Frames', 'Lines', 'Budges'],
};

const recentlyUsedSlider = {
  title: 'Recently used',
  type: 'misc',
  itemWidth: 80,
  itemHeight: 80,
};

const sliders = [
  {
    id: 1,
    title: 'Shapes',
    type: 'icon',
    itemWidth: 80,
    itemHeight: 80,
    items: [
      { title: 'Circle', type: 'circle' },
      { title: 'Rectangle', type: 'rect' },
      { title: 'Triangle', type: 'triangle' },
      { title: 'Rounded Recatangle', type: 'rect-rounded' },
      { title: 'Star', type: 'star' },
    ],
  },
  {
    id: 2,
    title: 'Frames',
    type: 'icon',
    itemWidth: 80,
    itemHeight: 80,
    items: [
      { title: 'Circle Frame ', type: 'circle-frame' },
      { title: 'Rectangle Frame', type: 'rect-frame' },
      { title: 'Triangle Frame', type: 'triangle-frame' },
      { title: 'Rounded Recatangle Frame', type: 'rect-rounded-frame' },
      { title: 'Star Frame', type: 'star-frame' },
    ],
  },
  {
    id: 3,
    title: 'Lines',
    type: 'icon',
    itemWidth: 80,
    itemHeight: 80,
    items: [
      { title: 'Solid Line', type: 'solid-line' },
      { title: 'Dashed Line', type: 'dashed-line' },
      { title: 'Dotted Line', type: 'dotted-line' },
      { title: 'Centre Line', type: 'centre-line' },
      { title: 'Cut Line', type: 'cut-line' },
    ],
  },
  // {
  //   id: 4,
  //   title: 'Badges',
  //   type: 'image',
  //   itemWidth: 80,
  //   itemHeight: 80,
  //   items: [
  //     {
  //       title: 'Star Badge',
  //       src: 'https://cdn-icons-png.flaticon.com/512/744/744922.png',
  //       width: 512,
  //       height: 512,
  //     },
  //     {
  //       title: 'Warning Badge',
  //       src: 'https://cdn-icons-png.flaticon.com/512/1680/1680012.png',
  //       width: 512,
  //       height: 512,
  //     },
  //     {
  //       title: 'Verified Badge',
  //       src: 'https://cdn0.iconfinder.com/data/icons/simplie-essential-action/22/action_039-checkmark-check-done-verify-512.png',
  //       width: 512,
  //       height: 512,
  //     },
  //     // https://flagicons.lipis.dev/
  //     {
  //       title: 'USA Flag',
  //       src: 'https://lipis.github.io/flag-icon-css/flags/4x3/um.svg',
  //       width: 1593,
  //       height: 949,
  //     },
  //     {
  //       title: 'Peace Badge',
  //       src: 'https://cdn.pixabay.com/photo/2013/07/12/15/55/laurel-wreath-150577__480.png',
  //       width: 578,
  //       height: 480,
  //     },
  //   ],
  // },
];

function FormatStudioFormShapes({ className, handleOnSliderElClick }) {
  const [selectedSlider, setSelectedSlider] = useState(null);

  const [recentlyUsedShapes, setRecentlyUsedShpaes] = useState([
    { type: '' },
    { type: '' },
    { type: '' },
    { type: '' },
  ]);

  return (
    <div className={`${className} form__studio-shapes`}>
      <div className="form__studio-heading">ELEMENTS</div>
      <div style={{ overflowY: 'auto', height: 'calc(100vh - 12.5rem)' }}>
        <input className="form-control light" type="text" placeholder="Search" />
        <div className="d-flex flex-column">
          <FormSlider
            form={'shapes'}
            title={categoriesSlider.title}
            type={categoriesSlider.type}
            items={categoriesSlider.items}
            itemWidth={categoriesSlider.itemWidth}
            itemHeight={categoriesSlider.itemHeight}
            selectedSlider={selectedSlider}
            setSelectedSlider={setSelectedSlider}
          />

          <FormSlider
            form={'shapes'}
            title={recentlyUsedSlider.title}
            type={recentlyUsedSlider.type}
            items={recentlyUsedShapes}
            itemWidth={recentlyUsedSlider.itemWidth}
            itemHeight={recentlyUsedSlider.itemHeight}
            handleOnSliderElClick={handleOnSliderElClick}
          />

          {sliders.map(
            (slider, index) =>
              (!selectedSlider || (selectedSlider && selectedSlider === slider.id)) && (
                <FormSlider
                  form={'shapes'}
                  key={index}
                  id={slider.id}
                  title={slider.title}
                  type={slider.type}
                  items={slider.items}
                  itemWidth={slider.itemWidth}
                  itemHeight={slider.itemHeight}
                  selectedSlider={selectedSlider}
                  setSelectedSlider={setSelectedSlider}
                  recentlyUsedShapes={recentlyUsedShapes}
                  setRecentlyUsedShapes={setRecentlyUsedShpaes}
                  handleOnSliderElClick={handleOnSliderElClick}
                />
              )
          )}
        </div>
      </div>
    </div>
  );
}

export default FormatStudioFormShapes;
