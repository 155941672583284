import { useEffect, useState } from 'react';
import Button from 'components/common/Button';
import _ from 'lodash';

function GeneralForm({
  generalFormInputs,
  gFormData,
  setGFormData,
  isLoading,
  handleGFormChanges,
}) {
  const [heading, setHeading] = useState('');
  const [subheading, setSubheading] = useState('');
  const [isbn, setIsbn] = useState('');
  const [authors, setAuthors] = useState('');
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const [text3, setText3] = useState('');
  const [text4, setText4] = useState('');
  const [text5, setText5] = useState('');
  const [text6, setText6] = useState('');

  const getter = {
    heading,
    subheading,
    isbn,
    authors,
    text_1: text1,
    text_2: text2,
    text_3: text3,
    text_4: text4,
    text_5: text5,
    text_6: text6,
  };
  const setter = {
    heading: setHeading,
    subheading: setSubheading,
    isbn: setIsbn,
    authors: setAuthors,
    text_1: setText1,
    text_2: setText2,
    text_3: setText3,
    text_4: setText4,
    text_5: setText5,
    text_6: setText6,
  };

  useEffect(() => {
    // update local state from gFormData
    setHeading(gFormData.heading);
    setSubheading(gFormData.subheading);
    setIsbn(gFormData.isbn);
    setAuthors(gFormData.authors);
    setText1(gFormData.text_1);
    setText2(gFormData.text_2);
    setText3(gFormData.text_3);
    setText4(gFormData.text_4);
    setText5(gFormData.text_5);
    setText6(gFormData.text_6);
  }, [gFormData]);

  return (
    <div>
      {generalFormInputs.map(el => (
        <div className="form-group" key={'general-form+' + el.dataField}>
          <label>{el.label}</label>
          <input
            className="form-control"
            value={getter[el.dataField]}
            onChange={e => {
              setter[el.dataField](e.target.value);
            }}
          />
        </div>
      ))}
      {generalFormInputs.length ? (
        <>
          <div className="d-flex justify-content-center mt-4">
            <Button
              disabled={isLoading}
              onClick={() => handleGFormChanges(getter)}
              label={'Refresh'}
              className="btn-blue-forms"
              style={{ width: 100, height: 40 }}
            />
          </div>
        </>
      ) : (
        <>
          <p>
            Please select (click on) the page that includes the general info!&nbsp;
            <small style={{ fontStyle: 'italic' }}>
              (For ex: Heading, Subheading, ISBN, Authors, Text 1...etc.)
            </small>
          </p>
        </>
      )}
    </div>
  );
}

export default GeneralForm;
