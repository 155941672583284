import React from 'react';
import { Rect, Transformer, Line } from 'react-konva';
import { useIsShiftDown } from 'hooks/util';

function points(center, length, angle) {
  const x1 = center[0] - 0.5 * parseInt(length * Math.cos((Math.PI / 180) * angle));
  const y1 = center[1] - 0.5 * parseInt(length * Math.sin((Math.PI / 180) * angle));
  const x2 = center[0] + 0.5 * parseInt(length * Math.cos((Math.PI / 180) * angle));
  const y2 = center[1] + 0.5 * parseInt(length * Math.sin((Math.PI / 180) * angle));
  return [x1, y1, x2, y2];
}

const TLine = ({
  shapeProps,
  isSelected,
  onSelect,
  onDragStart,
  onDragMove,
  onChange,
  onDragEnd,
}) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  const lineRef = React.useRef();
  const [offsetX, setOffsetX] = React.useState(null);
  const [rectX, setRectX] = React.useState(shapeProps.x);
  const [rectY, setRectY] = React.useState(shapeProps.y);
  const [rectWidth, setRectWidth] = React.useState(shapeProps.width);
  const [rectRotation, setRectRotation] = React.useState(shapeProps.rotation);
  const { isShiftDown } = useIsShiftDown();

  // React.useEffect(() => {
  //   if (isSelected) {
  //     // we need to attach transformer manually
  //     trRef.current.nodes([shapeRef.current]);
  //     trRef.current.getLayer().batchDraw();
  //   }
  // }, [isSelected]);

  React.useEffect(() => {
    setOffsetX(shapeProps.width / 2);
  }, [shapeProps.width]);

  React.useEffect(() => {
    setRectRotation(shapeProps.rotation);
    setRectX(shapeProps.x);
    setRectY(shapeProps.y);
    setRectWidth(shapeProps.width);
  }, [shapeProps.rotation, shapeProps.x, shapeProps.y, shapeProps.width]);

  return (
    <>
      <Line
        ref={lineRef}
        onMouseDown={onSelect}
        onTap={onSelect}
        points={points([rectX, rectY], rectWidth, rectRotation)}
        dash={shapeProps.dash}
        strokeWidth={shapeProps.strokeWidth}
        stroke={shapeProps.stroke}
        opacity={shapeProps.opacity}
        strokeScaling={false}
      />
      <Rect
        {...shapeProps}
        offsetY={5}
        stroke={'rgba(0,0,0,0)'}
        // fill={'#ff0000'} // for debugging
        // opacity={.1} // for debugging
        offsetX={offsetX}
        onMouseDown={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        draggable
        onDragStart={onDragStart}
        onDragMove={e => {
          onDragMove(e);
          setRectX(e.target.x());
          setRectY(e.target.y());
        }}
        onDragEnd={e => onDragEnd(shapeProps, e)}
        onTransform={e => {
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          setRectWidth(Math.max(5, node.width() * scaleX));
          setRectRotation(node.rotation());
          setRectX(node.x());
          setRectY(node.y());
        }}
        onTransformEnd={e => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          // lineRef.current.scaleX(1);
          onChange(
            {
              ...shapeProps,
              x: node.x(),
              y: node.y(),
              // set minimal value
              width: Math.max(5, node.width() * scaleX),
              rotation: node.rotation(),
            },
            true
          );
        }}
      />
    </>
  );
};

export default TLine;
