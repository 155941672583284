import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export function useIsShiftDown() {
  const [isShiftDown, setIsShiftDown] = useState(false);

  function downHandler({ key }) {
    if (key === 'Shift') {
      setIsShiftDown(true);
    }
  }

  function upHandler({ key }) {
    if (key === 'Shift') {
      setIsShiftDown(false);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return { isShiftDown };
}

export function useIsAltDown() {
  const [isAltDown, setIsAltDown] = useState(false);

  function downHandler({ key }) {
    if (key === 'Alt') {
      setIsAltDown(true);
    }
  }

  function upHandler({ key }) {
    if (key === 'Alt') {
      setIsAltDown(false);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return { isAltDown };
}

export function useOnClickOutside(ref, handler, alt) {
  const { closest, className } = alt || { closest: false, className: false };
  useEffect(
    () => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (
          closest &&
          className &&
          (!ref.current || (ref.current && event.target.closest('.' + className)))
        ) {
          return;
        }

        if (!ref.current || (ref.current && ref.current.contains(event.target))) {
          return;
        }
        handler(event);
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

export function useOnClickOnEscape(handler) {
  useEffect(
    event => {
      const listener = event => {
        if (event.key === 'Escape') {
          handler && handler();
        }
      };
      document.addEventListener('keyup', listener);
      return () => {
        document.removeEventListener('keyup', listener);
      };
    },
    [handler]
  );
}

const ORIGIN = Object.freeze({ x: 0, y: 0 });

export function usePan(e) {
  const [panState, setPanState] = useState(ORIGIN);

  const lastPointRef = useRef(ORIGIN);

  const pan = useCallback(e => {
    const lastPoint = lastPointRef.current;
    const point = { x: e.pageX, y: e.pageY };
    lastPointRef.current = point;

    setPanState(panState => {
      const delta = {
        x: lastPoint.x - point.x,
        y: lastPoint.y - point.y,
      };
      const offset = {
        x: panState.x + delta.x,
        y: panState.y + delta.y,
      };

      return offset;
    });
  }, []);

  // Tear down listeners.
  const endPan = useCallback(() => {
    document.removeEventListener('mousemove', pan);
    document.removeEventListener('mouseup', endPan);
  }, [pan]);

  // Set up listeners.
  const startPan = useCallback(
    e => {
      document.addEventListener('mousemove', pan);
      document.addEventListener('mouseup', endPan);
      lastPointRef.current = { x: e.pageX, y: e.pageY };
    },
    [pan, endPan]
  );

  return [panState, startPan];
}

const MIN_SCALE = 10;
const MAX_SCALE = 300;

export function useEventListener(eventName, handler, ref) {
  // Create a ref that stores handler
  const savedHandler = useRef();
  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);
  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = ref.current && ref.current.addEventListener;
      if (!isSupported) return;
      // Create event listener that calls handler function stored in ref
      const eventListener = event => savedHandler.current(event);
      // Add event listener
      ref.current.addEventListener(eventName, eventListener);
      // Remove event listener on cleanup
      return () => {
        if (ref.current) ref.current.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, ref] // Re-run if eventName or element changes
  );
}

export function useScale(ref, initScale = 100) {
  const [scale, setScale] = useState(initScale);

  const updateScale = ({ direction, interval }) => {
    setScale(currentScale => {
      let scale;

      // Adjust up to or down to the maximum or minimum scale levels by `interval`.
      if (direction === 'down' && currentScale + interval < MAX_SCALE) {
        scale = currentScale + interval;
      } else if (direction === 'down') {
        scale = MAX_SCALE;
      } else if (direction === 'up' && currentScale - interval > MIN_SCALE) {
        scale = currentScale - interval;
      } else if (direction === 'up') {
        scale = MIN_SCALE;
      } else {
        scale = currentScale;
      }

      return scale;
    });
  };

  // Set up an event listener such that on `wheel`, we call `updateScale`.
  useEventListener(
    'wheel',
    e => {
      e.preventDefault();

      const isCTRLDown = e.ctrlKey || e.metaKey;
      if (isCTRLDown)
        updateScale({
          direction: e.deltaY > 0 ? 'up' : 'down',
          interval: 1,
        });
    },
    ref
  );

  return { scale, setScale };
}

export function useMousePos(ref) {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEventListener('mousemove', e => setPosition({ x: e.clientX, y: e.clientY }), ref);

  useEventListener('wheel', e => setPosition({ x: e.clientX, y: e.clientY }), ref);

  return position;
}
