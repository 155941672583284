import { useContext, useState } from 'react';
import { studioContext } from 'state/context/studio';

export function useStudioContext() {
  return useContext(studioContext);
}

export function useStudioContextProvider() {
  // formats
  const [formats, setFormats] = useState([]);
  const [selectedFormat, setSelectedFormat] = useState({});
  const [dragEl, setDragEl] = useState(null);
  // const [dragImgSrc, setDragImgSrc] = useState(null);
  const [dragImgID, setDragImgID] = useState(null);
  const [dragImgWidth, setDragImgWidth] = useState(null);
  const [dragImgHeight, setDragImgHeight] = useState(null);

  // templates
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});

  // manuscripts
  const [manuscripts, setManuscripts] = useState([]);
  const [selectedManuscript, setSelectedManuscript] = useState({});

  // covers
  const [covers, setCovers] = useState([]);
  const [selectedCover, setSelectedCover] = useState({});

  return {
    // formats
    formats,
    setFormats,
    selectedFormat,
    dragEl,
    // dragImgSrc,
    dragImgID,
    dragImgWidth,
    dragImgHeight,
    setSelectedFormat,
    setDragEl,
    // setDragImgSrc,
    setDragImgID,
    setDragImgWidth,
    setDragImgHeight,

    // templates
    templates,
    setTemplates,
    selectedTemplate,
    setSelectedTemplate,

    // manuscripts
    manuscripts,
    setManuscripts,
    selectedManuscript,
    setSelectedManuscript,

    // covers
    covers,
    setCovers,
    selectedCover,
    setSelectedCover,
  };
}
