// https://dmitrymorozoff.github.io/react-spinners-kit/
import { MagicSpinner, CircleSpinner } from 'react-spinners-kit';

import { colors } from 'settings/colors';
function Spinner({ type = 'global', color }) {
  return (
    <>
      {type === 'global' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            inset: 0,
            backgroundColor: colors.bgLight,
            zIndex: 10000,
          }}
        >
          <MagicSpinner size={50} color={colors.bgDark} />
        </div>
      )}

      {type === 'local' && (
        <CircleSpinner size={10} color={color || colors.white} />
        // <div style={{ scale: 0.8 }} className="spinner-border" role="status">
        //   <span className="sr-only"></span>
        // </div>
      )}
    </>
  );
}

export default Spinner;
