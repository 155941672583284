import React from 'react';
import TGridSudoku from './grids/TGridSudoku';
import TGridWordSearch from './grids/TGridWordSearch';
import TGridCrossWords from './grids/TGridCrossWords';

const TGrid = React.memo(props => {
  return (
    <>
      {props.type === 'sudoku' && <TGridSudoku {...props} />}
      {props.type === 'word-search' && <TGridWordSearch {...props} />}
      {props.type === 'number-search' && <TGridWordSearch {...props} />}
      {props.type === 'cross-words' && <TGridCrossWords {...props} />}
    </>
  );
});

export default TGrid;
