import './Button.scss';

import React, { useRef } from 'react';

import IncludeSvg from 'components/common/IncludeSvg';

function Button({ label, onClick, className, style, disabled, type, svgName, iconColor }) {
  const buttonRef = useRef(null);
  return (
    <>
      <button
        ref={buttonRef}
        onClick={onClick}
        style={{ display: 'none' }}
        disabled={disabled}
      ></button>
      <span 
        onClick={() => buttonRef.current.click()}
        className={`${className} noselect`}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: disabled? 'default' : 'pointer',
          opacity: disabled? .5: 1,
          ...style,
        }}
      >{svgName && <IncludeSvg name={svgName} color={iconColor} />}<span style={{marginLeft: svgName && label ? 10 : 0}}>{label}</span></span>
    </>
  );
}

export default Button;
