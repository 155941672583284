//https://github.com/reactjs/react-modal#api-documentation

import './Confirm';

import IncludeSvg from 'components/common/IncludeSvg';
import Modal from 'react-modal';
import React from 'react';
import { colors } from 'settings/colors';

export const customStyles = (width = '37.5rem') => ({
  content: {
    width,
    borderRadius: '0.8333rem',
    padding: 0,
    margin: 0,
    // border: `0.0833rem solid ${colors.black}`,
    backgroundColor: colors.bgGrey,
    color: 'white',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: '#00000055',
    zIndex: 1000,
  },
});

function Confirm({
  isOpen,
  setIsOpen,
  title,
  msg,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  handleConfirm,
  handleCancel,
  showClose = true,
  showProgress = false,
  showCancel = true,
  showConfirm = true,
  progress,
  children,
  shouldCloseAfterConfirm = true,
  width,
}) {
  return (
    <Modal isOpen={isOpen} style={customStyles(width)}>
      <div onClick={e => e.stopPropagation()}>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          {showClose && (
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '2.0833rem',
                marginRight: '1.25rem',
                marginTop: '1.25rem',
                cursor: 'pointer',
                backgroundColor: colors.lightGrey,
                borderRadius: '50%',
                width: '2.0833rem',
                height: '2.0833rem',
                opacity: 0.6,
              }}
              onClick={e => {
                e.stopPropagation();
                setIsOpen(false);
              }}
            >
              <IncludeSvg name="close-x" />
            </span>
          )}
        </div>
        <div style={{ margin: '2.0833rem', marginTop: showClose ? 0 : '2.0833rem' }}>
          <h3 className="noselect" style={{ fontWeight: '600' }}>
            {title}
          </h3>

          <p className="noselect" style={{ fontSize: 16 }}>
            {msg}
          </p>
          {children}
          {showProgress && (
            <div className="progress">
              <div
                class="progress-bar progress-bar-striped"
                role="progressbar"
                style={{ width: `${progress}%`, backgroundColor: 'var(--sys)' }}
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          )}
          {(showCancel || showConfirm) && (
            <div className="d-flex justify-content-between mt-5">
              {showCancel && (
                <button
                  className="btn-white-forms "
                  style={{ width: 'calc(50% - 1.6667rem)', outline: 'none' }}
                  onClick={e => {
                    e.stopPropagation();
                    handleCancel();
                  }}
                >
                  {cancelText}
                </button>
              )}
              {showConfirm && (
                <button
                  className="btn-blue-forms "
                  style={{ width: 'calc(50% - 1.6667rem)' }}
                  onClick={e => {
                    if (shouldCloseAfterConfirm) setIsOpen(false);
                    handleConfirm(e);
                  }}
                >
                  {confirmText}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default Confirm;
