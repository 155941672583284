import Main from 'layouts/Main';
import React from 'react';
import LibraryTopControls from 'components/library/LibraryTopControls';
import { useParams } from 'react-router-dom';
import WordListEditor from 'components/library/WordListEditor';

function ManageNegativeWords() {
  const { language } = useParams();

  return (
    <div className="manage-formats-page">
      <Main>
        <LibraryTopControls />
        <div className="resources">
          <WordListEditor isNegativeWordList={true} language={language} />
        </div>
      </Main>
    </div>
  );
}

export default ManageNegativeWords;
