import React from 'react';
import IncludeSvg from 'components/common/IncludeSvg';
import './TabsNavigator.scss';
import { useSelector } from 'react-redux';

function TabsNavigator({
  items,
  selectedItem,
  setSelectedItem,
  setShowSlideModal,
  setToRemove,
  type,
}) {
  const { user } = useSelector(state => state.auth);
  const canAddMoreItems =
    user && user.resources_permissions && user.resources_permissions[type]['add_' + type];

  return (
    <div className="tabs-navigator noselect">
      {items.map((item, index) => (
        <div
          key={item.id}
          className={`tab ${selectedItem.id === item.id ? 'active shadow-sm' : ''} ${
            index < 5 && 'spacer-after'
          }`}
          onClick={() => setSelectedItem(item)}
        >
          <div className={`title truncate`}>{item.title}</div>

          <div className={`close-btn`} onClick={() => setToRemove(item.id)}>
            <IncludeSvg name={'close-x'} />
          </div>
        </div>
      ))}
      {items.length < 6 && canAddMoreItems && (
        <div
          className={`add-btn`}
          onClick={() => {
            setShowSlideModal(true);
          }}
        >
          <IncludeSvg name={'format-plus'} />
        </div>
      )}
    </div>
  );
}

export default TabsNavigator;
