import React, { useState, useEffect } from 'react';
import SelectWrapper, { loadAsyncOptions } from 'components/forms/elements/SelectWrapper';
import Button from 'components/common/Button';
import CheckList from 'components/forms/elements/CheckList';
import InputNumber from 'components/forms/elements/InputNumber';
import InputDate from 'components/forms/elements/InputDate';
import { capitalizeFirst } from 'helpers/text';
import { getCategories } from 'api/common';
import _ from 'lodash';
import CollapsibleSection from 'components/forms/elements/CollapsibleSection';
import { getArticleLists } from 'api/library';

function WordSearchForm({
  mode,
  setMode,
  wordSearchFormInputs,
  wordSearchFormData,
  wordSearchCategories,
  selectedWordSearchCategory,
  setSelectedWordSearchCategory,
  setWordSearchCategories,
  wordLists,
  loadWordLists,
  selectedWordLists,
  setSelectedWordLists,
  isWordListsLoading,
  setWordSearchFormData,
  articleCategories,
  setArticleCategories,
  selectedArticleCategory,
  setSelectedArticleCategory,
  articleLists,
  setArticleLists,
  selectedArticleLists,
  setSelectedArticleLists,
  updateManuscriptWithWordSearch,
  activeModes,
}) {
  const wordListOptionsForSelectWrapper = wordLists.map(list => ({
    value: list.id,
    label: `${list.title}`,
  }));

  return (
    <div className="word-search-form">
      <div className="form-group" style={{ zIndex: 1000 }}>
        <div className="list-filters-section d-flex align-items-center gap-1">
          {['word-lists', 'article-lists'].map((opt, index) => (
            <div
              key={opt}
              className={`list-filters difficulty w-50 text-center ${
                mode === opt ? 'selected' : ''
              } ${!activeModes.includes(opt) ? 'disabled' : ''}`}
              onClick={() => {
                setMode(opt);
              }}
            >
              {capitalizeFirst(opt.replaceAll('-', ' '))}&nbsp;
            </div>
          ))}
        </div>
      </div>
      {Object.keys(wordSearchFormInputs.groups).map((groupTitle, index) => (
        <>
          {index === 1 && (
            <>
              {mode === 'word-lists' && (
                <div className=" d-flex flex-column form-group">
                  <CollapsibleSection title={'Word lists'}>
                    <label className="mb-2">Category</label>
                    <SelectWrapper
                      async
                      key={'category' + wordSearchFormData.language_id}
                      options={wordSearchCategories}
                      value={selectedWordSearchCategory}
                      setValue={setSelectedWordSearchCategory}
                      loadOptions={(search, loadedOptions = []) => {
                        const offset = loadedOptions.length;
                        return loadAsyncOptions({
                          loadedOptions,
                          getItems: getCategories,
                          params: {
                            search,
                            offset,
                            limit: 15,
                            language: wordSearchFormData.language_id,
                            kind: 'library_words',
                            sort: 'alpha',
                          },
                          itemsKey: 'categories',
                          setItemsExternally: setWordSearchCategories,
                          // setSelectedItem: setSelectedCategory,
                        });
                      }}
                    />

                    <label className="mt-4 mb-2">Wordlist</label>
                    <SelectWrapper
                      key={'wordlist' + wordSearchFormData.language_id}
                      value={selectedWordLists[selectedWordLists.length - 1]}
                      options={wordListOptionsForSelectWrapper}
                      setValue={value => {
                        const isListAlreadySelected = selectedWordLists.find(
                          list => list.id === value
                        );
                        if (isListAlreadySelected) return;
                        const list = wordLists.find(list => list.id === value);
                        setSelectedWordLists([...selectedWordLists, { ...list }]);
                      }}
                    />
                    <div className="mt-4">
                      <CheckList
                        title={'Selected Word Lists: ' + selectedWordLists.length}
                        itemsType={'word lists'}
                        items={selectedWordLists.map(list => ({
                          id: list.id,
                          title: list.title,
                          items_count: list.items_count,
                        }))}
                        checkedItems={selectedWordLists}
                        setCheckedItems={arr => setSelectedWordLists(arr)}
                      />
                    </div>
                  </CollapsibleSection>
                </div>
              )}

              {mode === 'article-lists' && (
                <div className="d-flex flex-column form-group">
                  <CollapsibleSection title={'Article lists'}>
                    <label className="mb-2">Category</label>
                    <SelectWrapper
                      async
                      key={'article_category' + wordSearchFormData.language_id}
                      options={articleCategories}
                      value={selectedArticleCategory}
                      setValue={setSelectedArticleCategory}
                      loadOptions={(search, loadedOptions = []) => {
                        const offset = loadedOptions.length;
                        return loadAsyncOptions({
                          loadedOptions,
                          getItems: getCategories,
                          params: {
                            search,
                            offset,
                            limit: 15,
                            language: wordSearchFormData.language_id,
                            kind: 'library_article',
                            sort: 'alpha',
                          },
                          itemsKey: 'categories',
                          setItemsExternally: setArticleCategories,
                          // setSelectedItem: setSelectedCategory,
                        });
                      }}
                    />

                    <label className="mt-4 mb-2">Article list</label>
                    <SelectWrapper
                      async
                      key={'articlelist' + selectedArticleCategory}
                      value={selectedArticleLists[selectedArticleLists.length - 1]}
                      options={articleLists}
                      setValue={value => {
                        const isListAlreadySelected = selectedArticleLists.find(
                          list => list.value === value
                        );
                        if (isListAlreadySelected) return;
                        const list = articleLists.find(list => list.value === value);
                        if (list) setSelectedArticleLists([...selectedArticleLists, { ...list }]);
                      }}
                      loadOptions={(search, loadedOptions = []) => {
                        const offset = loadedOptions.length;
                        return loadAsyncOptions({
                          loadedOptions,
                          getItems: getArticleLists,
                          params: {
                            category_id: selectedArticleCategory,
                            tags: '',
                            search,
                            offset,
                            limit: 15,
                            sort: 'alpha',
                          },
                          itemsKey: 'article_lists',
                          setItemsExternally: setArticleLists,
                          // setSelectedItem: setSelectedCategory,
                        });
                      }}
                    />
                    <div className="mt-4">
                      <CheckList
                        title={'Selected Article Lists: ' + selectedArticleLists.length}
                        itemsType={'article lists'}
                        items={selectedArticleLists.map(list => ({
                          id: list.value,
                          title: list.label,
                        }))}
                        checkedItems={selectedArticleLists.map(list => ({
                          id: list.value,
                          title: list.label,
                        }))}
                        setCheckedItems={arr => setSelectedArticleLists(arr)}
                      />
                    </div>
                  </CollapsibleSection>
                </div>
              )}
            </>
          )}
          <div className={`form-group`}>
            <CollapsibleSection title={capitalizeFirst(groupTitle.replace('_', ' '), true)}>
              <div className={`row ${groupTitle !== 'language' ? 'row-cols-2' : ''}`}>
                {wordSearchFormInputs.groups[groupTitle].map(input =>
                  input.type === 'number' ? (
                    <div className="col mb-2">
                      <label className="mb-2">{input.label}</label>
                      <InputNumber
                        value={wordSearchFormData[input.dataField]}
                        setValue={value =>
                          setWordSearchFormData({
                            ...wordSearchFormData,
                            [input.dataField]: value,
                          })
                        }
                        className="form-control"
                        style={{
                          backgroundColor: input.isSecondaryField ? 'rgb(41, 48, 57)' : undefined,
                        }}
                        type="number"
                        step={1}
                        min={1}
                      />
                    </div>
                  ) : input.type === 'select' ? (
                    <div className="col mb-2">
                      <label className="mb-2">{input.label}</label>

                      <SelectWrapper
                        options={input.options}
                        value={wordSearchFormData[input.dataField]}
                        setValue={value =>
                          setWordSearchFormData({
                            ...wordSearchFormData,
                            [input.dataField]: value,
                          })
                        }
                        menuPalcement={
                          ['Puzzle Shape', 'Difficulty'].includes(input.label) && 'top'
                        }
                        controllerBGColor={input.isSecondaryField ? 'rgb(41, 48, 57)' : undefined}
                      />
                    </div>
                  ) : (
                    <div className="mb-2 w-100" style={{ zIndex: 100 }}>
                      <label className="mb-2">{input.label}</label>
                      <InputDate
                        value={wordSearchFormData[input.dataField]}
                        setValue={value =>
                          setWordSearchFormData({
                            ...wordSearchFormData,
                            [input.dataField]: value,
                          })
                        }
                        className="form-control"
                      />
                    </div>
                  )
                )}

                {index == 1 && (
                  <div className="d-flex w-100 justify-content-center mt-4">
                    <Button
                      disabled={
                        isWordListsLoading ||
                        (selectedWordLists.length === 0 && selectedArticleLists.length === 0)
                      }
                      onClick={() => {
                        updateManuscriptWithWordSearch(mode);
                      }}
                      label={'Load Puzzles'}
                      className="btn-blue-forms"
                      style={{ width: 150, height: 40 }}
                    />
                  </div>
                )}
              </div>
            </CollapsibleSection>
          </div>
        </>
      ))}
    </div>
  );
}

export default WordSearchForm;
