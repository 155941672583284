const segments = [
  {
    to: 20,
    to1: 120,
    from: 1,
    name: 'Word Search (Easy)',
    from1: 101,
    pageSelectorMode: '2-ranges',
    pageSelectorHandSide: 0,
  },
  {
    to: 60,
    to1: 160,
    from: 21,
    name: 'Word Search  (Medium)',
    from1: 121,
    pageSelectorMode: '2-ranges',
    pageSelectorHandSide: 0,
  },
  {
    to: 100,
    to1: 200,
    from: 61,
    name: 'Word Search (Hard)',
    from1: 161,
    pageSelectorMode: '2-ranges',
    pageSelectorHandSide: 0,
  },
];

// result should look like this
// [
//   {
//     number: 1,
//     title: 'Word Search (Easy)' + ' - Puzzles',
//     dots: '...',
//     pageNumber: 1, // from
//   },
//   {
//     number: 2,
//     title: 'Word Search (Medium)' + ' - Puzzles',
//     dots: '...',
//     pageNumber: 21, // from
//   },

//   {
//     number: 3,
//     title: 'Word Search (Hard)' + ' - Puzzles',
//     dots: '...',
//     pageNumber: 61, // from
//   },
//   {
//     number: 4,
//     title: 'Word Search (Easy)' + ' - Solutions',
//     dots: '...',
//     pageNumber: 101, // from1
//   },
//   {
//     number: 5,
//     title: 'Word Search (Medium)' + ' - Solutions',
//     dots: '...',
//     pageNumber: 121, // from1
//   },
//   {
//     number: 6,
//     title: 'Word Search (Hard)' + ' - Solutions',
//     dots: '...',
//     pageNumber: 161, // from1
//   },
// ];

export const createTableOfContentsFromSegments = (segments, firstPageIndex) => {
  const table = [];
  segments.forEach(segment => {
    if (segment.to === segment.from1) {
      table.push({
        title: segment.name,
        dots: '...',
        pageNumber: segment.from - firstPageIndex,
      });
    } else {
      table.push({
        title: segment.name + ' - Puzzles',
        dots: '...',
        pageNumber: segment.from - firstPageIndex,
      });
      table.push({
        title: segment.name + ' - Solutions',
        dots: '...',
        pageNumber: segment.from1 - firstPageIndex,
      });
    }
  });

  // sort table using pageNumber
  table.sort((a, b) => a.pageNumber - b.pageNumber);

  // add number to each row
  table.forEach((row, index) => {
    row.number = index + 1;
  });

  return table;
};

export const updateShapeByAttr = ({ shape, newValue, attr, markedWords }) => {
  const newShape = { ...shape };
  newShape.attrs[attr] = newValue;
  if (markedWords) newShape.attrs.staticLink.markedWords = markedWords;
  return newShape;
};

export const shuffle = unshuffled =>
  unshuffled
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
