import VersionList from './VersionList';
import 'components/common/ListEditor.scss';
import Tags from 'components/common/Tags';
import { capitalizeFirst } from 'helpers/text';

import React, { useState, useEffect, useRef } from 'react';
import { addVersion, deleteVersion, editVersion, getOneVersion } from 'api/variables';
import { useGlobalContext } from 'state/hooks/global';

import IncludeSvg from 'components/common/IncludeSvg';
import { useSelector } from 'react-redux';
const OPTS_A = [''];
const OPTS_B = [''];

function VersionEditor({
  isSwap = false,
  selectedVariable,
  versionNames,
  selectedVersionName,
  handleVersionNameSelect,
  selectedVersion,
  language,
  allowedTags,
  status,
  handleAfterVersionChange,
}) {
  const { user } = useSelector(state => state.auth);
  const canChangeVariables = user?.resources_permissions?.variable?.change_variable;

  const [listA, setListA] = useState(OPTS_A);
  const [listB, setListB] = useState(OPTS_B);

  const [tagsArr, setTagsArr] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  // const [selectedParagraphsListA, setSelectedParagraphsListA] = useState([]);

  const [transferToBTrig, setTransferToBTrig] = useState({
    val: false,
    all: false,
  });
  // const [letterCase, setLetterCase] = useState('--');
  // const listNameRef = useRef(null);

  const { setMsgs, getLanguageID } = useGlobalContext();

  const transferToA = list => {
    setListA(listA[0] && listA[0].trim().length ? [listA[0] + '\n\n' + list[0]] : [list[0]]);
    setListB(['']);
  };

  const transferToB = list => {
    setListB([...list]);
    setListA(['']);
  };

  const transfer = (dir, all) => {
    switch (dir) {
      case 'toA':
        transferToA(listB);
        break;
      case 'toB':
        transferToB(listA);
        break;
    }
  };

  const next = () => {
    if (versionNames.length && selectedVersionName && handleVersionNameSelect) {
      const index = versionNames.findIndex(vN => vN.id === selectedVersionName.id);
      const next = index + 1;
      if (next > -1 && versionNames[next]) return versionNames[next];
      else return null;
    }
  };
  const handleNext = () => {
    if (isLoading) return;
    const n = next();
    if (n) handleVersionNameSelect(n);
  };

  const prev = () => {
    if (versionNames.length && selectedVersionName && handleVersionNameSelect) {
      const index = versionNames.findIndex(vN => vN.id === selectedVersionName.id);
      const prev = index - 1;
      if (prev > -1 && prev < versionNames.length - 1 && versionNames[prev])
        return versionNames[prev];
      else return null;
    }
  };

  const handlePrev = () => {
    if (isLoading) return;
    const p = prev();
    if (p) handleVersionNameSelect(p);
  };

  const handleSave = () => {
    const data = {
      tags: tagsArr.map(tag => tag.id),
      items: listA,
      draft_items: listB,
      is_published: true,
    };

    if (!selectedVersion) {
      data['version_name_id'] = selectedVersionName.id;
      data['language_id'] = getLanguageID(language);
      data['variable_id'] = selectedVariable.id;
      // add primary keys
      addVersion({ data, isSwap }).then(res => {
        if (res.success) setMsgs([{ type: 'success', content: 'Version created successfully!' }]);
        handleAfterVersionChange('create', selectedVariable.id, {
          ...res.version,
          version_id: res.version.id,
        });
      });
    } else {
      editVersion({ data, id: selectedVersion.version_id, isSwap }).then(res => {
        if (res.success) setMsgs([{ type: 'success', content: 'Version updated successfully!' }]);
      });
    }
  };

  // const updateList = (setter, cb) => {
  //   const list = listA.slice();
  //   const updatedList = cb(list);
  //   setter(updatedList);
  //   setSelectedParagraphsListA(['']);
  // };

  // const sortList = dir => {
  //   switch (dir) {
  //     case 'Ascending':
  //       updateList(setListA, list => list.sort());
  //       break;
  //     case 'Descending':
  //       updateList(setListA, list =>
  //         list.sort((a, b) => {
  //           if (a > b) return -1;
  //           if (b > a) return 1;
  //           return 0;
  //         })
  //       );
  //       break;
  //   }
  // };

  // const formatVersion = (format, word) => {
  //   let val;
  //   switch (format) {
  //     case '--':
  //       val= word;
  //       break;
  //     case 'AA':
  //       val = word.toUpperCase();
  //       break;
  //     case 'Aa':
  //       val = capitalizeFirst(word.toLowerCase());
  //       break;
  //     case 'aa':
  //     default:
  //       val = word.toLowerCase();
  //       break;
  //   }

  //   return val;
  // };

  const handleDelete = () => {
    setIsLoading(true);
    deleteVersion({ id: selectedVersion.version_id, isSwap })
      .then(res => {
        if (res.success) {
          handleAfterVersionChange('delete', selectedVariable.id, selectedVersion);
          setMsgs([{ type: 'success', content: 'Version deleted successfully!' }]);
        }
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const loadVersion = () => {
    setIsLoading(true);
    setTagsArr([]);
    setListA(['']);
    setListB(['']);
    if (selectedVersion)
      getOneVersion({ id: selectedVersion.version_id, isSwap }).then(res => {
        if (res.version.items)
          setListA(
            res.version.items && res.version.items.length ? res.version.items : ['']
            // res.version.items?.map(item => formatVersion('AA', item)) ?? []
          );

        if (res.version.draft_items)
          setListB(
            res.version.draft_items && res.version.draft_items.length
              ? res.version.draft_items
              : ['']
            // res.version.draft_items?.map(item => formatVersion('AA', item)) ??
            // [""]
          );
        setTagsArr(
          res.version.tags
            .map(id => allowedTags.find(tag => tag.id === id))
            .filter(tag => tag !== undefined) // in case category deleted
        );
        // sortList('Ascending');
        setIsLoading(false);
      });
    setIsLoading(false);
  };

  // useEffect(() => {
  //   updateList(setListA, list =>
  //     list.map(word => formatVersion(letterCase, word))
  //   );
  // }, [letterCase]);

  useEffect(loadVersion, [selectedVersion]);

  return (
    <div className={`list-editor ${isSwap ? 'swap' : ''}`}>
      <div className="list-editor__controls">
        <div className="list-editor__controls--name w-100 m-0 shadow-sm">
          {selectedVersion && <IncludeSvg name="var-exists" />}
          {!selectedVersion && status === false && <IncludeSvg name="var-partial" />}
          {!selectedVersion && status === null && <IncludeSvg name="var-missing" />}
          <input
            // ref={listNameRef}
            value={selectedVersionName.title + ' (' + capitalizeFirst(language) + ')'}
            className={'version'}
            placeholder="List name here..."
            disabled
            style={{ backgroundColor: 'transparent' }}
          />
          <div className={`right d-flex align-items-center justify-content-end`}>
            <span
              style={{
                transform: 'rotate(90deg)',
                marginRight: 10,
                marginLeft: 10,
              }}
              className="d-flex align-items-center"
            >
              <button
                disabled={isLoading}
                style={{ margin: 0 }}
                className={`arrow ${!!prev() ? 'active' : ''}`}
                onClick={handlePrev}
              >
                <IncludeSvg name={'arrow-left'} />
              </button>
            </span>
            <span
              style={{ transform: 'rotate(90deg)' }}
              className="d-flex align-items-center justify-content-center"
            >
              <button
                className={`arrow  ${!!next() ? 'active' : ''}`}
                style={{ margin: 0 }}
                onClick={handleNext}
              >
                <IncludeSvg name={'arrow-right'} />
              </button>
            </span>
          </div>
        </div>
      </div>

      <div className="list-editor__body shadow-sm">
        <VersionList
          key="listA"
          listName="listA"
          title={'Version'}
          paragraphs={listA}
          setParagraphs={setListA}
          // selectedParagraphs={selectedParagraphsListA}
          // setSelectedParagraphs={setSelectedParagraphsListA}
          size={!isSwap ? 15 : 8}
          editable={false}
          transfer={transferToB}
          transferTrig={transferToBTrig}
          setTransferTrig={setTransferToBTrig}
          isLoading={isLoading}
          // formatVersion={formatVersion}
          // updateList={updateList}
          // sortList={sortList}
          // letterCase={letterCase}
          // additionalLetterCase={true} // '--'additionalLetterCase
          // setLetterCase={setLetterCase}
          selectable={false}
        />
        <div className="list-editor__body--contorls">
          <button
            disabled={!listB[0].trim()}
            onClick={() => transfer('toA', false)}
            className="trivia blue shadow-sm"
          >
            &lsaquo;
          </button>
          <button
            disabled={!listA[0].trim()}
            onClick={() => transfer('toB', false)}
            className="trivia white shadow-sm"
          >
            &rsaquo;
          </button>
          <button
            disabled={!selectedVersion}
            onClick={handleDelete}
            className="trivia white shadow-sm align-items-center"
          >
            <IncludeSvg name="delete" />
          </button>
          {canChangeVariables && (
            <button onClick={handleSave} className="version blue shadow-sm">
              Save
            </button>
          )}
        </div>
        <div className="d-flex flex-column w-100">
          <div className="list-editor__body--textareas">
            <h4>Add Text</h4>
            <div className="list-editor__body--textareas-wrapper border shadow-sm">
              <textarea
                style={{ height: !isSwap ? 190 : 176 }}
                value={listB[0]}
                onChange={e => setListB([e.target.value])}
              ></textarea>
            </div>
          </div>
          {!isSwap && (
            <Tags
              title={'Tags'}
              allowedTags={allowedTags}
              tagsArr={tagsArr}
              setTagsArr={setTagsArr}
              classNameB={'border shadow-sm px-3 py-2'}
              inputClassName={'border shadow-sm w-100'}
              height={50}
              inputPlaceholder={'Teens, Adults, Seasons,... etc'}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default VersionEditor;
