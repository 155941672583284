import React, { useState, useEffect, useRef } from 'react';
import { useOnClickOnEscape, useWindowSize } from 'hooks/util';
import { addCover } from 'api/studio/covers';
import { Stage, Layer, Rect } from 'react-konva';
import { useHistory } from 'react-router-dom';
import CheckboxLock from 'components/forms/elements/CheckboxLock';
import InputNumber from 'components/forms/elements/InputNumber';
import SelectWrapper from 'components/forms/elements/SelectWrapper';
// import { paperThicknesses } from 'settings/paperThicknesses';
import { getManuscripts } from 'api/studio/manuscripts';
import IncludeSvg from 'components/common/IncludeSvg';

import Tags from 'components/common/Tags';
import { getCategories } from 'api/common';
import CheckList from 'components/forms/elements/CheckList';
import { getTags } from 'api/common';
import { useGlobalContext } from 'state/hooks/global';

import { PUZZLE_CONTENT_TYPES } from 'settings/puzzels';
import moment from 'moment';

const MM_TO_INCH = 0.0393701;

function CoverSpecForm({
  closeSlideModal,
  pageSizes,
  paperThicknessList,
  allowedTags,
  coverCategoryId,
  addCoverToWorkspace,
}) {
  const [step, setStep] = useState('a');
  const previewRef = useRef(null);
  const history = useHistory();

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [manuscriptsAllowedTags, setManuscriptsAllowedTags] = useState([]);
  const [manuscriptsTagsArr, setManuscriptsTagsArr] = useState([]);
  const [manuscriptsTags, setManuscriptsTags] = useState('');

  const [manuscripts, setManuscripts] = useState([]);
  const [manuscriptsCount, setManuscriptsCount] = useState(0);
  const [checkedManuscripts, setCheckedManuscripts] = useState([]);
  const [isManuscriptsLoading, setIsManuscriptsLoading] = useState(false);
  const [contentTypes, setContentTypes] = useState([]);
  const [width] = useWindowSize();
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [manuscriptError, setManuscriptError] = useState(false);

  const [pageSize, setPageSize] = useState([]);
  const [pageSizeIndex, setPageSizeIndex] = useState(0);
  const [paperThickness, setPaperThickness] = useState(
    paperThicknessList.length ? paperThicknessList[0].id : 0
  );

  const [marginT, setMarginT] = useState(0);
  const [marginR, setMarginR] = useState(0);
  const [marginB, setMarginB] = useState(0);
  const [marginL, setMarginL] = useState(0);
  const [marginsLocked, setMarginsLocked] = useState(true);

  const [bleedingT, setBleedingT] = useState(0.2);
  const [bleedingR, setBleedingR] = useState(0.2);
  const [bleedingB, setBleedingB] = useState(0.2);
  const [bleedingL, setBleedingL] = useState(0.2);
  const [bleedingsLocked, setBleedingsLocked] = useState(true);

  const [scale, setScale] = useState(1);
  const [tagsArr, setTagsArr] = useState([]);

  const { setMsgs } = useGlobalContext();

  const dims = {
    previewWidth: () => previewRef.current.clientWidth,

    totalHeightInPx: () => (pageSizes[pageSizeIndex].height + bleedingT + bleedingB) * 96,

    totalWidthInPx: () => (pageSizes[pageSizeIndex].width + bleedingR + bleedingL) * 96,

    pageHeight: () => pageSizes[pageSizeIndex].height,

    pageWidth: () => pageSizes[pageSizeIndex].width,

    pageHeightInPx: () => pageSizes[pageSizeIndex].height * 96,

    pageWidthInPx: () => pageSizes[pageSizeIndex].width * 96,

    marginsHeightInPx: () => (pageSizes[pageSizeIndex].height - marginT - marginB) * 96,

    marginsWidthInPx: () => (pageSizes[pageSizeIndex].width - marginR - marginL) * 96,
  };

  useEffect(() => {
    getTags({ kind: 'studio_manuscripts', sort: 'alpha' }).then(res => {
      if (res.success) {
        setManuscriptsAllowedTags(res.tags);
      }
    });
  }, []);

  useEffect(() => {
    setScale(
      Math.min(
        dims.previewWidth() / 1.5 / dims.pageHeightInPx(),
        dims.previewWidth() / 1.5 / dims.pageWidthInPx()
      )
    );
  }, [
    pageSizeIndex,
    marginT,
    marginR,
    marginB,
    marginL,
    bleedingT,
    bleedingR,
    bleedingB,
    bleedingL,
    width,
  ]);

  useOnClickOnEscape(closeSlideModal);

  const tagsIdsArr = () => tagsArr.map(item => item.id);

  const loadCategories = (search, offset) => {
    const params = {
      kind: 'studio_manuscripts',
      sort: 'alpha',
      search,
      offset,
    };
    return getCategories(params)
      .then(res => {
        if (res.success) {
          const list = [...categories];
          const items = offset === 0 ? res['categories'] : list.concat(res['categories']);

          const options = res.categories.map(category => ({
            label: category.title,
            value: category.id,
          }));

          setCategories(offset > 0 ? [...categories, ...options] : options);
          if (offset === 0 && search === '') setSelectedCategory(items[0].id);

          return {
            options,
            hasMore: offset + 15 < res.count,
            additional: {
              offset: offset + 15,
            },
          };
        }
      })
      .catch(e => console.log);
  };

  const loadManuscripts = offset => {
    if (isManuscriptsLoading) return;
    if (offset === 0) {
      setManuscripts([]);
      setManuscriptsCount(0);
    }
    if (offset === 0 || offset < manuscriptsCount) {
      setIsManuscriptsLoading(true);
      getManuscripts(selectedCategory, 'alpha', manuscriptsTags, offset)
        .then(res => {
          if (res.success) {
            const list = [...manuscripts];
            const items = offset === 0 ? res['manuscripts'] : list.concat(res['manuscripts']);
            setManuscriptsCount(res.count);
            setTimeout(() => {
              setManuscripts(items);
              setIsManuscriptsLoading(false);
            }, 500);
          }
        })
        .catch(e => console.log);
    }
  };

  const handleSubmit = () => {
    if (!checkedManuscripts.length) {
      setStep('a');
      setManuscriptError(true);
      return;
    }

    if (step === 'a') {
      setStep('b');
      return;
    }

    if (step === 'b') {
      if (!title.trim()) {
        setTitleError(true);
        return;
      }

      addCover({
        title: title,
        page_size: pageSizes[pageSizeIndex].id,
        paper_thickness: paperThickness,
        margin: {
          left: Number(marginL),
          right: Number(marginR),
          top: Number(marginT),
          bottom: Number(marginB),
        },
        bleeding: {
          left: Number(bleedingL),
          right: Number(bleedingR),
          top: Number(bleedingT),
          bottom: Number(bleedingB),
        },
        category: coverCategoryId,
        manuscript_id: checkedManuscripts[0].id,
        tags: tagsIdsArr(),
      }).then(res => {
        if (res.success) {
          addCoverToWorkspace(res.cover);
          history.push(`/home/covers/workspace`);
          closeSlideModal();
        } else {
          console.log(res);
        }
      });
    }
  };

  const handleLockedMarginsChange = val => {
    setMarginT(val);
    setMarginR(val);
    setMarginB(val);
    setMarginL(val);
  };

  const handleLockedBleedingsChange = val => {
    if (val <= dims.pageWidth() * 0.2) {
      setBleedingT(val);
      setBleedingR(val);
      setBleedingB(val);
      setBleedingL(val);
    }
  };

  useEffect(() => {
    if (checkedManuscripts.length) setManuscriptError(false);
  }, [checkedManuscripts.length]);

  useEffect(() => {
    if (selectedCategory) {
      setCheckedManuscripts([]);
      loadManuscripts(0);
    }
  }, [selectedCategory, manuscriptsTags]);

  useEffect(() => {
    let str = '&';
    manuscriptsTagsArr.forEach(tag => {
      const index = manuscriptsAllowedTags.findIndex(
        allowedTag => allowedTag.name.toLowerCase() === tag.name.toLowerCase()
      );
      if (index > -1) {
        str += `tag=${manuscriptsAllowedTags[index].id}&`;
      }
    });
    const s = str.slice(0, -1);
    setManuscriptsTags(s);
  }, [manuscriptsTagsArr]);

  useEffect(() => {
    if (checkedManuscripts.length) {
      const size = pageSizes.find(size => checkedManuscripts[0].page_size === size.id);
      if (size) setPageSize(`${size.width} x ${size.height} Inchs`);
      else setPageSize(`-`);

      const types = checkedManuscripts[0].content_types;

      const contentTypes = types
        .filter(type => PUZZLE_CONTENT_TYPES.includes(type))
        .map(type =>
          type.includes('Coloring Book Image') || type.includes('story-word-list-text')
            ? type
            : type
                .split('- ')
                [type.includes('grid') ? 0 : 1].toLowerCase()
                .replace(' ', '-')
                .replace('-grid-', '')
        );
      setContentTypes([...new Set(contentTypes)]);
    }
  }, [checkedManuscripts]);

  let spineSizeInInches = 0;
  const paperThick = paperThicknessList.find(t => t.id === paperThickness);
  if (paperThick && checkedManuscripts.length) {
    spineSizeInInches = paperThick.value * MM_TO_INCH * checkedManuscripts[0].number_of_pages;
    spineSizeInInches = spineSizeInInches.toFixed(3);
  }

  const copyCoverDetails = () => {
    const copyText = `Overall cover size: ${(dims.totalWidthInPx() / 96).toFixed(2)}" x ${(
      dims.totalHeightInPx() / 96
    ).toFixed(2)}" \n
    Page size: ${dims.pageWidth()} x ${dims.pageHeight()}" \n
    Spine size: ${spineSizeInInches}" \n
    Bleeding Top: ${bleedingT}" \n
    Bleeding Right: ${bleedingR}" \n
    Bleeding Bottom: ${bleedingB}" \n
    Bleeding Left: ${bleedingL}"`;
    navigator.clipboard.writeText(copyText);
    setMsgs([{ type: 'success', content: 'Copied Cover details!' }]);
  };

  return (
    <div className="form__spec">
      <div className="form__spec--preview manuscript" ref={previewRef}>
        {step === 'a' && checkedManuscripts.length ? (
          <div className=" d-flex flex-column w-100 justify-content-start align-items-center">
            <h3 className="title mb-4">Manuscript Sepecifications</h3>
            <h4 className="w-100 text-left">Template details</h4>
            <div
              className="details d-flex w-100 mx-5 p-4 h4"
              style={{
                border: '0.1667rem solid var(--sys)',
                borderRadius: 10,
              }}
            >
              {[
                { label: 'Name', valueField: 'title' },
                { label: 'Page Size', valueField: 'page_size' },
                { label: 'Number of Pages', valueField: 'number_of_pages' },
                { label: 'Created', valueField: 'created_at' },
                { label: 'Updated', valueField: 'updated_at' },
                { label: 'Last Used', valueField: 'last_used_at' },
                { label: 'Times Used', valueField: 'times_used' },
              ].map(detail => (
                <div className={detail.label === 'Name' ? 'mb-4' : 'mb-1'}>
                  <span className="label">{detail.label}:</span>
                  <span className="value">
                    {detail.valueField === 'page_size'
                      ? pageSize
                      : detail.valueField === 'created_at' || detail.valueField === 'updated_at'
                      ? moment(checkedManuscripts[0]?.[detail.valueField]).format('MMM DD, YYYY')
                      : checkedManuscripts[0]?.[detail.valueField] ?? '-'}
                  </span>
                </div>
              ))}
            </div>
            {contentTypes.length ? (
              <>
                <h4 className="w-100 text-left mt-4">Content Types</h4>
                <div
                  className="details d-flex w-100 mx-5 p-3 h4"
                  style={{
                    border: '0.1667rem solid var(--sys)',
                    borderRadius: 10,
                  }}
                >
                  <div className="top-controls icon-btns">
                    {contentTypes.map(type => (
                      <button>
                        <IncludeSvg name={type.includes('story') ? 'story-manuscript' : type} />
                      </button>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
        {step === 'b' && checkedManuscripts.length ? (
          <div className="d-flex flex-column w-100">
            <h3 className="title w-100 text-center">Cover Sepecifications</h3>
            <h4 className="w-100 text-left">Cover details</h4>
            <div
              className="details d-flex w-100 p-4 h4"
              style={{
                border: '0.1667rem solid var(--sys)',
                borderRadius: 10,
                position: 'relative',
              }}
            >
              <button
                style={{ width: 80, position: 'absolute', top: 10, right: 10 }}
                className="btn-blue-forms d-flex text center justify-content-center align-items-center"
                onClick={copyCoverDetails}
              >
                Copy
              </button>
              <div className="mb-1">
                <span className="label">Over all cover size:</span>
                <span className="value">{`${(dims.totalWidthInPx() / 96).toFixed(2)}" x ${(
                  dims.totalHeightInPx() / 96
                ).toFixed(2)}"`}</span>
              </div>
              <div className="mb-1">
                <span className="label">Page size:</span>
                <span className="value">{`${dims.pageWidth()} x ${dims.pageHeight()}"`}</span>
              </div>
              <div className="mb-1">
                <span className="label">Spine size:</span>
                <span className="value">{`${spineSizeInInches}"`}</span>
              </div>
              <div className="mb-1">
                <span className="label">Bleeding Top:</span>
                <span className="value">{`${bleedingT}"`}</span>
              </div>
              <div className="mb-1">
                <span className="label">Bleeding Right:</span>
                <span className="value">{`${bleedingR}"`}</span>
              </div>
              <div className="mb-1">
                <span className="label">Bleeding Bottom:</span>
                <span className="value">{`${bleedingB}"`}</span>
              </div>
              <div className="mb-1">
                <span className="label">Bleeding Left</span>
                <span className="value">{`${bleedingL}"`}</span>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>

      <div className="form form__spec--controls">
        {step === 'a' && (
          <div
            style={{
              height: 'calc(100vh - 12.5rem)',
              paddingBottom: 50,
            }}
          >
            <div>
              <div className="form__spec--controls-heading">Select Manuscript</div>
              <div className="form-group d-flex flex-column">
                <label className="mb-3">Manuscript Category</label>
                <SelectWrapper
                  async
                  options={categories}
                  value={selectedCategory}
                  setValue={setSelectedCategory}
                  loadOptions={(search, loadedOptions = []) =>
                    loadCategories(search, loadedOptions.length)
                  }
                />
              </div>

              <Tags
                title={'Manuscript Tags'}
                allowedTags={manuscriptsAllowedTags}
                tagsArr={manuscriptsTagsArr}
                setTagsArr={setManuscriptsTagsArr}
                classNameA={'form-group'}
                classNameB={''}
                inputClassName={'form-control light'}
                height={50}
                inputPlaceholder={'Search...'}
                showHead={false}
                showCount={false}
                scrollDirection={'x'}
                searchable={true}
              />
              <CheckList
                radioSelect
                title={'Manuscript'}
                itemsType={'manuscripts'}
                items={manuscripts}
                loadItems={loadManuscripts}
                checkedItems={checkedManuscripts}
                setCheckedItems={setCheckedManuscripts}
                isLoading={isManuscriptsLoading}
              />
              {manuscriptError && <span className="text-error">Manuscript is required!</span>}
            </div>
          </div>
        )}

        {step === 'b' && (
          <div>
            <div className="form__spec--controls-heading">Cover Specifications</div>
            <div className="scrollable-window " style={{ maxHeight: '77vh' }}>
              <div className="form-group">
                <label>Title</label>
                <input
                  className="form-control light mb-2"
                  type="text"
                  placeholder="Title goes here..."
                  value={title}
                  onChange={e => {
                    setTitle(e.target.value);
                    setTitleError(false);
                  }}
                />
                {titleError && <span className="text-error">Title is required!</span>}
              </div>
              <div className="form-section-2">
                <div className="form-group">
                  <label>Page size</label>
                  <SelectWrapper
                    className={`form-control`}
                    options={pageSizes.map((size, index) => ({
                      label: `${size.width} x ${size.height} Inches`,
                      value: index,
                    }))}
                    isSearchable={true}
                    value={pageSizeIndex}
                    setValue={setPageSizeIndex}
                  />
                </div>
                <div className="form-group">
                  <label>Paper Thickness</label>
                  <SelectWrapper
                    className={`form-control`}
                    options={paperThicknessList.map(t => ({
                      label: t.value,
                      value: t.id,
                    }))}
                    value={paperThickness}
                    setValue={setPaperThickness}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Margin</label>
                <div className="form-section-3">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <label className="text-end px-4">Left</label>
                      <InputNumber
                        className={'form-control'}
                        min={0}
                        step={0.01}
                        value={marginL}
                        setValue={val => {
                          if (marginsLocked) handleLockedMarginsChange(val);
                          else setMarginL(val);
                        }}
                      />
                    </div>
                    <div className="d-flex align-items-center mt-3">
                      <label className="text-end px-4">Right</label>
                      <InputNumber
                        className={'form-control'}
                        min={0}
                        step={0.01}
                        value={marginR}
                        setValue={val => {
                          if (marginsLocked) handleLockedMarginsChange(val);
                          else setMarginR(val);
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center middle">
                    <CheckboxLock
                      className="form-check-input"
                      checked={marginsLocked}
                      onChange={() => {
                        setMarginsLocked(!marginsLocked);
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <InputNumber
                        className={'form-control'}
                        min={0}
                        step={0.01}
                        value={marginT}
                        setValue={val => {
                          if (marginsLocked) handleLockedMarginsChange(val);
                          else setMarginT(val);
                        }}
                      />
                      <label className="px-4">Top</label>
                    </div>
                    <div className="d-flex align-items-center mt-3">
                      <InputNumber
                        className={'form-control'}
                        min={0}
                        step={0.01}
                        value={marginB}
                        setValue={val => {
                          if (marginsLocked) handleLockedMarginsChange(val);
                          else setMarginB(val);
                        }}
                      />
                      <label className="px-4">Bottom</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Bleeding</label>
                <div className="form-section-3">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <label className="text-end px-4">Left</label>
                      <InputNumber
                        className={'form-control'}
                        min={0}
                        max={dims.pageWidth() * 0.2}
                        step={0.01}
                        value={bleedingL}
                        setValue={val => {
                          if (bleedingsLocked) handleLockedBleedingsChange(val);
                          else setBleedingL(val);
                        }}
                      />
                    </div>
                    <div className="d-flex align-items-center mt-3">
                      <label className="text-end px-4">Right</label>
                      <InputNumber
                        className={'form-control'}
                        min={0}
                        max={dims.pageWidth() * 0.2}
                        step={0.01}
                        value={bleedingR}
                        setValue={val => {
                          if (bleedingsLocked) handleLockedBleedingsChange(val);
                          else setBleedingR(val);
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center middle">
                    <CheckboxLock
                      className="form-check-input"
                      checked={bleedingsLocked}
                      onChange={() => {
                        setBleedingsLocked(!bleedingsLocked);
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <InputNumber
                        className={'form-control'}
                        min={0}
                        max={dims.pageHeight() * 0.2}
                        step={0.01}
                        value={bleedingT}
                        setValue={val => {
                          if (bleedingsLocked) handleLockedBleedingsChange(val);
                          else setBleedingT(val);
                        }}
                      />
                      <label className="px-4">Top</label>
                    </div>
                    <div className="d-flex align-items-center mt-3">
                      <InputNumber
                        className={'form-control'}
                        min={0}
                        max={dims.pageHeight() * 0.2}
                        step={0.01}
                        value={bleedingB}
                        setValue={val => {
                          if (bleedingsLocked) handleLockedBleedingsChange(val);
                          else setBleedingB(val);
                        }}
                      />
                      <label className="px-4">Bottom</label>
                    </div>
                  </div>
                </div>
              </div>

              <Tags
                title={'Tags'}
                allowedTags={allowedTags}
                tagsArr={tagsArr}
                setTagsArr={setTagsArr}
                classNameA={'form-group'}
                classNameB={''}
                inputClassName={'form-control light'}
                height={'4.1667rem'}
                inputPlaceholder={'Teens, Adults, Seasons,... etc'}
                showCount={false}
              />
            </div>
          </div>
        )}

        <div className="d-flex justify-content-center mt-4">
          <div className="form-steps-group" style={{ position: 'absolute', bottom: 100 }}>
            <span
              className={`form-step ${step === 'a' ? 'active' : ''}`}
              onClick={() => setStep('a')}
            >
              &nbsp;
            </span>
            <span
              className={`form-step ${step === 'b' ? 'active' : ''}`}
              onClick={() => handleSubmit()}
            >
              &nbsp;
            </span>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-4">
          <div className="d-flex w-75" style={{ position: 'absolute', bottom: 25 }}>
            <button
              className="btn-white-forms mx-2"
              onClick={() => {
                closeSlideModal();
              }}
            >
              Cancel
            </button>
            <button className="btn-blue-forms mx-2" onClick={handleSubmit}>
              Next
            </button>
          </div>
        </div>
      </div>
      <button className="form__spec--close-btn" onClick={closeSlideModal}>
        x
      </button>
    </div>
  );
}

export default CoverSpecForm;
