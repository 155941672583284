import SelectWrapper, { loadAsyncOptions } from 'components/forms/elements/SelectWrapper';
import Button from 'components/common/Button';
import CheckList from 'components/forms/elements/CheckList';
import { getCategories } from 'api/common';
import _ from 'lodash';
import CollapsibleSection from 'components/forms/elements/CollapsibleSection';
import { getElements } from 'api/elements';

function ElementsForm({
  elementTypes,
  setElementTypes,
  selectedElementType,
  setSelectedElementType,
  elementCategories,
  setElementCategories,
  selectedElementsCategory,
  setSelectedElementsCategory,
  elements,
  setElements,
  selectedElements,
  selectedElementsIds,
  setSelectedElements,
  updateManuscriptWithElements,
  isLoading,
}) {
  return (
    <div>
      <div className=" d-flex flex-column form-group">
        <CollapsibleSection title={'Select Element Type'}>
          <label className="mb-2">Type</label>
          <SelectWrapper
            key={'element_type'}
            options={elementTypes.map(el => ({
              label: el.name,
              value: el.id,
            }))}
            value={selectedElementType}
            setValue={setSelectedElementType}
          />
        </CollapsibleSection>
      </div>

      <div className={`d-flex flex-column form-group `}>
        <CollapsibleSection title={'Select Elements'}>
          <div className={!selectedElementType ? 'disabled' : ''}>
            <label className="mb-2">Category</label>
            <SelectWrapper
              async
              key={'type_' + selectedElementType}
              disabled={!selectedElementType}
              options={elementCategories}
              value={selectedElementsCategory}
              setValue={setSelectedElementsCategory}
              loadOptions={(search, loadedOptions = []) => {
                if (!selectedElementType) return;
                const offset = loadedOptions.length;
                return loadAsyncOptions({
                  loadedOptions,
                  getItems: getCategories,
                  params: {
                    search,
                    kind: 'elements',
                    element_type: selectedElementType,
                    sort: 'most_popular',
                    offset,
                    limit: 15,
                  },
                  itemsKey: 'categories',
                  setItemsExternally: setElementCategories,
                });
              }}
            />
          </div>

          <div className={!selectedElementsCategory ? 'disabled' : ''}>
            <label className="mt-4 mb-2">Elements</label>
            <SelectWrapper
              async
              disabled={!selectedElementsCategory}
              key={'category_' + selectedElementsCategory}
              value={selectedElements[selectedElements.length - 1] || null}
              options={elements}
              setValue={value => {
                const isElementAlreadySelected = selectedElements.find(
                  list => list.value === value
                );
                if (isElementAlreadySelected) return;
                if (value === 'select-all') {
                  const allElements = elements.filter(
                    el => el.value !== 'select-all' && !selectedElementsIds.includes(el.value)
                  );
                  setSelectedElements([...selectedElements, ...allElements]);
                } else {
                  const element = elements.find(el => el.value === value);
                  if (element) setSelectedElements([...selectedElements, { ...element }]);
                }
              }}
              loadOptions={(search, loadedOptions = []) => {
                if (!selectedElementsCategory) return;
                const offset = loadedOptions.length;
                return loadAsyncOptions({
                  selectAllOption: true,
                  loadedOptions: [...loadedOptions],
                  getItems: getElements,
                  params: {
                    search,
                    category_id: selectedElementsCategory,
                    sort: 'alpha',
                    offset,
                    limit: 1000000,
                  },
                  itemsKey: 'elements',
                  setItemsExternally: setElements,
                });
              }}
            />
            <div className="mt-4">
              <CheckList
                title={'Selected Elements: ' + selectedElements.length}
                itemsType={'elements'}
                items={selectedElements.map(el => ({ id: el.value, title: el.label }))}
                checkedItems={selectedElements.map(el => ({ id: el.value, title: el.label }))}
                setCheckedItems={arr =>
                  setSelectedElements(arr.map(el => ({ value: el.id, label: el.title })))
                }
              />

              <div className="d-flex w-100 justify-content-center mt-4">
                <Button
                  disabled={isLoading || selectedElements.length === 0}
                  onClick={() => updateManuscriptWithElements(false)}
                  label={'Load Images'}
                  className="btn-blue-forms"
                  style={{ width: 150, height: 40 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-4">
                <Button
                  disabled={isLoading || selectedElements.length === 0}
                  onClick={() => updateManuscriptWithElements(true)}
                  label={'Randomize & Refresh'}
                  className="btn-blue-forms"
                  style={{ width: 250, height: 40 }}
                />
              </div>
            </div>
          </div>
        </CollapsibleSection>
      </div>
      {/*
       */}
    </div>
  );
}

export default ElementsForm;
