import React from 'react';
import LogoutButton from '../auth/LogoutButton';
import SideBarItem from './SideBarItem';
import { useGlobalContext } from 'state/hooks/global';
import { hasAccess } from 'helpers/permissions';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import logo from 'assets/imgs/logo.png';
import { useHistory } from 'react-router-dom';

function SideBar({ activeSpace }) {
  const { languages, elementTypes } = useGlobalContext();
  const { user } = useSelector(state => state.auth);
  const [language, setLanguage] = useState(null);
  const [elementType, setElementType] = useState(null);
  const history = useHistory();
  useEffect(() => {
    if (languages.length) {
      setLanguage(languages[0].name.replaceAll(' ', '-').toLowerCase());
    }
  }, [languages]);

  useEffect(() => {
    if (elementTypes.length) {
      setElementType(elementTypes[0].name.replaceAll(' ', '-').toLowerCase());
    }
  }, [elementTypes]);

  const sideBarItems = [
    {
      name: 'studio',
      route: '/home/formats',
      scope: 'home',
      subPaths: [
        '/home/formats',
        '/home/formats/manage',
        '/home/templates',
        '/home/manuscripts',
        '/home/covers',
        '/home/covers/manage',
      ],
    },
    {
      name: 'library',
      route: `/library/${language}/word-lists`,
      scope: 'library',
      subPaths: [
        '/library/:language/word-lists',
        '/library/:language/trivia-lists',
        '/library/:language/article-lists',
        '/library/:language/manage-negative-words',
        '/library/:language/manage-common-words',
      ],
    },
    {
      name: 'elements',
      route: `/elements/${elementType}`,
      scope: 'elements',
      subPaths: ['/elements/:elType', '/elements/:elType/manage'],
    },
    {
      name: 'variables',
      route: '/variables/' + (languages[0] ? languages[0].name.toLowerCase() : 'english'),
      scope: 'variables',
      subPaths: ['/variables/:language', '/variables/:language/manage'],
    },
    {
      name: 'settings',
      route: [
        '/settings/content',
        '/settings/analytics',
        '/settings/accounts',
        '/settings/languages',
        '/settings/tags/:kind',
      ].find(r => hasAccess(r, user)),
      seperator: true,
      scope: 'settings',
      subPaths: [
        '/settings/content',
        '/settings/analytics',
        '/settings/accounts',
        '/settings/languages',
        '/settings/tags/:kind',
      ],
    },
  ];

  const sideBarItemsWithPermissions = sideBarItems.filter(item => {
    return item.subPaths.some(path => hasAccess(path, user));
  });

  const goToHome = () => {
    history.push('/home/formats');
  };

  return (
    <div className="side-bar">
      {sideBarItemsWithPermissions.map((item, index) => (
        <SideBarItem
          key={index}
          name={item.name}
          route={item.route}
          active={activeSpace === item.scope}
          seperator={item.seperator}
        />
      ))}
      <div className="logo-logout-wrapper">
        <a hrer="#" onClick={goToHome}>
          <img className="logo" src={logo} alt="Funcrane Logo" />
        </a>
        <LogoutButton />
      </div>
    </div>
  );
}

export default SideBar;
