import axios from 'axios';
import urls from 'settings/urls.js';

const LIMIT = 15;

export async function getVariables({ params, tagsString = '', isSwap = false }) {
  const requestOptions = {
    method: 'GET',
    url: urls.variables + tagsString,
    data: { is_swap: isSwap ? true : undefined },
    params: { ...params, limit: LIMIT },
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function addVariable({ data, isSwap }) {
  const requestOptions = {
    method: 'POST',
    url: urls.newVariable,
    data,
    params: { is_swap: isSwap ? true : undefined },
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function editVariable({ data, id, isSwap }) {
  const requestOptions = {
    method: 'PATCH',
    url: urls.editVariable(id),
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function deleteVariable({ id, isSwap }) {
  const requestOptions = {
    method: 'DELETE',
    url: urls.deleteVariable(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function getVersionNames() {
  const requestOptions = {
    method: 'GET',
    url: urls.versionNames,
  };
  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function getOneVersion({ id, isSwap }) {
  const requestOptions = {
    method: 'GET',
    url: urls.oneVersion(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function addVersionName({ data, isSwap }) {
  const requestOptions = {
    method: 'POST',
    url: urls.newVersionName,
    data: { ...data, is_swap: isSwap ? true : false },
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function addVersion({ data, isSwap }) {
  const requestOptions = {
    method: 'POST',
    url: urls.newVersion,
    data: { ...data, is_swap: isSwap ? true : undefined },
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function editVersion({ data, id, isSwap }) {
  const requestOptions = {
    method: 'PATCH',
    url: urls.editVersion(id),
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function deleteVersion({ id, isSwap }) {
  const requestOptions = {
    method: 'DELETE',
    url: urls.deleteVersion(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}
