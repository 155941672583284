import React, { useEffect, useState } from 'react';
import {
  useSliderPermissions,
  useSliderControls,
  useSliderLoaders,
  useSliderNavigator,
} from 'hooks/slider/index';

import IncludeSvg from 'components/common/IncludeSvg';
import Item from 'components/common/Item';
import NewItem from 'components/common/NewItem';
import PopupMenu from 'components/common/PopupMenu';
import permissions from 'settings/permissions';
import { useGlobalContext } from 'state/hooks/global';
import usePopup from 'hooks/popup';

function Slider({
  title,
  newTitle,
  showTitle,
  type,
  kind,
  language,
  elementType,
  selectable,
  selectedItem,
  setSelectedItem,
  items = [],
  setItems,
  category,
  sortBy,
  tags,
  uncollapsed = false,
  handleNew,
  handleEditItem,
  updatedItemFromSlideModal,
  setCategoryId,
  newItemFromSlideModal,
  setCategoriesSortBy,
  // lists
  setShowWordListEditor,
  setShowTriviaListEditor,
  setShowArticleListEditor,
  setShowStoryEditor,
  setOffset,
  syncParentLeft,
  setIsCreateMode,
  readOnly = false,
  showNewButton = true,
  outerSelection = true,
  shouldLoadMore = false,
}) {
  const perms = useSliderPermissions(type);
  const [sliderItems, setSliderItems] = useState(items);
  const [left, setLeft] = useState(0);

  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const { languages, getLanguageID, elementTypes, getElementTypeID } = useGlobalContext();

  const { addNewItem, removeAddedNewItem, deleteItem, renameItem, editPageSize } =
    useSliderControls({
      type,
      category,
      sliderItems,
      setSliderItems,
      selectedItem,
      setSelectedItem,
      setCategoryId,
      handleNew,
      setIsCreateMode,
      setShowWordListEditor,
      setShowTriviaListEditor,
      setShowArticleListEditor,
      setShowStoryEditor,
      setOffset,
      elementType,
      setLeft,
      newItemFromSlideModal,
    });

  const {
    sliderTrackRef,
    sliderItemsRef,
    itemWrapperRef,
    moveRight,
    moveLeft,
    showingNumber,
    hiddenItems,
    itemWrapperWidth,
    sliderTrackWidth,
    setSliderTrackWidth,
  } = useSliderNavigator({
    left,
    setLeft,
    sliderItems,
    selectedItem,
    syncParentLeft,
    removeAddedNewItem,
    outerSelection,
  });

  const { handleScroll, count, loadMoreCategories } = useSliderLoaders({
    type,
    kind,
    category,
    sortBy,
    tags,
    searchInput,
    language,
    languages,
    elementType,
    elementTypes,
    permissions,
    setIsLoading,
    setItems,
    setSliderItems,
    sliderItems,
    itemWrapperWidth,
    left,
    setLeft,
    hiddenItems,
    getLanguageID,
    getElementTypeID,
    items,
  });

  useEffect(() => {
    if (updatedItemFromSlideModal) {
      const index = sliderItems.findIndex(item => item.id === updatedItemFromSlideModal.id);
      const newSliderItems = [...sliderItems];
      newSliderItems[index] = updatedItemFromSlideModal;
      setSliderItems(newSliderItems);
    }
  }, [updatedItemFromSlideModal]);

  useEffect(() => {
    if (shouldLoadMore) loadMoreCategories();
  }, [shouldLoadMore]);

  const { popupRef, popup, setPopup, popupOptions } = usePopup({
    popupOptions: [
      {
        title: 'Alphabetically',
        value: 'alpha',
        handler: () => {
          setCategoriesSortBy('alpha');
          setPopup(false);
        },
      },
      {
        title: 'Recently Added',
        value: 'recently_added',
        handler: () => {
          setCategoriesSortBy('recently_added');
          setPopup(false);
        },
      },
      {
        title: 'Most Popular',
        value: 'most_popular',
        handler: () => {
          setCategoriesSortBy('most_popular');
          setPopup(false);
        },
      },
    ],
  });

  useEffect(() => {
    if (selectable) setSelectedItem(null);
  }, [sortBy, searchInput]);

  const titleOf = objVal => {
    return popupOptions.find(option => option.value === objVal).title;
  };

  const showSeeAllBtn =
    !uncollapsed &&
    ![
      'category',
      'size',
      'element-type',
      'variable',
      'tag',
      'swap',
      'account',
      'language',
      'thickness',
    ].includes(type);

  const isSearchable = ['category', 'variable', 'swap'].includes(type);

  const arrowLeftActve =
    left < 0 || (left !== 0 && sliderTrackWidth < itemWrapperWidth * sliderItems.length);

  const arrowRightActve = hiddenItems() * itemWrapperWidth > -left;

  return (
    <>
      <div className={`slider ${type === 'swap' ? 'swap' : ''} ${uncollapsed ? 'd-none' : ''}`}>
        {RenderSliderHeader()}
        {RenderSlider(perms)}
      </div>

      <div className={`slider uncollapsed ${!uncollapsed ? 'd-none' : ''}`}>
        {RenderUncollapsedSliderHeader()}
        {RenderUncollapsedSlider(perms)}
      </div>
    </>
  );

  function RenderSliderHeader() {
    return (
      <div
        className={`slider-header ${
          type === 'category' || type === 'variable' || type === 'swap'
            ? 'categories-header shadow-sm'
            : ''
        }`}
      >
        <div className="d-flex align-items-center">
          <div className="title">{title}</div>
          {isSearchable && RenderSearchControls()}

          {showSeeAllBtn && (
            <span
              className="btn-all"
              onClick={() => {
                setSelectedItem(category);
              }}
            >
              All
            </span>
          )}
        </div>
        {RenderSliderArrows()}
      </div>
    );
  }

  function RenderSearchControls() {
    return (
      <>
        <div className="group" style={{ marginRight: 80 }}>
          <input
            type="text"
            name="search"
            style={{ marginLeft: 7 }}
            placeholder="Search..."
            autoComplete="off"
            value={searchInput}
            onChange={e => {
              setSearchInput(e.target.value);
            }}
          />
          <IncludeSvg name="search" />
        </div>
        <div
          className="group noselect sort-by"
          onClick={() => {
            setPopup(!popup);
          }}
        >
          <span className="title">Sort by:</span>
          <span className="variable">{titleOf(sortBy)}</span>
          <span className={`${popup && 'rotate-180'}`}>
            <IncludeSvg name="arrow-drop-down" />
          </span>
          {popup && <PopupMenu popupRef={popupRef} options={popupOptions} />}
        </div>
      </>
    );
  }

  function RenderSliderArrows() {
    return (
      <div className={`right ${uncollapsed ? 'd-none' : ''} d-flex justify-content-end`}>
        <span className="details truncate">
          Showing{' '}
          <span className="text-bold">
            {showingNumber()} of {count}
          </span>{' '}
          {showTitle || type}
        </span>
        <button className={`arrow ${arrowLeftActve ? 'active' : ''}`} onClick={moveLeft}>
          <IncludeSvg name={'arrow-left'} />
        </button>
        <button className={`arrow ${arrowRightActve ? 'active' : ''}`} onClick={moveRight}>
          <IncludeSvg name={'arrow-right'} />
        </button>
      </div>
    );
  }

  function RenderSlider(perms) {
    return (
      <div className={`slider-track-wrapper`}>
        {showNewButton && perms.canAdd && (
          <div className={`add-item-wrapper`}>{RenderNewItem()}</div>
        )}

        <div
          className={`slider-track w-100 `}
          ref={sliderTrackRef}
          onChange={e => {
            setSliderTrackWidth(sliderTrackRef.current.clientWidth);
          }}
        >
          <div className={`slider-items`} ref={sliderItemsRef} style={{ left: left }}>
            {sliderItems &&
              sliderItems.map((item, index) => (
                <div key={item.id} className="item-wrapper" ref={itemWrapperRef}>
                  {RenderItem(item, index, perms)}
                </div>
              ))}
            {RenderLoadingEffect(10)}
          </div>
        </div>
      </div>
    );
  }

  function RenderUncollapsedSliderHeader() {
    return (
      <div className="slider-header">
        <div className="d-flex align-items-center">
          <div className="title">{title}</div>
          <span
            className="btn-all"
            onClick={() => {
              setSelectedItem(null);
            }}
          >
            Less
          </span>
        </div>
      </div>
    );
  }

  function RenderUncollapsedSlider(perms) {
    return (
      <div
        className="d-flex flex-wrap"
        onScroll={handleScroll}
        style={{
          marginLeft: -10,
          marginRight: -10,
          maxHeight: 'calc(100vh - 47.75rem)',
          overflowY: 'scroll',
        }}
      >
        <div style={{ margin: 10 }}>{RenderNewItem()}</div>
        {sliderItems &&
          sliderItems.map((item, index) => (
            <div key={item.id} style={{ margin: 10 }}>
              {RenderItem(item, index, perms)}
            </div>
          ))}

        {RenderLoadingEffect(20, 10)}
      </div>
    );
  }

  function RenderLoadingEffect(n, margin) {
    return (
      ((sliderItems && !sliderItems.length && isLoading) || (!items && isLoading)) &&
      Array.from(Array(n).keys()).map(item => (
        <div key={item} className="item-wrapper" style={{ margin }}>
          <Item border={true} type={type} item={item} loadingEffect={true} />
        </div>
      ))
    );
  }

  function RenderNewItem() {
    return (
      <NewItem
        type={type}
        title={newTitle || `New ${type}`}
        border={
          type !== 'category' &&
          type !== 'variable' &&
          type !== 'element-type' &&
          type !== 'language'
        }
        onClick={addNewItem}
        // word lists
        category={category}
        setShowWordListEditor={setShowWordListEditor}
        setShowTriviaListEditor={setShowTriviaListEditor}
        setShowArticleListEditor={setShowArticleListEditor}
        setShowStoryEditor={setShowStoryEditor}
        setOffset={setOffset}
        removeAddedNewItem={removeAddedNewItem}
      />
    );
  }

  function RenderItem(item, index, perms) {
    return (
      <Item
        key={kind + item.name || item.title + item.id}
        border={true}
        type={type}
        kind={kind}
        language={language}
        elementType={elementType}
        selectable={selectable && !item.new}
        item={item}
        renameItem={renameItem}
        deleteItem={deleteItem}
        editPageSize={editPageSize}
        setItems={setItems}
        sliderItems={sliderItems}
        setSliderItems={setSliderItems}
        selectedItem={selectedItem}
        setSelectedItem={val => {
          if (setShowWordListEditor) setShowWordListEditor(false);
          if (setShowTriviaListEditor) setShowTriviaListEditor(false);
          if (setShowArticleListEditor) setShowArticleListEditor(false);
          if (setShowStoryEditor) setShowStoryEditor(false);
          setSelectedItem(val);
        }}
        removeAddedNewItem={removeAddedNewItem}
        setOffset={setOffset}
        index={index}
        // lists
        category={category}
        setShowWordListEditor={setShowWordListEditor}
        setShowTriviaListEditor={setShowTriviaListEditor}
        setShowArticleListEditor={setShowArticleListEditor}
        setShowStoryEditor={setShowStoryEditor}
        setIsCreateMode={setIsCreateMode}
        readOnly={readOnly}
        perms={perms}
        setLeft={setLeft}
        handleEditItem={handleEditItem}
      />
    );
  }
}

export default Slider;
