import React, { useState, useEffect } from 'react';
import Main from 'layouts/Main';
import TopControls from 'components/common/TopControls';
import Slider from 'components/common/Slider';
import { useLocation } from 'react-router-dom';
import { useGlobalContext } from 'state/hooks/global';
import TagsEditor from './TagsEditor';

const STUDIO_CATEGORIES = [
  { title: 'Formats', kind: 'studio_formats', id: 's1' },
  { title: 'Templates', kind: 'studio_templates', id: 's2' },
  { title: 'Manuscripts', kind: 'studio_manuscripts', id: 's3' },
  { title: 'Covers', kind: 'studio_covers', id: 's4' },
];

const LIBRARY_CATEGORIES = [
  { title: 'Word List', kind: 'library_words', id: 'l1' },
  { title: 'Trivia List', kind: 'library_trivia', id: 'l2' },
  { title: 'Article List', kind: 'library_article', id: 'l3' },
  { title: 'Story List', kind: 'library_story', id: 'l4' },
];

const VARIBLES_TAGS = [{ title: 'Variables', kind: 'variables', id: 'v1' }];

function Tags() {
  const [tagsCategories, setTagsCategories] = useState(STUDIO_CATEGORIES);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const location = useLocation();
  const pathname = location.pathname;
  const scope = pathname.split('/')[3];

  const { elementTypes } = useGlobalContext();

  useEffect(() => {
    setSelectedCategory(null);
    switch (scope) {
      case 'studio':
        setTagsCategories(STUDIO_CATEGORIES);
        setSelectedCategory(STUDIO_CATEGORIES[0]);
        break;
      case 'library':
        setTagsCategories(LIBRARY_CATEGORIES);
        setSelectedCategory(LIBRARY_CATEGORIES[0]);
        break;
      case 'variables':
        setTagsCategories(VARIBLES_TAGS);
        setSelectedCategory(VARIBLES_TAGS[0]);
        break;
      case 'elements':
        setTagsCategories(elementTypes);
        setSelectedCategory(elementTypes[0]);
        break;
      default:
        alert('Not suported tags scope');
    }
  }, [scope, elementTypes]);

  return (
    <div>
      <Main>
        <TopControls
          btns={[
            {
              text: 'Studio',
              path: `/settings/tags/studio`,
              selected: pathname.includes('studio'),
            },
            {
              text: 'Library',
              path: `/settings/tags/library`,
              selected: pathname.includes('library'),
            },
            {
              text: 'Elements',
              path: `/settings/tags/elements`,
              selected: pathname.includes('elements'),
            },
            {
              text: 'Variables',
              path: `/settings/tags/variables`,
              selected: pathname.includes('variables'),
            },
          ]}
        />
        <div className="resources">
          <Slider
            title={'Tags Categories'}
            kind={scope}
            type={'tag'}
            selectable={true}
            items={tagsCategories}
            selectedItem={selectedCategory}
            setSelectedItem={setSelectedCategory}
            readOnly={true}
            showNewButton={false}
            outerSelection={false}
          />
          <TagsEditor scope={scope} category={selectedCategory} />
        </div>
      </Main>
    </div>
  );
}

export default Tags;
