import { addCategory, deleteCategory, editCategory, getCategories } from 'api/common';
import {
  addElementType,
  deleteElement,
  deleteElementType,
  editElement,
  editElementType,
} from 'api/elements';
import { addLanguage, deleteLanguage, editLanguage } from 'api/global';
import {
  addPageSize,
  deleteFormat,
  deletePageSize,
  editFormat,
  duplicateFormat,
  editPageSize,
} from 'api/studio/formats';
import {
  addPaperThickness,
  deleteCover,
  deletePaperThickness,
  editCover,
  editPaperThickness,
} from 'api/studio/covers';
import { addVariable, deleteVariable, editVariable } from 'api/variables';
import { deleteManuscript, editManuscript } from 'api/studio/manuscripts';
import { deleteTemplate, editTemplate } from 'api/studio/templates';
import {
  deleteWordList,
  deleteTriviaList,
  deleteArticleList,
  deleteStory,
  editWordList,
  editTriviaList,
  editArticleList,
  editStory,
} from 'api/library';

import { capitalizeFirst } from 'helpers/text';
import { useGlobalContext } from 'state/hooks/global';

export function useItemHandlers({
  type,
  item,
  index,
  sliderItems,
  setSliderItems,
  setItems,
  selectable,
  selectedItem,
  setSelectedItem,
  setPopup,
  setOffset,
  setShowWordListEditor,
  setShowTriviaListEditor,
  setShowArticleListEditor,
  setShowStoryEditor,
  setEditMode,
  setIsCreateMode,
  itemTitle,
  setItemTitle,
  itemWidth,
  itemHeight,
  itemValue,
  setHilighted,
  category,
  language,
  elementType,
  elType,
  kind,
  history,
  setLeft,
  handleEditItem,
}) {
  const { setMsgs, setElementTypes, getLanguageID, getElementTypeID, setLanguages } =
    useGlobalContext();

  const handleOnClick = () => {
    const addItemToWorkspace = (id, key) => {
      history.push(`/home/${key}s/workspace?ids=` + id);
    };

    if (selectable && !item.new) setSelectedItem(item);
    else {
      switch (type) {
        case 'format':
        case 'template':
        case 'manuscript':
        case 'cover':
          addItemToWorkspace(item.id, type);
          break;
        case 'word list': {
          setOffset({ value: index, category_id: category.id, item_id: item.id });
          setShowWordListEditor(true);
          break;
        }
        case 'trivia list': {
          setOffset({ value: index, category_id: category.id, item_id: item.id });
          setShowTriviaListEditor(true);
          break;
        }
        case 'article list': {
          setOffset({ value: index, category_id: category.id, item_id: item.id });
          setShowArticleListEditor(true);
          break;
        }
        case 'story': {
          setOffset({ value: index, category_id: category.id, item_id: item.id });
          setShowStoryEditor(true);
          break;
        }
        case 'element':
          history.push(`/elements/${elementType}/${item.id}`);
          break;
        default:
          return;
      }
    }
  };

  const handleOnKeyUpForTitle = e => {
    if (e.key === 'Enter' && e.target.value.trim()) {
      if (item.new) {
        let data = { title: itemTitle.trim() };
        let params = { kind };

        const createFunc = {
          'element-type': addElementType,
          language: addLanguage,
          variable: addVariable,
          swap: addVariable,
        };

        // replace switch with if else
        if (type === 'category') {
          if (language) {
            const languageID = getLanguageID(language);
            if (languageID) params.language = languageID;
          } else if (elementType) {
            const elementTypeID = getElementTypeID(elementType);
            if (elementType) params.element_type = elementTypeID;
          }
          addCategory({ data, params }).then(res => {
            if (res.success) handleAfterCreation(res.category);
          });
        } else if (type === 'variable' || type === 'swap') {
          createFunc[type]({
            data: { title: itemTitle.trim() },
            isSwap: type === 'swap',
          }).then(res => {
            if (res.success) {
              handleAfterCreation(res.variable?.versions ?? { ...res.variable, versions: [] });
              setIsCreateMode(false);
            }
          });
        } else if (createFunc[type]) {
          createFunc[type]({ name: itemTitle.trim() }).then(res => {
            if (res.success) handleAfterCreation(res[type.replace(' ', '_').replace('-', '_')]);
          });
        } else {
          alert(`${type} create is not supported in item component yet`);
        }
      } else {
        // Edit
        const renameItem = (editFunc, dataObj) => {
          editFunc(dataObj, item.id).then(res => {
            if (res.success) handleAfterEdit(res[type.replace(' ', '_').replace('-', '_')]);
          });
        };

        const editFunc = {
          category: editCategory,
          format: editFormat,
          template: editTemplate,
          manuscript: editManuscript,
          cover: editCover,
          'word list': editWordList,
          'trivia list': editTriviaList,
          'article list': editArticleList,
          story: editStory,
          element: editElement,
          'element-type': editElementType,
          language: editLanguage,
          variable: editVariable,
          swap: editVariable,
        };

        if (type === 'variable' || type === 'swap') {
          renameItem(editVariable, {
            data: { title: itemTitle.trim() },
            id: item.id,
            isSwap: type === 'swap',
          });
        } else if (editFunc[type]) {
          renameItem(editFunc[type], { title: itemTitle.trim() });
        } else {
          alert(`${type} edit is not supported in item component yet`);
        }
      }
    } else if (e.key === 'Escape') {
      setItemTitle(item.title || item.name);
      setHilighted(item.hilighted);
      setEditMode(false);
    }
  };

  const handleOnKeyUpForSize = e => {
    if (e.key === 'Enter') {
      if (item.new && itemWidth > 0 && itemHeight > 0) {
        addPageSize({ width: itemWidth, height: itemHeight }).then(res => {
          if (res.success) handleAfterCreation(res.page_size);
          else console.log(res.errors);
        });
      }

      if (!item.new && itemWidth > 0 && itemHeight > 0) {
        editPageSize({ width: itemWidth, height: itemHeight }, item.id).then(res => {
          if (res.success) handleAfterEdit(res.page_size);
        });
      }
    } else if (e.key === 'Escape') {
      setItemTitle(item.title || item.name);
      setHilighted(item.hilighted);
      setEditMode(false);
    }
  };

  const handleOnKeyUpForThickness = e => {
    if (e.key === 'Enter') {
      if (item.new && itemValue > 0) {
        addPaperThickness({ value: itemValue }).then(res => {
          if (res.success) handleAfterCreation(res.paper_thickness);
          else console.log(res.errors);
        });
      }

      if (!item.new && itemValue > 0) {
        editPaperThickness({ value: itemValue }, item.id).then(res => {
          if (res.success) handleAfterEdit(res.paper_thickness);
        });
      }
    } else if (e.key === 'Escape') {
      setItemTitle(itemValue);
      setHilighted(item.hilighted);
      setEditMode(false);
    }
  };

  const handleDeleteItem = e => {
    e.stopPropagation();

    const del = {
      _deleteItem(func, id) {
        func(id).then(res => {
          if (res.success) {
            handleAfterDelete(id);
          } else {
            console.log(res.errors);
          }
        });
      },
      _deleteItemWithCheck(func, id) {
        if (item.is_deletable) {
          this._deleteItem(func, id);
        } else {
          setMsgs([{ type: 'warning', content: `This ${type} can't be deleted` }]);
          setPopup(false);
        }
      },
      category() {
        this._deleteItemWithCheck(deleteCategory, item.id);
      },
      size() {
        this._deleteItem(deletePageSize, item.id);
      },
      thickness() {
        this._deleteItem(deletePaperThickness, item.id);
      },
      format() {
        this._deleteItem(deleteFormat, item.id);
      },
      template() {
        this._deleteItem(deleteTemplate, item.id);
      },
      manuscript() {
        this._deleteItem(deleteManuscript, item.id);
      },
      cover() {
        this._deleteItem(deleteCover, item.id);
      },
      'word list'() {
        this._deleteItem(deleteWordList, item.id);
      },
      'trivia list'() {
        this._deleteItem(deleteTriviaList, item.id);
      },
      'article list'() {
        this._deleteItem(deleteArticleList, item.id);
      },
      story() {
        this._deleteItem(deleteStory, item.id);
      },
      element() {
        this._deleteItem(deleteElement, item.id);
      },
      'element-type'() {
        this._deleteItemWithCheck(deleteElementType, item.id);
      },
      language() {
        this._deleteItemWithCheck(deleteLanguage, item.id);
      },
      variable() {
        this._deleteItem(deleteVariable, { id: item.id, isSwap: false });
      },
      swap() {
        this._deleteItem(deleteVariable, { id: item.id, isSwap: true });
      },
    };
    if (del[type]) del[type]();
    else alert(`handleDeleteItem: ${type} delete is not supported in item component yet`);
  };

  // After creation, edit, delete item you will need to update the list

  const handleAfterCreation = (item, isDuplicate) => {
    const list = [...sliderItems];
    if (!isDuplicate) list.shift();
    list.unshift(item);
    setSliderItems(list);
    setEditMode(false);
    if (setItems) setItems(list);
    if (setSelectedItem && type === 'category') setSelectedItem(item);

    if (type === 'element-type') {
      setElementTypes(list);
      history.push(`/elements/${list[0].name.toLowerCase()}/manage`);
    }

    if (type === 'language') {
      setLanguages(list);
    }
    setPopup(false);
    setMsgs([
      {
        type: 'success',
        content: `${capitalizeFirst(type)} created successfully!`,
      },
    ]);
  };

  const handleAfterEdit = item => {
    const list = [...sliderItems];
    const index = list.findIndex(el => el.id === item.id);
    list[index] = item;
    setSliderItems(list);
    setEditMode(false);
    if (setItems) setItems(list);
    if (setSelectedItem && type === 'category') setSelectedItem(item);
    if (type === 'element-type') {
      setElementTypes(list);
      history.push(`/elements/${item.name.toLowerCase()}/manage`);
    }
    if (type === 'language') setLanguages(list);

    setMsgs([
      {
        type: 'success',
        content: `${capitalizeFirst(type)} updated successfully!`,
      },
    ]);
  };

  const handleAfterDelete = id => {
    // ui fix
    if (selectable && selectedItem && selectedItem.id === id) setSelectedItem(null);

    const list = [...sliderItems];
    const index = list.findIndex(el => el.id === id);
    const deletedItemName = list[index]?.name ?? null;
    list.splice(index, 1);
    if (setItems) setItems(list);
    if (setSliderItems) setSliderItems(list);
    if (type === 'element-type') {
      setElementTypes(list);
      if (deletedItemName === elType)
        history.push(`/elements/${list[0].name.toLowerCase()}/manage`);
    }

    setMsgs([
      {
        type: 'success',
        content: `${capitalizeFirst(type)} deleted successfully!`,
      },
    ]);
  };

  const handleFavourite =
    type === 'category'
      ? (e, item) => {
          e.stopPropagation();
          editCategory(
            {
              is_favourite: !item.is_favourite,
              title: item.title, // cause title is required
            },
            item.id
          ).then(res => {
            if (res.success) {
              getCategories({ kind }).then(res => {
                if (res.success) {
                  setLeft(0);
                  setSliderItems(res.categories);
                  setItems(res.categories);
                }
              });
            }
          });
        }
      : null;

  const handleDuplicate = () => {
    duplicateFormat(item.id).then(res => {
      if (res.success) {
        handleAfterCreation(res.format, true);
      }
    });
  };

  const handleEdit = () => {
    handleEditItem(item);
  };

  return {
    handleDeleteItem,
    handleOnKeyUpForTitle,
    handleOnKeyUpForSize,
    handleOnKeyUpForThickness,
    handleOnClick,
    handleFavourite,
    handleDuplicate,
    handleEdit,
  };
}
