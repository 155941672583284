import React from 'react';

function ScrollableWindow({ children, onScrollEnd }) {
  const handleScroll = e => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop === clientHeight) {
      onScrollEnd();
    }
  };
  return (
    <div className="scrollable-window" onScroll={handleScroll}>
      {children}
    </div>
  );
}

export default ScrollableWindow;
