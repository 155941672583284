// NOTE: its important to follow {title; 'title...', content: 'content...'}
// Backend depends on it to filter stories with negative words

import React, { useState, useEffect, useRef } from 'react';
import 'components/common/List.scss';
import ListControls from 'components/common/ListControls';
import { capitalizeFirst } from 'helpers/text';

const autoExpandVertically = e => {
  e.target.style.height = 'inherit';
  e.target.style.height = `${e.target.scrollHeight}px`;
};

function Story({
  editable = false,
  listName,
  title,
  stories,
  setStories,
  selectedStories,
  setSelectedStories,
  size,
  transfer,
  transferTrig,
  setTransferTrig,
  offset,
  formatStory,
  updateList,
  sortList,
  letterCase,
  setLetterCase,
  firstItemAlwaysSeleted,
}) {
  const [search, setSearch] = useState('');
  const [focus, setFocus] = useState(0);
  const [sortDir, setSortDir] = useState('Sort');

  const textAreaRefs = useRef([]);

  useEffect(() => {
    // Trigger autoExpandVertically for each text area on initial load
    textAreaRefs.current.forEach(textarea => {
      if (textarea) {
        const event = { target: textarea };
        autoExpandVertically(event);
      }
    });
  }, [stories]);

  useEffect(() => {
    // make first item sleceted always
    if (stories.length && !selectedStories.length && firstItemAlwaysSeleted) {
      setSelectedStories([0]);
    }
  }, [stories, selectedStories]);

  const handleSelectToggle = (index, deslectAllFirst) => {
    let list;
    if (deslectAllFirst) list = [];
    else list = selectedStories.slice();
    if (selectedStories.includes(index)) {
      const i = list.findIndex(item => item === index);
      list.splice(i, 1);
    } else {
      list.push(index);
    }
    setSelectedStories(list);
  };

  const selectAll = () => {
    setSelectedStories([...Array(stories.length).keys()]);
  };

  const deslectAll = () => {
    setSelectedStories([]);
  };

  const removeDuplicates = () => {
    updateList(
      stories,
      setStories,
      list =>
        [...new Set(list)]
          .filter(
            (v, i, a) => a.findIndex(t => t.title === v.title && t.content === v.content) === i
          )
          .filter(i => i.content !== '' && i.title !== ''),
      editable
    );
  };

  const removeEmptyCells = () => {
    updateList(
      stories,
      setStories,
      list => [...list].filter(i => i.content !== '' && i.title !== ''),
      editable
    );
  };

  const transferItems = (all, difficulty) => {
    const list = stories.slice();
    let filteredList;
    if (all) filteredList = list;
    else filteredList = list.filter((el, i) => selectedStories.includes(i));
    if (difficulty) transfer(filteredList.map(i => ({ ...i, difficulty })));
    else transfer(filteredList);
    if (all) setStories([]);
    else deleteSelected();
  };

  const deleteSelected = () => {
    updateList(
      stories,
      setStories,
      list => list.filter((el, i) => !selectedStories.includes(i)),
      editable
    );
  };
  const toggleLetterCase = () => {
    const options = ['aa', 'Aa', 'AA'];
    let i = (options.findIndex(o => o === letterCase) + 1) % 3;
    setLetterCase(options[i]);
  };

  const toggleSort = () => {
    const options = ['Sort', 'Ascending', 'Descending'];
    let i = (options.findIndex(o => o === sortDir) + 1) % 3;
    if (i === 0) i = 1;

    setSortDir(options[i]);
  };

  const focusPrev = () => {
    if (focus > -1) setFocus(focus - 1);
  };

  const focusNext = () => {
    if (focus < stories.length - 1) setFocus(focus + 1);
  };

  useEffect(() => {
    updateList(
      stories,
      setStories,
      list => list.map(word => formatStory(letterCase, word)),
      editable
    );
  }, [letterCase]);

  useEffect(() => {
    sortList(stories, setStories, sortDir);
  }, [sortDir]);

  useEffect(() => {
    updateList(
      stories,
      setStories,
      list => list.map(word => formatStory(letterCase, word)),
      editable
    );
    setSortDir('Ascending');
  }, [stories.length]);

  useEffect(() => {
    removeEmptyCells();
  }, [stories.length]);

  useEffect(() => {
    if (transferTrig.val) {
      transferItems(transferTrig.all, transferTrig.difficulty);
      setTransferTrig({ val: false, all: false });
    }
  }, [transferTrig && transferTrig.val]);

  useEffect(() => {
    if (focus > -1) handleSelectToggle(focus, true);
    else {
      deslectAll();
      const el = document.getElementById(`${listName}-option-${focus}`);
      if (el) el.focus();
    }
  }, [focus]);

  useEffect(() => {
    if (selectedStories.length) setSelectedStories([]);
  }, [search]);

  const handleStoryUpdate = (index, key, value) => {
    const list = stories.slice();
    list[index][key] = value;
    setStories(list);
  };

  return (
    <div
      key={listName}
      tabIndex="0"
      className="list"
      onKeyUp={e => {
        e.preventDefault();
        e.stopPropagation();
        if ((e.ctrlKey && e.key === 'a') || (e.metaKey && e.key === 'a')) {
          selectAll();
        }
        if (e.key === 'ArrowDown') {
          focusNext();
        }
        if (e.key === 'ArrowUp') {
          focusPrev();
        }
      }}
    >
      <h4 className="list__title ">{title}</h4>

      <ListControls
        search={search}
        setSearch={setSearch}
        setFocus={setFocus}
        listName={listName}
        toggleSort={toggleSort}
        sortDir={sortDir}
        letterCase={letterCase}
        toggleLetterCase={toggleLetterCase}
        removeDuplicates={removeDuplicates}
        selectedItems={selectedStories}
        deleteSelected={deleteSelected}
      />

      <div
        style={{ height: size * 22 + 20 }}
        className="list__list-wrapper bg-white shadow-sm border"
      >
        <div
          style={{ height: size * 22 }}
          className="list__list"
          onClick={e => {
            e.target.focus();
            !search && setFocus(stories.length);
          }}
        >
          {stories.map(
            (story, index) =>
              (!search ||
                (search && (story.content.includes(search) || story.title.includes(search)))) && (
                <div
                  className={`list__option--story ${
                    selectedStories.includes(index) ? 'selected' : ''
                  } ${focus === index ? 'focus' : ''}  ${!editable ? 'cursor-pointer' : ''}`}
                  key={index}
                  id={`${listName}-option-${index}`}
                  onClick={e => {
                    e.stopPropagation();
                    if (e.ctrlKey) handleSelectToggle(index);
                    else {
                      handleSelectToggle(index, true);
                    }
                  }}
                >
                  <div>
                    <span>Title: </span>
                    <span className="d-flex w-100">
                      {editable ? (
                        <input
                          className={`list__option--editable `}
                          placeholder="title goes here..."
                          value={story.title}
                          onChange={e => {
                            handleStoryUpdate(index, 'title', e.target.value);
                          }}
                          onMouseDown={e => {
                            if (e.ctrlKey) handleSelectToggle(index);
                            else deslectAll();
                          }}
                        />
                      ) : (
                        story.title
                      )}
                    </span>
                  </div>
                  <div style={{ paddingBottom: '.5rem' }}>
                    <span>Content:</span>
                    <span className="d-flex w-100">
                      {editable ? (
                        <textarea
                          key={story + index}
                          ref={el => (textAreaRefs.current[index] = el)}
                          className={`list__option--editable`}
                          style={{ resize: 'none' }}
                          placeholder="story goes here..."
                          value={story.content}
                          onChange={e => {
                            autoExpandVertically(e);
                            handleStoryUpdate(index, 'content', e.target.value);
                          }}
                          onMouseDown={e => {
                            autoExpandVertically(e);
                            if (e.ctrlKey) handleSelectToggle(index);
                            else deslectAll();
                          }}
                        />
                      ) : (
                        <pre
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: '1.3333rem',
                            whiteSpace: 'pre-wrap',
                            maxWidth: 500,
                            paddingBottom: '.5rem',
                          }}
                        >
                          {story.content || ''}
                        </pre>
                      )}
                    </span>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
      <div className="list__list--counter">
        <span>
          {selectedStories.length &&
          stories[selectedStories[0]] &&
          stories[selectedStories[0]].difficulty
            ? 'Level: ' + capitalizeFirst(stories[selectedStories[0]].difficulty)
            : ''}
        </span>
        <span>
          {selectedStories.length &&
          stories[selectedStories[0]] &&
          stories[selectedStories[0]].content
            ? stories[selectedStories[0]].content.trim().length + ' Characters'
            : ''}
        </span>
        <span>
          {stories.length} Story{stories.length === 1 ? '' : 's'}
        </span>
      </div>
    </div>
  );
}

export default Story;
