import { getCovers, getPaperThicknessList } from 'api/studio/covers';
import { getElementTypes, getElements } from 'api/elements';
import { getFormats, getPageSizes } from 'api/studio/formats';
import { getTriviaLists, getWordLists, getArticleLists, getStories } from 'api/library';
import { useEffect, useState } from 'react';

import { cloneObj } from 'helpers/objects';
import { getAccounts } from 'api/settings/accounts';
import { getCategories } from 'api/common';
import { getLanguages } from 'api/global';
import { getManuscripts } from 'api/studio/manuscripts';
import { getTemplates } from 'api/studio/templates';
import { getVariables } from 'api/variables';

export function useSliderLoaders({
  type,
  kind,
  category,
  sortBy,
  tags,
  searchInput,
  language,
  languages,
  elementType,
  elementTypes,
  permissions,
  setIsLoading,
  setItems,
  setSliderItems,
  sliderItems,
  itemWrapperWidth,
  left,
  setLeft,
  hiddenItems,
  getLanguageID,
  getElementTypeID,
  items,
}) {
  const [count, setCount] = useState(0);

  const loadItems = {
    load(offset, getItems, key, passOffsetOnly, paramsObj) {
      let getFunc;
      if (paramsObj) {
        getFunc = () => getItems(paramsObj);
      } else {
        getFunc = !passOffsetOnly
          ? () => getItems(category.id, sortBy, tags, offset)
          : () => getItems(offset);
      }

      if (offset === 0 || offset < count) {
        return getFunc().then(res => {
          setIsLoading(true);
          if (res.success) {
            const list = [...sliderItems];
            const items = offset === 0 ? res[key] : list.concat(res[key]);
            setSliderItems(items);
            if (key === 'variables') setItems(items); // to update view state
            setCount(res.count);
            setIsLoading(false);
          } else {
            console.log(res.message);
            setIsLoading(false);
          }
        });
      }
    },
    category(offset) {
      if (['library_words'].includes(kind) && !languages.length) return;
      const params = {
        kind,
        sort: sortBy,
        search: searchInput.trim(),
        offset,
      };

      if (kind.includes('library')) {
        const languageID = getLanguageID(language);
        if (languageID) params.language = languageID;
      } else if (kind === 'elements') {
        const elementTypeID = getElementTypeID(elementType);
        if (elementTypeID) params.element_type = elementTypeID;
      }

      if (
        (offset === 0 || offset < count) &&
        (!kind.includes('library') ||
          kind !== 'elements' ||
          (kind.includes('library') && languages.length) ||
          (kind === 'elements' && elementTypes.length && params.element_type))
      ) {
        getCategories(params).then(res => {
          setIsLoading(true);
          if (res.success) {
            const list = [...sliderItems];
            const categories = offset === 0 ? res.categories : list.concat(res.categories);

            setSliderItems(categories);
            setItems(categories); // to update Studio page
            setCount(res.count);
            setIsLoading(false);
          } else {
            console.log(res.message);
            setIsLoading(false);
          }
        });
      }
    },
    format(offset) {
      return this.load(offset, getFormats, 'formats', false);
    },
    template(offset) {
      return this.load(offset, getTemplates, 'templates', false);
    },
    manuscript(offset) {
      return this.load(offset, getManuscripts, 'manuscripts', false);
    },
    cover(offset) {
      return this.load(offset, getCovers, 'covers', false);
    },
    size(offset) {
      return this.load(offset, getPageSizes, 'page_sizes', true);
    },
    thickness(offset) {
      return this.load(offset, getPaperThicknessList, 'paper_thickness_list', true);
    },
    'word list'(offset) {
      return this.load(offset, getWordLists, 'word_lists', false);
    },
    'trivia list'(offset) {
      return this.load(offset, getTriviaLists, 'trivia_lists', false);
    },
    'article list'(offset) {
      return this.load(offset, getArticleLists, 'article_lists', false, {
        category_id: category.id,
        sort: sortBy,
        tags,
        offset,
      });
    },
    story(offset) {
      return this.load(offset, getStories, 'stories', false, {
        category_id: category.id,
        sort: sortBy,
        tags,
        offset,
      });
    },
    element(offset) {
      return this.load(offset, getElements, 'elements', false, {
        category_id: category.id,
        sort: sortBy,
        tags,
        offset,
      });
    },
    'element-type'(offset) {
      return this.load(offset, getElementTypes, 'element_types', false, { offset, limit: 15 });
    },
    language(offset) {
      return this.load(offset, getLanguages, 'languages', false, { offset, limit: 15 });
    },
    variable(offset) {
      return this.load(offset, getVariables, 'variables', false, {
        params: {
          kind,
          sort: sortBy,
          search: searchInput.trim(),
          offset,
        },
        isSwap: type === 'swap',
      });
    },
    account(offset) {
      if (offset === 0 || offset < count)
        return getAccounts().then(res => {
          setIsLoading(true);
          if (res.success) {
            const list = [...sliderItems];
            const accounts = (offset === 0 ? res.users : list.concat(res.users)).map(user => ({
              ...user,
              name: user.first_name + ' ' + user.last_name,
            }));

            setSliderItems(accounts);
            setItems(accounts); // to update view state
            setCount(res.count);
            setIsLoading(false);
          } else {
            console.log(res.message);
            setIsLoading(false);
          }
        });
    },
  };

  const handleScroll = e => {
    const bottom = e.target.scrollHeight - e.target.scrollTop >= e.target.clientHeight;
    if (bottom) {
      if (loadItems[type]) loadItems[type](sliderItems.length);
      else alert(`handleScroll: ${type} has no loader function`);
    }
  };

  const loadMoreCategories = () => {
    loadItems.category(sliderItems.length);
  };

  // load items
  useEffect(() => {
    if (type !== 'variable') setLeft(0); // UI Fix

    if (type === 'variable' || type === 'swap' || type === 'tag') return;
    // --> will be handled in different useEffect

    if (loadItems[type]) loadItems[type](0);
    else alert(`useEfffect: ${type} is not supported as a slider type`);
  }, [sortBy, tags, searchInput.trim(), language, languages, elementTypes]);

  // load variables and swaps
  useEffect(() => {
    if (type === 'variable' || type === 'swap') {
      loadItems['variable'](0);
    }
  }, [sortBy, tags, searchInput.trim(), type]);

  useEffect(() => {
    if (type === 'tag') setSliderItems(items);
  }, [items]);

  // load more
  useEffect(() => {
    if (hiddenItems() * itemWrapperWidth <= -left) {
      if (type === 'tag') return;
      else if (type === 'variable' || type === 'swap') loadItems['variable'](sliderItems.length);
      else if (loadItems[type]) loadItems[type](sliderItems.length);
      else alert(`useEffect: ${type} has no load more function`);
    }
  }, [left]);

  useEffect(() => {
    if (type === 'account') {
      setSliderItems(items);
    }
  }, [items]);

  return {
    handleScroll,
    count,
    loadMoreCategories,
  };
}
