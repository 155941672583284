import React, { useState, useEffect } from 'react';
import InputNumber from 'components/forms/elements/InputNumber';
import SelectWrapper from 'components/forms/elements/SelectWrapper';
import { getCategories } from 'api/common';
import { getFormats } from 'api/studio/formats';
import Tags from 'components/common/Tags';

import CheckList from 'components/forms/elements/CheckList';

import { PAGE_SELECTOR_MODES, PAGE_SELECTOR_HAND_SIDES } from 'settings/page-selector';
import _ from 'lodash';
import PageSelector from 'components/forms/PageSelector';

function TemplateForm({
  pageSelectorMode,
  setPageSelectorMode,
  pageSelectorHandSide,
  setPageSelectorHandSide,
  from: fromParent,
  setFrom: setFromParent,
  to: toParent,
  setTo: setToParent,
  from1: from1Parent,
  setFrom1: setFrom1Parent,
  to1: to1Parent,
  setTo1: setTo1Parent,
  segments,
  setSegments,
  allowedTags,
  checkedFormats,
  setCheckedFormats,
  pages,
  template,
}) {
  // Categories
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  // Formats
  const [tagsArr, setTagsArr] = useState([]);
  const [tags, setTags] = useState(''); // formatted String
  const [listFilter, setListFilter] = useState([null, null, null]);
  const [formats, setFormats] = useState([]);
  const [formatsCount, setFormatsCount] = useState(0);
  const [isFormatsLoading, setIsFormatsLoading] = useState(false);
  const [from, setFrom] = useState(fromParent);
  const [to, setTo] = useState(toParent);
  const [from1, setFrom1] = useState(from1Parent);
  const [to1, setTo1] = useState(to1Parent);

  useEffect(() => {
    const updateParentRange = _.debounce(() => {
      setFromParent(from);
      setToParent(to);
      setFrom1Parent(from1);
      setTo1Parent(to1);
    }, 500);
    updateParentRange();

    return () => updateParentRange.cancel();
  }, [from, to, from1, to1]);

  useEffect(() => {
    const updateChildRange = () => {
      if (fromParent !== from) setFrom(fromParent);
      if (toParent !== to) setTo(toParent);
      if (from1Parent !== from1) setFrom1(from1Parent);
      if (to1Parent !== to1) setTo1(to1Parent);
    };

    updateChildRange();
  }, [fromParent, toParent, from1Parent, to1Parent]);

  useEffect(() => {
    let str = '';
    tagsArr.forEach(tag => {
      str += `&tag=${tag.id}`;
    });
    setTags(str);
  }, [tagsArr]);

  useEffect(() => {
    // set default filters here
    if (pageSelectorHandSide === 0) {
      setListFilter([1, 0, 2]);
    } else if (pageSelectorHandSide === 1) {
      setListFilter([1, 0, null]);
    } else if (pageSelectorHandSide === 2) {
      setListFilter([null, 0, 2]);
    }
  }, [pageSelectorHandSide]);

  const loadCategories = (search, offset) => {
    const params = {
      kind: 'studio_formats',
      sort: 'alpha',
      search,
      offset,
    };
    return getCategories(params)
      .then(res => {
        if (res.success) {
          const list = [...categories];
          const items = offset === 0 ? res['categories'] : list.concat(res['categories']);

          const options = res.categories.map(category => ({
            label: category.title,
            value: category.id,
          }));

          setCategories(offset > 0 ? [...categories, ...options] : options);
          if (offset === 0 && search === '') setSelectedCategory(items[0].id);

          return {
            options,
            hasMore: offset + 15 < res.count,
            additional: {
              offset: offset + 15,
            },
          };
        }
      })
      .catch(e => console.log);
  };

  const loadFormats = offset => {
    if (isFormatsLoading) return;
    if (offset === 0) {
      setFormats([]);
      setFormatsCount(0);
    }
    if (offset === 0 || offset < formatsCount) {
      const params = {
        page_size: template.page_size,
      };
      setIsFormatsLoading(true);
      getFormats(selectedCategory, 'alpha', tags, offset, params, listFilter)
        .then(res => {
          if (res.success) {
            const list = [...formats];
            const items = offset === 0 ? res['formats'] : list.concat(res['formats']);
            setFormatsCount(res.count);
            setTimeout(() => {
              setFormats(items);
              setIsFormatsLoading(false);
            }, 500);
          }
        })
        .catch(e => console.log);
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      setCheckedFormats([]);
      loadFormats(0);
    }
  }, [selectedCategory, tags, listFilter]);

  return (
    <div className="form__studio-text template">
      <div className="form__studio-heading">SPECIFICATIONS</div>

      <div
        style={{
          overflowY: 'auto',
          height: 'calc(100vh - 12.5rem)',
          paddingBottom: 50,
        }}
      >
        <PageSelector
          pageSelectorMode={pageSelectorMode}
          setPageSelectorMode={setPageSelectorMode}
          pageSelectorHandSide={pageSelectorHandSide}
          setPageSelectorHandSide={setPageSelectorHandSide}
          pages={pages}
          from={from}
          setFrom={setFrom}
          to={to}
          setTo={setTo}
          from1={from1}
          setFrom1={setFrom1}
          to1={to1}
          setTo1={setTo1}
          segments={segments}
          setSegments={setSegments}
          allowNewSegments={true}
        />

        <div className="form-group d-flex flex-column">
          <label className="mb-3">Format Category</label>
          <SelectWrapper
            async
            options={categories}
            value={selectedCategory}
            setValue={setSelectedCategory}
            loadOptions={(search, loadedOptions = []) =>
              loadCategories(search, loadedOptions.length)
            }
          />
        </div>

        <Tags
          title={'Format Tags'}
          allowedTags={allowedTags}
          tagsArr={tagsArr}
          setTagsArr={setTagsArr}
          classNameA={'form-group'}
          classNameB={''}
          inputClassName={'form-control light'}
          height={50}
          inputPlaceholder={'Search...'}
          showHead={false}
          showCount={false}
          scrollDirection={'x'}
          searchable={true}
        />

        <CheckList
          radioSelect
          title={'Format Selector'}
          itemsType={'formats'}
          items={formats}
          loadItems={loadFormats}
          checkedItems={checkedFormats}
          setCheckedItems={setCheckedFormats}
          isLoading={isFormatsLoading}
          classNames={'doubleH'}
        >
          <div className="list-filters-section d-flex align-items-center mb-3">
            <span>List filter: </span>
            {[
              PAGE_SELECTOR_HAND_SIDES[1],
              PAGE_SELECTOR_HAND_SIDES[0],
              PAGE_SELECTOR_HAND_SIDES[2],
            ].map((opt, index) => (
              <div
                key={opt.value}
                className={`list-filters page-hand-side text-nowrap ${
                  listFilter.includes(opt.value) ? 'selected' : ''
                }`}
                onClick={() => {
                  const list = [...listFilter];
                  if (list[index] === null) list[index] = opt.value;
                  else list[index] = null;
                  setListFilter(list);
                }}
              >
                {opt.label}
              </div>
            ))}
          </div>
        </CheckList>
      </div>
    </div>
  );
}

export default TemplateForm;
