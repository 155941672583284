import axios from 'axios';
import urls from 'settings/urls.js';

// Formats
export async function getFormats(category_id, sort, tags, offset, params, listFilter) {
  let str = '';
  if (listFilter && listFilter.length)
    listFilter.forEach(filter => {
      if (filter !== null) str += `&page_hand_side=${filter}`;
    });

  const requestOptions = {
    method: 'GET',
    url: urls.formats(category_id, sort, tags, offset, str),
  };

  if (params) requestOptions.params = params;

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function getOneFormat(id) {
  const requestOptions = {
    method: 'GET',
    url: urls.oneFormat(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function addFormat(data) {
  const requestOptions = {
    method: 'POST',
    url: urls.newFormat,
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function editFormat(data, id) {
  const requestOptions = {
    method: 'PATCH',
    url: urls.editFormat(id),
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function deleteFormat(id) {
  const requestOptions = {
    method: 'DELETE',
    url: urls.deleteFormat(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

// duplicate format
export async function duplicateFormat(id) {
  const requestOptions = {
    method: 'POST',
    url: urls.duplicateFormat(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

// Page Sizes
export async function getPageSizes(offset, limit) {
  const requestOptions = {
    method: 'GET',
    url: urls.pageSizes(offset, limit),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function addPageSize(data) {
  const requestOptions = {
    method: 'POST',
    url: urls.newPageSize,
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function editPageSize(data, id) {
  const requestOptions = {
    method: 'PATCH',
    url: urls.editPageSize(id),
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function deletePageSize(id) {
  const requestOptions = {
    method: 'DELETE',
    url: urls.deletePageSize(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}
