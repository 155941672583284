import React, { useState } from 'react';
import './CollapsibleSection.scss';

const CollapsibleSection = ({ children, initialIsActive = false, title = '' }) => {
  const [isActive, setIsActive] = useState(initialIsActive);

  const toggleCollapsible = () => {
    setIsActive(!isActive);
  };

  return (
    <div>
      <label className={`mb-2 collapsible ${isActive ? 'active' : ''}`} onClick={toggleCollapsible}>
        {title} <span className="arrow"></span>
      </label>
      <div className={`content ${isActive ? 'activeContent' : ''}`}>{children}</div>
    </div>
  );
};

export default CollapsibleSection;
