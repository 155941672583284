// https://www.jclem.net/posts/pan-zoom-canvas-react

import './ZoomableArea.scss';

import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { usePan, useScale } from 'hooks/util';

import Button from 'components/common/Button';
import { round10 } from '../../helpers/math';

const pointUtils = {
  ORIGIN: Object.freeze({ x: 0, y: 0 }),
};

const ZoomableArea = forwardRef(
  (
    { children, initZoom, isPannable, show, setScale, status, stageWidth, stageHeight, onClick },
    zoomableAreaRef
  ) => {
    const contentAreaRef = useRef(null);
    const [buffer, setBuffer] = useState(pointUtils.ORIGIN);
    const [offset, startPan] = usePan();
    const { scale, setScale: updateScale } = useScale(zoomableAreaRef, initZoom);
    const [zoomableAreaWidth, setZoomableAreaWidth] = useState(0);
    const [zoomableAreaHeight, setZoomableAreaHeight] = useState(0);
    const [showContent, setShowContent] = useState(false);
    const [isScrollDown, setIsScrollDown] = useState(false);

    useEffect(() => {
      // UI fix
      setTimeout(() => setShowContent(true), 500);
    }, []);

    useEffect(() => {
      setScale(scale); // update parent state
    }, [scale]);

    useEffect(() => {
      if (zoomableAreaRef.current && !zoomableAreaWidth) {
        setZoomableAreaWidth(zoomableAreaRef.current.clientWidth);
        setZoomableAreaHeight(zoomableAreaRef.current.clientHeight);
      }
    }, [zoomableAreaRef.current, show]);

    useEffect(() => {
      const height = stageHeight;
      const width = stageWidth;
      setBuffer({
        x: (width - width / scale) / 2,
        y: (height - height / scale) / 2,
      });
    }, [setBuffer, scale, stageHeight, stageWidth]);

    const startPanOrNot = e => {
      if (isPannable && show) {
        setIsScrollDown(true);
        startPan(e);
      }
    };

    return (
      <div
        className={`zoomable-area grabbable ${isScrollDown ? 'active' : ''}`}
        ref={zoomableAreaRef}
        onMouseDown={startPanOrNot}
        onMouseUp={() => setIsScrollDown(false)}
        onClick={onClick}
      >
        <div
          className={`${showContent ? '' : 'd-none'} stage-area`}
          ref={contentAreaRef}
          onClick={e => e.stopPropagation()}
          style={{
            width: stageWidth,
            height: stageHeight,
            left: -offset.x - buffer.x + 0.5 * zoomableAreaWidth,
            top: -offset.y - buffer.y + 0.5 * zoomableAreaHeight,
            transition: 'all 0.1s ease 0s',
          }}
        >
          {children}
        </div>
        <div className="info">
          <div className="noselect">{status}</div>
        </div>

        <div
          className="zoom-controls"
          style={{
            position: 'absolute',
            right: 20,
            bottom: 20,
          }}
        >
          <div className="noselect d-flex align-items-center mx-4">Scale:{scale}</div>
          <Button
            svgName="zoom-out"
            onClick={() => updateScale(scale => round10(scale - 10 >= 10 ? scale - 10 : 10, 1))}
          />

          <Button svgName="zoom-reset" onClick={() => updateScale(initZoom)} />
          <Button
            svgName="zoom-in"
            onClick={() => updateScale(scale => round10(scale + 10 <= 300 ? scale + 10 : 300, 1))}
          />
        </div>

        {!showContent && (
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        )}
      </div>
    );
  }
);

export default ZoomableArea;
