import { Group, Rect, Text } from 'react-konva';

import LinkIndicator from '../LinkIndicator';
import React, { useEffect } from 'react';
import { calculateTextWidth } from 'helpers/studio';
import { has } from 'lodash';

const findMaxLenWord = wordList =>
  wordList.reduce((maxLenWord, word) => (word.length > maxLenWord.length ? word : maxLenWord), '');

const sumToIndex = (arr, index) => {
  let sum = 0;
  for (let i = 0; i < index; i++) {
    sum += arr[i];
  }
  return sum;
};

const reCalculateIncs = incs => {
  const newIncs = [...incs];
  const totalPositive = incs.reduce((acc, curr) => (curr > 0 ? acc + curr : acc), 0);
  const negativeCount = incs.reduce((acc, curr) => (curr < 0 ? acc + 1 : acc), 0);
  const reds = totalPositive / negativeCount;
  return newIncs.map(inc => {
    if (inc > 0) return inc;
    return -1 * reds;
  });
};

// const extractWords = (text, sepArr = ['\n', ',', '|', '/']) => {
//   let output = text;
//   // replace multiple white spaces with one
//   output = output.replaceAll(/\n+/g, '\n');
//   output = output.replaceAll(/  +/g, ' ');

//   // replace seperators with commas
//   sepArr.forEach(sep => {
//     output = output.replaceAll(sep, ',');
//   });
//   output = output.replaceAll(/,+/g, ',');
//   return output.trim().split(',');
// };

const extractWords = (text, sepArr = ['\n', ',', '|', '/'], isArticleOrStory = false) => {
  // Join the separators into a single regex pattern
  const sepPattern = sepArr.map(sep => `\\${sep}`).join('|');
  const regex = new RegExp(`(${sepPattern})`, 'g');

  // Replace multiple white spaces with one
  let output = text.replace(/\s+/g, ' ').trim();

  // Split based on the separator pattern
  let words = output.split(regex);

  // Filter out any empty strings or commas by them selves and trim whitespace from each word
  return words.filter(word => word.trim() !== '' && word.trim() !== ','); // commas always come with a word before
};

const alignGrid = (grid, align, maxGridWidth) => {
  // 1. get the max width of each row
  const rows = grid.reduce((acc, curr) => {
    if (!acc[curr.row]) {
      acc[curr.row] = [];
    }
    acc[curr.row].push(curr);

    // if curr.rowHasRetrunAtEnd add rowHasRetrunAtEnd to every word object in the prev row
    if (curr.rowHasRetrunAtEnd && acc[curr.row - 1]) {
      acc[curr.row - 1] = acc[curr.row - 1].map(word => ({ ...word, rowHasRetrunAtEnd: true }));
    }

    return acc;
  }, []);

  const maxRowWidths = rows.map(row => row.reduce((acc, curr) => acc + curr.width, 0));
  const wordsInEachRow = rows.map(row => row.length);
  const rowsWithReturnAtEnd = rows
    .filter(row => row.some(word => word.rowHasRetrunAtEnd))
    .map(row => row[0].row);

  // 2. calculate the new x for each word in the grid
  const alignedGrid = grid.map(word => {
    const rowWidth = maxRowWidths[word.row];

    let x = 0;
    if (align === 'center') {
      x = (maxGridWidth - rowWidth) / 2;
    } else if (align === 'right') {
      x = maxGridWidth - rowWidth;
    } else if (
      align === 'justify' &&
      word.row !== rows.length - 1 &&
      !rowsWithReturnAtEnd.includes(word.row)
    ) {
      x = ((maxGridWidth - rowWidth) / wordsInEachRow[word.row]) * word.rowIndex;
    }
    return { ...word, x: word.x + x };
  });

  return alignedGrid;
};

const TWordList = React.memo(
  ({
    type,
    isNumberList,
    shapeProps,
    setTextareaProps,
    isEditMode,
    setIsEditMode,
    setTextareaValue,
    isSelected,
    scale,
    setSelectedShapeId,
    stageWidth,
    stageHeight,
    bleedingTop,
    bleedingLeft,
    onSelect,
    onDragStart,
    onDragMove,
    onDragEnd,
    onChange,
    trForceUpdate,
    handleStoryPagesCreation,
    pageNumber,
  }) => {
    const shapeRef = React.useRef();
    const [classicGrid, setClassicGrid] = React.useState([]);
    const [smartGrid, setSmartGrid] = React.useState([]);
    const [articleGrid, setArticleGrid] = React.useState([]);

    const [forcedMinWidth, setForcedMinWidth] = React.useState(0);
    const classWordlistWidth = shapeProps.id.includes('classic-word-list')
      ? forcedMinWidth > shapeProps.width
        ? forcedMinWidth
        : shapeProps.width
      : null;

    // cols increments for each col in the wordlist
    const [incs, setIncs] = React.useState([0, 0, 0]);

    const getAngleInRadians = degrees => {
      const pi = Math.PI;
      return degrees * (pi / 180);
    };

    const topLeftPoint = (cx, cy, w, h, rotation, scale) => {
      const x =
        ((cx + bleedingLeft - (w / 2) * Math.cos(rotation) + (h / 2) * Math.sin(rotation)) *
          scale) /
        100;
      const y =
        ((cy + bleedingTop - (w / 2) * Math.sin(rotation) - (h / 2) * Math.cos(rotation)) * scale) /
        100;
      return { x, y };
    };

    React.useEffect(() => {
      if (isSelected && isEditMode) {
        shapeRef.current.hide();
      } else if (!isSelected || !isEditMode) {
        shapeRef.current.show();
      }
    }, [isSelected, isEditMode]);

    React.useEffect(() => {
      // condition for story with text coming as grid already
      if (!shapeProps.text) return;
      if (shapeProps.id.includes('story') && typeof shapeProps.text !== 'string') {
        setArticleGrid(shapeProps.text);
        return;
      }
      const sepArr =
        shapeProps.id.includes('article') || shapeProps.id.includes('story')
          ? [' ', '\\n']
          : undefined;

      const words = extractWords(shapeProps.text, sepArr);
      const grid = [];
      if (shapeProps.id.includes('classic-word-list')) {
        const rowsLen = Math.ceil(words.length / shapeProps.staticLink.cols);
        const colsLen = Math.ceil(words.length / rowsLen);

        const chunkSize = shapeProps.staticLink.isAcrossDown ? rowsLen : colsLen;
        for (let i = 0; i < words.length; i += chunkSize) {
          const chunk = words.slice(i, i + chunkSize);
          grid.push(chunk);
        }
        setClassicGrid(grid);
      } else if (
        shapeProps.id.includes('smart-word-list') ||
        shapeProps.id.includes('article-word-list') ||
        shapeProps.id.includes('story-word-list')
      ) {
        const rowWidth = shapeProps.width;
        let currRow = 0;
        let currWord = 0;
        let x = 0;
        const lowerMarkedWords = shapeProps.staticLink.markedWords
          ? shapeProps.staticLink.markedWords.map(w => w.toLowerCase())
          : [];
        words.forEach((word, i) => {
          const fontStyle = lowerMarkedWords.includes(word.toLowerCase())
            ? shapeProps.staticLink.markedWordsStyles.fontStyle
            : shapeProps.fontStyle;

          const width = calculateTextWidth({
            text: word + ' ',
            fontSize: shapeProps.fontSize,
            fontFamily: shapeProps.fontFamily,
            fontStyle: fontStyle,
            addedSpace: 0,
            minWidth: 0,
            canvasWidth: shapeProps.width,
          });

          const wordWidth =
            width +
            shapeProps.letterSpacing * word.length +
            (shapeProps.id.includes('smart') ? 10 : 0); // 10 padding

          // check for returns
          if (x + wordWidth + shapeProps.fontSize > rowWidth) {
            x = wordWidth;
            currRow++;
            currWord = 0;
          } else if (word === '\\n') {
            x = 0;
            currRow++;
            currWord = 0;
          } else {
            x += wordWidth;
          }
          currWord++;

          const gapX = shapeProps.id.includes('smart') ? 15 : 0;
          const gapY = shapeProps.id.includes('smart') ? 15 : 0;
          const w = {
            text: word === '\\n' ? '' : word,
            x: x - wordWidth + gapX * currWord,
            y:
              currRow * shapeProps.fontSize +
              shapeProps.fontSize * 0.5 * currRow +
              (shapeProps.lineHeight + gapY) * currRow,
            width: word === '\\n' ? 0 : wordWidth,
            row: currRow,
            rowIndex: currWord - 1,
            rowHasRetrunAtEnd: word === '\\n',
          };

          if (lowerMarkedWords.includes(word.toLowerCase())) {
            w.textDecoration = shapeProps.staticLink.markedWordsStyles.textDecoration;
            w.fontStyle = shapeProps.staticLink.markedWordsStyles.fontStyle;
            w.fill = shapeProps.staticLink.markedWordsStyles.fill;
          }
          grid.push(w);
        });

        // setSmartListHeight(currRow * shapeProps)
        if (shapeProps.id.includes('smart')) setSmartGrid(grid);
        else if (shapeProps.id.includes('article') || shapeProps.id.includes('story')) {
          const alignedText = alignGrid(grid, shapeProps.align, shapeProps.width);

          setArticleGrid(alignedText);

          // let manuscript know that we need more pages for the story in case of overflow
          if (shapeProps.id.includes('story')) {
            const totalHeight = grid[grid.length - 1].y + shapeProps.fontSize;
            if (totalHeight > shapeProps.height && handleStoryPagesCreation) {
              handleStoryPagesCreation({
                grid: alignedText,
                totalHeight,
                textHeight: shapeProps.height,
                pageNumber,
              });
            }
          }
        }
      }
    }, [
      shapeProps.width,
      shapeProps.height,
      shapeProps.text,
      shapeProps.align,
      shapeProps.staticLink.cols,
      shapeProps.staticLink.rows,
      shapeProps.lineHeight,
      shapeProps.letterSpacing,
      shapeProps.fontSize,
      shapeProps.staticLink.markedWords,
      shapeProps.staticLink.markedWordsStyles && shapeProps.staticLink.markedWordsStyles.fill,
      shapeProps.staticLink.markedWordsStyles &&
        shapeProps.staticLink.markedWordsStyles.textDecoration,
      shapeProps.staticLink.markedWordsStyles && shapeProps.staticLink.markedWordsStyles.fontStyle,
    ]);

    // // for alignment
    // useEffect(() => {
    //   if (!shapeProps.id.includes('article-word-list')) return;
    //   if (articleGrid.length === 0) return;
    //   const alignedText = alignGrid(articleGrid, shapeProps.align, shapeProps.width);
    //   setArticleGrid(alignedText);
    // }, [shapeProps.align, shapeProps.width]);

    // Next 3 useEffects is All about syncing text area with text - be careful!
    React.useEffect(() => {
      if (shapeRef.current) {
        if (isSelected) {
          const node = shapeRef.current;
          // const childNode = childRef.current;

          const { x, y } = topLeftPoint(
            node.x(),
            node.y(),
            node.width(),
            node.height(),
            getAngleInRadians(node.rotation()),
            scale
          );

          setTextareaProps({
            styles: {
              position: 'absolute',
              left: stageWidth / 2 + x + shapeProps.width / 2,
              top: stageHeight / 2 + y,
              width: shapeProps.width,
              height: Math.max(
                200,
                Math.floor((shapeProps.text.length * shapeProps.fontSize) / shapeProps.width) *
                  shapeProps.fontSize *
                  0.6
              ),
              fontSize: shapeProps.fontSize,
              textAlign: shapeProps.align,
              border: 'none',
              padding: shapeProps.padding,
              overflow: 'hidden',
              background: '#FAFAFA',
              outline: 'none',
              resize: 'none',
              lineHeight: shapeProps.lineHeight,
              letterSpacing: shapeProps.letterSpacing,
              fontFamily: shapeProps.fontFamily,
              fontStyle: shapeProps.fontStyle.includes('bold') ? 'bold' : false,
              fontStyle: shapeProps.fontStyle.includes('italic') ? 'italic' : 'normal',
              transformOrigin: 'top left',
              textAlign: 'left',
              color: shapeProps.fill,
              transform: `translateY(-2px) scale(${scale / 100}) rotateZ(${node.rotation()}deg)`,
            },
            id: shapeProps.id,
          });

          setTextareaValue(shapeProps.text);
        }
      }
    }, [
      isSelected,
      isEditMode,
      shapeRef.current,
      scale,
      shapeProps.id,
      shapeProps.fontSize,
      shapeProps.fontStyle,
      shapeProps.fontFamily,
      shapeProps.letterSpacing,
      shapeProps.lineHeight,
      shapeProps.align,
      shapeProps.width,
      shapeProps.height,
      shapeProps.text,
      shapeProps.fill,
      shapeProps.x,
      shapeProps.y,
      shapeProps.rotation,
      stageHeight,
      stageWidth,
    ]);

    // React.useEffect(() => {
    //   if (isSelected && shapeProps.id.includes('classic-word-list')) {
    //     const height = shapeProps.staticLink.rows * (shapeProps.fontSize + shapeProps.lineHeight) * .6; // .25 is UI fix - Don't remove it
    //     onChange({ ...shapeProps, height });
    //   } else {
    //     // UI fix - Don't remove it
    //     delete shapeRef.current.height;
    //   }
    // }, [
    //   isSelected,
    //   shapeProps.staticLink.rows,
    //   shapeProps.fontSize,
    //   shapeProps.padding,
    //   shapeProps.lineHeight,
    // ]);

    React.useEffect(() => {
      if (isSelected && shapeProps.id.includes('classic-word-list')) {
        trForceUpdate();
      }
    }, [
      isSelected,
      shapeProps.staticLink.rows,
      shapeProps.staticLink.cols,
      shapeProps.fontSize,
      shapeProps.padding,
      shapeProps.lineHeight,
    ]);

    React.useEffect(() => {
      // UI fix - Don't remove it
      // delete shapeRef.current.height;
    }, [shapeProps]);

    React.useEffect(() => {
      setIncs(new Array(shapeProps.staticLink.cols).fill(0));
      // force showing all words in case of trunctation or force min width
      if (shapeProps.id.includes('classic-word-list')) {
        const incs = [];
        let totalInc = 0;
        const colWidth = shapeProps.width / shapeProps.staticLink.cols;
        let maxWordWidth = 0;
        classicGrid.forEach(col => {
          const maxWord = findMaxLenWord(col);
          maxWordWidth = calculateTextWidth({
            text: maxWord,
            fontSize: shapeProps.fontSize,
            fontFamily: shapeProps.fontFamily,
            canvasWidth: shapeProps.width,
            minWidth: 0,
          });

          if (maxWordWidth === colWidth) {
            incs.push(0);
          } else {
            incs.push(maxWordWidth - colWidth);
            totalInc += maxWordWidth - colWidth;
          }
        });
        if (totalInc > 0) {
          setForcedMinWidth(shapeProps.width + totalInc);
        } else {
          setIncs(reCalculateIncs(incs));
        }
      }
    }, [
      classicGrid,
      shapeProps.staticLink.cols,
      shapeProps.text,
      shapeProps.fontSize,
      shapeProps.fontFamily,
      shapeProps.width,
    ]);

    return (
      <>
        {shapeProps.link && isSelected && !isEditMode && (
          <LinkIndicator shapeProps={shapeProps} offset={false} />
        )}
        <Group
          onMouseDown={onSelect}
          onDblClick={() => {
            if (shapeProps.id.includes('smart-word-list')) {
              setSelectedShapeId(null); // UI fix - Don't remove it
              setTimeout(() => {
                setIsEditMode(true);
                setSelectedShapeId(shapeProps.id);
              }, 500);
            }
          }}
          onTap={onSelect}
          onDblTap={() => {
            if (shapeProps.id.includes('smart-word-list')) {
              setSelectedShapeId(null); // UI fix - Don't remove it
              setIsEditMode(true);
              setSelectedShapeId(shapeProps.id);
            }
          }}
          ref={shapeRef}
          {...shapeProps}
          draggable
          onDragStart={onDragStart}
          onDragMove={onDragMove}
          onDragEnd={e => onDragEnd(shapeProps, e)}
          onTransformEnd={e => {
            // transformer is changing scale of the node
            // and NOT its width or height- shapeRef.current.fontSize()/scale/100/2 - shapeRef.current.padding()/scale/100
            // but in the store we have only width and height
            // to match the data better we will re- shapeRef.current.fontSize()/scale/100/2 - shapeRef.current.padding()/scale/100set scale on transform end
            const node = shapeRef.current;
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();

            node.scaleX(1);
            node.scaleY(1);

            const newProps = {
              ...shapeProps,
              x: node.x(),
              y: node.y(), // set minimal value
              width: node.width() * scaleX,
              scaleX: 1,
              scaleY: 1,
              rotation: node.rotation(),
            };

            if (shapeProps.id.includes('story-word-list')) {
              newProps.height = node.height() * scaleY;
            }

            // we will reset it back
            onChange(newProps, true);
            trForceUpdate();
          }}
        >
          <Rect // this to constrain width
            x={0}
            y={0}
            width={classWordlistWidth || shapeProps.width}
            height={shapeProps.height || 10}
            stroke={'rgba(0,0,0,0)'}
            fill={'rgba(0,0,0,0)'}
          />
          {articleGrid.map(word => (
            <>
              <Rect
                x={word.x}
                y={word.y}
                cornerRadius={shapeProps.fontSize}
                offsetY={shapeProps.fontSize / 2 - 6}
                width={word.width}
                height={shapeProps.fontSize + 10}
                stroke={'transparent'}
                fill={'rgba(0,0,0,0)'}
                strokeWidth={1}
              />
              <Text
                // problem is here
                wrap={'none'}
                key={'x' + word.x + ' y' + word.y}
                {...shapeProps}
                height={shapeProps.fontSize}
                x={word.x}
                y={word.y}
                lineHeight={0}
                width={word.width}
                offsetY={-shapeProps.fontSize * 0.5}
                text={word.text.trim()}
                rotation={0}
                verticalAlign={'top'}
                fill={word.fill || shapeProps.fill}
                fontStyle={word.fontStyle}
                textDecoration={word.textDecoration}
              />
            </>
          ))}
          {smartGrid.map(word => (
            <>
              <Text
                wrap={'none'}
                key={'x' + word.x + ' y' + word.y}
                {...shapeProps}
                x={word.x}
                y={word.y}
                lineHeight={0}
                width={word.width}
                offsetY={-shapeProps.fontSize * 0.5}
                text={word.text.trim()}
                rotation={0}
                verticalAlign={'top'}
                padding={5}
              />
              <Rect
                x={word.x}
                y={word.y}
                cornerRadius={shapeProps.fontSize}
                offsetY={shapeProps.fontSize / 2 - 6}
                width={word.width}
                height={shapeProps.fontSize + 10}
                stroke={'#000000'}
                fill={'rgba(0,0,0,0)'}
                strokeWidth={1}
              />
            </>
          ))}
          {classicGrid.map((wordsChunk, i) =>
            wordsChunk.map((word, j) => {
              let x = 0;
              let y = 0;

              if (!shapeProps.staticLink.isAcrossDown) {
                // consider filling the grid from left to right
                x =
                  j * (classWordlistWidth / shapeProps.staticLink.cols) +
                  (j === 0 ? 0 : sumToIndex(incs, j));
                y = i * shapeProps.fontSize + i * shapeProps.lineHeight;
              } else {
                // consider filling the grid from top to bottom
                x =
                  i * (classWordlistWidth / shapeProps.staticLink.cols) +
                  (i === 0 ? 0 : sumToIndex(incs, i));
                y = j * shapeProps.fontSize + j * shapeProps.lineHeight;
              }

              let markedWordProps = {};
              if (
                shapeProps.staticLink.markedWords &&
                shapeProps.staticLink.markedWords.includes(word.trim())
              ) {
                markedWordProps.textDecoration =
                  shapeProps.staticLink.markedWordsStyles.textDecoration;
                markedWordProps.fontStyle = shapeProps.staticLink.markedWordsStyles.fontStyle;
                markedWordProps.fill = shapeProps.staticLink.markedWordsStyles.fill;
              }

              return (
                <Text
                  ellipsis
                  wrap={'none'}
                  key={'col' + j + '-' + 'row' + i}
                  {...shapeProps}
                  {...markedWordProps}
                  x={x}
                  y={y}
                  width={classWordlistWidth / shapeProps.staticLink.cols + incs[i]}
                  text={word.trim()}
                  lineHeight={1}
                  rotation={0}
                />
              );
            })
          )}
        </Group>
      </>
    );
  }
);

export default TWordList;
