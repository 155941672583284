import { useEffect } from 'react';

export const autoFillWordSearchForm = (
  manuscript,
  setWordSearchFormData,
  activeModes,
  setActiveModes,
  setMode,
  selectedPages
) => {
  if (!manuscript || !manuscript.content_types) return;
  // handle modes first
  manuscript.content_types.every(role => {
    if (role.includes('classic-word-list') || role.includes('smart-word-list')) {
      setActiveModes(m => [...m, 'word-lists']);
    } else if (role.includes('article-word-list')) {
      setActiveModes(m => [...m, 'article-lists']);
      setMode('article-lists');
    }
    if (activeModes.length > 1) return false;
    return true;
  });

  // init values
  const wordSearch = {
    nrows: 0,
    ncols: 0,
    npuzzles: 0,
    nwords: 0,
    puzzle_shape: '',
  };

  manuscript.links_map.forEach(group => {
    const { indecies, links_arr } = group;
    const selectedIndecies = indecies.filter(index => selectedPages.includes(index + 1));
    links_arr.forEach(link => {
      if (link.role === 'word-search-grid - puzzle') {
        wordSearch.npuzzles += selectedIndecies.length;
        if (!wordSearch.nrows) {
          wordSearch.nrows = link.rows;
          wordSearch.ncols = link.cols;
          wordSearch.puzzle_shape = link.puzzleShape;
        }
      }
      if (!wordSearch.nwords && link.role === 'classic-word-list-text') {
        wordSearch.nwords = link.cols * link.rows;
      }
    });
  });

  console.log('wordSearch', wordSearch);
  // auto fill form
  if (
    wordSearch.npuzzles ||
    wordSearch.nrows ||
    wordSearch.ncols ||
    wordSearch.puzzle_shape ||
    wordSearch.nwords
  )
    setWordSearchFormData(formData => ({
      ...formData,
      nrows: wordSearch.nrows,
      ncols: wordSearch.ncols,
      npuzzles: wordSearch.npuzzles,
      nwords: wordSearch.nwords,
      puzzle_shape: wordSearch.puzzle_shape,
    }));
};

export const autoFillNumberSearchForm = (manuscript, setNumberSearchFormData, selectedPages) => {
  if (!manuscript || !manuscript.links_map) return;
  // init values
  const numberSearch = {
    nrows: 0,
    ncols: 0,
    npuzzles: 0,
    nwords: 0,
    puzzle_shape: '',
  };

  manuscript.links_map.forEach(group => {
    const { indecies, links_arr } = group;
    const selectedIndecies = indecies.filter(index => selectedPages.includes(index + 1));

    links_arr.forEach(link => {
      if (link.role === 'number-search-grid - puzzle') {
        numberSearch.npuzzles += selectedIndecies.length;
        if (!numberSearch.nrows) {
          numberSearch.nrows = link.rows;
          numberSearch.ncols = link.cols;
          numberSearch.puzzle_shape = link.puzzleShape;
        }
      }
      if (!numberSearch.nwords && link.role === 'classic-number-list-text') {
        numberSearch.nwords = link.cols * link.rows;
      }
    });
  });

  // auto fill form
  if (
    numberSearch.npuzzles ||
    numberSearch.nrows ||
    numberSearch.ncols ||
    numberSearch.puzzle_shape ||
    numberSearch.nwords
  )
    setNumberSearchFormData(formData => ({
      ...formData,
      nrows: numberSearch.nrows,
      ncols: numberSearch.ncols,
      npuzzles: numberSearch.npuzzles,
      nwords: numberSearch.nwords,
      puzzle_shape: numberSearch.puzzle_shape,
    }));
};

export const autoFillSudokuForm = (manuscript, setSudokuFormData, selectedPages) => {
  if (!manuscript || !manuscript.links_map) return;
  // init values
  const sudoku = {
    npuzzles: 0,
    size: 0,
  };

  manuscript.links_map.forEach(group => {
    const { indecies, links_arr } = group;

    const selectedIndecies = indecies.filter(index => selectedPages.includes(index + 1));

    links_arr.forEach(link => {
      if (link.role === 'sudoku-grid - puzzle') {
        sudoku.npuzzles += selectedIndecies.length;
        if (!sudoku.size) {
          sudoku.size = link.rows;
        }
      }
    });
  });

  // auto fill form
  if (sudoku.npuzzles || sudoku.size)
    setSudokuFormData(formData => ({
      ...formData,
      npuzzles: sudoku.npuzzles,
      size: sudoku.size,
    }));
};

export const autoFillTriviaForm = (manuscript, setRequiredTriviasNumber, selectedPages) => {
  let ntriviasQ = 0;
  let ntriviasA = 0;
  if (manuscript && manuscript.links_map) {
    manuscript.links_map.forEach(group => {
      const { indecies, links_arr } = group;
      const selectedIndecies = indecies.filter(index => selectedPages.includes(index + 1));

      links_arr.forEach(link => {
        if (link.role === 'Problem - Trivia') {
          ntriviasQ += selectedIndecies.length;
        } else if (link.role === 'Solution - Trivia') {
          ntriviasA += indecies.length;
        }
      });
    });
  }

  if (ntriviasQ === ntriviasA) {
    setRequiredTriviasNumber(ntriviasQ);
  } else if (ntriviasQ === 2 * ntriviasA) {
    setRequiredTriviasNumber(ntriviasA);
  } else {
    setRequiredTriviasNumber(Math.min(ntriviasQ, ntriviasA));
  }
};

export const autoFillStoriesform = () => {
  return;
};
