import React, { useState, useRef } from 'react';
import IncludeSvg from 'components/common/IncludeSvg';
import PopupMenu from 'components/common/PopupMenu';
import { useOnClickOutside, useOnClickOnEscape } from 'hooks/util';
import { isSelected, toggleSelect, removeDuplicates } from 'components/common/Tags';
import './Tags.scss'

function SearchControls({
  type,
  allowedTags,
  tagsArr,
  setTagsArr,
  sortBy,
  setSortBy,
  showing,
  searchable = true,
}) {
  const popupRef = useRef(null);
  const [tagInput, setTagInput] = useState('');
  const [popup, setPopup] = useState(false);
  const [showSearchList, setShowSearchList] = useState(false);
  const listSearchRef = useRef(null);

  useOnClickOutside(popupRef, () => setPopup(false));
  useOnClickOutside(listSearchRef, () => {
    setShowSearchList(false);
  });

  useOnClickOnEscape(() => setPopup(false));

  const searchTags = () =>
    allowedTags.filter(
      tag =>
        tag.name.toLowerCase().includes(tagInput.toLowerCase().trim()) &&
        !tagsArr.includes(tag)
    );

  const popupOptions = [
    {
      title: 'Alphabetically',
      value: 'alpha',
      handler: () => {
        setSortBy('alpha');
        setPopup(false);
      },
    },
    {
      title: 'Recently Added',
      value: 'recently_added',
      handler: () => {
        setSortBy('recently_added');
        setPopup(false);
      },
    },
    {
      title: 'Most Popular',
      value: 'most_popular',
      handler: () => {
        setSortBy('most_popular');
        setPopup(false);
      },
    },
  ];

  const titleOf = objVal => {
    return popupOptions.find(option => option.value === objVal).title;
  };

  return (
    <div className="search-controls">
      <div className="controls shadow-sm">
        <div className="d-flex">
          <div className="group">
            <span className="title">Tag filter:</span>
            <div className="tags-body" >
              <div className='d-flex'>
              <input
                className='radius-0'
                type="text"
                name="search"
                placeholder="Type here..."
                autoComplete="off"
                value={tagInput}
                onChange={e => {
                  setTagInput(e.target.value);
                }}
                onKeyUp={e => {
                  if (searchable) setShowSearchList(true);
                  // else if (searchable) setShowSearchList(false);

                  if (e.key === 'Enter' && tagInput.trim()) {
                    const index = allowedTags.findIndex(
                      allowedTag =>
                        allowedTag.name.toLowerCase() ===
                        tagInput.trim().toLowerCase()
                    );
                    if (index > -1) {
                      setTagsArr(
                        removeDuplicates([...tagsArr, allowedTags[index]], 'id')
                      );
                      if (searchable) setShowSearchList(false);
                      setTagInput('');
                    }
                  }
                }}
                onFocus={() => setShowSearchList(true)}
                />
                <IncludeSvg name="search" />
                </div>
              <div className="allowed-tags">
                <div
                  className={`allowed-tags-list bottom scrollable-y shadow-sm ${
                    showSearchList ? '' : 'd-none'
                  }`}
                  ref={listSearchRef}
                >
                  {!searchTags().length && (
                    <span className="empty-state">No tags.</span>
                  )}
                  {searchTags().map((tag, index) => (
                    <span
                      key={index}
                      onClick={() => {
                        toggleSelect({ tag, tagsArr, setTagsArr });
                        setTagInput('');
                        setShowSearchList(false);
                      }}
                      className={`tag no-x ${
                        isSelected({ tag, tagsArr }) ? 'selected' : ''
                      }`}
                    >
                      {tag.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div
            className="group noselect sort-by"
            onClick={() => {
              setPopup(!popup);
            }}
          >
            <span className="title">Sort by:</span>
            <span className="variable">{titleOf(sortBy)}</span>
            <span className={`${popup && 'rotate-180'}`}>
              <IncludeSvg name="arrow-drop-down" />
            </span>
            {popup && <PopupMenu popupRef={popupRef} options={popupOptions} />}
          </div>
        </div>
        <div className="group d-flex w-50 justify-content-end">
          <span className="title">Showing:</span>

          <span className="variable truncate" style={{ maxWidth: 150 }}>
            <span className="text-bold" title={showing}>
              {showing}
            </span>
          </span>
          <span className="variable">{type}s</span>
        </div>
      </div>
      <div className="tags-container">
        {tagsArr.map((tag, index) => {
          return (
            <span key={index} className="tag">
              <span className="name">{tag.name}</span>{' '}
              <span
                className="btn close-btn"
                onClick={() => {
                  const newTags = [...tagsArr];
                  newTags.splice(index, 1);
                  setTagsArr(newTags);
                }}
              >
                x
              </span>
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default SearchControls;
