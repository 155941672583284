import React from 'react';

function PopupMenu({
  popupRef,
  options,
  closeBtn,
  type,
  setOption,
  setOptionTitle,
  isOpen = true,
  setIsOpen,
}) {
  const typeOptions = {
    sort: [
      {
        title: 'Alphabetically',
        value: 'alpha',
      },
      {
        title: 'Recently Added',
        value: 'recently_added',
      },
      {
        title: 'Most Popular',
        value: 'most_popular',
      },
    ],
    sortDir: [
      {
        title: 'Ascending',
        value: 'asc',
      },
      {
        title: 'Descending',
        value: 'desc',
      },
    ],
  };

  const handleClick = (e, option) => {
    if (type) {
      setOptionTitle(option.title);
      setOption(option.value);
    } else {
      option.handler(e);
    }
    if(setIsOpen) setIsOpen(false);
  };

  const opts = options || typeOptions[type];

  return (
    isOpen && (
      <div
        ref={popupRef}
        className="popup"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {closeBtn && (
          <div className="close-btn-wrapper">
            <button className="close-btn" onClick={closeBtn.handler}>
              x
            </button>
          </div>
        )}
        {opts.map((option, index) => (
          <div
            key={index}
            className="option"
            onClick={e => handleClick(e, option)}
          >
            {option.title}
          </div>
        ))}
      </div>
    )
  );
}

export default PopupMenu;
