import React, { useState, useEffect, useRef, useCallback } from 'react';
import { getOneFormat } from 'api/studio/formats';
import { useWindowSize, useScale } from 'hooks/util';

import { KonvaStage, getInitialShapes } from 'components/studio/FormatsStage';

const TemplatePage = ({
  isSelected,
  pageSizes,
  template,
  handlePageClick,
  index,
  page,
  show,
  passRef,
  handleStoryPagesCreation,
  pageNumber,
}) => {
  const pageRef = useRef();
  const stageRef = useRef(null);
  const shapesLayerRef = useRef(null);
  const [pageWidth, setPageWidth] = useState(0);
  const [pageHeight, setPageHeight] = useState(0);
  const [width] = useWindowSize();
  const [format, setFormat] = useState(null);
  const [shapes, setShapes] = useState([]);

  useEffect(() => {
    if (format && format.stage_json) setShapes(getInitialShapes(format));
  }, [format]);

  useEffect(() => {
    if (pageSizes.length) {
      const pageWidth = pageRef.current.clientWidth - 2;
      setPageWidth(pageWidth);

      const pageSize = pageSizes.find(pageSize => template.page_size === pageSize.id);

      const ratio = pageSize ? pageSize.height / pageSize.width : 0;
      setPageHeight(pageWidth * ratio);
    }
  }, [pageSizes, template && template.page_size, pageRef.current, width, show]);

  useEffect(() => {
    if (page && page.stage_json) {
      const timeout = setTimeout(() => {
        setFormat({
          ...page,
        });
      }, 1000);
      return () => clearTimeout(timeout);
    } else if (page && page.id) {
      getOneFormat(page.id).then(res => {
        setFormat(res.format);
      });
    }
  }, [page, page && page.id]);

  const widthIncludingBleedingInInches = format
    ? format.width + format.bleeding.left / 96 + format.bleeding.right / 96
    : 0; // in inches
  const heightIncludingBleedingInInches = format
    ? format.height + format.bleeding.top / 96 + format.bleeding.bottom / 96
    : 0; // in inches

  const scaleToShow = format ? pageWidth / (format.width * 96) : 0;

  useEffect(() => {
    if (passRef) passRef(stageRef, shapesLayerRef);
  }, [format, format && stageRef.current]);

  return (
    <>
      <div
        ref={pageRef}
        id={`t-${template.id}-pn-${index + 1}`}
        key={index + 1}
        onClick={e => handlePageClick(index + 1, e)}
        className={`page animated-background noselect d-flex flex-column justify-content-center align-items-center ${
          isSelected ? 'selected' : ''
        } ${!format && !isSelected ? 'shadow' : ''}`}
        style={{
          height: pageHeight + 4, // UI fix: 4px is the border width
          backgroundColor: !format ? 'white' : undefined,
        }}
      >
        {format && format.stage_json && (
          <div style={{ transform: `scale(${scaleToShow})` }}>
            {/* transparent div to cover KonvaStage to prevent dragging */}
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: 1000,
              }}
            ></div>
            {/* end: transparent div to cover KonvaStage to prevent dragging */}

            <KonvaStage
              key={index}
              stageRef={stageRef}
              shapesLayerRef={shapesLayerRef}
              format={{
                ...format,
                width: widthIncludingBleedingInInches,
                height: heightIncludingBleedingInInches,
                margin: {
                  top: format.margin.top + format.bleeding.top,
                  left: format.margin.left + format.bleeding.left,
                  bottom: format.margin.bottom + format.bleeding.bottom,
                  right: format.margin.right + format.bleeding.right,
                },
              }}
              shapes={shapes}
              scale={100}
              handleStoryPagesCreation={handleStoryPagesCreation}
              pageNumber={pageNumber}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default TemplatePage;
