import { useEffect, useState } from 'react';
import { PAGE_SELECTOR_MODES_MANUSCRIPT, PAGE_SELECTOR_HAND_SIDES } from 'settings/page-selector';
import SelectWrapper from 'components/forms/elements/SelectWrapper';
import InputNumber from 'components/forms/elements/InputNumber';
import Confirm from 'components/common/Confirm';
import InputText from 'components/forms/elements/InputText';
import './PageSelector.scss';
import _ from 'lodash';
function PageSelector({
  pageSelectorMode,
  setPageSelectorMode,
  pageSelectorHandSide,
  setPageSelectorHandSide,
  pages,
  from,
  setFrom,
  to,
  setTo,
  from1,
  setFrom1,
  to1,
  setTo1,
  segments,
  setSegments,
  allowNewSegments = false,
}) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [showTableConfirm, setShowTableConfirm] = useState(false);
  const [segmentName, setSegmentName] = useState('');
  const [error, setError] = useState('');
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [localSegments, setLocalSegments] = useState(segments);

  useEffect(() => {
    setLocalSegments(
      segments.map(s => ({ ...s, from: +s.from, to: +s.to, from1: +s.from1, to1: +s.to1 }))
    );

    // update form options of selected segment
    const selectedSegmentIndex = segments.findIndex(
      s => s.name + ' (2-ranges)' === pageSelectorMode
    );
    if (selectedSegmentIndex < 0) {
      setPageSelectorMode('single');
      return;
    }
    const newSeg = segments[selectedSegmentIndex];
    setPageSelectorMode(newSeg.name + ' (2-ranges)');
    setPageSelectorHandSide(newSeg.pageSelectorHandSide);
    setFrom(+newSeg.from);
    setTo(+newSeg.to);
    setFrom1(+newSeg.from1);
    setTo1(+newSeg.to1);
  }, [segments]);

  const pageSelectionOptions = [
    ...PAGE_SELECTOR_MODES_MANUSCRIPT,
    ...segments.map(s => ({ label: s.name, value: s.name + ' (2-ranges)' })),
  ];

  const handleSaveSegmentConfirm = () => {
    if (!segmentName) {
      setError('Segment name is required');
      return;
    }

    const segmentIndex = segments.findIndex(s => s.name + ' (2-ranges)' === pageSelectorMode);
    // check if segment name is preserved or already exists
    // exclude current segment name

    if (
      pageSelectionOptions.find(m => m.label === segmentName) &&
      pageSelectorMode !== segmentName + ' (2-ranges)'
    ) {
      setError('Segment name is perserved or already exists');
      return;
    }

    if (pageSelectorMode.includes('(2-ranges)')) {
      const newSegments = [...segments];

      newSegments[segmentIndex] = {
        name: segmentName,
        pageSelectorMode,
        pageSelectorHandSide,
        from,
        to,
        from1,
        to1,
      };
      setSegments(newSegments);
    } else {
      setSegments(s => [
        ...s,
        { name: segmentName, pageSelectorMode, pageSelectorHandSide, from, to, from1, to1 },
      ]);
    }
    setPageSelectorMode(segmentName + ' (2-ranges)');
    setShowConfirm(false);
    setSegmentName('');
  };

  const handleDeleteSegmentUsingName = () => {
    const newSegments = segments.filter(s => s.name + ' (2-ranges)' !== pageSelectorMode);
    setSegments(newSegments);
    setSegmentName('');
    setPageSelectorMode('2-ranges');
  };

  useEffect(() => {
    if (error && segmentName && !pageSelectionOptions.find(m => m.label === segmentName)) {
      setError('');
    }
  }, [segmentName, error]);

  useEffect(() => {
    if (pageSelectorMode.includes('(2-ranges)')) {
      const segment = segments.find(s => s.name + ' (2-ranges)' === pageSelectorMode);
      if (segment) {
        setPageSelectorHandSide(segment.pageSelectorHandSide);
        setFrom1(+segment.from1);
        setTo1(+segment.to1);
        setFrom(+segment.from);
        setTo(+segment.to);
      }
    }
  }, [pageSelectorMode]);

  return (
    <div className="form-group d-flex flex-column">
      <label className="mb-3">Page Selector</label>
      <div className="d-flex flex-column align-items-center">
        <div className="w-100">
          <SelectWrapper
            className={''}
            options={pageSelectionOptions}
            value={pageSelectorMode}
            setValue={setPageSelectorMode}
          />
        </div>
        <div className="w-100" style={{ marginTop: 10, marginBottom: 10 }}>
          <SelectWrapper
            options={PAGE_SELECTOR_HAND_SIDES}
            value={pageSelectorHandSide}
            setValue={setPageSelectorHandSide}
          />
        </div>
      </div>
      {pageSelectorMode.includes('2-ranges') && (
        <label className="my-2">
          <b>Puzzles</b>
        </label>
      )}
      <div className="d-flex justify-content-center mb-2">
        <div className="d-flex align-items-center w-100">
          <label>{pageSelectorMode === 'single' ? 'Page' : 'From'}</label>
          <InputNumber
            style={{ width: '100%' }}
            className={'form-control'}
            min={pageSelectorHandSide === 1 ? 2 : 1}
            max={pages.length}
            step={pageSelectorHandSide === 0 ? 1 : 2}
            value={from}
            setValue={setFrom}
          />
        </div>

        <div style={{ marginLeft: 10 }} className="d-flex align-items-center w-100">
          {pageSelectorMode.includes('range') && (
            <>
              <label>To</label>
              <InputNumber
                style={{ width: '100%' }}
                className={'form-control'}
                min={pageSelectorHandSide === 1 ? 2 : 1}
                max={pages.length}
                step={pageSelectorHandSide === 0 ? 1 : 2}
                value={to}
                setValue={value => (value > pages.length ? setTo(pages.length) : setTo(value))}
              />
            </>
          )}
        </div>
      </div>
      {pageSelectorMode.includes('2-ranges') && (
        <>
          <label className="mb-2">
            <hr />
            <b>Solutions</b>
          </label>
          <div className="d-flex justify-content-center mb-2">
            <div className="d-flex align-items-center w-100">
              <label>From</label>
              <InputNumber
                style={{ width: '100%' }}
                className={'form-control'}
                min={pageSelectorHandSide === 1 ? 2 : 1}
                max={pages.length}
                step={pageSelectorHandSide === 0 ? 1 : 2}
                value={from1}
                setValue={setFrom1}
              />
            </div>

            <div style={{ marginLeft: 10 }} className="d-flex align-items-center w-100">
              <label>To</label>
              <InputNumber
                style={{ width: '100%' }}
                className={'form-control'}
                min={pageSelectorHandSide === 1 ? 2 : 1}
                max={pages.length}
                step={pageSelectorHandSide === 0 ? 1 : 2}
                value={to1}
                setValue={value => {
                  value > pages.length ? setTo1(pages.length) : setTo1(value);
                }}
              />
            </div>
          </div>
          {allowNewSegments && (
            <div className="d-flex gap-3">
              <a
                className="h5 color-sys"
                onClick={() => {
                  if (pageSelectorMode === '2-ranges') {
                    setShowConfirm(true);
                    setSegmentName('');
                  } else {
                    setSegmentName(pageSelectorMode.replace(' (2-ranges)', ''));
                    setShowConfirm(true);
                  }
                }}
              >
                {pageSelectorMode === '2-ranges' ? 'Add New' : 'Rename'}
              </a>
              {pageSelectorMode.includes('(2-ranges)') && (
                <a className="h5 color-sys" onClick={() => setIsDeleteConfirmOpen(true)}>
                  Delete
                </a>
              )}
              {pageSelectorMode.includes('(2-ranges)') && (
                <a className="h5 color-sys" onClick={() => setShowTableConfirm(true)}>
                  Controls
                </a>
              )}
            </div>
          )}
        </>
      )}

      <Confirm
        isOpen={showConfirm}
        setIsOpen={setShowConfirm}
        title={pageSelectorMode === '2-ranges' ? 'New Segment' : 'Edit Segment'}
        msg={''}
        confirmText={'Save'}
        cancelText={"Don't Save"}
        handleConfirm={() => handleSaveSegmentConfirm()}
        handleCancel={() => setShowConfirm(false)}
        shouldCloseAfterConfirm={false}
      >
        {/* table with segments */}
        <div className="h4 d-flex flex-column gap-3">
          <div>Page Selector Mode: {pageSelectorMode}</div>
          <div>
            Page Selector Hand Side:{' '}
            {PAGE_SELECTOR_HAND_SIDES.find(s => s.value === pageSelectorHandSide).label}
          </div>
          <div>
            <span>Puzzle</span>
            <span className="mx-3 h5">From: {from}</span>
            <span className="h5">To: {to}</span>
          </div>
          <div>
            <span>Solution</span>
            <span className="mx-3 h5">From: {from1}</span>
            <span className="h5">To: {to1}</span>
          </div>

          <div>
            <label className="h4">Segment Name:</label>
            <InputText
              className={'h2 color-sys'}
              style={{
                width: '100%',
                border: 0,
                borderBottom: '1px solid #FFF',
                borderRadius: 0,
                background: 'transparent',
                outline: 'none',
              }}
              placeholder={'Enter segment name'}
              value={segmentName}
              setValue={setSegmentName}
            />
            {error && <div className="text-danger h5">{error}</div>}
          </div>
        </div>
      </Confirm>

      <Confirm
        isOpen={showTableConfirm}
        setIsOpen={setShowTableConfirm}
        title={'Segments'}
        msg={''}
        confirmText={'Update Segments'}
        cancelText={'Hide Controls'}
        handleConfirm={() => setSegments(localSegments)}
        handleCancel={() => setShowTableConfirm(false)}
        shouldCloseAfterConfirm={true}
        width="90rem"
      >
        <SegmentsEditableTable
          segments={segments}
          localSegments={localSegments}
          setLocalSegments={setLocalSegments}
        />
      </Confirm>

      <Confirm
        isOpen={isDeleteConfirmOpen}
        setIsOpen={setIsDeleteConfirmOpen}
        title={'Are you sure?'}
        msg={`"${pageSelectorMode.replace(' (2-ranges)', '')}" segment will be deleted!`}
        confirmText={'Delete'}
        cancelText={'Cancel'}
        handleConfirm={handleDeleteSegmentUsingName}
        handleCancel={() => setIsDeleteConfirmOpen(false)}
      />
    </div>
  );
}

export default PageSelector;

const SegmentsEditableTable = ({ localSegments, setLocalSegments }) => {
  const handleSegmentChange = (index, key, value) => {
    // make sure all (to, from, to1, from1, npages, npages1) values are numbers
    const newSegments = localSegments.map(s => ({
      ...s,
      from: +s.from,
      to: +s.to,
      npages: +s.nPages,
      from1: +s.from1,
      to1: +s.to1,
      npages1: +s.nPages1,
    }));
    newSegments[index][key] = value;

    if (key === 'nPages') {
      newSegments[index].to = +value + newSegments[index].from - 1;
    } else if (key === 'nPages1') {
      newSegments[index].to1 = +value + newSegments[index].from1 - 1;
    } else if (key === 'to' || key === 'from') {
      newSegments[index].nPages = newSegments[index].to - newSegments[index].from + 1;
    } else if (key === 'to1' || key === 'from1') {
      newSegments[index].nPages1 = newSegments[index].to1 - newSegments[index].from1 + 1;
    }

    setLocalSegments(newSegments);
  };

  const handleSegmentDelete = index => {
    const newSegments = [...localSegments];
    newSegments.splice(index, 1);
    setLocalSegments(newSegments);
  };

  const handleAddNewSegment = () => {
    const newSegments = [...localSegments];
    newSegments.push({
      to: 1,
      to1: 1,
      cols: 1,
      from: 1,
      name: 'New Segment',
      rows: 1,
      from1: 1,
      pageSelectorMode: '2-ranges',
      pageSelectorHandSide: 0,
    });
    setLocalSegments(newSegments);
  };
  return (
    <table className="segments-editable-table">
      <thead>
        <tr>
          <th rowSpan={2}>Segment Name</th>
          <th colSpan={3}>
            <b>Puzzles</b>
          </th>

          <th colSpan={3}>
            <b>Solutions</b>
          </th>
          <th rowSpan={2}>Actions</th>
        </tr>
        <tr>
          <th>From</th>
          <th>N Pages</th>
          <th>To</th>
          <th>From</th>
          <th>N Pages</th>
          <th>To</th>
        </tr>
      </thead>

      <tbody>
        {localSegments.map((segment, index) => (
          <tr key={index}>
            <td>
              <input
                type="text"
                value={segment.name}
                onChange={e => handleSegmentChange(index, 'name', e.target.value)}
              />
            </td>
            <td>
              <input
                type="number"
                value={segment.from}
                min="1"
                onChange={e => handleSegmentChange(index, 'from', e.target.value)}
              />
            </td>
            <td>
              <input
                type="number"
                style={{ backgroundColor: 'lightGray', outline: 'none' }}
                value={segment.nPages || segment.to - segment.from + 1}
                min="1"
                onChange={e => handleSegmentChange(index, 'nPages', e.target.value)}
              />
            </td>
            <td>
              <input
                type="number"
                value={segment.to}
                min="1"
                onChange={e => handleSegmentChange(index, 'to', e.target.value)}
              />
            </td>
            <td>
              <input
                type="number"
                value={segment.from1}
                min="1"
                onChange={e => handleSegmentChange(index, 'from1', e.target.value)}
              />
            </td>
            <td>
              <input
                type="number"
                style={{ backgroundColor: 'lightGray', outline: 'none' }}
                value={segment.nPages1 || segment.to1 - segment.from1 + 1}
                min="1"
                onChange={e => handleSegmentChange(index, 'nPages1', e.target.value)}
              />
            </td>
            <td>
              <input
                type="number"
                value={segment.to1}
                min="1"
                onChange={e => handleSegmentChange(index, 'to1', e.target.value)}
              />
            </td>
            <td>
              <button
                className="btn-white-forms"
                style={{
                  backgroundColor: 'transparent',
                  height: '3rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => handleSegmentDelete(index)}
              >
                Delete
              </button>
            </td>
          </tr>
        ))}
        <tr>
          <td colSpan={8}>
            <button
              className="btn-white-forms"
              style={{
                backgroundColor: 'transparent',
                height: '3rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={handleAddNewSegment}
            >
              Add New Segment
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
