import React from 'react';
import Main from 'layouts/Main';
import Slider from 'components/common/Slider';
import TopControls from 'components/common/TopControls';

function ManageCovers() {
  return (
    <div className="languages-page">
      <Main>
      <TopControls btns={[
          {text: 'Languages', path: '/settings/languages', selected: true}
        ]}/>
        <div className="resources">
          <Slider
            title={'Languages'}
            type={'language'}
          />
        </div>
      </Main>
    </div>
  )
}

export default ManageCovers