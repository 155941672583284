import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Toast.scss'
import { useEffect } from 'react';


import React from 'react';

function Toast({ msgs, setMsgs }) {
  useEffect(() => {
    msgs.forEach(msg => toast[msg.type](msg.content));
  }, [msgs]);
  return (
    <ToastContainer
      position="bottom-center"
      autoClose={3000}
      closeButton={false}
      theme={'dark'}
      // hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
}

export default Toast;
