import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'hooks/router';
import Main from 'layouts/Main';
import TabsNavigator from 'components/studio/TabsNavigator';
import FormatsStage from 'components/studio/FormatsStage';
import { useStudioContext } from 'hooks/studio';
import { getOneFormat } from 'api/studio/formats';
import FormatSpecForm from 'components/forms/FromatSpecForm';
import { default as SlideModal } from 'react-slide-out';
import { getTags } from 'api/common';
import { getPageSizes } from 'api/studio/formats';

function FormatWorkspace() {
  const history = useHistory();
  const { formats, setFormats, selectedFormat, setSelectedFormat } = useStudioContext();
  const query = useQuery();
  const [showSlideModal, setShowSlideModal] = useState(false);
  const [allowedTags, setAllowedTags] = useState([]);
  const [tagsArr, setTagsArr] = useState([]);
  const [tags, setTags] = useState(''); // formatted String
  const [pageSizes, setPageSizes] = useState([]);
  const [toRemove, setToRemove] = useState(null);

  async function multipleAPICalls(list, callFn) {
    const requests = list.map(item => callFn(item));
    return Promise.all(requests);
  }

  const addFormatToWorkspace = format => {
    const list = [...formats];
    list.push(format);
    setFormats(list);
  };

  async function loadFormats(ids) {
    try {
      const list = await multipleAPICalls(ids, getOneFormat);
      setFormats(
        list.map(res => {
          if (res.success) return res.format;
        })
      );
    } catch {
      history.replace('/');
    }
  }

  useEffect(() => {
    const ids = query.get('ids');
    const idsArr = ids && ids.length ? ids.split(',') : null;
    if (idsArr) loadFormats(idsArr);
    else history.replace('/');

    return () => {
      setFormats([]);
    };
  }, []);

  useEffect(() => {
    if (formats.length) {
      setSelectedFormat(formats[formats.length - 1]);
      history.replace(`/home/formats/workspace?ids=${formats.map(f => f.id).join(',')}`);
    }
  }, [formats.length]);

  useEffect(() => {
    getTags({ kind: 'studio_formats' }).then(res => {
      if (res.success) {
        setAllowedTags(res.tags);
      } else {
        console.log(res.errors);
      }
    });
  }, []);

  useEffect(() => {
    getPageSizes(0, 10000).then(res => {
      // page sizes can't be more than 10000
      if (res.success) {
        setPageSizes(res.page_sizes);
      } else {
        console.log(res.errors);
      }
    });
  }, []);

  useEffect(() => {
    let str = '';
    tagsArr.forEach(tag => {
      const index = allowedTags.findIndex(
        allowedTag => allowedTag.name.toLowerCase() === tag.name.toLowerCase()
      );
      if (index > -1) {
        str += `&tag=${allowedTags[index].id}`;
      }
    });
    setTags(str);
  }, [tagsArr]);

  return (
    <Main>
      <TabsNavigator
        type={'format'}
        items={formats}
        selectedItem={selectedFormat}
        setSelectedItem={setSelectedFormat}
        setShowSlideModal={setShowSlideModal}
        setToRemove={setToRemove}
      />
      {formats.length > 0 &&
        formats.map(format => {
          return (
            <FormatsStage
              key={format.id}
              format={{
                ...format,
                width: format.width + format.bleeding.left + format.bleeding.right,
                height: format.height + format.bleeding.top + format.bleeding.bottom,
                margin: {
                  top: format.margin.top + format.bleeding.top,
                  left: format.margin.left + format.bleeding.left,
                  bottom: format.margin.bottom + format.bleeding.bottom,
                  right: format.margin.right + format.bleeding.right,
                },
              }}
              show={format.id === selectedFormat.id}
              toRemove={toRemove}
              setToRemove={setToRemove}
            />
          );
        })}
      <SlideModal
        isOpen={showSlideModal}
        onOutsideClick={() => {
          setShowSlideModal(false);
        }}
      >
        <FormatSpecForm
          allowedTags={allowedTags}
          pageSizes={pageSizes}
          formatCategoryId={selectedFormat ? selectedFormat.category : 1}
          closeSlideModal={() => {
            setShowSlideModal(false);
          }}
          addFormatToWorkspace={addFormatToWorkspace}
          showCategory={true}
        />
      </SlideModal>
    </Main>
  );
}

export default FormatWorkspace;
