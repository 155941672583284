import React from 'react';
import Main from 'layouts/Main';
import Slider from 'components/common/Slider';
import TopControls from 'components/common/TopControls';

function ManageCovers() {
  return (
    <div className="manage-covers-page">
      <Main>
      <TopControls btns={[
          {text: 'Covers', path: '/home/covers'},
          {text: 'Manage Covers', path: '/home/covers/manage', selected: true}
        ]}/>
        <div className="resources">
          <Slider
            title={'Paper Thickness'}
            type={'thickness'}
          />
          </div>
      </Main>
    </div>
  )
}

export default ManageCovers
