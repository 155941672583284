import { colors } from 'settings/colors';
import { Group, Rect, Text } from 'react-konva';
import React from 'react';

const linkTextProps = {
  align: 'right',
  fill: colors.blue,
  fontFamily: 'Open Sans',
  fontSize: 14,
  fontStyle: 'Italic',
  letterSpacing: 0,
  lineHeight: 1,
  opacity: 1,
  padding: 8,
  rotation: 0,
  scaleX: 1,
  scaleY: 1,
  stroke: '#949494',
  strokeWidth: 0,
  text: 'link applied',
};

function LinkIndicator({ shapeProps, offset = true }) {
  const textRef = React.useRef();
  const linkProps = {
    ...linkTextProps,
    text:
      shapeProps.staticLink?.role ??
      (shapeProps.link.type === 'Puzzle'
        ? shapeProps.link.role + ' - ' + shapeProps.link.format
        : shapeProps.link.role),
    x: shapeProps.x,
    y: shapeProps.y,
    offsetX: offset ? shapeProps.width / 2 : 0,
    offsetY: !offset
      ? 40
      : shapeProps.offsetY
      ? shapeProps.offsetY + 40
      : shapeProps.height / 2 + 40,
    rotation: shapeProps.rotation,
  };

  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    if (textRef.current) {
      setWidth(textRef.current.width());
      setHeight(textRef.current.height());
    }
  }, [
    textRef.current,
    shapeProps.staticLink && shapeProps.staticLink.role,
    shapeProps.link && shapeProps.link.role,
    shapeProps.link && shapeProps.link.format,
  ]);

  return (
    <>
      {linkProps.text && (
        <Rect
          stroke={'#00000022'}
          x={linkProps.x}
          y={linkProps.y}
          offsetX={linkProps.offsetX}
          offsetY={linkProps.offsetY}
          width={width}
          height={height}
          cornerRadius={[10, 10, 10, 10]}
          rotation={linkProps.rotation}
          fill={'#ffffff'}
        />
      )}
      <Text ref={textRef} {...linkProps} />
    </>
  );
}

export default LinkIndicator;
