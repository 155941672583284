import React from 'react';
import IncludeSvg from 'components/common/IncludeSvg';

function NewItem({ type, title, border, onClick}) {

  return (
    <div
      className={`item shadow-sm active ${type === 'swap' ? 'swap' : ''}`}
      onClick={onClick}
    >
      {border ? (
        <div
          className="svg-wrapper"
          style={{ backgroundColor: (type !== 'category') && 'var(--sys)' }}
        >
          <IncludeSvg name={`${type}-plus`} />
        </div>
      ) : (
        <IncludeSvg name={`${type}-plus`} />
      )}
      <div className="title">{type === 'swap' && <IncludeSvg name="version-plus" color={'var(--sys)'} /> }&nbsp; {title}</div>
    </div>
  );
}

export default NewItem;
