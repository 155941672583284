import React, { useState, useEffect } from 'react';
import SelectWrapper from 'components/forms/elements/SelectWrapper';
import CheckList from 'components/forms/elements/CheckList';
import { useGlobalContext } from 'state/hooks/global';
import { getVariables, getVersionNames, getOneVersion } from 'api/variables';
import { getTags } from 'api/common';
import Tags from 'components/common/Tags';
import { capitalizeFirst } from 'helpers/text';

function FormatStudioFormDB({
  className,
  setForm,
  selectedShapeId,
  onChange,
  secondarySelectedIds,
  setSelectedShapeId,
  shapes,
  setShapes,
}) {
  const [language, setLanguage] = useState('');
  const { languages, getLanguageID } = useGlobalContext();
  const [variables, setVariables] = useState([]);
  const [selectedVariable, setSelectedVariable] = useState('');
  const [allowedTags, setAllowedTags] = useState([]);
  const [versionNames, setVersionNames] = useState([]);
  const [selectedVersionName, setSelectedVersionName] = useState(null);
  const [tagsArr, setTagsArr] = useState([]);
  const [tags, setTags] = useState('');
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [isSwaps, setIsSwaps] = useState(false);

  useEffect(() => {
    if (languages.length) {
      // Set default language English
      const lang = languages.find(lang => lang.name.toLowerCase() === 'english');
      if (lang) setLanguage(lang.id);
      else setLanguage(languages[0].id);
    }
  }, [languages]);

  useEffect(() => {
    getTags({ kind: 'variables', sort: 'alpha' }).then(res => {
      if (res.success) {
        setAllowedTags(res.tags);
      }
    });
  }, []);

  useEffect(() => {
    getVersionNames().then(res => {
      if (res.success) setVersionNames([...res.version_names].filter(v => v.is_swap === isSwaps));
    });
  }, [isSwaps]);

  useEffect(() => {
    let str = '?';
    tagsArr.forEach(tag => {
      const index = allowedTags.findIndex(
        allowedTag => allowedTag.name.toLowerCase() === tag.name.toLowerCase()
      );
      if (index > -1) {
        str += `tag=${allowedTags[index].id}&`;
      }
    });
    const s = str.slice(0, -1);
    setTags(s);
  }, [tagsArr]);

  useEffect(() => {
    if (selectedVariable) {
      const variable = variables.find(v => selectedVariable === v.value);
      if (variable) setVersions(variable.versions.filter(v => v.language_id === language));
    }
  }, [selectedVariable, variables]);

  useEffect(() => {
    if (selectedVersion)
      getOneVersion({ id: selectedVersion }).then(res =>
        applyOnSelectedNode(node => {
          if (res.success) {
            node.text = res.version.items[0];
            node.link = {
              type: 'Text',
              role: `Data field - v: ${language}.${selectedVariable}.${selectedVersion}`,
              languageId: language,
              variableId: selectedVariable,
              versionId: selectedVersion,
            };
            onChange(node, true);
          }
        })
      );

    // setLink();
  }, [selectedVersion]);

  const applyOnSelectedNode = callback => {
    if (selectedShapeId) {
      const list = [...shapes];
      const index = shapes.findIndex(item => item.id === selectedShapeId);
      const node = list[index];
      callback(node);
    }
  };

  useEffect(() => {
    setVariables([]);
    setVersions([]);
    setSelectedVariable('');
    setSelectedVersion(null);
  }, [language, tagsArr, isSwaps]);

  useEffect(() => {
    if (selectedVariable)
      applyOnSelectedNode(node => {
        if (
          node &&
          node.link &&
          node.link.role &&
          node.link.role.includes('Data field') &&
          node.link.versionId
        ) {
          setSelectedVersion(node.link.versionId);
        }
      });
  }, [selectedVariable]);

  const loadVariables = (search, offset) => {
    const params = {
      kind: 'variables',
      sort: 'alpha',
      language,
      search,
      offset,
    };

    return getVariables({ params, tagsString: tags, isSwap: isSwaps })
      .then(res => {
        if (res.success) {
          const options = res.variables.map(variable => ({
            label: variable.title,
            value: variable.id,
            versions: variable.versions,
          }));

          setVariables(offset > 0 ? [...variables, ...options] : options);

          return {
            options,
            hasMore: offset + 15 < res.count,
            additional: {
              offset: offset + 15,
            },
          };
        }
      })
      .catch(e => console.log);
  };

  return (
    <div className={`${className} form__studio-links`}>
      <div className="form__studio-heading">DATA FIELDS</div>
      <div className="form__studio-scroll">
        {!selectedShapeId || !selectedShapeId.includes('text') ? (
          <p>Select some text element.</p>
        ) : (
          <div>
            <div className="form-group">
              <div className="list-filters-section d-flex align-items-center gap-1">
                {['variables', 'swaps'].map((opt, index) => (
                  <div
                    key={opt}
                    className={`list-filters difficulty w-50 text-center ${
                      (opt === 'swaps' && isSwaps) || (opt === 'variables' && !isSwaps)
                        ? 'selected'
                        : ''
                    }`}
                    onClick={() => {
                      setIsSwaps(opt === 'swaps');
                    }}
                  >
                    {capitalizeFirst(opt)}&nbsp;
                  </div>
                ))}
              </div>
            </div>

            <div className=" d-flex flex-column mb-3 form-group">
              <label className="mb-3">Data Language</label>
              <SelectWrapper
                options={languages.map(lang => ({
                  label: lang.name,
                  value: lang.id,
                }))}
                value={language}
                setValue={setLanguage}
              />
            </div>
            <Tags
              title={'Variable Tags'}
              allowedTags={allowedTags}
              tagsArr={tagsArr}
              setTagsArr={setTagsArr}
              classNameA={'form-group'}
              classNameB={''}
              inputClassName={'form-control light'}
              height={50}
              inputPlaceholder={'Search...'}
              showCount={false}
              scrollDirection={'x'}
              searchable={true}
              showLabelOnly={true}
            />
            <div className=" d-flex flex-column mb-3 form-group">
              <label className="mb-3">Search Variables</label>

              <SelectWrapper
                async
                key={`${language} + ${isSwaps ? 'swaps' : 'variables'} + ${JSON.stringify(
                  tagsArr
                )}`}
                options={variables}
                value={selectedVariable}
                setValue={setSelectedVariable}
                loadOptions={(search, loadedOptions = []) =>
                  loadVariables(search, loadedOptions.length)
                }
              />
            </div>

            <CheckList
              radioSelect
              title={'Select data version'}
              itemsType={'versions'}
              items={versions
                .map(v => ({
                  id: v.version_id,
                  title: versionNames.find(vN => vN.id === v.version_name_id).title,
                }))
                .sort((a, b) => (a.title < b.title ? -1 : 1))}
              checkedItems={[{ id: selectedVersion }]}
              setCheckedItems={arr => setSelectedVersion(arr[0].id)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default FormatStudioFormDB;
