import 'react-slide-out/lib/index.css';

import React, { useEffect, useState } from 'react';

import FormatSpecForm from 'components/forms/FromatSpecForm';
import Main from 'layouts/Main';
import ScrollableWindow from 'layouts/ScrollableWindow';
import SearchControls from 'components/common/SearchControls';
import { default as SlideModal } from 'react-slide-out';
import Slider from 'components/common/Slider';
import TopControls from 'components/common/TopControls';
import { getPageSizes } from 'api/studio/formats';
import { getTags } from 'api/common';
import { useStudioContext } from 'hooks/studio';

import useTrigger from 'hooks/useTrigger';
import { hasAccess } from 'helpers/permissions';
import { useSelector } from 'react-redux';

export default function Formats() {
  const { user } = useSelector(state => state.auth);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showSlideModal, setShowSlideModal] = useState(false);
  const [categoriesSortBy, setCategoriesSrotBy] = useState('most_popular');
  const [formatsSortBy, setFormatsSortBy] = useState('most_popular');
  const [allowedTags, setAllowedTags] = useState([]);
  const [tagsArr, setTagsArr] = useState([]);
  const [tags, setTags] = useState(''); // formatted String
  const [pageSizes, setPageSizes] = useState([]);

  const [categoryId, setCategoryId] = useState(null);
  const [item, setItem] = useState(null);
  const [newItemFromSlideModal, setNewItemFromSlideModal] = useState(null);
  const [editItemFromSlideModal, setEditItemFromSlideModal] = useState(null);
  const { formats, setFormats } = useStudioContext();

  const [shouldLoadMore, triggerLoadMore] = useTrigger();
  const [updatedFormatFromSlideModal, setUpdatedFormatFromSlideModal] = useState(null);

  const addFormatToWorkspace = format => {
    const list = [...formats];
    list.push(format);
    setFormats(list);
  };

  useEffect(() => {
    getTags({ kind: 'studio_formats', sort: 'alpha' }).then(res => {
      if (res.success) {
        setAllowedTags(res.tags);
      } else {
        console.log(res.errors);
      }
    });
  }, []);

  useEffect(() => {
    let str = '';
    tagsArr.forEach(tag => {
      const index = allowedTags.findIndex(
        allowedTag => allowedTag.name.toLowerCase() === tag.name.toLowerCase()
      );
      if (index > -1) {
        str += `&tag=${allowedTags[index].id}`;
      }
    });
    setTags(str);
  }, [tagsArr]);

  useEffect(() => {
    getPageSizes(0, 10000).then(res => {
      // page sizes can't be more than 10000!
      if (res.success) {
        setPageSizes(res.page_sizes);
      } else {
        console.log(res.errors);
      }
    });
  }, []);

  const updateFormatFromSlideModal = format => {
    setUpdatedFormatFromSlideModal(format);
  };

  return (
    <div className="studio-page">
      <Main>
        <TopControls
          btns={[
            {
              text: 'Formats',
              path: '/home/formats',
              selected: true,
              hidden: !hasAccess('/home/formats', user),
            },
            {
              text: 'Manage Formats',
              path: '/home/formats/manage',
              hidden: !hasAccess('/home/formats/manage', user),
            },
          ]}
        />

        <div className="resources">
          <Slider
            title={'Categories'}
            type={'category'}
            kind={'studio_formats'}
            selectable={true}
            items={categories}
            selectedItem={selectedCategory}
            setSelectedItem={setSelectedCategory}
            setItems={setCategories}
            setShowSlideModal={setShowSlideModal}
            sortBy={categoriesSortBy}
            setCategoriesSortBy={setCategoriesSrotBy}
            shouldLoadMore={shouldLoadMore}
          />

          <SearchControls
            type={`format`}
            allowedTags={allowedTags}
            tagsArr={tagsArr}
            setTagsArr={setTagsArr}
            sortBy={formatsSortBy}
            setSortBy={setFormatsSortBy}
            showing={(selectedCategory && selectedCategory.title) || 'All'}
          />

          <ScrollableWindow onScrollEnd={triggerLoadMore}>
            {!selectedCategory &&
              categories.map(category => (
                <Slider
                  key={category.id}
                  id={category.id}
                  title={category.title}
                  type={'format'}
                  category={category}
                  tags={tags}
                  sortBy={formatsSortBy}
                  setSelectedItem={setSelectedCategory}
                  handleNew={() => {
                    setItem(null);
                    setShowSlideModal(true);
                  }}
                  handleEditItem={item => {
                    setItem(item);
                    setCategoryId(item.category); // for explicity: to overwrite the category id from new item
                    setShowSlideModal(true);
                  }}
                  updatedItemFromSlideModal={
                    updatedFormatFromSlideModal &&
                    updatedFormatFromSlideModal.category === category.id
                      ? updatedFormatFromSlideModal
                      : null
                  }
                  setCategoryId={setCategoryId}
                  newItemFromSlideModal={newItemFromSlideModal}
                  editItemFromSlideModal={editItemFromSlideModal}
                />
              ))}
          </ScrollableWindow>

          {selectedCategory && (
            <Slider
              uncollapsed
              key={selectedCategory.id}
              id={selectedCategory.id}
              title={selectedCategory.title}
              type={'format'}
              category={selectedCategory}
              tags={tags}
              sortBy={formatsSortBy}
              handleNew={() => {
                setItem(null);
                setShowSlideModal(true);
              }}
              handleEditItem={item => {
                setItem(item);
                setCategoryId(item.category); // for explicity: to overwrite the category id from new item
                setShowSlideModal(true);
              }}
              updatedItemFromSlideModal={
                updatedFormatFromSlideModal &&
                updatedFormatFromSlideModal.category === selectedCategory.id
                  ? updatedFormatFromSlideModal
                  : null
              }
              setSelectedItem={setSelectedCategory}
              setCategoryId={setCategoryId}
              newItemFromSlideModal={newItemFromSlideModal}
              editItemFromSlideModal={editItemFromSlideModal}
            />
          )}
          <SlideModal
            isOpen={showSlideModal}
            onOutsideClick={() => {
              setShowSlideModal(false);
            }}
          >
            <FormatSpecForm
              allowedTags={allowedTags}
              pageSizes={pageSizes}
              formatCategoryId={categoryId}
              format={item}
              closeSlideModal={() => {
                setShowSlideModal(false);
              }}
              addFormatToWorkspace={addFormatToWorkspace}
              updateFormatFromSlideModal={updateFormatFromSlideModal}
            />
          </SlideModal>
        </div>
      </Main>
    </div>
  );
}
