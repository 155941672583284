import { useSelector } from 'react-redux';
export function useSliderPermissions(type) {
  const { user } = useSelector(state => state.auth);
  let permissionsScope = type.replace(' ', '').replace('-', '');
  if (permissionsScope === 'size') permissionsScope = 'pagesize';
  if (permissionsScope === 'thickness') permissionsScope = 'paperthickness';
  if (type === 'swap') permissionsScope = 'variable';
  if (permissionsScope === 'articlelist') permissionsScope = 'articlelist';
  // console.log('permissionsScope:', permissionsScope);
  const perms = user && user.resources_permissions && user.resources_permissions[permissionsScope];
  // console.log('perms:', perms);
  let canAdd, canChange, canDelete;
  if (user.is_staff && permissionsScope === 'account') {
    canAdd = true;
    canChange = true;
    canDelete = true;
  } else {
    canAdd = perms ? perms['add_' + permissionsScope] : false;
    canChange = perms ? perms['change_' + permissionsScope] : false;
    canDelete = perms ? perms['delete_' + permissionsScope] : false;
  }

  // console.log('canAdd:', canAdd);
  return {
    canAdd,
    canChange,
    canDelete,
  };
}
