export default {
  category: {
    add_category: false,
    change_category: false,
    delete_category: false,
    view_category: false,
  },
  cover: {
    add_cover: false,
    can_create_pdf: false,
    change_cover: false,
    delete_cover: false,
    view_cover: false,
  },
  elementtype: {
    add_elementtype: false,
    change_elementtype: false,
    delete_elementtype: false,
    view_elementtype: false,
  },
  element: {
    add_element: false,
    change_element: false,
    delete_element: false,
    view_element: false,
  },
  format: {
    add_format: false,
    can_create_pdf: false,
    change_format: false,
    delete_format: false,
    view_format: false,
  },
  language: {
    add_language: false,
    change_language: false,
    delete_language: false,
    view_language: false,
  },
  manuscript: {
    add_manuscript: false,
    can_create_pdf: false,
    change_manuscript: false,
    delete_manuscript: false,
    view_manuscript: false,
  },
  pagesize: {
    add_pagesize: false,
    change_pagesize: false,
    delete_pagesize: false,
    view_pagesize: false,
  },
  paperthickness: {
    add_paperthickness: false,
    change_paperthickness: false,
    delete_paperthickness: false,
    view_paperthickness: false,
  },
  tag: {
    add_tag: false,
    change_tag: false,
    delete_tag: false,
    view_tag: false,
  },
  template: {
    add_template: false,
    change_template: false,
    delete_template: false,
    view_template: false,
  },
  trivialist: {
    add_trivialist: false,
    change_trivialist: false,
    delete_trivialist: false,
    view_trivialist: false,
  },

  variable: {
    add_variable: false,
    change_variable: false,
    delete_variable: false,
    view_variable: false,
  },
  wordlist: {
    add_wordlist: false,
    change_wordlist: false,
    delete_wordlist: false,
    view_wordlist: false,
  },
  articlelist: {
    add_articlelist: false,
    change_articlelist: false,
    delete_articlelist: false,
    view_articlelist: false,
  },
  story: {
    add_story: false,
    change_story: false,
    delete_story: false,
    view_story: false,
  },
};
