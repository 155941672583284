import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export function useSliderControls({
  type,
  category,
  sliderItems,
  setSliderItems,
  selectedItem,
  setSelectedItem,
  setCategoryId,
  handleNew,
  setIsCreateMode,
  setShowWordListEditor,
  setShowTriviaListEditor,
  setShowArticleListEditor,
  setShowStoryEditor,
  setOffset,
  elementType,
  setLeft,
  newItemFromSlideModal,
}) {
  const history = useHistory();

  const addNewItem = () => {
    if (setSelectedItem) setSelectedItem(null);
    switch (type) {
      case 'format':
      case 'template':
      case 'manuscript':
      case 'cover': {
        setCategoryId(category.id);
        handleNew();
        break;
      }
      case 'account': {
        handleNew();
        break;
      }
      case 'word list': {
        if (!selectedItem) setSelectedItem(category);
        setOffset({ value: null, category_id: category.id });
        setShowWordListEditor(true);
        break;
      }
      case 'trivia list': {
        if (!selectedItem) setSelectedItem(category);
        setOffset({ value: null, category_id: category.id });
        setShowTriviaListEditor(true);
        break;
      }
      case 'article list': {
        if (!selectedItem) setSelectedItem(category);
        setOffset({ value: null, category_id: category.id });
        setShowArticleListEditor(true);
        break;
      }
      case 'story': {
        if (!selectedItem) setSelectedItem(category);
        setOffset({ value: null, category_id: category.id });
        setShowStoryEditor(true);
        break;
      }
      case 'element': {
        history.push(`/elements/${elementType}/${category.id}/new`);
        break;
      }
      default: {
        const list = [...sliderItems];
        setLeft(0);
        if (setIsCreateMode) setIsCreateMode(true);
        list.unshift({
          id: 0,
          title: '',
          editMode: true,
          active: true,
          new: true,
        });
        setSliderItems(list);
      }
    }
  };

  const removeAddedNewItem = () => {
    if (sliderItems[0] && sliderItems[0].id === 0) {
      const list = [...sliderItems];
      list.shift();
      if (setIsCreateMode) setIsCreateMode(false);
      setSliderItems(list);
    }
  };

  const deleteItem = id => {
    const list = [...sliderItems];
    const index = list.indexOf(item => item.id === id);
    list.splice(index, 1);
    setSliderItems(list);
  };

  const renameItem = (id, newTitle) => {
    const list = [...sliderItems];
    const index = list.indexOf(item => item.id === id);
    list[index].title = newTitle;
  };

  const editPageSize = (id, width, height) => {
    const list = [...sliderItems];
    const index = list.indexOf(item => item.id === id);
    list[index].width = width;
    list[index].height = height;
  };

  useEffect(() => {
    if (newItemFromSlideModal && newItemFromSlideModal.category === category.id) {
      const list = [...sliderItems];
      setLeft(0);
      list.unshift(newItemFromSlideModal);
      setSliderItems(list);
    }
  }, [newItemFromSlideModal]);

  return {
    addNewItem,
    removeAddedNewItem,
    deleteItem,
    renameItem,
    editPageSize,
  };
}
