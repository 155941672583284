// NOTE: its important to follow {q: 'question...?', a: 'answer...'}
// Backend depends on it to filter trivias with negative words
import React, { useState, useEffect } from 'react';
import 'components/common/List.scss';
import ListControls from 'components/common/ListControls';
import { capitalizeFirst } from 'helpers/text';

function TriviaList({
  editable = false,
  listName,
  title,
  trivias,
  setTrivias,
  selectedTrivias,
  setSelectedTrivias,
  size,
  transfer,
  transferTrig,
  setTransferTrig,
  offset,
  formatTrivia,
  updateList,
  sortList,
  letterCase,
  setLetterCase,
  firstItemAlwaysSeleted,
}) {
  const [search, setSearch] = useState('');
  const [focus, setFocus] = useState(0);
  const [sortDir, setSortDir] = useState('Sort');

  useEffect(() => {
    // make first item sleceted always
    if (trivias.length && !selectedTrivias.length && firstItemAlwaysSeleted) {
      setSelectedTrivias([0]);
    }
  }, [trivias, selectedTrivias]);

  const handleSelectToggle = (index, deslectAllFirst) => {
    let list;
    if (deslectAllFirst) list = [];
    else list = selectedTrivias.slice();
    if (selectedTrivias.includes(index)) {
      const i = list.findIndex(item => item === index);
      list.splice(i, 1);
    } else {
      list.push(index);
    }
    setSelectedTrivias(list);
  };

  const selectAll = () => {
    setSelectedTrivias([...Array(trivias.length).keys()]);
  };

  const deslectAll = () => {
    setSelectedTrivias([]);
  };

  const removeDuplicates = () => {
    updateList(
      trivias,
      setTrivias,
      list =>
        [...new Set(list)]
          .filter((v, i, a) => a.findIndex(t => t.a === v.a && t.q === v.q) === i)
          .filter(i => i.a !== '' && i.q !== ''),
      editable
    );
  };

  const removeEmptyCells = () => {
    updateList(
      trivias,
      setTrivias,
      list => [...list].filter(i => i.a !== '' && i.q !== ''),
      editable
    );
  };

  const transferItems = (all, difficulty) => {
    const list = trivias.slice();
    let filteredList;
    if (all) filteredList = list;
    else filteredList = list.filter((el, i) => selectedTrivias.includes(i));
    if (difficulty) transfer(filteredList.map(i => ({ ...i, difficulty })));
    else transfer(filteredList);
    if (all) setTrivias([]);
    else deleteSelected();
  };

  const deleteSelected = () => {
    updateList(
      trivias,
      setTrivias,
      list => list.filter((el, i) => !selectedTrivias.includes(i)),
      editable
    );
  };
  const toggleLetterCase = () => {
    const options = ['aa', 'Aa', 'AA'];
    let i = (options.findIndex(o => o === letterCase) + 1) % 3;
    setLetterCase(options[i]);
  };

  const toggleSort = () => {
    const options = ['Sort', 'Ascending', 'Descending'];
    let i = (options.findIndex(o => o === sortDir) + 1) % 3;
    if (i === 0) i = 1;

    setSortDir(options[i]);
  };

  const focusPrev = () => {
    if (focus > -1) setFocus(focus - 1);
  };

  const focusNext = () => {
    if (focus < trivias.length - 1) setFocus(focus + 1);
  };

  useEffect(() => {
    updateList(
      trivias,
      setTrivias,
      list => list.map(word => formatTrivia(letterCase, word)),
      editable
    );
  }, [letterCase]);

  useEffect(() => {
    sortList(trivias, setTrivias, sortDir);
  }, [sortDir]);

  useEffect(() => {
    updateList(
      trivias,
      setTrivias,
      list => list.map(word => formatTrivia(letterCase, word)),
      editable
    );
    setSortDir('Ascending');
  }, [trivias.length]);

  useEffect(() => {
    removeEmptyCells();
  }, [trivias.length]);

  useEffect(() => {
    if (transferTrig.val) {
      transferItems(transferTrig.all, transferTrig.difficulty);
      setTransferTrig({ val: false, all: false });
    }
  }, [transferTrig && transferTrig.val]);

  useEffect(() => {
    if (focus > -1) handleSelectToggle(focus, true);
    else {
      deslectAll();
      const el = document.getElementById(`${listName}-option-${focus}`);
      if (el) el.focus();
    }
  }, [focus]);

  useEffect(() => {
    if (selectedTrivias.length) setSelectedTrivias([]);
  }, [search]);

  const handleTriviaUpdate = (index, key, value) => {
    const list = trivias.slice();
    list[index][key] = value;
    setTrivias(list);
  };

  return (
    <div
      key={listName}
      tabIndex="0"
      className="list"
      onKeyUp={e => {
        e.preventDefault();
        e.stopPropagation();
        if ((e.ctrlKey && e.key === 'a') || (e.metaKey && e.key === 'a')) {
          selectAll();
        }
        if (e.key === 'ArrowDown') {
          focusNext();
        }
        if (e.key === 'ArrowUp') {
          focusPrev();
        }
      }}
    >
      <h4 className="list__title ">{title}</h4>

      <ListControls
        search={search}
        setSearch={setSearch}
        setFocus={setFocus}
        listName={listName}
        toggleSort={toggleSort}
        sortDir={sortDir}
        letterCase={letterCase}
        toggleLetterCase={toggleLetterCase}
        removeDuplicates={removeDuplicates}
        selectedItems={selectedTrivias}
        deleteSelected={deleteSelected}
      />

      <div
        style={{ height: size * 22 + 20 }}
        className="list__list-wrapper bg-white shadow-sm border"
      >
        <div
          style={{ height: size * 22 }}
          className="list__list"
          onClick={e => {
            e.target.focus();
            !search && setFocus(trivias.length);
          }}
        >
          {trivias.map(
            (trivia, index) =>
              (!search || (search && (trivia.a.includes(search) || trivia.q.includes(search)))) && (
                <div
                  className={`list__option--trivia ${
                    selectedTrivias.includes(index) ? 'selected' : ''
                  } ${focus === index ? 'focus' : ''}  ${!editable ? 'cursor-pointer' : ''}`}
                  key={index}
                  id={`${listName}-option-${index}`}
                  onClick={e => {
                    e.stopPropagation();
                    if (e.ctrlKey) handleSelectToggle(index);
                    else {
                      handleSelectToggle(index, true);
                    }
                  }}
                >
                  <div>
                    <span>Q.</span>
                    <span className="d-flex w-100">
                      {editable ? (
                        <input
                          className={`list__option--editable `}
                          placeholder="question goes here..."
                          value={trivia.q}
                          onChange={e => {
                            handleTriviaUpdate(index, 'q', e.target.value);
                          }}
                          onMouseDown={e => {
                            if (e.ctrlKey) handleSelectToggle(index);
                            else deslectAll();
                          }}
                        />
                      ) : (
                        trivia.q
                      )}
                    </span>
                  </div>
                  <div>
                    <span>A.</span>
                    <span className="d-flex w-100">
                      {editable ? (
                        <input
                          className={`list__option--editable`}
                          placeholder="answer goes here..."
                          value={trivia.a}
                          onChange={e => {
                            handleTriviaUpdate(index, 'a', e.target.value);
                          }}
                          onMouseDown={e => {
                            if (e.ctrlKey) handleSelectToggle(index);
                            else deslectAll();
                          }}
                        />
                      ) : (
                        trivia.a
                      )}
                    </span>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
      <div className="list__list--counter">
        <span>
          {selectedTrivias.length &&
          trivias[selectedTrivias[0]] &&
          trivias[selectedTrivias[0]].difficulty
            ? 'Level: ' + capitalizeFirst(trivias[selectedTrivias[0]].difficulty)
            : ''}
        </span>
        <span>
          {trivias.length} Q&A{trivias.length === 1 ? '' : 's'}
        </span>
      </div>
    </div>
  );
}

export default TriviaList;
