import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const setSavedUser = user => localStorage.setItem('user', JSON.stringify(user));
const setSavedAccessToken = access => localStorage.setItem('access_token', JSON.stringify(access));
const setSavedRefreshToken = refresh =>
  localStorage.setItem('refresh_token', JSON.stringify(refresh));
const setSavedKeepLogin = keep_login =>
  localStorage.setItem('keep_login', JSON.stringify(keep_login));
const setSavedExpiresAt = expires_at =>
  localStorage.setItem('expires_at', JSON.stringify(expires_at));

const authState = {
  user: null,
  access_token: null,
  refresh_token: null,
  expires_at: null,
  is_authenticated: false,
  keep_login: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: authState,
  reducers: {
    login(state, action) {
      // set state
      state.user = action.payload?.user ?? state.user;
      state.access_token = action.payload?.access_token ?? state.access_token;
      state.refresh_token = action.payload?.refresh_token ?? state.refresh_token;
      state.expires_at = action.payload?.expires_at ?? state.expires_at;
      state.keep_login = action.payload?.keep_login ?? state.keep_login;
      state.is_authenticated = true;
      state.error = action.payload.error;

      // persist session
      if (!action.payload.from_local_storage) {
        setSavedUser(action.payload?.user ?? state.user);
        setSavedAccessToken(action.payload?.access_token ?? state.access_token);
        setSavedRefreshToken(action.payload?.refresh_token ?? state.refresh_token);
        setSavedKeepLogin(action.payload?.keep_login ?? state.keep_login);
        setSavedExpiresAt(action.payload?.expires_at ?? state.expires_at);
      }
    },
    updateUser(state, action) {
      state.user = action.payload.user;
      setSavedUser(action.payload?.user ?? state.user);
    },
    clearError(state) {
      state.error = null;
    },
    logout(state) {
      // clear state
      state.user = null;
      state.access_token = null;
      state.refresh_token = null;
      state.expires_at = null;
      state.keep_login = false;
      state.is_authenticated = false;

      // clear session
      localStorage.clear();
    },
  },
});

export const { login, updateUser, clearError, logout } = authSlice.actions;

export default authSlice.reducer;
