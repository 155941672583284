import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory, Route } from 'react-router-dom';
import { useGlobalContext } from 'state/hooks/global';

import { getLanguages } from 'api/global';
import { getElementTypes } from 'api/elements';
import { hasAccess } from 'helpers/permissions';
// const resources_permissions = {
//   variable: {
//     add_variable: true,
//     change_variable: true,
//     delete_variable: true,
//     view_variable: true,
//   },
//   versionname: {
//     add_versionname: true,
//     change_versionname: true,
//     delete_versionname: true,
//     view_versionname: true,
//   },
//   version: {
//     add_version: true,
//     change_version: true,
//     delete_version: true,
//     view_version: true,
//   },
// };

function PrivateRoute({ children, ...rest }) {
  const { user } = useSelector(state => state.auth);
  const { access_token, is_authenticated } = useSelector(state => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { setShowSpinner, setLanguages, setElementTypes } = useGlobalContext();
  const pathname = location.pathname;
  const hasViewPerm =
    pathname === '/settings/accounts' && user && user.is_staff ? true : hasAccess(pathname, user);

  useEffect(() => {
    if (access_token)
      getLanguages().then(res => {
        if (res.success) {
          setLanguages(res.languages);
        }
      });
  }, [access_token]);

  useEffect(() => {
    if (access_token)
      getElementTypes().then(res => {
        if (res.success) {
          setElementTypes(res.element_types);
        }
      });
  }, [access_token]);

  useEffect(() => {
    if (!is_authenticated) {
      history.push('/login', {
        from: location.pathname + location.search,
        redirect_after_login: true,
      });
    } else if (hasViewPerm === false) {
      history.push('/home');
    } else {
      setShowSpinner(false);
    }
  }, [is_authenticated, location.pathname, location.search, hasViewPerm]);

  return <Route {...rest}>{is_authenticated && children}</Route>;
}

export default PrivateRoute;
