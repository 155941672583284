import React from 'react';
import TopControls from 'components/common/TopControls';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function LibraryTopControls() {
  const { language } = useParams();
  const location = useLocation();
  const pathname = location.pathname;

  const { user } = useSelector(state => state.auth);

  const isSelected = path => {
    const arr = path.split('/');
    return pathname.includes(arr[arr.length - 1]);
  };

  const isHidden = text => {
    if (['Negative Words', 'Common Words'].includes(text)) return !user.is_staff;

    // remove last char
    const resource_attr = text.toLowerCase().replaceAll(' ', '').slice(0, -1);

    return (
      user &&
      user.resources_permissions &&
      user.resources_permissions[resource_attr] &&
      !user.resources_permissions[resource_attr][`view_${resource_attr}`]
    );
  };

  return (
    <TopControls
      btns={[
        {
          text: 'Word Lists',
          path: `/library/${language}/word-lists`,
        },
        {
          text: 'Trivia Lists',
          path: `/library/${language}/trivia-lists`,
        },
        {
          text: 'Article Lists',
          path: `/library/${language}/article-lists`,
        },
        {
          text: 'Story Lists',
          path: `/library/${language}/story-lists`,
        },
        { text: 'Negative Words', path: `/library/${language}/manage-negative-words` },
        { text: 'Common Words', path: `/library/${language}/manage-common-words` },
      ]
        .map(btn => ({ ...btn, selected: isSelected(btn.path) }))
        .map(btn => ({ ...btn, hidden: isHidden(btn.text) }))}
    />
  );
}

export default LibraryTopControls;
