import permissions from 'settings/permissions';
import { camelToTitle } from 'helpers/text';

export default function PermissionsManager({ perms, setPerms }) {
  const handlePermChecked = (scope, perm, value) => {
    const newPerms = { ...perms };
    newPerms[scope][perm] = value;
    if ((value === true && !perm.includes('view')) || forceViewChecked(scope)) {
      newPerms[scope][`view_${scope}`] = true;
    }
    setPerms(newPerms);
  };

  const handleScopeChecked = (scope, value) => {
    const scopePerms = Object.keys(perms[scope]);
    scopePerms.forEach(perm => {
      handlePermChecked(scope, perm, value);
    });
  };

  const forceViewChecked = scope => {
    const scopePerms = Object.keys(perms[scope]);
    const checked = scopePerms.some(perm => perms[scope][perm] === true && !perm.includes('view'));
    return checked;
  };

  const handleAllChecked = (e, value) => {
    e.preventDefault();
    Object.keys(permissions).forEach(scope => {
      handleScopeChecked(scope, value);
    });
  };

  const isScopeChecked = scope => {
    return Object.keys(perms[scope]).every(perm => perms[scope][perm]);
  };

  return (
    <>
      <div className="permissions-manager__header">
        <h3>permissions</h3>
        <div>
          <button onClick={e => handleAllChecked(e, true)}>Select All</button>
          <button onClick={e => handleAllChecked(e, false)}>Unselect All</button>
        </div>
      </div>

      <div className="permissions-manager__checkboxes">
        {Object.keys(permissions).map((scope, i) => {
          return (
            <div key={i}>
              <input
                type="checkbox"
                onChange={e => handleScopeChecked(scope, e.target.checked)}
                checked={isScopeChecked(scope)}
              />
              &nbsp;
              <label className="h4">{camelToTitle(scope)}</label>
              <div>
                {Object.keys(permissions[scope]).map((perm, j) => {
                  return (
                    <div key={j} className="d-flex flex-column" style={{ marginLeft: 20 }}>
                      <div className="d-flex">
                        <input
                          type="checkbox"
                          checked={perms[scope][perm]}
                          onChange={e => handlePermChecked(scope, perm, e.target.checked)}
                        />
                        &nbsp;
                        <label>{perm.replaceAll('_', ' ')}</label>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
