import axios from 'axios';
import urls from 'settings/urls.js';

const LIMIT = 15;

// Categories
export async function getCategories(params) {
  const requestOptions = {
    method: 'GET',
    url: urls.categories,
    params: { ...params, limit: LIMIT },
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function addCategory({ data, params }) {
  const requestOptions = {
    method: 'POST',
    url: urls.newCategory,
    data,
    params,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function editCategory(data, id) {
  const requestOptions = {
    method: 'PATCH',
    url: urls.editCategory(id),
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function deleteCategory(id) {
  const requestOptions = {
    method: 'DELETE',
    url: urls.deleteCategory(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

// Tags
export async function getTags(params) {
  const requestOptions = {
    method: 'GET',
    url: urls.tags,
    params,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function addTags(data, params) {
  const requestOptions = {
    method: 'POST',
    url: urls.addTags,
    params,
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function deleteTags(data) {
  const requestOptions = {
    method: 'DELETE',
    url: urls.deleteTags,
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

// notifications
export async function sendPdfCreationNotification(params) {
  const requestOptions = {
    method: 'POST',
    url: urls.pdfNotificaction,
    params,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}
