import { pathToRegexp } from 'path-to-regexp';
import viewPermissions from 'settings/viewPermissions';

export const isPathMatchingPattern = (path, pattern) => {
  const keys = [];
  const regex = pathToRegexp(pattern, keys);

  const match = regex.exec(path);

  return !!match;

   
};

export function hasAccess(pathname, user) {
  if (!user) return false;
  const viewPerm = viewPermissions.find(p => isPathMatchingPattern(pathname, p.pathPattern));
  const hasViewPerm =
    viewPerm && user && user.resources_permissions
      ? user.resources_permissions[viewPerm.scope][viewPerm.perm]
      : null;

  return hasViewPerm;
}
