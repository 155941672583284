import React from 'react';
import svg from 'assets/icons/svgs.svg';

function IncludeSvg({ name, color }) {
  return (
    <svg className={`svg-icon ${name}-icon`} fill={color} >
      <use xlinkHref={`${svg}#${name}`} width="100%" height="100%" />
    </svg>
  );
}

export default IncludeSvg;
