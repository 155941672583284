import React from 'react';
import './ProgressBar.scss';

function ProgressBar({percentage}) {
  return (
    <div className="fc-progress-bar">
      <div
        className="fc-progress-bar__progress"
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
}

export default ProgressBar;
