import React, { useEffect, useState } from 'react';

export const LetterQ = props => {
  return (
    <>
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88">
        <path
          d="M17.89 0h88.9c8.85 0 16.1 7.24 16.1 16.1v90.68c0 8.85-7.24 16.1-16.1 16.1H16.1c-8.85 0-16.1-7.24-16.1-16.1v-88.9C0 8.05 8.05 0 17.89 0zm67.27 80.02c2.33 1.63 3.86 2.66 4.58 3.07 1.06.61 2.52 1.32 4.33 2.12l-5.19 10.51a59.624 59.624 0 01-7.78-4.53c-2.57-1.75-4.37-3.07-5.39-3.95-4.14 1.8-9.33 2.7-15.59 2.7-9.24 0-16.52-2.4-21.85-7.21-6.31-5.69-9.46-13.68-9.46-23.97 0-10 2.76-17.76 8.27-23.31 5.52-5.53 13.21-8.3 23.11-8.3 10.08 0 17.86 2.7 23.35 8.12 5.49 5.4 8.23 13.15 8.23 23.22.01 8.97-2.2 16.15-6.61 21.53zm-14.39-9.63c1.5-2.67 2.25-6.68 2.25-12.01 0-6.12-1.15-10.49-3.42-13.13-2.29-2.62-5.43-3.93-9.45-3.93-3.75 0-6.78 1.34-9.09 4.02-2.33 2.67-3.49 6.86-3.49 12.55 0 6.63 1.13 11.29 3.39 13.96 2.28 2.67 5.39 4.02 9.33 4.02 1.27 0 2.48-.13 3.61-.37-1.58-1.53-4.07-2.96-7.48-4.31l2.94-6.75c1.67.3 2.97.68 3.89 1.12.93.44 2.74 1.6 5.45 3.48.63.43 1.32.89 2.07 1.35z"
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </svg>
    </>
  );
};
