import 'react-slide-out/lib/index.css';

import React, { useEffect, useState } from 'react';

import Main from 'layouts/Main';
import ScrollableWindow from 'layouts/ScrollableWindow';
import SearchControls from 'components/common/SearchControls';
import { default as SlideModal } from 'react-slide-out';
import Slider from 'components/common/Slider';
import TemplateSpecForm from 'components/forms/TemplateSpecForm';
import TopControls from 'components/common/TopControls';
import { getPageSizes } from 'api/studio/formats';
import { getTags } from 'api/common';
import { useStudioContext } from 'hooks/studio';
import useTrigger from 'hooks/useTrigger';

export default function Templates() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showSlideModal, setShowSlideModal] = useState(false);
  const [categoriesSortBy, setCategoriesSrotBy] = useState('most_popular');
  const [templatesSortBy, setTemplatesSortBy] = useState('most_popular');
  const [allowedTags, setAllowedTags] = useState([]);
  const [tagsArr, setTagsArr] = useState([]);
  const [tags, setTags] = useState(''); // templateted String
  const [pageSizes, setPageSizes] = useState([]);

  const [categoryId, setCategoryId] = useState(null);
  const [newItemFromSlideModal] = useState(null);
  const { templates, setTemplates } = useStudioContext();

  const addTemplateToWorkspace = template => {
    console.log(templates);
    const list = [...templates];
    list.push(template);
    setTemplates(list);
  };

  const [shouldLoadMore, triggerLoadMore] = useTrigger();

  useEffect(() => {
    getTags({ kind: 'studio_templates', sort: 'alpha' }).then(res => {
      if (res.success) {
        setAllowedTags(res.tags);
      } else {
        console.log(res.errors);
      }
    });
  }, []);

  useEffect(() => {
    let str = '';
    tagsArr.forEach(tag => {
      const index = allowedTags.findIndex(
        allowedTag => allowedTag.name.toLowerCase() === tag.name.toLowerCase()
      );
      if (index > -1) {
        str += `&tag=${allowedTags[index].id}`;
      }
    });
    setTags(str);
  }, [tagsArr, allowedTags]);

  useEffect(() => {
    getPageSizes(0, 10000).then(res => {
      // page sizes can't be more than 10000!
      if (res.success) {
        setPageSizes(res.page_sizes);
      } else {
        console.log(res.errors);
      }
    });
  }, []);

  return (
    <div className="studio-page">
      <Main>
        <TopControls btns={[{ text: 'Templates', path: '/home/templates', selected: true }]} />

        <div className="resources">
          <Slider
            title={'Categories'}
            type={'category'}
            kind={'studio_templates'}
            selectable={true}
            items={categories}
            selectedItem={selectedCategory}
            setSelectedItem={setSelectedCategory}
            setItems={setCategories}
            setShowSlideModal={setShowSlideModal}
            sortBy={categoriesSortBy}
            setCategoriesSortBy={setCategoriesSrotBy}
            shouldLoadMore={shouldLoadMore}
          />

          <SearchControls
            type={`template`}
            allowedTags={allowedTags}
            tagsArr={tagsArr}
            setTagsArr={setTagsArr}
            sortBy={templatesSortBy}
            setSortBy={setTemplatesSortBy}
            showing={(selectedCategory && selectedCategory.title) || 'All'}
          />

          <ScrollableWindow onScrollEnd={triggerLoadMore}>
            {!selectedCategory &&
              categories.map(category => (
                <Slider
                  key={category.id}
                  id={category.id}
                  title={category.title}
                  type={'template'}
                  category={category}
                  tags={tags}
                  sortBy={templatesSortBy}
                  setSelectedItem={setSelectedCategory}
                  handleNew={() => setShowSlideModal(true)}
                  setCategoryId={setCategoryId}
                  newItemFromSlideModal={newItemFromSlideModal}
                />
              ))}
          </ScrollableWindow>

          {selectedCategory && (
            <Slider
              uncollapsed
              key={selectedCategory.id}
              id={selectedCategory.id}
              title={selectedCategory.title}
              type={'template'}
              category={selectedCategory}
              tags={tags}
              sortBy={templatesSortBy}
              handleNew={() => setShowSlideModal(true)}
              setSelectedItem={setSelectedCategory}
              setCategoryId={setCategoryId}
              newItemFromSlideModal={newItemFromSlideModal}
            />
          )}
          <SlideModal
            isOpen={showSlideModal}
            onOutsideClick={() => {
              setShowSlideModal(false);
            }}
          >
            <TemplateSpecForm
              allowedTags={allowedTags}
              pageSizes={pageSizes}
              templateCategoryId={categoryId}
              closeSlideModal={() => {
                setShowSlideModal(false);
              }}
              addTemplateToWorkspace={addTemplateToWorkspace}
            />
          </SlideModal>
        </div>
      </Main>
    </div>
  );
}
