import React from 'react';
import Spinner from 'components/common/Spinner';
import './CheckList.scss';

function CheckList({
  radioSelect,
  radioStyles = false,
  title,
  items,
  itemsType,
  loadItems,
  checkedItems,
  setCheckedItems,
  isLoading,
  children,
  classNames,
}) {
  console.log('items', items);
  const handleScroll = e => {
    const bottom = e.target.scrollHeight - e.target.scrollTop >= e.target.clientHeight;
    if (bottom && loadItems) {
      loadItems(items.length);
    }
  };

  const handleItemChecked = item => {
    let list = [...checkedItems];
    const index = checkedItems.findIndex(f => f.id === item.id);
    if (radioSelect) {
      list = [item];
    } else if (index > -1) {
      list.splice(index, 1);
    } else {
      list.push(item);
    }
    setCheckedItems(list);
  };

  return (
    <div className={`form-group d-flex flex-column check-list`}>
      <div>
        <label className="mb-2">{title}</label>
        {children}
        <div className={`form-control list light ${classNames}`}>
          <div className={`form-control light list in ${classNames}`} onScroll={handleScroll}>
            {items.length === 0 && !isLoading && <div>No {itemsType}.</div>}
            {!radioStyles &&
              items.map((item, index) => (
                <div key={index} className="form-check">
                  <input
                    className="form-check-input"
                    id="check"
                    type="checkbox"
                    checked={!!checkedItems.find(f => f.id === item.id)}
                    onChange={() => handleItemChecked(item)}
                  />
                  <label className="form-check-label truncate" htmlFor="check">
                    {item.title}
                  </label>
                  {item.items_count && <span>({item.items_count})</span>}
                </div>
              ))}

            {radioStyles &&
              items.map((item, index) => (
                <div key={index}>
                  <div className="">
                    <input
                      className="form-check-input radio position-static"
                      type="radio"
                      checked={!!checkedItems.find(f => f.id === item.id)}
                      onChange={() => handleItemChecked(item)}
                    />
                    <span className="truncate">{item.title}</span>
                    {item.items_count && <span>({item.items_count})</span>}
                  </div>
                </div>
              ))}

            {isLoading && (
              <div>
                <Spinner type="local" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckList;
