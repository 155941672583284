import React, { useState, useEffect } from 'react';
import 'components/common/List.scss';
import { capitalizeFirst } from 'helpers/text';
import ListControls from 'components/common/ListControls';
import Spinner from 'components/common/Spinner';

function WordList({
  listName,
  title,
  words,
  setWords,
  selectedWords,
  setSelectedWords,
  editable,
  size,
  transfer,
  transferTrig,
  setTransferTrig,
  offset,
  isLoading,
  wordsCount,
  loadWords,
  firstItemAlwaysSeleted,
}) {
  const [search, setSearch] = useState('');
  const [focus, setFocus] = useState(0);
  const [sortDir, setSortDir] = useState('ٍSort');
  const [letterCase, setLetterCase] = useState('AA');

  const handleSelectToggle = index => {
    const list = selectedWords.slice();
    if (selectedWords.includes(index)) {
      const i = list.findIndex(item => item === index);
      list.splice(i, 1);
    } else {
      list.push(index);
    }
    setSelectedWords(list);
  };

  const selectAll = () => {
    setSelectedWords([...Array(words.length).keys()]);
  };

  const deslectAll = () => {
    setSelectedWords([]);
  };

  const formatWord = (format, word) => {
    let val;
    switch (format) {
      case 'AA':
        val = word.toUpperCase();
        break;
      case 'Aa':
        val = capitalizeFirst(word.toLowerCase());
        break;
      case 'aa':
      default:
        val = word.toLowerCase();
        break;
    }

    return val;
  };

  const filterInput = value => {
    return value.replace(/[0-9]/g, '').trimStart();
  };

  const getWords = val => {
    return val.split('\n');
  };

  const handleOptionUpdate = (e, index) => {
    if (editable) {
      deslectAll();
      const list = words.slice();
      const newList = getWords(e.target.value);
      if (newList.length === 1) list[index] = filterInput(e.target.value);
      else if (newList.length > 1) {
        const filteredWords = newList.filter(filterInput);
        list.splice(index, 0, ...filteredWords);
      }

      setWords(list);
      setSortDir('Sort');
    }
  };

  const updateList = cb => {
    const list = words.slice();
    const updatedList = cb(list);
    setWords(updatedList);
    setSelectedWords([]);
  };

  const removeDuplicates = () => {
    updateList(list => [...new Set(list)].filter(i => i !== ''));
  };

  const removeEmptyCells = () => {
    updateList(list => [...list].filter(i => i !== ''));
  };

  const transferItems = all => {
    const list = words.slice();
    let filteredList;
    if (all) filteredList = list;
    else filteredList = list.filter((el, i) => selectedWords.includes(i));
    transfer(filteredList);
    if (all) setWords([]);
    else deleteSelected();
  };

  const deleteSelected = () => {
    updateList(list => list.filter((el, i) => !selectedWords.includes(i)));
  };

  const sortList = dir => {
    switch (dir) {
      case 'Ascending':
        updateList(list => list.sort());
        break;
      case 'Descending':
        updateList(list =>
          list.sort((a, b) => {
            if (a > b) return -1;
            if (b > a) return 1;
            return 0;
          })
        );
        break;
    }
  };

  const toggleLetterCase = () => {
    const options = ['AA', 'aa', 'Aa'];
    let i = (options.findIndex(o => o === letterCase) + 1) % 3;
    setLetterCase(options[i]);
  };

  const toggleSort = () => {
    const options = ['Sort', 'Ascending', 'Descending'];
    let i = (options.findIndex(o => o === sortDir) + 1) % 3;
    if (i === 0) i = 1;

    setSortDir(options[i]);
  };

  const focusPrev = () => {
    if (focus > -1) setFocus(focus - 1);
  };

  const focusNext = () => {
    if ((editable && focus < words.length) || (!editable && focus < words.length - 1)) {
      setFocus(focus + 1);
    }
  };

  useEffect(() => {
    if (editable) setSortDir('Ascending');
    setLetterCase('AA'); // Yet you have to format on the parent fetch anyway
    setSearch('');
  }, [offset && offset.value]); // reset everything back

  useEffect(() => {
    updateList(list => list.map(word => formatWord(letterCase, word)));
  }, [letterCase]);

  useEffect(() => {
    sortList(sortDir);
  }, [sortDir]);

  useEffect(() => {
    updateList(list => list.map(word => formatWord(letterCase, word)));
    if (editable) setSortDir('Sort');
    else setSortDir('Ascending');
  }, [words.length]);

  useEffect(() => {
    removeEmptyCells();
  }, [words.length]);

  useEffect(() => {
    if (transferTrig.val) {
      transferItems(transferTrig.all);
      setTransferTrig({ val: false, all: false });
    }
  }, [transferTrig && transferTrig.val]);

  useEffect(() => {
    const el = document.getElementById(`${listName}-option-${focus}`);
    if (el) el.focus();
    else setFocus(0);
  }, [focus]);

  useEffect(() => {
    if (selectedWords.length) setSelectedWords([]);
  }, [search]);

  useEffect(() => {
    // make first item sleceted always
    if (words.length && !selectedWords.length && firstItemAlwaysSeleted) {
      setSelectedWords([0]);
    }
  }, [words, selectedWords]);

  const handleScroll = e => {
    if (!loadWords) return;
    const el = e.target;
    if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
      loadWords();
    }
  };

  return (
    <div
      className="list"
      onKeyDown={e => {
        if ((e.ctrlKey && e.key === 'a') || (e.metaKey && e.key === 'a')) {
          e.preventDefault();
          selectAll();
        }
        if (e.key === 'ArrowDown') {
          focusNext();
        }
        if (e.key === 'ArrowUp') {
          focusPrev();
        }
      }}
    >
      <h4 className="list__title">{title}</h4>
      <ListControls
        search={search}
        setSearch={setSearch}
        setFocus={setFocus}
        listName={listName}
        toggleSort={toggleSort}
        sortDir={sortDir}
        letterCase={letterCase}
        toggleLetterCase={toggleLetterCase}
        removeDuplicates={removeDuplicates}
        selectedItems={selectedWords}
        deleteSelected={deleteSelected}
        dark={listName === 'listB'}
      />

      <div
        style={{ height: size * 22 + 20 }}
        className="list__list-wrapper shadow-sm border bg-white"
      >
        <div
          style={{ height: size * 22 }}
          className="list__list"
          onClick={e => {
            e.target.focus();
            !search && setFocus(words.length);
          }}
          onScroll={handleScroll}
        >
          {words.concat(editable ? [''] : []).map(
            (word, index) =>
              (!search || (search && word.includes(search))) && (
                <div className={`list__option`} key={index}>
                  <input
                    placeholder={!words.length && index === 0 ? 'type words here...' : ''}
                    autoFocus={!words.length ? true : false}
                    autoComplete={'off'}
                    id={`${listName}-option-${index}`}
                    className={`list__option--editable ${
                      selectedWords.includes(index) ? 'selected' : ''
                    } ${focus === index ? 'focus' : ''} ${!editable ? 'cursor-pointer' : ''}`}
                    value={word}
                    onMouseDown={e => {
                      if (editable && e.ctrlKey) handleSelectToggle(index);
                      else if (editable && !e.ctrlKey) deslectAll();
                      else handleSelectToggle(index);
                    }}
                    onClick={e => {
                      e.stopPropagation();
                      setFocus(index);
                    }}
                    onChange={e => {
                      handleOptionUpdate(e, index);
                    }}
                    onKeyDown={e => {
                      if ((e.ctrlKey && e.key === 'a') || (e.metaKey && e.key === 'a'))
                        e.preventDefault();
                      if (e.ctrlKey && e.key === 'Enter' && focus !== words.length)
                        handleSelectToggle(index);

                      if (!e.ctrlKey && e.key === 'Enter' && focus < words.length) focusNext();
                    }}
                  />
                </div>
              )
          )}
          <div className="p-3">
            {isLoading && loadWords && <Spinner type={'local'} color={'black'} />}
          </div>
        </div>
      </div>
      <div className="list__list--counter">
        <span>{selectedWords.length ? `${selectedWords.length} selected` : ''}</span>
        <span>
          {`${words.length} ${loadWords ? '/' + wordsCount : ''} word${
            words.length === 1 ? '' : 's'
          }`}
        </span>
      </div>
    </div>
  );
}

export default WordList;
