import React from 'react';
import IncludeSvg from 'components/common/IncludeSvg';

function StageTopControls({ cb, actions, activeAction, title, subTitle, subTitleLink, pageSize }) {
  return (
    <div className={`top-controls icon-btns shadow-sm d-flex justify-content-between h4`}>
      <div className="d-flex">
        {pageSize && (
          <div style={{ marginRight: 10 }}>
            Size:
            <span style={{ fontWeight: 'bold' }}>{' ' + pageSize.split(' ')[0]}</span>
            <span>{' ' + pageSize.split(' ')[1]}</span>
          </div>
        )}
        <div>{title}</div>
        {subTitleLink !== '#' && (
          <div className="text-italic mx-4">
            <a href={subTitleLink} target="_blank">
              {subTitle}
            </a>
          </div>
        )}
        {subTitleLink === '#' && <div className="text-italic mx-4">{subTitle}</div>}
      </div>
      <div className="d-flex">
        {actions.map(
          action =>
            !action.hidden && (
              <button
                disabled={action.disabled}
                key={action.name}
                className={`${activeAction === action.name && 'active'}`}
                onClick={() => cb(action.name)}
              >
                <IncludeSvg name={action.icon || action.name} />
              </button>
            )
        )}
      </div>
    </div>
  );
}

export default StageTopControls;
