import { useState, useEffect } from 'react';

export default function useTrigger() {
  const [isOn, setIsOn] = useState(false);
  useEffect(() => {
    let to;
    if (isOn) {
      to = setTimeout(() => setIsOn(false), 100);
    }
    return () => {
      clearTimeout(to);
    };
  }, [isOn]);

  return [isOn, () => setIsOn(true)];
}
