import 'react-slide-out/lib/index.css';

import React, { useEffect, useState } from 'react';

import Main from 'layouts/Main';
import ScrollableWindow from 'layouts/ScrollableWindow';
import SearchControls from 'components/common/SearchControls';
import Slider from 'components/common/Slider';
import TopControls from 'components/common/TopControls';
import { getTags } from 'api/common';
import { useGlobalContext } from 'state/hooks/global';
import { useParams } from 'react-router-dom';
import useTrigger from 'hooks/useTrigger';

export default function Elements() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoriesSortBy, setCategoriesSrotBy] = useState('most_popular');
  const [sortBy, setSortBy] = useState('most_popular');
  const [allowedTags, setAllowedTags] = useState([]);
  const [tagsArr, setTagsArr] = useState([]);
  const [tags, setTags] = useState(''); // formatted String
  const { elType } = useParams();
  const { getElementTypeID } = useGlobalContext();

  const [shouldLoadMore, triggerLoadMore] = useTrigger();

  useEffect(() => {
    setSelectedCategory(null);
  }, [elType]);

  useEffect(() => {
    if (!elType) return;
    getTags({
      kind: 'elements',
      element_type_id: getElementTypeID(elType),
      sort: 'alpha',
    }).then(res => {
      if (res.success) {
        setAllowedTags(res.tags);
      } else {
        console.log(res.errors);
      }
    });
  }, [elType]);

  useEffect(() => {
    let str = '';
    tagsArr.forEach(tag => {
      const index = allowedTags.findIndex(
        allowedTag => allowedTag.name.toLowerCase() === tag.name.toLowerCase()
      );
      if (index > -1) {
        str += `&tag=${allowedTags[index].id}`;
      }
    });
    setTags(str);
  }, [tagsArr]);

  return (
    <div className="elements-page">
      <Main>
        <TopControls
          btns={[
            {
              text: 'Elements',
              path: `/elements/${elType}`,
              selected: true,
            },
            {
              text: 'Manage Elements',
              path: `/elements/${elType}/manage`,
            },
          ]}
        />

        <div className="resources">
          <Slider
            key={elType}
            title={'Categories'}
            type={'category'}
            kind={'elements'}
            elementType={elType}
            selectable={true}
            items={categories}
            selectedItem={selectedCategory}
            setSelectedItem={setSelectedCategory}
            setItems={setCategories}
            sortBy={categoriesSortBy}
            setCategoriesSortBy={setCategoriesSrotBy}
            shouldLoadMore={shouldLoadMore}
          />
          <div>
            <SearchControls
              type={'element'}
              allowedTags={allowedTags}
              tagsArr={tagsArr}
              setTagsArr={setTagsArr}
              sortBy={sortBy}
              setSortBy={setSortBy}
              showing={(selectedCategory && selectedCategory.title) || 'All'}
            />
            <ScrollableWindow onScrollEnd={triggerLoadMore}>
              {!selectedCategory &&
                categories.map(category => (
                  <Slider
                    key={category.id}
                    id={category.id}
                    title={category.title}
                    type={'element'}
                    elementType={elType}
                    category={category}
                    tags={tags}
                    sortBy={sortBy}
                    setSelectedItem={setSelectedCategory}
                    uncollapsed={false}
                  />
                ))}
            </ScrollableWindow>

            {selectedCategory && (
              <Slider
                uncollapsed
                key={selectedCategory.id}
                id={selectedCategory.id}
                title={selectedCategory.title}
                type={'element'}
                elementType={elType}
                category={selectedCategory}
                tags={tags}
                sortBy={sortBy}
                setSelectedItem={setSelectedCategory}
              />
            )}
          </div>
        </div>
      </Main>
    </div>
  );
}
