import React from 'react';
import IncludeSvg from 'components/common/IncludeSvg';
import { useSelector } from 'react-redux';

function Header() {
  const { user } = useSelector(state => state.auth);
  return (
    <>
      <div className="welcome-msg">
        <span className="msg">Hi {user && user.first_name}</span>
        <span>
          {user.profile_picture && (
            <img className="profile-pic" src={user.profile_picture} alt="profile" />
          )}
          {!user.profile_picture && <IncludeSvg name={'avatar'} />}
        </span>
      </div>
    </>
  );
}

export default Header;
