import React from 'react';
import TWordList from './lists/TWordList';
import TNumberList from './lists/TNumberList';

const TList = React.memo(props => {
  return (
    <>
      {props.isNumberList && <TNumberList {...props} />}
      {!props.isNumberList && <TWordList {...props} />}
    </>
  );
});

export default TList;
