import React from 'react';
import SideMenuItem from './SideMenuItem';
import { useGlobalContext } from 'state/hooks/global';
import { useLocation } from 'react-router-dom';
import { hasAccess } from 'helpers/permissions';
import { useSelector } from 'react-redux';

function SideMenu({ activeSpace, activeMenu, activeTab }) {
  const { user } = useSelector(state => state.auth);
  const location = useLocation();
  const pathname = location.pathname;
  const { languages, elementTypes } = useGlobalContext();
  const sideMenuItems = {
    home: [
      {
        name: 'formats',
        route: hasAccess('/home/formats', user) ? '/home/formats' : '/home/formats/manage',
        subPaths: ['/home/formats', '/home/formats/manage'],
      },
      { name: 'templates', route: '/home/templates', subPaths: ['/home/templates'] },
      { name: 'manuscripts', route: '/home/manuscripts', subPaths: ['/home/manuscripts'] },
      {
        name: 'covers',
        route: hasAccess('/home/covers', user) ? '/home/covers' : '/home/covers/manage',
        subPaths: ['/home/covers', '/home/covers/manage'],
      },
    ],
    library: languages.map(lang => ({
      name: lang.name,
      route: `/library/${lang.name.replaceAll(' ', '-').toLowerCase()}/${activeTab}`,
      subPaths: ['/library/:language/word-lists', '/library/:language/trivia-lists'],
    })),
    elements: elementTypes.map(type => ({
      name: type.name,
      route: hasAccess(`/elements/${type.name.replaceAll(' ', '-').toLowerCase()}`, user)
        ? `/elements/${type.name.replaceAll(' ', '-').toLowerCase()}`
        : `/elements/${type.name.replaceAll(' ', '-').toLowerCase()}/manage`,
      subPaths: [
        `/elements/${type.name.replaceAll(' ', '-').toLowerCase()}`,
        `/elements/${type.name.replaceAll(' ', '-').toLowerCase()}/manage`,
      ],
    })),
    variables: languages.map(lang => ({
      name: lang.name,
      route: `/variables/${lang.name.replaceAll(' ', '-').toLowerCase()}${
        activeTab ? '/' + activeTab : ''
      }`,
    })),
    settings: [
      { name: 'content', route: '/settings/content' },
      { name: 'analytics', route: '/settings/analytics' },
      { name: 'accounts', route: '/settings/accounts' },
      { name: 'languages', route: '/settings/languages' },
      { name: 'tags', route: '/settings/tags/studio' },
    ],
  };

  let sideMenuItemsWithPermissions = sideMenuItems[activeSpace].filter(item => {
    return item.subPaths
      ? item.subPaths.some(path => hasAccess(path, user))
      : hasAccess(item.route, user);
  });

  if (user.is_staff && pathname.includes('/settings')) {
    sideMenuItemsWithPermissions = sideMenuItemsWithPermissions.concat({
      name: 'accounts',
      route: '/settings/accounts',
    });
  }

  return (
    <div className={`side-menu ${sideMenuItemsWithPermissions.length > 0 ? '' : 'd-none'}`}>
      {sideMenuItemsWithPermissions.map((item, index) => (
        <SideMenuItem
          key={index}
          name={item.name}
          route={item.route}
          active={
            pathname === item.route ||
            pathname.includes(`/${item.name.replaceAll(' ', '-').toLowerCase()}`)
          }
        />
      ))}
    </div>
  );
}

export default SideMenu;
