import React, { useState, useEffect } from 'react';
import VersionList from './VersionList';
import Button from 'components/common/Button';
import { useHistory } from 'react-router-dom';

import Tags from 'components/common/Tags';
import { getOneVersion } from 'api/variables';
import { useSelector } from 'react-redux';
function VersionViewer({ isSwap = false, selectedVersion, allowedTags, language }) {
  const { user } = useSelector(state => state.auth);
  const canChangeVariables = user?.resources_permissions?.variable?.change_variable;
  const [tagsArr, setTagsArr] = useState([]);
  const history = useHistory();
  const [listA, setListA] = useState([]);

  useEffect(() => {
    setTagsArr([]);
    setListA([]);
    if (selectedVersion) {
      getOneVersion({ id: selectedVersion.version_id, isSwap }).then(res => {
        if (res.success) setListA(res.version.items);
        setTagsArr(
          res.version.tags
            .map(id => allowedTags.find(tag => tag.id === id))
            .filter(tag => tag !== undefined) // in case category deleted
        );
      });
    }
  }, [selectedVersion]);

  return (
    <div
      className="version-viewer shadow-sm"
      style={{
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 10,
        marginTop: 20,
      }}
    >
      <div className=" d-flex justify-content-space-between">
        <div className="d-flex" style={{ width: '100%' }}>
          <VersionList
            key="listA"
            listName="listA"
            title="Word Search"
            paragraphs={listA}
            setParagraphs={setListA}
            selectedParagraphs={[]}
            setSelectedParagraphs={() => {}}
            size={8}
            isReadOnly={true}
            selectable={false}
          />
        </div>
        <div style={{ width: 50 }}></div>
        <div className="d-flex flex-column" style={{ width: '100%', marginTop: 3 }}>
          <Tags
            title={'Tags'}
            allowedTags={allowedTags}
            tagsArr={tagsArr}
            setTagsArr={setTagsArr}
            classNameA={'w-100'}
            classNameB={'border shadow-sm px-3 py-2'}
            inputClassName={'border shadow-sm w-100'}
            height={50}
            inputPlaceholder={'Teens, Adults, Seasons,... etc'}
            readOnly={true}
          />
          {canChangeVariables && (
            <div className="d-flex justify-content-center">
              <Button
                label="Edit Variable"
                className="btn-blue-forms"
                onClick={() => history.push(`/variables/${language}/manage`)}
                style={{ height: 45, width: 150 }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default VersionViewer;
