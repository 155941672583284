export const getUniqueId = (prefix) => {
  const date = new Date();
  const components = [
      prefix,
      date.getYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
  ];

  return components.join("");
}