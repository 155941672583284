import { useHistory, useParams } from 'react-router-dom';
import { useOnClickOnEscape, useOnClickOutside } from 'hooks/util';
import { useRef, useState } from 'react';

import Confirm from 'components/common/Confirm';
import IncludeSvg from 'components/common/IncludeSvg';
import PopupMenu from 'components/common/PopupMenu';
import { useItemHandlers } from 'hooks/slider/index';
import usePopup from 'hooks/popup';

export default function Item({
  type,
  item,
  kind,
  language,
  elementType,
  border,
  sliderItems,
  setSliderItems,
  setItems,
  selectedItem,
  setSelectedItem,
  selectable,
  removeAddedNewItem,
  loadingEffect,
  setShowWordListEditor,
  setShowTriviaListEditor,
  setShowArticleListEditor,
  setShowStoryEditor,
  category,
  setOffset,
  index,
  setIsCreateMode,
  readOnly,
  perms,
  setLeft,
  handleEditItem,
}) {
  const history = useHistory();
  const itemRef = useRef(null);
  const [hilighted, setHilighted] = useState(false);
  const [editMode, setEditMode] = useState(!!item.editMode);
  const [itemTitle, setItemTitle] = useState(item.title || item.name || '');
  const [itemWidth, setItemWidth] = useState(item.width);
  const [itemHeight, setItemHeight] = useState(item.height);

  const [itemValue, setItemValue] = useState(item.value); // paper thickness

  const { elType } = useParams();

  const popupOPTs = [
    {
      title: 'Delete',
      handler: e => {
        setIsDeleteConfirmOpen(true);
        e.stopPropagation();
      },
      hidden: perms && !perms.canDelete,
    },
    {
      title: ['size', 'thickness', 'language'].includes(type) ? 'Edit' : 'Rename',
      handler: e => {
        e.stopPropagation();
        setPopup(false);
        setEditMode(true);
      },
      hidden: perms && !perms.canChange,
    },
  ];

  // add duplicate button to popup menu if type is format
  if (type === 'format') {
    popupOPTs.push(
      {
        title: 'Edit',
        handler: e => {
          e.stopPropagation();
          handleEdit();
        },
      },
      {
        title: 'Duplicate',
        handler: e => {
          e.stopPropagation();
          handleDuplicate();
        },
      }
    );
  }

  const { popupRef, popup, setPopup, popupOptions } = usePopup({
    popupOptions: popupOPTs,
    initialPopupState: !!item.showPopup,
  });

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const {
    handleDeleteItem,
    handleOnKeyUpForTitle,
    handleOnKeyUpForSize,
    handleOnKeyUpForThickness,
    handleOnClick,
    handleFavourite,
    handleDuplicate,
    handleEdit,
  } = useItemHandlers({
    type,
    item,
    index,
    sliderItems,
    setSliderItems,
    setItems,
    selectable,
    selectedItem,
    setSelectedItem,
    setPopup,
    setOffset,
    setShowWordListEditor,
    setShowTriviaListEditor,
    setShowArticleListEditor,
    setShowStoryEditor,
    setEditMode,
    setIsCreateMode,
    itemTitle,
    setItemTitle,
    itemWidth,
    itemHeight,
    itemValue,
    setHilighted,
    category,
    language,
    elementType,
    elType,
    kind,
    history,
    setLeft,
    handleEditItem,
  });

  useOnClickOutside(
    itemRef,
    e => {
      if (item.new) {
        removeAddedNewItem();
      }
    },
    { closest: true, className: 'new-item' }
  );

  useOnClickOnEscape(() => {
    setEditMode(false);
    if (item.new) removeAddedNewItem();
  });

  const popupCloseBtn = {
    handler: e => {
      e.stopPropagation();
      setItemTitle(item.title || item.name || '');
      setEditMode(false);
      setPopup(!popup);
    },
  };

  return (
    <div
      ref={itemRef}
      className={`item shadow-sm ${
        (selectable && selectedItem && item.id === selectedItem.id && !popup) || editMode
          ? 'active'
          : ''
      }  ${type === 'swap' ? 'swap' : ''} ${item.new ? 'new-item' : ''}`}
      onClick={handleOnClick}
    >
      {!loadingEffect && !readOnly && (
        <>
          {perms && perms.canChange && handleFavourite && (
            <div className="favourite" onClick={e => handleFavourite(e, item)}>
              <IncludeSvg name={item.is_favourite ? 'fav-star-filled' : 'fav-star'} />
            </div>
          )}
          {((perms && perms.canDelete) || (perms && perms.canChange)) && (
            <div
              className="options"
              onClick={e => {
                e.stopPropagation();
                // if (item.new) removeAddedNewItem();
                if (hilighted) setHilighted(false);
                if (editMode) setEditMode(false);
                setPopup(!popup);
              }}
            >
              <IncludeSvg name={'three-dots'} />
            </div>
          )}
        </>
      )}

      {border ? (
        <div
          className={`svg-wrapper ${type} ${
            selectable && selectedItem && selectedItem.id === item.id ? `selected` : ''
          } ${loadingEffect ? 'loading' : ''}`}
          style={
            type === 'element'
              ? {
                  backgroundImage: `url(${item.thumbnail})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundColor: 'white',
                  padding: 6,
                }
              : {}
          }
        >
          {type === 'element' ? (
            ''
          ) : (
            <div title={`${item.width && item.height ? `${item.width}" X ${item.height}"` : ''}`}>
              <IncludeSvg
                name={
                  type === 'element'
                    ? ''
                    : selectable && selectedItem && selectedItem.id === item.id
                    ? `${type}-open`
                    : type
                }
              />
            </div>
          )}
        </div>
      ) : (
        <IncludeSvg
          name={selectable && selectedItem && item.id === selectedItem.id ? `${type}-open` : type}
        />
      )}
      <div
        className={`title truncate-wrap ${loadingEffect ? 'loading' : ''}`}
        title={`${
          type === 'size'
            ? `${item.width}" X ${item.height}"`
            : type === 'thickness'
            ? `${item.value}"`
            : itemTitle || ''
        }`}
      >
        {editMode && type !== 'size' && type !== 'thickness' ? (
          <input
            className={'titleInputKey'}
            value={itemTitle}
            type="text"
            autoFocus={true}
            onChange={e => {
              setItemTitle(e.target.value);
            }}
            onKeyUp={handleOnKeyUpForTitle}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        ) : editMode && type === 'thickness' ? (
          <div className="d-felx">
            <input
              className="w-50 text-center"
              type="number"
              step="0.1"
              min="0.1"
              value={itemValue}
              placeholder="thick."
              onKeyUp={handleOnKeyUpForThickness}
              onChange={e => {
                setItemValue(e.target.value);
              }}
            />
          </div>
        ) : editMode && type === 'size' ? (
          <div className="d-felx">
            <input
              className="w-50 text-center"
              type="number"
              step="0.1"
              min="0.1"
              value={itemWidth}
              placeholder="W"
              onKeyUp={handleOnKeyUpForSize}
              onChange={e => {
                setItemWidth(e.target.value);
              }}
            />
            <span>x</span>
            <input
              className="w-50 text-center"
              type="number"
              step="0.1"
              min="0.1"
              value={itemHeight}
              placeholder="H"
              onKeyUp={handleOnKeyUpForSize}
              onChange={e => {
                setItemHeight(e.target.value);
              }}
            />
          </div>
        ) : (
          `${
            type === 'size'
              ? `${item.width}" X ${item.height}"`
              : type === 'thickness'
              ? `${item.value} mm`
              : itemTitle
          }`
        )}
      </div>
      {popup && (
        <PopupMenu
          popupRef={popupRef}
          options={popupOptions.filter(o => !o.hidden)}
          closeBtn={popupCloseBtn}
        />
      )}

      <Confirm
        isOpen={isDeleteConfirmOpen}
        setIsOpen={setIsDeleteConfirmOpen}
        title={'Are you sure?'}
        msg={`This ${type} will be deleted!`}
        confirmText={'Delete'}
        cancelText={'Cancel'}
        handleConfirm={handleDeleteItem}
        handleCancel={() => setIsDeleteConfirmOpen(false)}
      />
    </div>
  );
}
