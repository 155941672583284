import Main from 'layouts/Main';
import React, { useState, useEffect } from 'react';
import TopControls from 'components/common/TopControls';
// import SearchControls from 'components/common/SearchControls';
import Slider from 'components/common/Slider';
import { getTags } from 'api/common';
import { useParams } from 'react-router-dom';
import VersionsTable from 'components/variables/VersionsTable';
import VersionViewer from 'components/variables/VersionViewer';
import VersionEditor from 'components/variables/VersionEditor';
import { getVersionNames, addVersionName } from 'api/variables';
import { useSelector } from 'react-redux';
export default function Variables({ isManage, isSwaps }) {
  const { user } = useSelector(state => state.auth);
  const canChangeVariables = user?.resources_permissions?.variable?.change_variable;
  const [variables, setVariables] = useState([]);
  const [versionNames, setVersionNames] = useState([]);
  const [selectedVariable, setSelectedVariable] = useState(null);
  const [selectedVersionName, setSelectedVersionName] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [sortBy, setSortBy] = useState('most_popular');
  const [allowedTags, setAllowedTags] = useState([]);
  const [tagsArr] = useState([]);
  const [, setTags] = useState(''); // formatted String
  const { language } = useParams();
  const [left, setLeft] = useState(0);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [selectedTab, setSelectedTab] = useState('variables');

  // version Status
  // null: missing from all languages // false: missing here // true: exists
  const [selectedVersionStatus, setSelectedVersionStatus] = useState(null);

  const handleAddVersionName = title => {
    addVersionName({ data: { title }, isSwap: isSwaps }).then(res => {
      if (res.success) {
        setVersionNames([...versionNames, res.version_name]);
      }
    });
  };

  const handleVersionNameSelect = versionName => {
    if (!selectedVariable) return;
    else {
      setSelectedVersionName(versionName);
      if (Array.isArray(selectedVariable.versions))
        setSelectedVersion(
          selectedVariable.versions.find(v => v.version_name_id === versionName.id)
        );
      else setSelectedVersion(selectedVariable.versions[versionName.id]);
    }
  };

  const handleAfterVersionChange = (type, variable_id, version) => {
    const list = [...variables];
    const variableIndex = list.findIndex(v => v.id === variable_id);
    switch (type) {
      case 'delete': {
        const versionIndex = list[variableIndex].versions.findIndex(
          ver => ver.version_id === version.version_id
        );
        list[variableIndex].versions.splice(versionIndex, 1);
        setSelectedVersion(null);
        break;
      }
      case 'create':
        list[variableIndex].versions.push(version);
        break;
      default:
        alert('not supported change type (variables view)');
    }
    setVariables(list);
  };

  useEffect(() => {
    if (isManage) setSelectedTab('manage');
    else if (isSwaps) setSelectedTab('swaps');
    else setSelectedTab('variables');
  }, [isManage, isSwaps]);

  useEffect(() => {
    setSelectedVariable(null);
  }, [isSwaps]);

  useEffect(() => {
    getTags({ kind: 'variables', sort: 'alpha' }).then(res => {
      if (res.success) {
        setAllowedTags(res.tags);
      } else {
        console.log(res.errors);
      }
    });
  }, []);

  useEffect(() => {
    const isSwaps = selectedTab === 'swaps';
    getVersionNames().then(res => {
      if (res.success) setVersionNames(res.version_names.filter(v => v.is_swap === isSwaps));
    });
  }, [selectedTab]);

  useEffect(() => {
    let str = '';
    tagsArr.forEach(tag => {
      const index = allowedTags.findIndex(
        allowedTag => allowedTag.name.toLowerCase() === tag.name.toLowerCase()
      );
      if (index > -1) {
        str += `&tag=${allowedTags[index].id}`;
      }
    });
    setTags(str);
  }, [tagsArr, allowedTags]);

  return (
    <div className="elements-page">
      <Main>
        <TopControls
          btns={[
            {
              path: `/variables/${language}`,
              text: 'Variables',
              selected: selectedTab === 'variables',
              action: () => setSelectedTab('variables'),
            },
            {
              path: `/variables/${language}/manage`,
              text: 'Manage Variables',
              selected: selectedTab === 'manage',
              action: () => setSelectedTab('manage'),
              hidden: !canChangeVariables,
            },
            {
              path: `/variables/${language}/swaps`,
              text: 'Swaps',
              selected: selectedTab === 'swaps',
              action: () => setSelectedTab('swaps'),
            },
          ]}
        />

        <div className="resources">
          <div>
            <Slider
              title={selectedTab === 'swaps' ? 'Swaps' : 'Variables'}
              type={selectedTab === 'swaps' ? 'swap' : 'variable'}
              kind={selectedTab === 'swaps' ? 'swaps' : 'variables'}
              selectable={true}
              items={variables}
              selectedItem={selectedVariable}
              setSelectedItem={setSelectedVariable}
              setItems={setVariables}
              sortBy={sortBy}
              setCategoriesSortBy={setSortBy}
              syncParentLeft={setLeft}
              language={language}
              isCreateMode={isCreateMode}
              setIsCreateMode={setIsCreateMode}
            />

            <VersionsTable
              isSwap={selectedTab === 'swaps'}
              variables={variables}
              selectedVariable={selectedVariable}
              setSelectedVariable={setSelectedVariable}
              selectedVersion={selectedVersion}
              setSelectedVersion={setSelectedVersion}
              versionNames={versionNames}
              selectedVersionName={selectedVersionName}
              setSelectedVersionName={setSelectedVersionName}
              left={left}
              language={language}
              isCreateMode={isCreateMode}
              isManage={isManage}
              handleAddVersionName={handleAddVersionName}
              setSelectedVersionStatus={setSelectedVersionStatus}
              handleVersionNameSelect={handleVersionNameSelect}
            />

            {selectedVariable && selectedVersionName && selectedTab === 'variables' && (
              <VersionViewer
                selectedVersion={selectedVersion}
                allowedTags={allowedTags}
                language={language}
              />
            )}
            {selectedVariable && selectedVersionName && selectedTab === 'manage' && (
              <VersionEditor
                versionNames={versionNames}
                handleVersionNameSelect={handleVersionNameSelect}
                selectedVariable={selectedVariable}
                selectedVersionName={selectedVersionName}
                selectedVersion={selectedVersion}
                language={language}
                allowedTags={allowedTags}
                handleAfterVersionChange={handleAfterVersionChange}
                status={selectedVersionStatus}
              />
            )}

            {selectedVariable && selectedVersionName && selectedTab === 'swaps' && (
              <VersionEditor
                isSwap={true}
                versionNames={versionNames}
                handleVersionNameSelect={handleVersionNameSelect}
                selectedVariable={selectedVariable}
                selectedVersionName={selectedVersionName}
                selectedVersion={selectedVersion}
                language={language}
                allowedTags={allowedTags}
                handleAfterVersionChange={handleAfterVersionChange}
                status={selectedVersionStatus}
              />
            )}
          </div>
        </div>
      </Main>
    </div>
  );
}
