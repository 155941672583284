import 'react-slide-out/lib/index.css';

import React, { useEffect, useState } from 'react';

import Main from 'layouts/Main';
import ScrollableWindow from 'layouts/ScrollableWindow';
import SearchControls from 'components/common/SearchControls';
import Slider from 'components/common/Slider';
import StoryEditor from 'components/library/StoryEditor';
import { getTags } from 'api/common';
import { useParams } from 'react-router-dom';
import useTrigger from 'hooks/useTrigger';
import LibraryTopControls from 'components/library/LibraryTopControls';

export default function Stories() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoriesSortBy, setCategoriesSrotBy] = useState('most_popular');
  const [storiesSortBy, setStoriesSortBy] = useState('most_popular');
  const [allowedTags, setAllowedTags] = useState([]);
  const [tagsArr, setTagsArr] = useState([]);
  const [tags, setTags] = useState(''); // formatted String
  const [newItemFromSlideModal, setNewItemFromSlideModal] = useState(null);
  const { language } = useParams();
  const [showStoryEditor, setShowStoryEditor] = useState(false);
  const [offset, setOffset] = useState(null);

  const [shouldLoadMore, triggerLoadMore] = useTrigger();

  useEffect(() => {
    setSelectedCategory(null);
    setShowStoryEditor(false);
  }, [language]);

  useEffect(() => {
    getTags({ kind: 'library_story', sort: 'alpha' }).then(res => {
      if (res.success) {
        setAllowedTags(res.tags);
      } else {
        console.log(res.errors);
      }
    });
  }, []);

  useEffect(() => {
    let str = '';
    tagsArr.forEach(tag => {
      const index = allowedTags.findIndex(
        allowedTag => allowedTag.name.toLowerCase() === tag.name.toLowerCase()
      );
      if (index > -1) {
        str += `&tag=${allowedTags[index].id}`;
      }
    });
    setTags(str);
  }, [tagsArr]);

  return (
    <div className="library-page">
      <Main>
        <LibraryTopControls />
        <div className="resources">
          <Slider
            key={language}
            title={'Categories'}
            type={'category'}
            kind={'library_story'}
            language={language}
            selectable={true}
            items={categories}
            selectedItem={selectedCategory}
            setSelectedItem={setSelectedCategory}
            setItems={setCategories}
            sortBy={categoriesSortBy}
            setCategoriesSortBy={setCategoriesSrotBy}
            setShowStoryEditor={setShowStoryEditor}
            setOffset={setOffset}
            shouldLoadMore={shouldLoadMore}
          />

          <div>
            <SearchControls
              type={'story'}
              allowedTags={allowedTags}
              tagsArr={tagsArr}
              setTagsArr={setTagsArr}
              sortBy={storiesSortBy}
              setSortBy={setStoriesSortBy}
              showing={(selectedCategory && selectedCategory.title) || 'All'}
            />

            <ScrollableWindow onScrollEnd={triggerLoadMore}>
              {!selectedCategory &&
                categories.map(category => (
                  <Slider
                    key={category.id}
                    id={category.id}
                    title={category.title}
                    type={'story'}
                    category={category}
                    tags={tags}
                    sortBy={storiesSortBy}
                    setSelectedItem={setSelectedCategory}
                    setShowStoryEditor={setShowStoryEditor}
                    setOffset={setOffset}
                    newItemFromSlideModal={newItemFromSlideModal}
                  />
                ))}
            </ScrollableWindow>

            {selectedCategory && (
              <Slider
                uncollapsed
                key={selectedCategory.id}
                id={selectedCategory.id}
                title={selectedCategory.title}
                type={'story'}
                category={selectedCategory}
                tags={tags}
                sortBy={storiesSortBy}
                setSelectedItem={setSelectedCategory}
                setShowStoryEditor={setShowStoryEditor}
                setOffset={setOffset}
                newItemFromSlideModal={newItemFromSlideModal}
              />
            )}
          </div>

          {showStoryEditor && (
            <StoryEditor
              initIsNew={offset.value === null ? true : false}
              offset={offset}
              setOffset={setOffset}
              allowedTags={allowedTags}
              tagsToFilter={tags}
              sortBy={storiesSortBy}
              setSortBy={setStoriesSortBy}
              setShowStoryEditor={setShowStoryEditor}
              setNewItemFromSlideModal={setNewItemFromSlideModal}
            />
          )}
        </div>
      </Main>
    </div>
  );
}
