import WordList from './WordList';
import PopupMenu from 'components/common/PopupMenu';
import Tags from 'components/common/Tags';
import { capitalizeFirst } from 'helpers/text';
import { default as SlideModal } from 'react-slide-out';
import { useOnClickOnEscape } from 'hooks/util';
import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { addWordList, editWordList, getOneWordList, getWordLists } from 'api/library';
import { useGlobalContext } from 'state/hooks/global';
import IncludeSvg from 'components/common/IncludeSvg';
import { useOnClickOutside } from 'hooks/util';
import { getOneLanguage, editLanguage } from 'api/global';
import { useSelector } from 'react-redux';

import 'components/common/ListEditor.scss';

const WORDS_TO_LOAD = 200;

function WordListEditor({
  initIsNew,
  offset,
  setOffset,
  allowedTags,
  tagsToFilter,
  sortBy,
  setSortBy,
  isNegativeWordList,
  isCommonWordList,
  setNewItemFromSlideModal,
  language,
  setShowWordListEditor,
}) {
  const { user } = useSelector(state => state.auth);
  const canChangeWordlists = user?.resources_permissions?.wordlist?.change_wordlist;
  const [listName, setListName] = useState('');
  const [wordTheme, setWordTheme] = useState('');
  const [count, setCount] = useState(0);
  const [listA, setListA] = useState([]);
  const [listB, setListB] = useState([]);
  const [error, setError] = useState(false);
  const [id, setId] = useState(null);
  const [isNew, setIsNew] = useState(initIsNew);
  const [tagsArr, setTagsArr] = useState([]);
  const [popup, setPopup] = useState(false);
  const popupRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSavable, setIsSavable] = useState(true);
  const [showSlideModal, setShowSlideModal] = useState(
    isNegativeWordList || isCommonWordList ? false : true
  );

  useOnClickOutside(popupRef, () => setPopup(false));

  const [selectedWordsListA, setSelectedWordsListA] = useState([]);
  const [selectedWordsListB, setSelectedWordsListB] = useState([]);

  const [transferToATrig, setTransferToATrig] = useState({
    val: false,
    all: false,
  });
  const [transferToBTrig, setTransferToBTrig] = useState({
    val: false,
    all: false,
  });

  const [listOffset, setListOffset] = useState(0);
  const [listLimit, setListLimit] = useState(WORDS_TO_LOAD);
  const [wordsCount, setWordsCount] = useState(0);

  const listNameRef = useRef(null);

  const { setMsgs, languages, getLanguageID } = useGlobalContext();

  const transferToA = list => {
    setListA([...listA, ...list]);
  };

  const transferToB = list => {
    setListB([...listB, ...list]);
  };

  const transfer = (dir, all) => {
    switch (dir) {
      case 'toA':
        setTransferToATrig({ val: true, all });
        break;
      case 'toB':
        setTransferToBTrig({ val: true, all });
        break;
    }
  };

  const popupOptions = [
    {
      title: 'Alphabetically',
      value: 'alpha',
      handler: () => {
        setSortBy('alpha');
        setPopup(false);
      },
    },
    {
      title: 'Recently Added',
      value: 'recently_added',
      handler: () => {
        setSortBy('recently_added');
        setPopup(false);
      },
    },
    {
      title: 'Most Popular',
      value: 'most_popular',
      handler: () => {
        setSortBy('most_popular');
        setPopup(false);
      },
    },
  ];

  const loadWords = listID => {
    if (isLoading || (!listID && listOffset >= wordsCount)) return;
    setIsLoading(true);
    getOneWordList(listID || id, { listOffset: listID ? 0 : listOffset, listLimit }).then(res => {
      if (res.success) {
        setListName(res.word_list.title);
        setWordTheme(res.word_list.word_theme || '');
        const loadedWords =
          typeof res.word_list.items === 'object' && res.word_list.items !== null
            ? res.word_list.items.sort().map(word => word.toUpperCase())
            : [];

        if (listID) setListA([...loadedWords]);
        else setListA(listA => [...listA, ...loadedWords]);

        setListB(
          typeof res.word_list.draft_items === 'object' && res.word_list.draft_items !== null
            ? res.word_list.draft_items.map(word => word.toUpperCase())
            : []
        );
        setWordsCount(res.word_list_items_count);
        setListOffset(listOffset => Math.min(listOffset + listLimit, res.word_list_items_count));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const handleNext = () => {
    if (offset.value === null) {
      setOffset({ category_id: offset.category_id, value: 0 });
      setIsNew(false);
    } else if (offset.value < count - 1)
      setOffset({ category_id: offset.category_id, value: offset.value + 1 });
  };

  const handlePrev = () => {
    if (offset.value === 0) {
      setIsNew(true);
      setOffset({ category_id: offset.category_id, value: null });
    } else if (offset.value > 0)
      setOffset({ category_id: offset.category_id, value: offset.value - 1 });
  };

  const handleSave = () => {
    if (!isNegativeWordList && !isCommonWordList) {
      if (!listName.trim()) {
        setError(true);
        setListName('');
        listNameRef.current.focus();
        setMsgs([{ type: 'error', content: 'List name is missing!' }]);
        return;
      }

      const data = {
        title: listName.trim(),
        word_theme: wordTheme.trim(),
        tags: tagsArr.map(tag => tag.id),
        created_by: 2, // TEMP Fix Later Backend should Handle
        items: listA,
        draft_items: listB,
        is_published: true,
        loaded_words: listOffset,
      };

      if (isNew) {
        data['category'] = offset.category_id;
        addWordList(data).then(res => {
          if (res.success) {
            setIsNew(false);
            setId(res.word_list.id);
            setMsgs([{ type: 'success', content: 'List saved successfully!' }]);
            setNewItemFromSlideModal(res.word_list);
            return loadWords(res.word_list.id);
          }
        });
      } else {
        editWordList(data, id).then(res => {
          if (res.success) {
            setId(res.word_list.id);
            setMsgs([{ type: 'success', content: 'Word list is updated!' }]);
            return loadWords(res.word_list.id);
          }
        });
      }
      setIsSavable(false);
    } else if (isNegativeWordList) {
      editLanguage(
        {
          negative_words: listA,
          draft_negative_words: listB,
        },
        getLanguageID(language)
      ).then(res => {
        if (res.success)
          setMsgs([
            {
              type: 'success',
              content: `${capitalizeFirst(language)} negative words are updated!`,
            },
          ]);
      });
    } else if (isCommonWordList) {
      editLanguage(
        {
          common_words: listA,
          draft_common_words: listB,
        },
        getLanguageID(language)
      ).then(res => {
        if (res.success)
          setMsgs([
            {
              type: 'success',
              content: `${capitalizeFirst(language)} common words are updated!`,
            },
          ]);
      });
    }
  };

  useEffect(() => {
    setIsSavable(true);
  }, [listA, listB, tagsArr]);

  useEffect(() => {
    setIsNew(initIsNew);
  }, [initIsNew]);

  useEffect(() => {
    if (isNegativeWordList || isCommonWordList) return;
    setId(offset.item_id);
    loadWords(offset.item_id);
  }, [offset]);

  useEffect(() => {
    if (isNegativeWordList && languages.length) {
      const id = getLanguageID(language);
      getOneLanguage(id).then(res => {
        if (res.success) {
          setListA(
            res.language.negative_words
              ? JSON.parse(res.language.negative_words.replace(/'/g, '"'))
              : []
          );
          setListB(
            res.language.draft_negative_words
              ? JSON.parse(res.language.draft_negative_words.replace(/'/g, '"'))
              : []
          );
        }
      });
    } else if (isCommonWordList && languages.length) {
      const id = getLanguageID(language);
      getOneLanguage(id).then(res => {
        if (res.success) {
          setListA(
            res.language.common_words
              ? JSON.parse(res.language.common_words.replace(/'/g, '"'))
              : []
          );
          setListB(
            res.language.draft_common_words
              ? JSON.parse(res.language.draft_common_words.replace(/'/g, '"'))
              : []
          );
        }
      });
    }
  }, [language, languages.length]);

  const titleOf = objVal => {
    return popupOptions.find(option => option.value === objVal).title;
  };

  const closeSlideModal = () => {
    setShowSlideModal(false);
    setShowWordListEditor(false);
  };

  useOnClickOnEscape(closeSlideModal);

  const bulkUploadInputRef = useRef();

  const showFileSelector = () => {
    bulkUploadInputRef.current && bulkUploadInputRef.current.click();
  };

  const filterInput = value => {
    return value.replace(/[0-9]/g, '').trimStart();
  };

  const handleBulkUpload = e => {
    const fileInput = e.target;
    const file = fileInput.files[0];
    setListName(file.name.split('.')[0]);

    if (file) {
      const reader = new FileReader();

      reader.onload = function (e) {
        const contents = e.target.result;
        const words = contents.split('\n');

        // inject words in listB
        setListB(words.map(filterInput));
      };

      reader.readAsText(file);
    }
  };

  return showSlideModal ? (
    <SlideModal isOpen={showSlideModal} onOutsideClick={closeSlideModal}>
      <div className="pt-5 bg-white" style={{ height: '100%' }}>
        <button className="form__spec--close-btn" onClick={closeSlideModal}>
          x
        </button>
        {RenderTheEditor({
          isNegativeWordList,
          isCommonWordList,
          listNameRef,
          popupRef,
          listName,
          setListName,
          wordTheme,
          setWordTheme,
          error,
          setPopup,
          popup,
          titleOf,
          sortBy,
          popupOptions,
          offset,
          count,
          isLoading,
          handlePrev,
          handleNext,
          isNew,
          language,
          listA,
          setListA,
          selectedWordsListA,
          setSelectedWordsListA,
          transferToB,
          transferToBTrig,
          setTransferToBTrig,
          selectedWordsListB,
          transfer,
          listB,
          bulkUploadInputRef,
          handleBulkUpload,
          showFileSelector,
          setListB,
          setSelectedWordsListB,
          transferToA,
          transferToATrig,
          setTransferToATrig,
          allowedTags,
          tagsArr,
          setTagsArr,
          canChangeWordlists,
          isSavable,
          handleSave,
          wordsCount,
          loadWords,
        })}
      </div>
    </SlideModal>
  ) : (
    RenderTheEditor({
      isNegativeWordList,
      isCommonWordList,
      listNameRef,
      popupRef,
      listName,
      setListName,
      error,
      setPopup,
      popup,
      titleOf,
      sortBy,
      popupOptions,
      offset,
      count,
      isLoading,
      handlePrev,
      handleNext,
      isNew,
      language,
      listA,
      setListA,
      selectedWordsListA,
      setSelectedWordsListA,
      transferToB,
      transferToBTrig,
      setTransferToBTrig,
      selectedWordsListB,
      transfer,
      listB,
      bulkUploadInputRef,
      handleBulkUpload,
      showFileSelector,
      setListB,
      setSelectedWordsListB,
      transferToA,
      transferToATrig,
      setTransferToATrig,
      allowedTags,
      tagsArr,
      setTagsArr,
      canChangeWordlists,
      isSavable,
      handleSave,
      wordsCount,
      loadWords,
    })
  );
}

export default WordListEditor;

function RenderTheEditor({
  isNegativeWordList,
  isCommonWordList,
  listNameRef,
  popupRef,
  listName,
  setListName,
  wordTheme,
  setWordTheme,
  error,
  setPopup,
  popup,
  titleOf,
  sortBy,
  popupOptions,
  offset,
  count,
  isLoading,
  handlePrev,
  handleNext,
  isNew,
  language,
  listA,
  setListA,
  selectedWordsListA,
  setSelectedWordsListA,
  transferToB,
  transferToBTrig,
  setTransferToBTrig,
  selectedWordsListB,
  transfer,
  listB,
  bulkUploadInputRef,
  handleBulkUpload,
  showFileSelector,
  setListB,
  setSelectedWordsListB,
  transferToA,
  transferToATrig,
  setTransferToATrig,
  allowedTags,
  tagsArr,
  setTagsArr,
  canChangeWordlists,
  isSavable,
  handleSave,
  wordsCount,
  loadWords,
}) {
  return (
    <div className="list-editor">
      <div className="list-editor__body">
        <div className="d-flex flex-column w-100 my-4">
          {!isNegativeWordList && !isCommonWordList && (
            <WordListEditorHeader
              ref={{ listNameRef, popupRef }}
              listName={listName}
              setListName={setListName}
              error={error}
              setPopup={setPopup}
              popup={popup}
              titleOf={titleOf}
              sortBy={sortBy}
              popupOptions={popupOptions}
              offset={offset}
              count={count}
              isLoading={isLoading}
              handlePrev={handlePrev}
              handleNext={handleNext}
              wordTheme={wordTheme}
              setWordTheme={setWordTheme}
            />
          )}
          <WordList
            key="listA"
            listName="listA"
            title={
              isNew
                ? `New List`
                : isNegativeWordList
                ? `${capitalizeFirst(language)} negative words`
                : isCommonWordList
                ? `${capitalizeFirst(language)} common words`
                : ``
            }
            words={listA}
            setWords={setListA}
            selectedWords={selectedWordsListA}
            setSelectedWords={setSelectedWordsListA}
            size={isNegativeWordList || isCommonWordList ? 30 : 30}
            editable={false}
            transfer={transferToB}
            transferTrig={transferToBTrig}
            setTransferTrig={setTransferToBTrig}
            offset={offset}
            isLoading={isLoading}
            wordsCount={wordsCount}
            loadWords={loadWords}
          />
        </div>

        <div className="list-editor__body--contorls">
          <button
            disabled={!selectedWordsListB.length}
            onClick={() => transfer('toA', false)}
            className="blue shadow-sm"
          >
            &lsaquo;
          </button>
          <button
            disabled={!listB.length}
            onClick={() => transfer('toA', true)}
            className="blue shadow-sm"
          >
            &laquo;{' '}
          </button>
          <button
            disabled={!selectedWordsListA.length}
            onClick={() => transfer('toB', false)}
            className="white shadow-sm"
          >
            &rsaquo;
          </button>
          <button
            disabled={!listA.length}
            onClick={() => transfer('toB', true)}
            className="white shadow-sm"
          >
            &raquo;{' '}
          </button>
        </div>

        <div className="d-flex flex-column w-100 dark p-4">
          <div className="list-title">WORD LISTS</div>
          <input
            ref={bulkUploadInputRef}
            type="file"
            className="d-none"
            onChange={handleBulkUpload}
          />
          <button onClick={showFileSelector} className="bulk-upload-btn">
            BULK UPLOAD
          </button>
          <div className="p-4 borderd-sec shadow">
            <WordList
              firstItemAlwaysSeleted
              key="listB"
              listName="listB"
              title={`${
                isNegativeWordList
                  ? 'Add more negative words'
                  : isCommonWordList
                  ? 'Add more common words'
                  : 'Type or paste words'
              }`}
              words={listB}
              setWords={setListB}
              selectedWords={selectedWordsListB}
              setSelectedWords={setSelectedWordsListB}
              size={isNegativeWordList ? 22 : 13}
              editable={true}
              transfer={transferToA}
              transferTrig={transferToATrig}
              setTransferTrig={setTransferToATrig}
              offset={offset}
              isLoading={isLoading}
            />
          </div>

          {!isNegativeWordList && !isCommonWordList && (
            <div
              className="p-4 borderd-sec shadow mt-4 form__spec--controls"
              style={{ marginRight: 0 }}
            >
              <Tags
                title={'Tags'}
                allowedTags={allowedTags}
                tagsArr={tagsArr}
                setTagsArr={setTagsArr}
                lassNameA={'form-group'}
                classNameB={''}
                inputClassName={'form-control light'}
                height={'4.1667rem'}
                inputPlaceholder={'Teens, Adults, Seasons,... etc'}
              />
            </div>
          )}
          <button
            style={{ visibility: canChangeWordlists ? 'visible' : 'hidden' }}
            disabled={!isSavable}
            onClick={handleSave}
            className="purple w-25 align-self-center"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

const WordListEditorHeader = forwardRef(
  (
    {
      listName,
      setListName,
      wordTheme,
      setWordTheme,
      error,
      setPopup,
      popup,
      titleOf,
      sortBy,
      popupOptions,
      offset,
      count,
      isLoading,
      handlePrev,
      handleNext,
    },
    ref
  ) => {
    const { listNameRef, popupRef } = ref;
    return (
      <div className="list-editor__controls flex-column">
        <div className="list-editor__controls--name shadow-sm">
          <input
            ref={listNameRef}
            value={listName}
            onChange={e => setListName(e.target.value)}
            className={error ? 'error w-100' : 'w-100'}
            placeholder="List name here..."
          />
          <div
            className="group noselect sort-by ml-2"
            onClick={() => {
              setPopup(!popup);
            }}
          >
            <span className="variable">{titleOf(sortBy)}</span>
            <span className={`${popup ? 'rotate-180' : ''}`}>
              <IncludeSvg name="arrow-drop-down" />
            </span>
            {popup && <PopupMenu popupRef={popupRef} options={popupOptions} />}
          </div>
        </div>
        <div className="word-theme">
          <label>Word Theme:</label>
          <input
            value={wordTheme}
            onChange={e => setWordTheme(e.target.value)}
            placeholder="Word Theme here..."
          />
        </div>
      </div>
    );
  }
);
