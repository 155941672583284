export const PAGE_SELECTOR_MODES = [
  {
    label: 'Single page',
    value: 'single',
  },
  {
    label: 'Range',
    value: 'range',
  },
];

export const PAGE_SELECTOR_MODES_MANUSCRIPT = [
  ...PAGE_SELECTOR_MODES,
  {
    label: '2 Ranges',
    value: '2-ranges',
  },
];

export const PAGE_SELECTOR_HAND_SIDES = [
  {
    label: 'Left & Right',
    value: 0,
  },
  {
    label: 'Left',
    value: 1,
  },
  {
    label: 'Right',
    value: 2,
  },
];
