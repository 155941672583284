import React, { useRef } from 'react';
import IncludeSvg from 'components/common/IncludeSvg';

function CheckboxLock({checked, disabled, onChange, className }) {
  const inputRef = useRef();
  return (
    <>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span
        className={className}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: disabled ?.6 : 1,
        }}
        onClick={() => {
          inputRef.current.click();
        }}
      >
        {checked && <IncludeSvg name="check-lock" />}
      </span>
    </>
  );
}

export default CheckboxLock;
